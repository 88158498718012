import { createSlice, createAction } from "@reduxjs/toolkit";

export const push = createAction("router/push", payload => {
  return {
    payload: {
      pathname: payload.pathname || payload,
      state: payload.state || null
    }
  };
});
export const replace = createAction("router/replace");
export const go = createAction("router/go");
export const goBack = createAction("router/goBack");
export const goForward = createAction("router/goForward");

const slice = createSlice({
  name: "router",
  initialState: {
    pathname: "/",
    state: null
  },
  reducers: {
    locationChange: (location, action) => {
      const { pathname, state } = action.payload;
      location.pathname = pathname;
      location.state = state;
    }
  }
});

export const { locationChange } = slice.actions;

export default slice.reducer;
