import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import { styled } from '@mui/material/styles';
import Grid from "@mui/material/Grid";

const GridElement = styled(Grid)(({ theme }) => ({
  margin: "0 -15px",
  width: "calc(100% + 30px)"
}))

function GridContainer({ ...props }) {
  const { children, className, ...rest } = props;
  return (
    <GridElement container {...rest} className={className}>
      {children}
    </GridElement>
  );
}

GridContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

export default GridContainer;
