import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  forwardSection: {
    category: '',
    id: ''
  }
};

const slice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setForwardSection: (state, action) => {
      state.forwardSection = {...action.payload};
    },

    resetForwardSection: (state) => {
      state.forwardSection = {};
    },
  },
});

export const notificationForwardSection = (state) => state.UI.notifications.forwardSection;

export const { setForwardSection, resetForwardSection } = slice.actions;

export default { name: slice.name, reducer: slice.reducer };
