import { apis } from "./base";

const remindersService = apis.injectEndpoints({
  endpoints: (builder) => ({
    // Reminder types
    getAllReminderTypes: builder.query({
      query: () => {
        return `/admin/reminder/types/all`;
      },
      transformResponse: (response) => response.response,
      providesTags: ["ReminderTypes"],
    }),

    postReminderType: builder.mutation({
      query: (data) => ({
        url: `/admin/reminder/types/add`,
        method: "POST",
        body: data,
      }),
      // transformResponse: (response) => response.response
      invalidatesTags: ["ReminderTypes"],
    }),

    putReminderType: builder.mutation({
      query: ({reminderTypeId, data}) => ({
        url: `/admin/reminder/types/edit/${reminderTypeId}`,
        method: "PUT",
        body: data,
      }),
      // transformResponse: (response) => response.response
      invalidatesTags: ["ReminderTypes"],
    }),

    deleteReminderType: builder.mutation({
      query: (id) => ({
        url: `/admin/reminder/types/delete/${id}`,
        method: "DELETE",
      }),
      transformResponse: (response) => response.response,
      invalidatesTags: ["ReminderTypes"],
    }),

    // Upcoming reminders
    getAllUpcomingReminders: builder.query({
      query: () => {
        return `/admin/reminder/get-upcoming-reminders`;
      },
      transformResponse: (response) => response.response.sort((a, b) => b.id - a.id),
      providesTags: ["Reminders"],
    }),


    // Reminder history
    getAllPastReminders: builder.query({
      query: () => {
        return `/admin/reminder/get-reminder-history`;
      },
      transformResponse: (response) => response.response.sort((a, b) => b.id - a.id),
      providesTags: ["Reminders"],
    }),

    // Custom reminders
    getAllCustomReminders: builder.query({
      query: () => {
        return `/admin/reminder/custom/all`;
      },
      transformResponse: (response) => response.response.sort((a, b) => b.id - a.id),
      providesTags: ["Reminders"],
    }),

    postCustomReminder: builder.mutation({
      query: (data) => ({
        url: `/admin/reminder/custom/add`,
        method: "POST",
        body: data,
      }),
      // transformResponse: (response) => response.response
      invalidatesTags: ["Reminders"],
    }),

    putCustomReminder: builder.mutation({
      query: ({id, data}) => ({
        url: `/admin/reminder/custom/edit/${id}`,
        method: "PUT",
        body: data,
      }),
      // transformResponse: (response) => response.response
      invalidatesTags: ["Reminders"],
    }),

    deleteCustomReminder: builder.mutation({
      query: (id) => ({
        url: `/admin/reminder/custom/delete/${id}`,
        method: "DELETE",
      }),
      transformResponse: (response) => response.response,
      invalidatesTags: ["Reminders"],
    }),
  }),
});

export const {
  useGetAllReminderTypesQuery,
  useLazyGetAllReminderTypesQuery,
  usePostReminderTypeMutation,
  usePutReminderTypeMutation,
  useDeleteReminderTypeMutation,
  useGetAllUpcomingRemindersQuery,
  useGetAllPastRemindersQuery,
  useGetAllCustomRemindersQuery,
  usePostCustomReminderMutation,
  usePutCustomReminderMutation,
  useDeleteCustomReminderMutation,
} = remindersService;
