/* eslint-disable react/prop-types */
import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";

import { Notify } from "notiflix/build/notiflix-notify-aio";

import { styled } from "@mui/material/styles";

import PropTypes from "prop-types";

import moment from "moment";

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import MDButton from "components/MDButton";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";

import MDBadge from "components/MDBadge";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import Button from "@mui/material/Button";

import DataTable from "examples/Tables/DataTable";

import { Loading } from "notiflix/build/notiflix-loading-aio";

import Alert from 'components/CustomAlert';

import { useLazyGetAllOrdersByCompanyQuery } from "services/orders";

import { globalCountry } from "store/entities/countries";

import { dateFormat } from "utils/constants";

import OrderDetails from 'views/Orders/OrderDetails'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

import MDBox from "components/MDBox";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const OrderHistory = ({ company }) => {

  const selectedCountry = useSelector(globalCountry);

  const [open, setOpen] = useState(false);

  const [
    getOrdersByCompanyId,
    { data: orderHistory = [], isLoading: isLoadingOrders, isSuccess: isSuccessOrders },
  ] = useLazyGetAllOrdersByCompanyQuery();

  const handleClickOpen = () => {
    setOpen(true);

    getOrdersByCompanyId(company.id);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    isLoadingOrders
      ? Loading.dots({
          svgColor: "rgba(159, 3, 3, 0.8)",
        })
      : Loading.remove(500);
  }, [isLoadingOrders]);

  return (
    <>
      <Tooltip title="View past orders" placement="top">
        <MDButton
          variant="contained"
          size="small"
          color="info"
          sx={{
            ml: 1,
            color: "#fff",
            fontSize: "10px!important",
            fontWeight: "500",
            letterSpacing: '0.5px'
          }}
          onClick={handleClickOpen}
        >
          Order history
        </MDButton>
      </Tooltip>
      <BootstrapDialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        maxWidth="lg"
        sx={{
          "& .MuiPaper-root": {
            width: "950px",
          },
        }}
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2, color: "#4b4b4f" }} id="customized-dialog-title">
          Order history of {company.name}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {orderHistory.length > 0 ? (
          <DataTable
            columns={[
              {
                Header: "Order Id",
                accessor: "id",
              },
              {
                Header: "Contact Name",
                accessor: "contact_name",
                Cell: ({ row: { original } }) =>
                  original.reseller?.companies?.name ? <MDBox>
                        <MDBox>{original.contact_name}</MDBox>
                        <MDBox sx={{ fontWeight: 600, fontSize: '11px' }}>{original.reseller?.companies?.name}</MDBox>
                      </MDBox>
                    : <>
                  <>
                    {original.contact_name}
                  </>
                </>,
              },
              {
                Header: "Date",
                accessor: "datetime",
                Cell: ({ row: { original } }) => moment(original.datetime).format(dateFormat),
              },
              {
                Header: "Payment Method",
                accessor: "payment_method",
                Cell: ({ row: { original } }) => (
                  <>
                    {original.payment_method == "CREDIT" ? <>Net 30 Day Account Credit</> : <>{original.payment_method}</>}
                  </>
                ),
              },
              {
                Header: "Payment Status",
                accessor: "payment_status",
                Cell: ({ row: { original } }) =>
                  original.payment_status == "PENDING" ? <>
                  <MDBox>
                    <MDBadge badgeContent="Pending" color="info" variant="gradient" size="sm" />
                  </MDBox>
                  </> : original.payment_status == "PAID" ? <MDBox>
                    <MDBadge badgeContent="Paid" color="success" variant="gradient" size="sm" />
                  </MDBox> : <MDBox>
                    <MDBadge badgeContent="Refunded" color="error" variant="gradient" size="sm" />
                  </MDBox>,
              },
              {
                Header: "Total",
                accessor: "grandtotal",
                Cell: ({ row: { original } }) => (
                  <>
                    {selectedCountry.currency} {original.grandtotal.toFixed(2)}
                  </>
                ),
              },
              // {
              //   Header: "Actions",
              //   accessor: "actions",
              //   disableSortBy: true,
              //   disableFilters: true,
              //   show: true,
              //   align: "right",
              //   // eslint-disable-next-line react/display-name
              //   Cell: ({ row: { original } }) => (
              //     <div className="actions-right">
              //       <OrderDetails order={original} />
              //     </div>
              //   ),
              // },
            ]}
            data={orderHistory || []}
            isSorted={true}
            entriesPerPage={false}
            showTotalEntries={false}
            hideColumnSelection={false}
            noEndBorder
            canSearch
          />
          ) : (
            <Alert severity="warning">No Orders found!</Alert>
          )}
        </DialogContent>
        <DialogActions>
          <MDButton color="secondary" variant="gradient" autoFocus onClick={handleClose}>
            Cancel
          </MDButton>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

OrderHistory.propTypes = {
  company: PropTypes.object,
};

export default OrderHistory;
