import { apis } from "./base";

const notificationsService = apis.injectEndpoints({
  endpoints: (builder) => ({
    getAllNotifications: builder.query({
      query: () => {
        return `/admin/notifications/all`;
      },
      transformResponse: (response) => response.response.sort((a, b) => b.id - a.id),
      providesTags: ["Notifications"],
    }),

    changeReadStatus: builder.mutation({
        query: ({ id, status }) => ({
          url: `/admin/notifications/mark/${id}`,
          method: "PUT",
          body: { status: status },
        }),
        // transformResponse: (response) => response.response
        invalidatesTags: ["Notifications"],
    }),

    changeAllReadStatuses: builder.mutation({
        query: (status) => ({
          url: `/admin/notifications/mark-all`,
          method: "POST",
          body: { status: status },
        }),
        // transformResponse: (response) => response.response
        invalidatesTags: ["Notifications"],
    }),

    // product stock
    getAllStockNotifications: builder.query({
      query: (countryId) => {
        return `/admin/notifications/stock-backs/${countryId}`;
      },
      transformResponse: (response) => response.response,
      providesTags: ["StockBacks"],
    }),
  }),
});

export const {
  useGetAllNotificationsQuery,
  useLazyGetAllNotificationsQuery,
  useChangeReadStatusMutation,
  useChangeAllReadStatusesMutation,
  useGetAllStockNotificationsQuery,
  useLazyGetAllStockNotificationsQuery,
} = notificationsService;
