import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const slice = createSlice({
  name: "bdm",
  initialState,
  reducers: {
    addBDM: (state, action) => {
      state = [...state, action.payload ];
    },
    removeBDM: (state, action) => {
      state = state.filter(item != item.id != action.payload.id)
    },

  },
});

export const getBDMList = (state) => state.Entities.bdms;

export const { addBDM, removeBDM } = slice.actions;

export default { name: slice.name, reducer: slice.reducer };
