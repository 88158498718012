import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

// react-router components
import { useLocation, Link, useHistory } from "react-router-dom";

import moment from "moment";

import { Notify } from "notiflix/build/notiflix-notify-aio";
import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Confirm } from "notiflix/build/notiflix-confirm-aio";
import { Block } from "notiflix/build/notiflix-block-aio";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

import Popper from "@mui/material/Popper";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";

//components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Tooltip from "@mui/material/Tooltip";

//example components
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";
import MarkChatReadRoundedIcon from "@mui/icons-material/MarkChatReadRounded";
import MarkUnreadChatAltRoundedIcon from "@mui/icons-material/MarkUnreadChatAltRounded";
import ForwardIcon from "@mui/icons-material/Forward";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import Badge from "@mui/material/Badge";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";

import ClickAwayListener from "@mui/base/ClickAwayListener";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

//context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";

import { notificationForwardSection, setForwardSection } from "store/entities/notifications";

import { globalCountry } from "store/entities/countries";

import { setStockId } from "store/entities/stockNotifications";

// rtk queries
import {
  useGetAllNotificationsQuery,
  useLazyGetAllNotificationsQuery,
  useChangeReadStatusMutation,
  useChangeAllReadStatusesMutation,
  useLazyGetAllStockNotificationsQuery,
} from "services/notifications";

import { dateTimeFormat } from "utils/constants";

function DashboardNavbar({ absolute, light, isMini, currentRoute }) {

  const globalSelectedCountry = useSelector(globalCountry);

  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;

  const [openNotificationMenu, setOpenNotificationMenu] = useState(false);

  const [openStockNotificationMenu, setOpenStockNotificationMenu] = useState(false);

  const [openLogoutMenu, setOpenLogoutMenu] = useState(false);

  const [unreadCount, setUnreadCount] = useState(0);

  const [unreadStockCount, setUnreadStockCount] = useState(0);

  const route = useLocation().pathname.split("/").slice(1);

  const history = useHistory();

  const triggerDispatch = useDispatch();

  const [changeReadStatus, { isLoading: isStatusChanging }] = useChangeReadStatusMutation();

  const [changeAllReadStatuses, { isLoading: isAllStatusChanging }] =
    useChangeAllReadStatusesMutation();

  // const {
  //   data: getAllNotifications = [],
  //   isLoading: isNotificationsLoading,
  //   isSuccess: isNotificationsSuccess,
  // } = useGetAllNotificationsQuery({
  //   pollingInterval: 10000,
  // });

  const [
    triggerNotifications,
    {
      data: getAllNotifications = [],
      isLoading: isNotificationsLoading,
      isSuccess: isNotificationsSuccess,
    },
  ] = useLazyGetAllNotificationsQuery({
    // pollingInterval: 10000,
  });

  const [
    triggerStockNotifications,
    {
      data: getAllStockNotifications = [],
      isLoading: isStockNotificationsLoading,
      isSuccess: isStockNotificationsSuccess,
    },
  ] = useLazyGetAllStockNotificationsQuery({
    // pollingInterval: 10000,
  });

  const handleChangeReadStatus = (id, status, notificationID) => {
    Block.dots(notificationID, { svgColor: "rgba(159, 3, 3, 0.8)" });
    try {
      changeReadStatus({ id: id, status: status }).then((response) => {
        if (response.data?.status == "success") {
          // Notify.success("Marked as!");
        } else {
          Notify.failure("Something went wront!");
        }
      });
    } catch (e) {
    } finally {
      Block.remove(notificationID, 2000);
    }
  };

  const handleChangeAllReadStatus = (status) => {
    try {
      changeAllReadStatuses(status).then((response) => {
        if (response.data?.status == "success") {
          // Notify.success("Marked as!");
        } else {
          Notify.failure("Something went wront!");
        }
      });
    } catch (e) {
    } finally {
    }
  };

  useEffect(() => {
    triggerNotifications();
    if(globalSelectedCountry && globalSelectedCountry.id) {
      triggerStockNotifications(globalSelectedCountry.id);
    }
    // localStorage.setItem('notificationLastFetch', )
  }, [currentRoute, globalSelectedCountry]);

  useEffect(() => {
    let count = 0;
    if (isNotificationsSuccess && getAllNotifications.length > 0) {
      getAllNotifications.map((item) => {
        if (item.read == false) count++;
      });
      setUnreadCount(count);
    }
  }, [isNotificationsSuccess, getAllNotifications]);

  useEffect(() => {
    if (isStockNotificationsSuccess && getAllStockNotifications.length > 0) {
      setUnreadStockCount(getAllStockNotifications.length);
    }
  }, [isStockNotificationsSuccess, getAllStockNotifications]);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  useEffect(() => {
    isAllStatusChanging
      ? Block.dots("#notificationWrapper", { svgColor: "rgba(159, 3, 3, 0.8)" })
      : Block.remove("#notificationWrapper", 2000);
  }, [isAllStatusChanging]);

  useEffect(() => {
    isStockNotificationsLoading
      ? Block.dots("#stockNotificationWrapper", { svgColor: "rgba(159, 3, 3, 0.8)" })
      : Block.remove("#stockNotificationWrapper", 2000);
  }, [isStockNotificationsLoading]);

  const allNotifications = useMemo(() => {
    if (getAllNotifications && getAllNotifications.length > 0) {
      let modifiedNotifications = getAllNotifications.map((item) => {
        // if(item.read == false) {
        //   return {
        //     id: item.id,
        //     title: item.title,
        //     body: item.body,
        //     category: item.category,
        //     datetime: moment(item.datetime).format(dateTimeFormat),
        //     read: item.read == 1 ? true : false,
        //     related_id: item.related_id,
        //   }
        // }
        return {
          id: item.id,
          title: item.title,
          body: item.body,
          category: item.category,
          datetime: moment(item.datetime).format(dateTimeFormat),
          read: item.read == 1 ? true : false,
          related_id: item.related_id,
        };
      });
      return modifiedNotifications;
    } else {
      return [];
    }
  }, [getAllNotifications]);

  const logout = () => {
    triggerDispatch({ type: "LOG_OUT" });
    localStorage.clear();
    setTimeout(function () {
      history.push("/auth/login");
    }, 3000);
  };

  const notificationForward = (category, id) => {
    triggerDispatch(setForwardSection({ category: category, id: id }));

    if (category == "ORDERS") {
      history.push("/user/orders");
    } else {
      history.push("/user/companies");
    }
  };

  const inventoryForward = (id, company_id) => {
    triggerDispatch(setStockId({ id, company_id }));

    history.push("/user/inventory");
  };

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenNotificationMenu = (event) => {
    setOpenNotificationMenu(event.currentTarget);
    triggerNotifications();
  };
  const handleCloseNotificationMenu = () => setOpenNotificationMenu(false);

  const handleOpenLogoutMenu = (event) => setOpenLogoutMenu(event.currentTarget);
  const handleCloseLogoutMenu = () => setOpenLogoutMenu(false);

  // Render the notifications menu
  const notificationMenu = () => (
    <ClickAwayListener onClickAway={handleCloseNotificationMenu}>
      <Popper
        id="notificationPopper"
        open={Boolean(true)}
        anchorEl={openNotificationMenu}
        placement="bottom-end"
        transition
        onClose={handleCloseNotificationMenu}
        sx={{ zIndex: 1100 }}
        // modifiers={[
        //   {
        //     name: 'arrow',
        //     enabled: true,
        //     options: {
        //       element: arrowRef,
        //     },
        //   },
        // ]}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper
              sx={{
                display: "block",
                position: "relative",
                overflow: "visible",
                boxShadow:
                  "0rem 0.5rem 1.5rem 0.125rem rgba(0, 0, 0, 0.125), 0rem 0.25rem 0.75rem -0.125rem rgba(0, 0, 0, 0.05)",
                width: 350,
                mt: 2,
                // height: 350,
                // mt: 1,
                // '& .MuiAvatar-root': {
                //   width: 32,
                //   height: 32,
                //   ml: -0.5,
                //   mr: 1,
                // },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              }}
            >
              <MDBox sx={{ padding: "10px 12px", borderBottom: "1px solid rgba(0, 0, 0, 0.05)" }}>
                <Typography
                  sx={{ fontWeight: "500", fontSize: "14px" }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  Notifications
                </Typography>
              </MDBox>
              <MDBox
                id="notificationWrapper"
                sx={{
                  margin: "4px 0",
                  paddingRight: "2px",
                }}
              >
                <MDBox
                  sx={{
                    maxHeight: "350px",
                    overflowY: "scroll",
                    padding: "10px",
                  }}
                >
                  <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
                    {allNotifications && allNotifications.length > 0 ? (
                      <>
                        {allNotifications.map((item, key) => (
                          <>
                            <ListItem
                              id={`notificationIcon${key}`}
                              key={key}
                              alignItems="flex-start"
                              sx={{
                                paddingRight: "40px",
                                position: "relative",
                                zIndex: 1,
                                "& .MuiTypography-body1": {
                                  fontSize: "0.85rem",
                                  fontWeight: 500,
                                  lineHeight: 1.5,
                                },
                                "& .MuiListItemText-root": {
                                  lineHeight: 1,
                                },
                                "& .MuiTypography-body2": {
                                  fontSize: "0.75rem",
                                  fontWeight: 400,
                                  lineHeight: 1.275,
                                },
                                "& .MuiIconButton-root": {
                                  marginRight: "5px",
                                  fontSize: "1rem",
                                },
                                "&::before": {
                                  content: '""',
                                  background:
                                    item.read == false ? "rgb(135, 225, 251, 0.1)" : "transparent",
                                  position: "absolute",
                                  top: "-10px",
                                  bottom: "-10px",
                                  left: "-10px",
                                  right: "-10px",
                                  zIndex: -1,
                                },
                              }}
                              secondaryAction={
                                <MDBox>
                                  <Tooltip
                                    title={
                                      <MDBox
                                        component="span"
                                        sx={{ fontSize: "11px", color: "#fff" }}
                                      >
                                        {item.read ? "Mark as unread" : "Mark as read"}
                                      </MDBox>
                                    }
                                    placement="top"
                                    sx={{ "& .MuiTooltip-tooltip": { fontSize: "8px!important" } }}
                                  >
                                    <IconButton
                                      edge="end"
                                      aria-label="comments"
                                      onClick={() =>
                                        handleChangeReadStatus(
                                          item.id,
                                          !item.read,
                                          `#notificationIcon${key}`
                                        )
                                      }
                                    >
                                      {item.read ? (
                                        <MarkUnreadChatAltRoundedIcon />
                                      ) : (
                                        <MarkChatReadRoundedIcon />
                                      )}
                                    </IconButton>
                                  </Tooltip>
                                </MDBox>
                              }
                            >
                              <ListItemAvatar>
                                <Tooltip
                                  title={
                                    <MDBox
                                      component="span"
                                      sx={{ fontSize: "11px", color: "#fff" }}
                                    >
                                      Click to visit
                                    </MDBox>
                                  }
                                  placement="top"
                                  sx={{ "& .MuiTooltip-tooltip": { fontSize: "8px!important" } }}
                                >
                                  <IconButton
                                    edge="end"
                                    aria-label="comments"
                                    onClick={() =>
                                      notificationForward(item.category, item.related_id)
                                    }
                                  >
                                    <Avatar>
                                      {item.category == "ORDERS" ? (
                                        <AttachMoneyIcon fontSize="small" />
                                      ) : (
                                        <Icon fontSize="small">storefront</Icon>
                                      )}
                                    </Avatar>
                                  </IconButton>
                                </Tooltip>
                              </ListItemAvatar>
                              <ListItemText
                                primary={
                                  <Tooltip
                                    title={
                                      <MDBox
                                        component="span"
                                        sx={{ fontSize: "11px", color: "#fff" }}
                                      >
                                        Click to visit
                                      </MDBox>
                                    }
                                    placement="top"
                                    sx={{ "& .MuiTooltip-tooltip": { fontSize: "8px!important" } }}
                                  >
                                    <MDBox
                                      component="span"
                                      sx={{ cursor: "pointer" }}
                                      onClick={() =>
                                        notificationForward(item.category, item.related_id)
                                      }
                                    >
                                      {item.title}
                                    </MDBox>
                                  </Tooltip>
                                }
                                secondary={
                                  <Tooltip
                                    title={
                                      <MDBox
                                        component="span"
                                        sx={{ fontSize: "11px", color: "#fff" }}
                                      >
                                        Click to visit
                                      </MDBox>
                                    }
                                    placement="top"
                                    sx={{ "& .MuiTooltip-tooltip": { fontSize: "8px!important" } }}
                                  >
                                    <MDBox
                                      component="span"
                                      sx={{ cursor: "pointer" }}
                                      onClick={() =>
                                        notificationForward(item.category, item.related_id)
                                      }
                                    >
                                      <Typography
                                        sx={{ display: "block" }}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                      >
                                        {item.body}
                                      </Typography>
                                      <Typography
                                        sx={{
                                          fontSize: "0.6rem!important",
                                          color: "rgba(0, 0, 0, 0.7)!important",
                                          display: "inline-block",
                                          marginTop: "4px",
                                        }}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                      >
                                        {item.datetime}
                                      </Typography>
                                    </MDBox>
                                  </Tooltip>
                                }
                              />
                            </ListItem>
                            {key != allNotifications.length && (
                              <Divider variant="inset" component="li" />
                            )}
                          </>
                        ))}
                      </>
                    ) : (
                      <>
                        <ListItem
                          alignItems="flex-start"
                          sx={{
                            paddingRight: 0,
                            "& .MuiTypography-body1": {
                              fontSize: "0.85rem",
                              fontWeight: 500,
                              lineHeight: 1.5,
                            },
                          }}
                        >
                          <ListItemText
                            primary="No notifications"
                            sx={{
                              fontSize: "14px",
                              textAlign: "center",
                            }}
                          />
                        </ListItem>
                      </>
                    )}
                  </List>
                </MDBox>
              </MDBox>
              {/* {allNotifications && allNotifications.length > 0} */}
              <MDBox sx={{ padding: "10px 12px", borderTop: "1px solid rgba(0, 0, 0, 0.05)" }}>
                <MDButton
                  variant="outlined"
                  startIcon={<MarkChatReadRoundedIcon />}
                  sx={{
                    fontWeight: "400",
                    fontSize: "14px",
                    background: "transparent",
                    color: "rgba(0, 0, 0, 0.75)",
                    borderColor: "transparent",
                    fontWeight: 400,
                    fontSize: "13px",
                    padding: "0 5px",
                    minHeight: "auto",
                    lineHeight: 1.4,
                    borderRadius: "0.125rem",
                    border: 0,
                    minWidth: "auto",
                    outline: 0,
                    textTransform: "initial",
                    "& .MuiButton-startIcon": {
                      marginRight: "6px",
                      color:
                        allNotifications && allNotifications.length > 0
                          ? "rgba(0, 0, 0, 0.65)"
                          : "#fff",
                    },
                    "&:hover": {
                      outline: 0,
                      border: "none",
                      color: "rgba(0, 0, 0, 0.65)",
                    },
                    "&:active": {
                      outline: 0,
                      border: "none",
                      color: "rgba(0, 0, 0, 0.65)!important",
                      opacity: "0.9!important",
                    },
                  }}
                  onClick={() => handleChangeAllReadStatus(true)}
                  disableRipple
                  // disabled={allNotifications ? true : false}
                >
                  Mark all as read
                </MDButton>
              </MDBox>
            </Paper>
          </Fade>
        )}
      </Popper>
    </ClickAwayListener>
  );

  // Render the logout menu
  const logoutMenu = () => (
    <Menu
      anchorEl={openLogoutMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(openLogoutMenu)}
      onClose={handleCloseLogoutMenu}
      sx={{ mt: 2 }}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          boxShadow:
            "0rem 0.5rem 1.5rem 0.125rem rgba(0, 0, 0, 0.125), 0rem 0.25rem 0.75rem -0.125rem rgba(0, 0, 0, 0.05)",
          width: 300,
          // mt: 1,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
    >
      <NotificationItem onClick={logout} icon={<Icon>logout</Icon>} title="Logout" />
    </Menu>
  );

  const handleStockOpenNotificationMenu = (event) => {
    setOpenStockNotificationMenu(event.currentTarget);
    if(globalSelectedCountry && globalSelectedCountry.id) {
      triggerStockNotifications(globalSelectedCountry.id);
    }
  };
  const handleCloseStockNotificationMenu = () => setOpenStockNotificationMenu(false);

  // Render the stock notifications menu
  const stockNotificationMenu = () => (
    <ClickAwayListener onClickAway={handleCloseStockNotificationMenu}>
      <Popper
        id="stockNotificationPopper"
        open={Boolean(true)}
        anchorEl={openStockNotificationMenu}
        placement="bottom-end"
        transition
        onClose={handleCloseStockNotificationMenu}
        sx={{ zIndex: 1100 }}
        // modifiers={[
        //   {
        //     name: 'arrow',
        //     enabled: true,
        //     options: {
        //       element: arrowRef,
        //     },
        //   },
        // ]}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper
              sx={{
                display: "block",
                position: "relative",
                overflow: "visible",
                boxShadow:
                  "0rem 0.5rem 1.5rem 0.125rem rgba(0, 0, 0, 0.125), 0rem 0.25rem 0.75rem -0.125rem rgba(0, 0, 0, 0.05)",
                width: 350,
                mt: 2,
                // height: 350,
                // mt: 1,
                // '& .MuiAvatar-root': {
                //   width: 32,
                //   height: 32,
                //   ml: -0.5,
                //   mr: 1,
                // },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              }}
            >
              <MDBox sx={{ padding: "10px 12px", borderBottom: "1px solid rgba(0, 0, 0, 0.05)" }}>
                <Typography
                  sx={{ fontWeight: "500", fontSize: "14px" }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  Product stock alerts
                </Typography>
              </MDBox>
              <MDBox
                id="stockNotificationWrapper"
                sx={{
                  margin: "4px 0",
                  paddingRight: "2px",
                }}
              >
                <MDBox
                  sx={{
                    maxHeight: "350px",
                    overflowY: "scroll",
                    padding: "10px",
                  }}
                >
                  <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
                    {getAllStockNotifications && getAllStockNotifications.length > 0 ? (
                      <>
                        {getAllStockNotifications.map((item, key) => (
                          <>
                            <ListItem
                              id={`stockNotificationIcon${key}`}
                              key={key}
                              alignItems="flex-start"
                              sx={{
                                paddingRight: "40px",
                                position: "relative",
                                zIndex: 1,
                                "& .MuiTypography-body1": {
                                  fontSize: "0.85rem",
                                  fontWeight: 500,
                                  lineHeight: 1.5,
                                },
                                "& .MuiListItemText-root": {
                                  lineHeight: 1,
                                },
                                "& .MuiTypography-body2": {
                                  fontSize: "0.75rem",
                                  fontWeight: 400,
                                  lineHeight: 1.275,
                                },
                                "& .MuiIconButton-root": {
                                  marginRight: "5px",
                                  fontSize: "1rem",
                                },
                                "&::before": {
                                  content: '""',
                                  background: "transparent",
                                  position: "absolute",
                                  top: "-10px",
                                  bottom: "-10px",
                                  left: "-10px",
                                  right: "-10px",
                                  zIndex: -1,
                                },
                              }}
                            >
                              <ListItemAvatar
                                sx={{
                                  mt: 0,
                                  transform: "translateY(-2px)",
                                  padding: "3px 0",
                                }}
                              >
                                <Tooltip
                                  title={
                                    <MDBox
                                      component="span"
                                      sx={{ fontSize: "11px", color: "#fff" }}
                                    >
                                      Click to visit
                                    </MDBox>
                                  }
                                  placement="top"
                                  sx={{ "& .MuiTooltip-tooltip": { fontSize: "8px!important" } }}
                                >
                                  <IconButton
                                    edge="end"
                                    aria-label="comments"
                                    onClick={() =>
                                      inventoryForward(item.product_id, item.reseller.company_id)
                                    }
                                  >
                                    <Avatar>
                                      <ProductionQuantityLimitsIcon fontSize="small" />
                                    </Avatar>
                                  </IconButton>
                                </Tooltip>
                              </ListItemAvatar>
                              <ListItemText
                                primary={
                                  <Tooltip
                                    title={
                                      <MDBox
                                        component="span"
                                        sx={{ fontSize: "11px", color: "#fff" }}
                                      >
                                        Click to visit
                                      </MDBox>
                                    }
                                    placement="top"
                                    sx={{ "& .MuiTooltip-tooltip": { fontSize: "8px!important" } }}
                                  >
                                    <MDBox
                                      component="span"
                                      sx={{ cursor: "pointer" }}
                                      onClick={() => inventoryForward(item.product_id, item.reseller.company_id)}
                                    >
                                      {item.products && item.products.name
                                        ? item.products.name
                                        : ""}
                                    </MDBox>
                                  </Tooltip>
                                }
                                secondary={
                                  <Typography
                                    sx={{ display: "block" }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                  >
                                    <MDBox
                                      component="span"
                                      sx={{ fontWeight: 500, cursor: "pointer" }}
                                      onClick={() => {
                                        notificationForward("COMPANY", item.reseller.company_id);
                                      }}
                                    >
                                      {item.reseller &&
                                      (item.reseller.first_name || item.reseller.last_name)
                                        ? item.reseller.first_name + " " + item.reseller.last_name
                                        : ""}
                                    </MDBox>{" "}
                                    is waiting for stock {item.quantity && <MDBox component="span" sx={{ fontWeight: 500 }}>({item.quantity})</MDBox>}!
                                  </Typography>
                                }
                              />
                            </ListItem>
                            {key != allNotifications.length && (
                              <Divider variant="inset" component="li" />
                            )}
                          </>
                        ))}
                      </>
                    ) : (
                      <>
                        <ListItem
                          alignItems="flex-start"
                          sx={{
                            paddingRight: 0,
                            "& .MuiTypography-body1": {
                              fontSize: "0.85rem",
                              fontWeight: 500,
                              lineHeight: 1.5,
                            },
                          }}
                        >
                          <ListItemText
                            primary="No alerts yet"
                            sx={{
                              fontSize: "14px",
                              textAlign: "center",
                            }}
                          />
                        </ListItem>
                      </>
                    )}
                  </List>
                </MDBox>
              </MDBox>
            </Paper>
          </Fade>
        )}
      </Popper>
    </ClickAwayListener>
  );

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            {/* <MDBox pr={1}>
              <MDInput label="Search here" />
            </MDBox> */}
            <IconButton
              size="small"
              disableRipple
              color="inherit"
              sx={navbarMobileMenu}
              onClick={handleMiniSidenav}
            >
              <Icon sx={iconsStyle} fontSize="medium">
                {miniSidenav ? "menu_open" : "menu"}
              </Icon>
            </IconButton>
            <MDBox
              color={light ? "white" : "inherit"}
              sx={{
                mr: 1,
                "& .MuiBadge-badge": {
                  fontWeight: 500,
                  fontSize: "0.6rem",
                  minWidth: "17px",
                  lineHeight: 1,
                  padding: "0 5px",
                  height: "17px",
                },
              }}
            >
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                aria-describedby="stockNotificationPopper"
                aria-haspopup="true"
                variant="contained"
                onClick={handleStockOpenNotificationMenu}
              >
                <Badge badgeContent={unreadStockCount} color="primary">
                  <ProductionQuantityLimitsIcon sx={iconsStyle} />
                </Badge>
              </IconButton>
              {openStockNotificationMenu && stockNotificationMenu()}
            </MDBox>
            <MDBox
              color={light ? "white" : "inherit"}
              sx={{
                mr: 1,
                "& .MuiBadge-badge": {
                  fontWeight: 500,
                  fontSize: "0.6rem",
                  minWidth: "17px",
                  lineHeight: 1,
                  padding: "0 5px",
                  height: "17px",
                },
              }}
            >
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                aria-describedby="notificationPopper"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenNotificationMenu}
              >
                <Badge badgeContent={unreadCount} color="primary">
                  <Icon sx={iconsStyle}>notifications</Icon>
                </Badge>
              </IconButton>
              {openNotificationMenu && notificationMenu()}
            </MDBox>
            <MDBox color={light ? "white" : "inherit"} sx={{ mr: 1 }}>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenLogoutMenu}
              >
                <Icon sx={iconsStyle}>account_circle</Icon>
              </IconButton>
              {logoutMenu()}
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
  currentRoute: PropTypes.any,
};

export default DashboardNavbar;
