/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Notify } from "notiflix/build/notiflix-notify-aio";
import { Confirm } from "notiflix/build/notiflix-confirm-aio";

import { styled } from "@mui/material/styles";

import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";

import { convertToHTML } from "draft-convert";

import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Block } from "notiflix/build/notiflix-block-aio";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";

import TextField from "@mui/material/TextField";

import Box from "@mui/material/Box";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { AddBox } from "@mui/icons-material";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Typography from "@mui/material/Typography";

import ControlledTextInput from "components/FormInputs/ControlledTextInput";
import ControlledDateTime from "components/FormInputs/ControlledDateTime";
import ControlledCheckbox from "components/FormInputs/ControlledCheckbox";
import ControlledSelect from "components/FormInputs/ControlledSelect";
import CustomInputs from "components/CustomInputs";
import { CustomSelect } from "components/CustomSelect";

//components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
import MDBadge from "components/MDBadge";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import { countriesSchema } from "../schemas/_model";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import Tooltip from "@mui/material/Tooltip";

import Edit from "@mui/icons-material/Edit";
import Close from "@mui/icons-material/Close";

import InputAdornment from "@mui/material/InputAdornment";

import Alert from "components/CustomAlert";
import Divider from "@mui/material/Divider";

import Instructions from "../InstructionsDialog";

import instructions from "../data/instructions";

import { tables } from "utils/constants";

// rtk queries
import {
  useGetAllCountriesQuery,
  useGetAllInactiveCountriesQuery,
  useLazyGetAllStatesByCountryIdQuery,
  useUpdateCountryMutation,
  useDeleteCountryMutation,
  useGetAllUrlsByCountryIdQuery,
  useLazyGetAllUrlsByCountryIdQuery
} from "services/countries";

import {
  useGetWoocommerceSiteCategoriesMutation,
  usePostCategoryNameMutation,
} from "services/categories";

import {
  useGetWoocommerceSiteProductsMutation,
  usePostProductMutation,
  useUploadProductImageMutation,
} from "services/products";

import { getTempURLs, setTempURLs } from "store/entities/countries";

import {
  setGlobalLoader,
  setGlobalLoaderMessage,
  resetGlobalLoader,
  loaderStatus,
  globalLoaderMessage,
} from "store/ui/loader";

import "../assets/styles.css";
import { useDispatch, useSelector } from "react-redux";

import { removeLocalStorageItemsWithPrefix, replaceHTMLEntityAnd } from "utils/helperFunctions";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function AddCountry() {

  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

  const [convertedContent, setConvertedContent] = useState(null);

  const [open, setOpen] = useState(false);

  const [updateCountry, { isLoading: isUpdateCountryLoading }] = useUpdateCountryMutation();

  const dispatch = useDispatch();

  let savedCategories = [];
  // let savedProducts = [];

  // const [categoriesToLocalStorage, setCategoriesToLocalStorage] = useState([]);

  const [
    triggerStatesByCountryId,
    { data: statesByCountryId = [], isLoading: isLoadingStates, isSuccess: isSuccessStates },
  ] = useLazyGetAllStatesByCountryIdQuery();

  const {
    data: inactiveCountries = [],
    isLoading: isInactiveCountriesLoading,
    isSuccess: isInactiveCountriesSuccess,
  } = useGetAllInactiveCountriesQuery();

  const initialFormState = {
    name: "",
    // currency: "",
    // url: "",
    // phone_code: "",
    // consumer_key: "",
    // consumer_secret: "",
    isTaxIncluded: true,
    deliveryCharges: 0,
    freeDeliveryAbove: 0,
    expressShippingFee: 0,
    taxPercentage: 0,
    stripeSecretKey: "",
    stripePublicKey: "",
    stripeWebhookSecret: "",
    invoiceNinjaAPI: "",
    regionalTaxes: [],
  };

  const { control, watch, handleSubmit, setValue, reset } = useForm({
    resolver: yupResolver(countriesSchema),
    defaultValues: initialFormState,
  });

  const { fields: stateFields, append: appendState, remove: removeState } = useFieldArray({ name: "regionalTaxes", control });

  const isTaxIncluded = watch("isTaxIncluded");

  const countryField = watch("name");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    let html = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(html);
  }, [editorState]);

  const onSubmit = async (data) => {

    if(!isTaxIncluded) {
      if(data.regionalTaxes.length == 0) {
        Notify.failure("You must add at least one tax percentage");
        return;
      }
    } else {
      data.regionalTaxes = [];
    }

    Loading.dots({
      svgColor: "rgba(159, 3, 3, 0.8)",
    })

    // dispatch(setGlobalLoaderMessage("Getting things ready!"));

    // dispatch(setGlobalLoader());

    inactiveCountries.map((item) => {
      if (item.id == data.name) {
        data.regNoTerm = item.reg_no_term != null ? item.reg_no_term : "sss";
        data.taxNoTerm = item.tax_no_term != null ? item.tax_no_term : "sss";
      }
    });

    // dispatch(setTempURLs(dynamicFields));

    data.termsAndConditions = convertedContent;

    let id = parseInt(data.name);
    data.id = id;

    data.countryId = id;

    // deleting unnecessary stuff
    delete data.name;
    delete data.terms_and_conditions;

    // console.log(data);

    try {
      updateCountry({ countryId: id, data: data }).then((response) => {
        if (response.error) {
          Notify.failure(response.error.data.message);
        } else {
          // dispatch(setGlobalLoaderMessage("The country has been added!"));

          setTimeout(() => {
            // dispatch(resetGlobalLoader());
            Notify.success("The country has been added!");
          }, 1000);
        }
      });
    } catch (e) {
      console.log(e);
    } finally {
      setTimeout(() => {
        Loading.remove(500);
      }, 500);
      handleClose();
    }
  };

  useEffect(() => {
    if (countryField) {
      triggerStatesByCountryId(countryField);
    }
  }, [countryField]);

  useEffect(() => {
    open &&
      isInactiveCountriesSuccess &&
      Block.dots("#dynamicStatesElement", {
        svgColor: "rgba(159, 3, 3, 0.8)",
      });

    isInactiveCountriesSuccess && Block.remove("#dynamicStatesElement", 500);
  }, [isInactiveCountriesSuccess]);

  useEffect(() => {
    if (statesByCountryId)  {
      if (statesByCountryId.length > 0) {
        setValue('regionalTaxes', []);
        setValue('isTaxIncluded', true);
        let filterStates = statesByCountryId.map((item) => ({
          id: item.id,
          value: item.tax_percentage,
        }));
        appendState(filterStates);
      }
    }
  }, [statesByCountryId]);

  // const addStateFields = () => {
  //   let newfield = { id: "", value: "" };
  //   setDynamicStates([...dynamicStates, newfield]);
  // };

  // const removeStateFields = (index) => {
  //   let data = [...dynamicStates];
  //   data.splice(index, 1);
  //   setDynamicStates(data);
  // };

  return (
    <>
    <MDButton variant="gradient" color="primary" sx={{ ml: 2 }} onClick={handleClickOpen}>
      Add New
    </MDButton>
    <BootstrapDialog
          // onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          maxWidth="xl"
          sx={{
            "& .MuiPaper-root": {
              width: "900px",
            },
          }}
          open={open}
        >
          <DialogTitle sx={{ m: 0, p: 2, color: "#3f3f3f" }} id="customized-dialog-title">
            Add Region
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <br />
            <form>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                  <ControlledSelect
                    name={"name"}
                    control={control}
                    label="Select Country *"
                    menu={{
                      id: "id",
                      value: "name",
                      title: "Countries",
                      list: inactiveCountries.filter((item) => item.id != 0),
                    }}
                    defaultValue=""
                    sx={{
                      "& .MuiFormLabel-root": {
                        backgroundColor: "#fff",
                      },
                      "& .MuiInputBase-root": {
                        height: "44px",
                      },
                    }}
                  />
                </GridItem>

                {/* <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                  <ControlledTextInput
                    name="currency"
                    control={control}
                    labelText="Currency *"
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem> */}

                <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                  <ControlledTextInput
                    name="deliveryCharges"
                    control={control}
                    labelText="Delivery charges *"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "number",
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                  <ControlledTextInput
                    name="freeDeliveryAbove"
                    control={control}
                    labelText="Free delivery above *"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "number",
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                  <ControlledTextInput
                    name="expressShippingFee"
                    control={control}
                    labelText="Express shipping fee *"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "number",
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={12} sx={{ mb: 1 }}>
                  <Divider />
                </GridItem>

                <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                  <ControlledTextInput
                    name="stripeSecretKey"
                    control={control}
                    labelText="Stripe Secret Key *"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Instructions data={instructions.stripe_secret} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                  <ControlledTextInput
                    name="stripePublicKey"
                    control={control}
                    labelText="Stripe Public Key *"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Instructions data={instructions.stripe_public} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                  <ControlledTextInput
                    name="stripeWebhookSecret"
                    control={control}
                    labelText="Stripe Webhook Secret *"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Instructions data={instructions.stripe_webhook} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                  <ControlledTextInput
                    name="invoiceNinjaAPI"
                    control={control}
                    labelText="InvoiceNinja API Key *"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Instructions data={instructions.invoice_ninja_key} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={12}>
                  <br />
                </GridItem>

                <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                  <ControlledCheckbox
                    name="isTaxIncluded"
                    control={control}
                    label="Is Tax included?"
                    disabled={countryField == 0 ? true : false}
                  />
                </GridItem>

                {isTaxIncluded ? (
                  <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                    <ControlledTextInput
                      name="taxPercentage"
                      control={control}
                      labelText="Tax Percentage"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "number",
                      }}
                    />
                  </GridItem>
                ) : (
                  <GridItem xs={12} sm={12}>
                    <Box
                      px={2}
                      pb={2}
                      p1={1}
                      sx={{ border: "0.0625rem solid #f0f2f5", borderRadius: "0.375rem" }}
                      id="dynamicStatesElement"
                    >
                      <Box display="flex" justifyContent="space-between" my={1}>
                        <Typography
                          variant="h6"
                          sx={{
                            fontWeight: 500,
                            color: "#3f3f3f",
                          }}
                        >
                          States
                        </Typography>
                        {/* <MDButton color="info" variant="gradient" iconOnly onClick={addStateFields}>
                        <AddBox sx={{}} />
                      </MDButton> */}
                      </Box>
                      <br />
                      {stateFields.map((input, index) => {
                        return (
                          <GridContainer key={index}>
                            <GridItem xs={12} sm={12} md={11} lg={11} xl={11}>
                              <GridContainer>
                                <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                                  <ControlledSelect
                                    name={`regionalTaxes.${index}.id`}
                                    control={control}
                                    label="Select State *"
                                    menu={{
                                      id: "id",
                                      value: "state_name",
                                      title: "State",
                                      list: statesByCountryId,
                                    }}
                                    sx={{
                                      "& .MuiFormLabel-root": {
                                        backgroundColor: "#fff",
                                      },
                                      "& .MuiInputBase-root": {
                                        height: "44px",
                                      },
                                    }}
                                    inputProps={{
                                      readOnly: true,
                                    }}
                                  />
                                </GridItem>

                                <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                                  <ControlledTextInput
                                    name={`regionalTaxes.${index}.value`}
                                    control={control}
                                    labelText="Tax percentage *"
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                    inputProps={{
                                      
                                    }}
                                  />
                                </GridItem>
                              </GridContainer>
                            </GridItem>
                          </GridContainer>
                        );
                      })}
                    </Box>
                  </GridItem>
                )}

                <GridItem xs={12} sm={12} md={4} lg={4} xl={4}></GridItem>

                <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                  <br />
                  <FormControl variant="outlined">
                    <InputLabel
                      htmlFor="terms-condition"
                      sx={{
                        transform: "translate(12px, -5px) scale(.95)",
                        backgroundColor: "#fff",
                        padding: "0 5px 0 0",
                      }}
                    >
                      Terms And Conditions
                    </InputLabel>
                    <MDBox>
                      <Editor
                        id="terms-condition"
                        editorState={editorState}
                        onEditorStateChange={setEditorState}
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        toolbarClassName="toolbar-class"
                      />
                    </MDBox>
                  </FormControl>
                </GridItem>
              </GridContainer>
            </form>
          </DialogContent>
          <DialogActions>
            <MDButton
              type="submit"
              color="primary"
              variant="gradient"
              id="submit"
              onClick={handleSubmit(onSubmit)}
            >
              Add Country
            </MDButton>
            <MDButton color="secondary" variant="gradient" autoFocus onClick={handleClose}>
              Cancel
            </MDButton>
          </DialogActions>
        </BootstrapDialog>
    </>
  );
}

export default AddCountry;
