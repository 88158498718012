import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoggedIn: null,
  userId: "",
  email: "",
  tokenKey: "",
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (auth, action) => ({ ...auth, ...action.payload }),
  },
});

export const { setAuth } = slice.actions;

export default slice.reducer;
