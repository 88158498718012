import { apis } from "./base";

const countriesService = apis.injectEndpoints({
  endpoints: (builder) => ({
    getAllCountries: builder.query({
      query: () => ({
        url: "/admin/countries/all",
      }),
      transformResponse: (response) => response.response,
      providesTags: ["Countries"],
    }),
    getAllInactiveCountries: builder.query({
      query: () => ({
        url: "/admin/countries/inactive/all",
      }),
      transformResponse: (response) => response.response,
      providesTags: ["Countries"],
    }),
    getAllStatesByCountryId: builder.query({
      query: (country_id) => {
        return `/states/all/${country_id}`;
      },
      transformResponse: (response) => response.response,
      providesTags: ["Countries"],
    }),
    getAllUrlsByCountryId: builder.query({
      query: (country_id) => {
        return `/admin/countries/id/${country_id}`;
      },
      transformResponse: (response) => response.response,
      providesTags: ["Countries", "URLs"],
    }),
    updateCountry: builder.mutation({
      query: ({ countryId, data }) => ({
        url: `/admin/countries/edit/${countryId}`,
        method: "PUT",
        body: data,
      }),
      // transformResponse: (response) => response.response
      invalidatesTags: ["Countries"],
    }),
    deleteCountry: builder.mutation({
      query: (id, body) => ({
        url: `/admin/countries/update-status/${id}`,
        method: "PUT",
        body: body
      }),
      transformResponse: (response) => response.response,
      invalidatesTags: ["Countries"],
    }),

    deleteCountryURL: builder.mutation({
      query: ({urlId}) => ({
        url: `/admin/countries/urls/delete/${urlId}`,
        method: "DELETE"
      }),
      transformResponse: (response) => response.response,
      invalidatesTags: ["URLs"],
    }),
  }),
});

export const {
  useGetAllCountriesQuery,
  useGetAllInactiveCountriesQuery,
  useGetAllStatesByCountryIdQuery,
  useLazyGetAllStatesByCountryIdQuery,
  useLazyGetAllUrlsByCountryIdQuery,
  useGetAllUrlsByCountryIdQuery,
  useUpdateCountryMutation,
  useDeleteCountryMutation,
  useDeleteCountryURLMutation
} = countriesService;
