/* eslint-disable react/prop-types */
import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";

import { useForm, useFieldArray, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import moment from "moment";

import { styled } from "@mui/material/styles";

import PropTypes from "prop-types";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import KeyIcon from "@mui/icons-material/Key";
import CloseIcon from "@mui/icons-material/Close";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";

import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";

import ControlledTextInput from "components/FormInputs/ControlledTextInput";

import { digitalKeysUpdateSchema } from "./schemas/_model";

import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";

import DataTable from "examples/Tables/DataTable";

import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Notify } from "notiflix/build/notiflix-notify-aio";
import { Confirm } from "notiflix/build/notiflix-confirm-aio";

import Alert from "components/CustomAlert";

import { dateFormat } from "utils/constants";

import {
  useLazyGetAllDigitalKeysByProductIdQuery,
  useChangeDigitalKeysStatusMutation,
} from "services/digitalKeys";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const DigitalKeysUpdateDialog = ({
  openDigitalKeysUpdateDialog,
  setOpenDigitalKeysUpdateDialog,
  fetchedDigitalKeys,
  setFetchedDigitalKeys,
  onDigitalKeysSubmit,
  setTempDeliveredData,
  tempDeliveredData,
}) => {
  // const [tableData, setTableData] = useState([]);
  const initialFormState = {
    keys: [],
  };

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(digitalKeysUpdateSchema),
    defaultValues: initialFormState,
    mode: "onChange",
  });

  const { fields, append, remove } = useFieldArray({ name: "keys", control });

  const handleDialogClose = () => {
    setOpenDigitalKeysUpdateDialog(false);
    setFetchedDigitalKeys([]);
    setTempDeliveredData({});
    remove();
  };

  useEffect(() => {
    // update field array when ticket number changed
    // if (openDigitalKeysUpdateDialog && fetchedDigitalKeys && fetchedDigitalKeys.length > 0) {
    //   let uniqueKeys = fetchedDigitalKeys.filter(
    //     (obj, index, self) =>
    //       index === self.findIndex((t) => t.id === obj.id && t.product_id === obj.product_id)
    //   );
    //   for (let i = 0; i < parseInt(uniqueKeys.length); i++) {
    //     if (uniqueKeys[i].product_id == productId)
    //       append({ id: uniqueKeys[i].id, key: uniqueKeys[i].id });
    //   }
    // }
    // // console.log(productId);

    if (fetchedDigitalKeys && fetchedDigitalKeys.length > 0) {
      let filteredDigitalKeys = fetchedDigitalKeys.filter(
        (item) => item.product_id == tempDeliveredData.productId
      );

      for (let i = 0; i < parseInt(filteredDigitalKeys.length); i++) {
        append({ id: filteredDigitalKeys[i].id, key: filteredDigitalKeys[i].key });
      }
    }
  }, [fetchedDigitalKeys, tempDeliveredData]);

  const onSubmitDigitalPendingKeys = async (data) => {
    await onDigitalKeysSubmit(data);
    handleDialogClose();
  };

  return (
    <>
      <BootstrapDialog
        // onClose={handleDialogClose}
        aria-labelledby="customized-dialog-title"
        maxWidth="lg"
        sx={{
          "& .MuiPaper-root": {
            width: "850px",
          },
        }}
        open={openDigitalKeysUpdateDialog}
      >
        <DialogTitle sx={{ m: 0, p: 2, color: "#4b4b4f" }} id="customized-dialog-title">
          Digital keys of {tempDeliveredData.product_name}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleDialogClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <MDTypography
            variant="h6"
            sx={{
              fontWeight: 400,
              mb: tempDeliveredData.product_variation ? 0 : 1,
              color: "#3f3f3f",
            }}
          >
            <strong>Product:</strong> {tempDeliveredData.product_name}
          </MDTypography>
          {tempDeliveredData.product_variation && <MDTypography
            variant="h6"
            sx={{
              fontWeight: 400,
              mb: 1,
              color: "#3f3f3f",
            }}
          >
            <strong>Variation:</strong> {tempDeliveredData.product_variation}
          </MDTypography>}
          <MDTypography variant="p" sx={{ fontWeight: 400, mt: 1, color: "#3f3f3f" }}>
            Update the "Pending" keys in order to mark as "Delivered".
          </MDTypography>
          <MDBox marginTop={2} marginBottom={2}>
            <form>
              {fields.map((item, i) => (
                <MDBox key={i}>
                  <Controller
                    render={({ field: { onChange, value }, fieldState: { error }, formState }) => (
                      <>
                        <TextField
                          helperText={error ? error.message : null}
                          size="small"
                          error={!!error}
                          onChange={onChange}
                          value={value}
                          fullWidth
                          label="Key"
                          variant="outlined"
                          sx={{
                            mb: 1.5,
                          }}
                        />
                      </>
                    )}
                    name={`keys.${i}.key`}
                    control={control}
                    defaultValue=""
                  />
                </MDBox>
              ))}
            </form>
          </MDBox>
        </DialogContent>
        <DialogActions>
          <MDButton
            type="submit"
            color="primary"
            variant="gradient"
            id="submit"
            onClick={handleSubmit(onSubmitDigitalPendingKeys)}
          >
            Mark as Delivered
          </MDButton>
          <MDButton color="secondary" variant="gradient" autoFocus onClick={handleDialogClose}>
            Cancel
          </MDButton>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

DigitalKeysUpdateDialog.propTypes = {
  openDigitalKeysUpdateDialog: PropTypes.bool.isRequired,
  setOpenDigitalKeysUpdateDialog: PropTypes.func,
  fetchedDigitalKeys: PropTypes.array,
};

export default DigitalKeysUpdateDialog;
