import { apis } from "./base";

const companiesService = apis.injectEndpoints({
  endpoints: (builder) => ({
    getAllCompanies: builder.query({
      query: (country_id) => ({
        url: `/admin/companies/all/${country_id}`,
      }),
      transformResponse: (response) => response.response.sort((a, b) => b.id - a.id),
      providesTags: ["Companies"],
    }),

    getCompanyDetailsById: builder.query({
      query: (id) => {
        return `/admin/companies/id/${id}`;
      },
      transformResponse: (response) => response.response,
      providesTags: ["Companies"],
    }),

    getDirectorsByCompanyId: builder.query({
      query: (company_id) => {
        return `/admin/companies/directors/all/${company_id}`;
      },
      transformResponse: (response) => response.response,
      // providesTags: ["OrderProducts"],
    }),

    getSuppliersByCompanyId: builder.query({
      query: (company_id) => {
        return `/admin/companies/suppliers/all/${company_id}`;
      },
      transformResponse: (response) => response.response,
      providesTags: ["Suppliers"],
    }),

    deleteSupplier: builder.mutation({
      query: ({company_id, supplier_id}) => ({
        url: `/admin/companies/suppliers/delete/${company_id}/${supplier_id}`,
        method: "DELETE",
      }),
      // transformResponse: (response) => response.response,
      invalidatesTags: ["Suppliers"],
    }),

    // getResellersByCompanyId: builder.query({
    //   query: (company_id) => {
    //     return `/admin/companies/resellers/${company_id}`;
    //   },
    //   transformResponse: (response) => response.response,
    //   // providesTags: ["OrderProducts"],
    // }),

    getHistoryByDirectorId: builder.query({
      query: (director_id) => {
        return `/admin/companies/directors/history/${director_id}`;
      },
      transformResponse: (response) => response.response,
      // providesTags: ["OrderProducts"],
    }),

    verifyCompany: builder.mutation({
      query: ({ company_Id, status, note, assignedBdmId }) => ({
        url: `/admin/companies/verify/${company_Id}`,
        method: "PUT",
        body: {
          status: status,
          note: note,
          assignedBdmId: assignedBdmId
        },
      }),
      // transformResponse: (response) => response.response
      invalidatesTags: ["Companies"],
    }),

    verifyCompanyCreditApplication: builder.mutation({
      query: ({ company_Id, status, note }) => ({
        url: `/admin/companies/change-credit-status/${company_Id}`,
        method: "PUT",
        body: {
          status: status,
          note: note
        },
      }),
      // transformResponse: (response) => response.response
      invalidatesTags: ["Companies"],
    }),

    getRequiredFilesForCompany: builder.query({
      query: (company_Id) => ({
        url: `/admin/companies/files/all/${company_Id}`,
      }),
      transformResponse: (response) => response.response,
      // providesTags: ["Companies"],
    }),

    assignBDM: builder.mutation({
      query: ({ bdmId, companyId }) => ({
        url: `/admin/bdms/assign/${bdmId}/${companyId}`,
        method: "POST",
        body: {},
      }),
      // transformResponse: (response) => response.response
      invalidatesTags: ["Companies"],
    }),

    // requestPasswordReset: builder.mutation({
    //   query: (data) => ({
    //     url: `/reseller/request-reset-password`,
    //     method: "POST",
    //     body: data,
    //   }),
    //   // transformResponse: (response) => response.response
    //   // invalidatesTags: ["Products"],
    // }),

    updateCompany: builder.mutation({
      query: ({ company_id, data }) => ({
        url: `/admin/companies/edit/${company_id}`,
        method: "PUT",
        body: data,
      }),
      // transformResponse: (response) => response.response
      invalidatesTags: ["Companies"],
    }),

    // Discount related APIs

    getAllDiscountProducts: builder.query({
      query: (company_id) => ({
        url: `/admin/companies/discount-products/get-assinged-products/${company_id}`,
      }),
      transformResponse: (response) => response.response,
      providesTags: ["DiscountedProducts"],
    }),

    addDiscount: builder.mutation({
      query: (data) => ({
        url: `/admin/companies/discount-products/add`,
        method: "POST",
        body: data,
      }),
      // transformResponse: (response) => response.response
      invalidatesTags: ["DiscountedProducts"],
    }),

    updateDiscount: builder.mutation({
      query: ({ discountProductd, data }) => ({
        url: `/admin/companies/discount-products/edit/${discountProductd}`,
        method: "PUT",
        body: data,
      }),
      // transformResponse: (response) => response.response
      invalidatesTags: ["DiscountedProducts"],
    }),

    deleteDiscount: builder.mutation({
      query: (discountProductd) => ({
        url: `/admin/companies/discount-products/delete/${discountProductd}`,
        method: "DELETE",
      }),
      // transformResponse: (response) => response.response,
      invalidatesTags: ["DiscountedProducts"],
    }),

  }),
});

export const {
  useGetAllCompaniesQuery,
  useGetRequiredFilesForCompanyQuery,
  useLazyGetCompanyDetailsByIdQuery,
  useLazyGetDirectorsByCompanyIdQuery,
  useLazyGetHistoryByDirectorIdQuery,
  useLazyGetSuppliersByCompanyIdQuery,
  useDeleteSupplierMutation,
  // useLazyGetResellersByCompanyIdQuery,
  useAssignBDMMutation,
  useVerifyCompanyMutation,
  useVerifyCompanyCreditApplicationMutation,
  useUpdateCompanyMutation,
  // discount related apis
  useGetAllDiscountProductsQuery,
  useLazyGetAllDiscountProductsQuery,
  useAddDiscountMutation,
  useUpdateDiscountMutation,
  useDeleteDiscountMutation
} = companiesService;
