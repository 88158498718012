/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { styled } from "@mui/material/styles";

import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Block } from "notiflix/build/notiflix-block-aio";
import { Confirm } from "notiflix/build/notiflix-confirm-aio";
import { Notify } from "notiflix/build/notiflix-notify-aio";

// @mui material components
import Box from "@mui/material/Box";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { AddBox } from "@mui/icons-material";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Typography from "@mui/material/Typography";

import ControlledTextInput from "components/FormInputs/ControlledTextInput";
import ControlledDateTime from "components/FormInputs/ControlledDateTime";
import ControlledCheckbox from "components/FormInputs/ControlledCheckbox";
import ControlledSelect from "components/FormInputs/ControlledSelect";
import CustomInputs from "components/CustomInputs";
import { CustomSelect } from "components/CustomSelect";

//components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import { reminderTypeSchema } from "../schemas/_model";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import InputAdornment from "@mui/material/InputAdornment";

import Tooltip from "@mui/material/Tooltip";

import Edit from "@mui/icons-material/Edit";
import Close from "@mui/icons-material/Close";

import Alert from "components/CustomAlert";

// rtk queries
import {
  usePutReminderTypeMutation
} from "services/reminders";


import "../assets/styles.css";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function EditReminderType ({ initialData }) {

  const [openEdit, setOpenEdit] = useState(false);

  const initialFormState = {
    name: "",
    day_or_month_count: 1,
    is_month: true,
    is_enabled_by_default: true,
  };

  const [updateReminderType] = usePutReminderTypeMutation();


  const { control, watch, handleSubmit, setValue, reset } = useForm({
    resolver: yupResolver(reminderTypeSchema),
    defaultValues: initialFormState,
  });

  useEffect(() => {
    reset(initialData);
  }, [initialData]);

  const handleClickOpen = () => {
    setOpenEdit(true);
  };
  const handleClose = () => {
    setOpenEdit(false);
  };

  const onSubmit = (data) => {
    Loading.dots({
      svgColor: "rgba(159, 3, 3, 0.8)",
    });

    data.is_month = data.is_month ? 1 : 0;
    data.is_enabled_by_default = data.is_enabled_by_default ? 1 : 0;

    try {
      updateReminderType({ reminderTypeId: initialData.id, data: { reminderType: data } }).then((response) => {
        if (response.data?.status == "success") {
          Notify.success("Succefully Updated!");
          handleClose();
        } else {
          Notify.failure("Something went wrong, Please try again!");
        }
      });
    } catch (e) {
    } finally {
      Loading.remove(500);
    }
  };

  return (
    <>
      <Tooltip title="Edit" placement="top">
        <IconButton aria-label="view" size="small" onClick={handleClickOpen}>
          <Edit color={"warning"} fontSize="inherit" />
        </IconButton>
      </Tooltip>
      <BootstrapDialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        maxWidth="xl"
        sx={{
          "& .MuiPaper-root": {
            width: "650px",
          },
        }}
        open={openEdit}
      >
        <DialogTitle sx={{ m: 0, p: 2, color: "#3f3f3f" }} id="customized-dialog-title">
          Edit Reminder Type
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <br />
          <form>
            <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                <ControlledTextInput
                  name="name"
                  control={control}
                  labelText="Name *"
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                <ControlledTextInput
                  name="day_or_month_count"
                  control={control}
                  labelText="Count (days/months) *"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "number",
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledCheckbox
                  name="is_month"
                  control={control}
                  label="Is month?"
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledCheckbox
                  name="is_enabled_by_default"
                  control={control}
                  label="Is enabled by default?"
                />
              </GridItem>
            </GridContainer>
          </form>
        </DialogContent>
        <DialogActions>
          <MDButton
            type="submit"
            color="primary"
            variant="gradient"
            id="submit"
            onClick={handleSubmit(onSubmit)}
          >
            Update Reminder Type
          </MDButton>
          <MDButton color="secondary" variant="gradient" autoFocus onClick={handleClose}>
            Cancel
          </MDButton>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}

export default EditReminderType;
