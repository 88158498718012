import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "tab",
  initialState: {
    currentTab: 0
  },
  reducers: {
    add: (state, action) => ({ ...state, ...action.payload }),
    setCurrentTab: (state, action) => {
      state.currentTab = action.payload
    },
    reset: () => initialState
  }
});

export const getCurrentTab = (state) => state.UI.tabs.currentTab;

export const actions = slice.actions;

export default slice.reducer;
