/* eslint-disable react/prop-types */
import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";

import { useForm, useFieldArray, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import moment from "moment";

import { styled } from "@mui/material/styles";

import PropTypes from "prop-types";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import KeyIcon from "@mui/icons-material/Key";
import CloseIcon from "@mui/icons-material/Close";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";

import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";

import ControlledTextInput from "components/FormInputs/ControlledTextInput";

import { poNumberUpdateSchema } from "./schemas/_model";

import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";

import DataTable from "examples/Tables/DataTable";

import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Notify } from "notiflix/build/notiflix-notify-aio";
import { Confirm } from "notiflix/build/notiflix-confirm-aio";

import Alert from "components/CustomAlert";

import { dateFormat } from "utils/constants";

import { useUpdatePONumberMutation } from "services/orders";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const EditPODialog = ({ orderId, currentPO, openPOEdit, setOpenPOEdit, invoiceNinjaAPI }) => {
  // const [tableData, setTableData] = useState([]);
  const initialFormState = {
    poNumber: "",
  };

  const {
    control,
    watch,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(poNumberUpdateSchema),
    defaultValues: initialFormState,
    mode: "onChange",
  });

  const [changeOPNumber] = useUpdatePONumberMutation();

  const handleDialogClose = () => {
    setOpenPOEdit(false);
  };

  useEffect(() => {
    if(currentPO) setValue('poNumber', currentPO)
  }, [currentPO]);

  const onSubmitPONumber = async (data) => {
    data.invoiceNinjaAPI = invoiceNinjaAPI;
    // orderId
    try {
      changeOPNumber({ order_id: orderId, data }).then((response) => {
        if (response.data?.status == "success") {
          Notify.success("Successfully updated!");
        } else {
          Notify.failure("Something went wrong!");
        }
      }).catch(e => console.log(e));
    } catch (e) {
      console.log(e)
    } finally {
      handleDialogClose();
      reset();
    }
  };

  return (
    <>
      <BootstrapDialog
        // onClose={handleDialogClose}
        aria-labelledby="customized-dialog-title"
        maxWidth="lg"
        sx={{
          "& .MuiPaper-root": {
            width: "650px",
          },
        }}
        open={openPOEdit}
      >
        <DialogTitle sx={{ m: 0, p: 2, color: "#4b4b4f" }} id="customized-dialog-title">
          Edit PO Number of #{orderId}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleDialogClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <form>
            <ControlledTextInput
              name="poNumber"
              control={control}
              labelText="PO number *"
              formControlProps={{
                  fullWidth: true,
              }}
              multiline
              sx={{ mt: 2 }}
              // inputProps={{
              //     type: "text",
              // }}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <MDButton
            type="submit"
            color="primary"
            variant="gradient"
            id="submit"
            onClick={handleSubmit(onSubmitPONumber)}
          >
            Submit
          </MDButton>
          <MDButton color="secondary" variant="gradient" autoFocus onClick={handleDialogClose}>
            Cancel
          </MDButton>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

EditPODialog.propTypes = {
  openDigitalKeysUpdateDialog: PropTypes.bool.isRequired,
  setOpenPOEdit: PropTypes.func,
  fetchedDigitalKeys: PropTypes.array,
};

export default EditPODialog;
