import { apis } from "./base";

const emailTemplatesService = apis.injectEndpoints({
  endpoints: (builder) => ({
    getAllEmailTemplateTypes: builder.query({
      query: () => ({
        url: `/admin/countries/email-template/types`,
      }),
      transformResponse: (response) => response.response,
      providesTags: ["EmailTemplateTypes"],
    }),

    getAllEmailTemplatesByCountry: builder.query({
      query: (country_id) => ({
        url: `/admin/countries/email-template/all/${country_id}`,
      }),
      transformResponse: (response) => response.response,
      providesTags: ["EmailTemplates"],
    }),

    getAllDefaultEmailTemplates: builder.query({
      query: () => ({
        url: `/admin/countries/email-template/all/`,
      }),
      transformResponse: (response) => response.response,
      providesTags: ["EmailTemplates"],
    }),

    postEmailTemplate: builder.mutation({
      query: (data) => ({
        url: `/admin/countries/email-template/new/${data.countryId}`,
        method: "POST",
        body: data,
      }),
      // transformResponse: (response) => response.response
      invalidatesTags: ["EmailTemplates"],
    }),

    putEmailTemplate: builder.mutation({
      query: (data) => ({
        url: `/admin/countries/email-template/edit/${data.id}`,
        method: "PUT",
        body: data,
      }),
      // transformResponse: (response) => response.response
      invalidatesTags: ["EmailTemplates"],
    }),

    deleteEmailTemplate: builder.mutation({
      query: (id) => ({
        url: `/admin/countries/email-template/delete/${id}`,
        method: "DELETE"
      }),
      transformResponse: (response) => response.response,
      invalidatesTags: ["EmailTemplates"],
    }),
  }),
});

export const {
  useGetAllDefaultEmailTemplatesQuery,
  useGetAllEmailTemplatesByCountryQuery,
  useLazyGetAllEmailTemplatesByCountryQuery,
  usePostEmailTemplateMutation,
  usePutEmailTemplateMutation,
  useDeleteEmailTemplateMutation
} = emailTemplatesService;
