/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import { Importer, ImporterField } from "react-csv-importer";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { styled } from "@mui/material/styles";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";

import Tooltip from "@mui/material/Tooltip";

import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import MDButton from "components/MDButton";

import { AddBox } from "@mui/icons-material";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";

import TextField from "@mui/material/TextField";

import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Notify } from "notiflix/build/notiflix-notify-aio";

import Typography from "@mui/material/Typography";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import DataTable from "examples/Tables/DataTable";

import ControlledTextInput from "components/FormInputs/ControlledTextInput";
import ControlledDateTime from "components/FormInputs/ControlledDateTime";
import ControlledCheckbox from "components/FormInputs/ControlledCheckbox";
import ControlledSelect from "components/FormInputs/ControlledSelect";
import CustomInputs from "components/CustomInputs";

import { CustomSelect } from "components/CustomSelect";

import moment from "moment";

//components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import MDAvatar from "components/MDAvatar";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

//example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import Box from "@mui/material/Box";

import Edit from "@mui/icons-material/Edit";
import Close from "@mui/icons-material/Close";

// queries
import {
  useGetAllResellerProductsByCountryIdQuery,
  usePutProductMutation,
  useGetProductByIdQuery,
} from "services/products";

import {
  useGetAllDigitalKeysByProductIdQuery,
  useLazyGetAllDigitalKeysByProductIdQuery,
  usePostDigitalKeyMutation,
  useDeleteDigitalKeyMutation,
  useChangeDigitalKeysStatusMutation,
} from "services/digitalKeys";

import { useGetAllUrlsByCountryIdQuery } from "services/countries";

// import { useGetAllProductsFromStoreUrlQuery } from "services/store";

import { globalCountry } from "store/entities/countries";

import Alert from "components/CustomAlert";

import CircularProgress from "@mui/material/CircularProgress";

import { dateFormat } from "utils/constants";

import { ArrowBack } from "@mui/icons-material";

import { inventorySchema } from "../schemas/_model";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function EditInventory() {
  const { id } = useParams();

  const initialFormState = {
    isDigitalGood: false,
    isFeaturedProduct: true,
    price: "",
    availability: 0,
  };

  const [openedTab, setOpenedTab] = useState("Variation 1");

  const [productData, setProductData] = useState({});

  const navigate = useHistory();

  const selectedCountry = useSelector(globalCountry);

  const [imageLoading, setImageLoading] = useState(true);

  const [filterDate, setFilterDate] = useState("");

  const [filterDates, setFilterDates] = useState([]);

  const [filteredKeysState, setFilteredKeysState] = useState("");

  const [updateProduct, { isLoading: isUpdateProductLoading }] = usePutProductMutation();

  const [createDigitalKey, { isLoading: isDigitalKeysCreatingtLoading }] =
    usePostDigitalKeyMutation();

  const [deleteDigitalKey, result] = useDeleteDigitalKeyMutation();

  const [changeDigitalKeyStatus, { isLoading: isStatusChanging }] =
    useChangeDigitalKeysStatusMutation();

  const [openDigitalKeysDialog, setOpenDigitalKeysDialog] = useState(false);

  const [dynamicFields, setDynamicFields] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);

  const [openDialog, setOpenDialog] = useState(false);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDialogClickOpen = () => {
    setOpenDialog(true);
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const { control, watch, handleSubmit, setValue } = useForm({
    resolver: yupResolver(inventorySchema),
    defaultValues: initialFormState,
  });

  const {
    data: urlsByCountryId = [],
    isLoading: isUrlsByCountryIdLoading,
    isSuccess: isUrlsByCountryIdSuccess,
  } = useGetAllUrlsByCountryIdQuery(selectedCountry.id);

  const [
    triggerDigitalKeys,
    {
      data: digitalKeysByProduct = [],
      isLoading: isLoadingDigitalKeysByProduct,
      isSuccess: isSuccessDigitalKeysByProduct,
    },
  ] = useLazyGetAllDigitalKeysByProductIdQuery();

  const {
    data: productById = [],
    isLoading: isProductByIdLoading,
    isSuccess: isProductByIdSuccess,
  } = useGetProductByIdQuery(id);

  const isDigitalGoodField = watch("isDigitalGood");

  const onSubmitDigitalKeys = () => {
    if (dynamicFields.length > 0) {
      console.log(dynamicFields);
      let data = { keys: dynamicFields };
      createDigitalKey({ product_id: id, data: data }).then((response) => {
        if (response.status == "success") {
          setDynamicFields([]);
        }
      });
      setOpenDigitalKeysDialog(false);
    }
  };

  const handleDigitalKeyDelete = (key_id) => {
    Loading.dots({
      svgColor: "rgba(159, 3, 3, 0.8)",
    });

    deleteDigitalKey(key_id)
      .then((response) => {
        console.log(response);
        Loading.remove(500);
      })
      .catch((error) => console.log(error));
  };

  const handleDeactivateKeys = (data) => {
    changeDigitalKeyStatus(data)
      .then((response) => {
        if (response.data?.status == "success") {
          Loading.remove(500);
          Notify.success("Succefully Changed!");
        } else {
          console.log(response);
          Loading.remove(500);
        }
      })
      .catch((err) => {
        console.log(err);
        Notify.failure("Something went wrong!");
        Loading.remove(500);
      });
  };

  const activateAllKeys = () => {
    if (filterDate != "") {
      Loading.dots({
        svgColor: "rgba(159, 3, 3, 0.8)",
      });

      let ids = digitalKeysTableDataByFilteredDate.map((item) => item.id);
      let data = {
        active: true,
        ids: ids,
      };

      changeDigitalKeyStatus(data)
        .then((response) => {
          if (response.data?.status == "success") {
            Loading.remove(500);
            Notify.success("Succefully Changed!");
          } else {
            console.log(response);
            Loading.remove(500);
          }
        })
        .catch((err) => {
          console.log(err);
          Notify.failure("Something went wrong!");
          Loading.remove(500);
        });
    }
  };

  const deactivateAllKeys = () => {
    if (filterDate != "") {
      Loading.dots({
        svgColor: "rgba(159, 3, 3, 0.8)",
      });

      let ids = digitalKeysTableDataByFilteredDate.map((item) => item.id);
      let data = {
        active: false,
        ids: ids,
      };

      changeDigitalKeyStatus(data)
        .then((response) => {
          if (response.data?.status == "success") {
            Loading.remove(500);
            Notify.success("Succefully Changed!");
          } else {
            console.log(response);
            Loading.remove(500);
          }
        })
        .catch((err) => {
          console.log(err);
          Notify.failure("Something went wrong!");
          Loading.remove(500);
        });
    }
  };

  const handleDynamicFields = (index, e) => {
    let data = [...dynamicFields];
    data[index] = e.target.value;
    setDynamicFields(data);
  };

  const addFields = () => {
    let newfield = "";
    setDynamicFields([...dynamicFields, newfield]);
  };

  const removeFields = (index) => {
    let data = [...dynamicFields];
    data.splice(index, 1);
    setDynamicFields(data);
  };

  useEffect(() => {
    Loading.dots({
      svgColor: "rgba(159, 3, 3, 0.8)",
    });
    if (isProductByIdSuccess)
      if (productById.length > 0) {
        let storageData = JSON.parse(localStorage.getItem("woocommerceProducts"));
        let filter = storageData.filter((item) => item.id == productById[0].parent_id);
        setProductData(filter[0]);
      }

    setTimeout(() => {
      Loading.remove(500);
    }, 500);
  }, [isProductByIdSuccess]);

  useEffect(() => {
    isLoadingDigitalKeysByProduct
      ? Loading.dots({ svgColor: "rgba(159, 3, 3, 0.8)" })
      : Loading.remove(500);
  }, [isLoadingDigitalKeysByProduct]);

  useEffect(() => {
    isDigitalKeysCreatingtLoading
      ? Loading.dots({ svgColor: "rgba(159, 3, 3, 0.8)" })
      : Loading.remove(500);
  }, [isDigitalKeysCreatingtLoading]);

  useEffect(() => {
    isStatusChanging ? Loading.dots({ svgColor: "rgba(159, 3, 3, 0.8)" }) : Loading.remove(500);
  }, [isStatusChanging]);

  useEffect(() => {
    if (productById)
      if (productById.length > 0) {
        setValue("isDigitalGood", productById[0].is_digital_good);
        setValue("isFeaturedProduct", productById[0].is_featured_product);
        setValue("urlId", productById[0].url_id);
        setValue("availability", productById[0].availability);
        setValue("price", productById[0].price);
        if (productById[0].is_digital_good) {
          triggerDigitalKeys(productById[0].id);
        }
      }
  }, [productById]);

  useEffect(() => {
    if (isDigitalGoodField)
      if (digitalKeysByProduct.length > 0) triggerDigitalKeys(productById[0].id);
  }, [isDigitalGoodField]);

  const digitalKeysTableData = useMemo(() => {
    if (digitalKeysByProduct)
      if (digitalKeysByProduct.length > 0) {
        let data = digitalKeysByProduct.map((prop) => ({
          id: prop.id,
          active: prop.active,
          product_id: prop.product_id,
          key: prop.key,
          order_id: prop.order_id,
          date_created: moment(prop.date_created).format(dateFormat),
          date_assigned:
            prop.date_assigned != null ? moment(prop.date_assigned).format(dateFormat) : "",
        }));
        return data;
      } else {
        return [];
      }
  }, [digitalKeysByProduct]);

  useEffect(() => {
    if (digitalKeysTableData)
      if (digitalKeysTableData.length > 0) {
        let data = [...new Set(digitalKeysTableData.map((item) => item.date_created))];
        setFilterDates(data);
      }
  }, [digitalKeysTableData]);

  const digitalKeysTableDataByFilteredDate = useMemo(() => {
    if (filterDate)
      if (digitalKeysTableData)
        if (digitalKeysTableData.length > 0) {
          let data = digitalKeysTableData.filter((item) => item.date_created == filterDate);

          // let digitalKeysStatus = data.every((item) => item.active == true);
          // setFilteredKeysState(digitalKeysStatus);

          // Check if all items are active
          const allActive = data.every((item) => item.active == true);

          // Check if all items are inactive
          const allInactive = data.every((item) => item.active == false);

          // Determine the status based on the above checks
          if (allActive) {
            setFilteredKeysState("active");
          } else if (allInactive) {
            setFilteredKeysState("inactive");
          } else {
            setFilteredKeysState(""); // Mixture of active and inactive
          }

          return data;
        } else {
          return [];
        }
  }, [filterDate, digitalKeysTableData]);

  const onSubmit = (data) => {
    Loading.dots({
      svgColor: "rgba(159, 3, 3, 0.8)",
    });

    data.countryId = productById[0].country_id;
    data.parentId = productById[0].parent_id;
    data.productId = productById[0].product_id;

    console.log(data);

    try {
      updateProduct({ id, data }).then((response) => {
        console.log(response);
      });
    } catch (e) {
    } finally {
      navigate.push("/user/inventory");
      Loading.remove(500);
    }
  };

  // useEffect(() => {
  //   Loading.dots({
  //     svgColor: "rgba(159, 3, 3, 0.8)",
  //   });

  //   isUrlsByCountryIdSuccess && Loading.remove(500);
  // }, [isUrlsByCountryIdSuccess]);

  return (
    <Card>
      <CardHeader
        // avatar={<AccountCircleIcon fontSize="large" color="dark" />}
        title={<MDTypography color="info">Edit Inventory</MDTypography>}
        action={
          <IconButton color={"secondary"} onClick={() => navigate.goBack()} size="large">
            <ArrowBack />
          </IconButton>
        }
      />
      <CardContent>
        {productData != {} && (
          <MDBox
            ml={1}
            display="flex"
            sx={{
              padding: "20px",
              border: "0.0625rem solid #f0f2f5",
              borderRadius: "0.375rem",
            }}
          >
            <MDBox lineHeight={1}>
              {/* {productData.images && productData.images.length > 0 ? <CircularProgress
                          color="primary"
                          sx={{
                            width: "50px",
                            height: "50px",
                          }}
                          size="small"
                        /> : <MDAvatar
              name={productData.name}
              src={productData.images[0].src}
              onLoad={() => setImageLoading(false)}
              size="xl"
              sx={{ mr: 3 }}
            />} */}
            </MDBox>
            <MDBox lineHeight={1}>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 400,
                  mb: 2,
                  color: "#3f3f3f",
                }}
              >
                <strong>{productData?.name}</strong>
              </Typography>
              <Typography
                variant="p"
                sx={{
                  fontWeight: 400,
                  color: "#3f3f3f",
                }}
              >
                <strong>
                  {selectedCountry.currency} {productById[0]?.price}
                </strong>
              </Typography>
            </MDBox>
          </MDBox>
        )}
        <br />
        {/* <CustomTabs
          headerColor="primary"
          tabs={[
            {
              tabName: "Variation 1",
              onClick: () => setOpenedTab("Variation 1"),
              tabContent: <Variation />,
            },
            // {
            //   tabName: "Variation 2",
            //   onClick: () => setOpenedTab("Variation 2"),
            //   tabContent: <DriverTracking />
            // },
          ]}
        /> */}
        <form>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
              <ControlledSelect
                name={"urlId"}
                control={control}
                label="Select URL *"
                menu={{
                  id: "id",
                  value: "url",
                  title: "URLs",
                  list: urlsByCountryId.urls,
                }}
                sx={{
                  "& .MuiFormLabel-root": {
                    backgroundColor: "#fff",
                  },
                  "& .MuiInputBase-root": {
                    height: "44px",
                  },
                }}
                inputProps={{
                  disabled: true,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
              <ControlledTextInput
                name="price"
                control={control}
                labelText="Enter Reseller Price *"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: "number",
                }}
              />
            </GridItem>

            <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
              <ControlledCheckbox
                name="isFeaturedProduct"
                control={control}
                label="Is Feature Product?"
              />
            </GridItem>

            <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
              <ControlledCheckbox name="isDigitalGood" control={control} label="Is Digital Good?" />
            </GridItem>

            <GridItem xs={12} sm={12}>
              <br />
            </GridItem>

            {isDigitalGoodField ? (
              <GridItem xs={12} sm={12}>
                <Box
                  px={2}
                  pb={2}
                  p1={1}
                  sx={{ border: "0.0625rem solid #f0f2f5", borderRadius: "0.375rem" }}
                >
                  <Box display="flex" justifyContent="space-between" my={1}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 500,
                        color: "#3f3f3f",
                      }}
                    >
                      Digital Keys
                    </Typography>
                  </Box>
                  <br />
                  <Box>
                    <div>
                      <GridContainer sx={{ mb: 2 }}>
                        <GridItem xs={12} sm={6} md={6} lg={6}>
                          <CustomSelect
                            id={"dates"}
                            label="Filter by Date"
                            inputProps={{
                              id: "date_label",
                            }}
                            value={filterDate}
                            onChange={(e) => {
                              setFilterDate(e.target.value);
                            }}
                            sx={{
                              "& .MuiFormLabel-root": {
                                backgroundColor: "#fff",
                              },
                              "& .MuiInputBase-root": {
                                height: "44px",
                              },
                            }}
                          >
                            {filterDates.map((x, i) => (
                              <MenuItem key={i} value={x}>
                                {x}
                              </MenuItem>
                            ))}
                          </CustomSelect>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6} lg={6}>
                          {filterDate && filteredKeysState == "active" ? (
                            <Tooltip
                              title="Deactivate All keys created in this Date"
                              placement="top"
                            >
                              <MDButton
                                variant="text"
                                color="info"
                                sx={{ ml: 2 }}
                                onClick={() => deactivateAllKeys()}
                              >
                                <MDBox sx={{ display: "inline-flex", alignItems: "center" }}>
                                  <PauseCircleIcon color="info" fontSize="inherit" />{" "}
                                  <MDBox component="span" ml={1}>
                                    {" "}
                                    Deactivate All
                                  </MDBox>
                                </MDBox>
                              </MDButton>
                            </Tooltip>
                          ) : filterDate && filteredKeysState == "inactive" ? (
                            <Tooltip title="Activate All keys created in this Date" placement="top">
                              <MDButton
                                variant="text"
                                color="success"
                                sx={{ ml: 2 }}
                                onClick={() => activateAllKeys()}
                              >
                                <MDBox sx={{ display: "inline-flex", alignItems: "center" }}>
                                  <PlayCircleIcon color="success" fontSize="inherit" />{" "}
                                  <MDBox component="span" ml={1}>
                                    {" "}
                                    Activate All
                                  </MDBox>
                                </MDBox>
                              </MDButton>
                            </Tooltip>
                          ) : (
                            ""
                          )}
                        </GridItem>
                      </GridContainer>
                      {digitalKeysByProduct?.length > 0 ? (
                        <DataTable
                          headerActions={
                            filterDate && (
                              <Tooltip title="Reset Filter" sx={{ ml: 1 }} placement="top">
                                <IconButton
                                  aria-label="view"
                                  size="small"
                                  onClick={() => setFilterDate("")}
                                >
                                  <RestartAltIcon color="info" fontSize="inherit" />
                                </IconButton>
                              </Tooltip>
                            )
                          }
                          columns={[
                            {
                              Header: "#",
                              accessor: "id",
                            },
                            {
                              Header: "Key",
                              accessor: "key",
                            },
                            {
                              Header: "Status",
                              accessor: "active",
                              Cell: ({ row: { original } }) =>
                                original.active ? (
                                  <MDBadge
                                    badgeContent="Active"
                                    color="success"
                                    variant="gradient"
                                    size="sm"
                                  />
                                ) : (
                                  <MDBadge
                                    badgeContent="Deactivated"
                                    color="info"
                                    variant="gradient"
                                    size="sm"
                                  />
                                ),
                            },
                            {
                              Header: "Created Date",
                              accessor: "date_created",
                            },
                            {
                              Header: "Assigned Date",
                              accessor: "date_assigned",
                              Cell: ({ row: { original } }) => (
                                <span>
                                  {original.date_assigned ? (
                                    original.date_assigned
                                  ) : (
                                    <MDBadge
                                      badgeContent="Not Assigned Yet"
                                      color="secondary"
                                      variant="gradient"
                                      size="sm"
                                    />
                                  )}
                                </span>
                              ),
                            },
                            {
                              Header: "Actions",
                              accessor: "actions",
                              disableSortBy: true,
                              disableFilters: true,
                              show: true,
                              align: "right",
                              // eslint-disable-next-line react/display-name
                              Cell: ({ row: { original } }) => (
                                <div className="actions-right">
                                  {original.active ? (
                                    <Tooltip title="Deactivate" sx={{ ml: 1 }} placement="top">
                                      <IconButton
                                        aria-label="view"
                                        size="small"
                                        onClick={() => {
                                          let data = {
                                            active: false,
                                            ids: [original.id],
                                          };
                                          handleDeactivateKeys(data);
                                        }}
                                      >
                                        <PauseCircleIcon color="info" fontSize="inherit" />
                                      </IconButton>
                                    </Tooltip>
                                  ) : (
                                    <Tooltip title="Activate" placement="top">
                                      <IconButton
                                        aria-label="view"
                                        size="small"
                                        onClick={() => {
                                          let data = {
                                            active: true,
                                            ids: [original.id],
                                          };
                                          handleDeactivateKeys(data);
                                        }}
                                      >
                                        <PlayCircleIcon color="success" fontSize="inherit" />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                  <Tooltip title="Delete Key" sx={{ ml: 1 }} placement="top">
                                    <IconButton
                                      aria-label="view"
                                      size="small"
                                      onClick={() => handleDigitalKeyDelete(original.id)}
                                    >
                                      <Close color="error" fontSize="inherit" />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                              ),
                            },
                          ]}
                          // data={digitalKeysTableData}
                          data={
                            filterDate ? digitalKeysTableDataByFilteredDate : digitalKeysTableData
                          }
                          isSorted={true}
                          entriesPerPage={false}
                          showTotalEntries={false}
                          hideColumnSelection={false}
                          noEndBorder
                          canSearch
                          actionButton={
                            <MDButton
                              variant="gradient"
                              color="primary"
                              sx={{ ml: 2 }}
                              onClick={() => setOpenDigitalKeysDialog(true)}
                            >
                              Add Digital keys
                            </MDButton>
                          }
                        />
                      ) : (
                        <Alert severity="warning">No Digital keys found!</Alert>
                      )}
                    </div>
                  </Box>
                  <BootstrapDialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    maxWidth="xl"
                    sx={{
                      "& .MuiPaper-root": {
                        width: "900px",
                      },
                    }}
                    open={openDigitalKeysDialog}
                  >
                    <DialogTitle sx={{ m: 0, p: 2, color: "#3f3f3f" }} id="customized-dialog-title">
                      Add Digital Keys
                    </DialogTitle>
                    <IconButton
                      aria-label="close"
                      onClick={() => setOpenDigitalKeysDialog(false)}
                      sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                    <DialogContent dividers>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          mb: 2,
                        }}
                      >
                        <Typography
                          variant="h6"
                          sx={{
                            fontWeight: 500,
                            color: "#3f3f3f",
                          }}
                        >
                          Click the button on the right to add/import keys.
                        </Typography>
                        <MDButton
                          color="info"
                          variant="gradient"
                          iconOnly
                          id="keys-button"
                          aria-controls={open ? "keys-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          onClick={handleClick}
                        >
                          <AddBox sx={{}} />
                        </MDButton>
                        <Menu
                          id="keys-menu"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          MenuListProps={{
                            "aria-labelledby": "keys-button",
                          }}
                        >
                          <MenuItem onClick={handleDialogClickOpen}>Import CSV</MenuItem>
                          <MenuItem onClick={addFields}>Add New Field</MenuItem>
                        </Menu>
                      </Box>
                      {dynamicFields.map((input, index) => {
                        return (
                          <GridContainer key={index}>
                            <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                              <CustomInputs
                                name="digital_key"
                                id="digital_key"
                                label="Digital Key *"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                value={input}
                                onChange={(e) => handleDynamicFields(index, e)}
                              />
                            </GridItem>

                            <GridItem xs={12} sm={12} md={1} lg={1} xl={1}>
                              <MDButton
                                color="warning"
                                variant="gradient"
                                iconOnly
                                onClick={() => removeFields(index)}
                              >
                                <IndeterminateCheckBoxIcon />
                              </MDButton>
                            </GridItem>
                          </GridContainer>
                        );
                      })}
                    </DialogContent>
                    <DialogActions>
                      <MDButton
                        type="submit"
                        color="primary"
                        variant="gradient"
                        id="submit"
                        disabled={dynamicFields.length == 0}
                        onClick={() => onSubmitDigitalKeys()}
                      >
                        Add {dynamicFields.length > 1 ? "Keys" : "Key"}
                      </MDButton>
                      <MDButton
                        color="secondary"
                        variant="gradient"
                        autoFocus
                        onClick={() => setOpenDigitalKeysDialog(false)}
                      >
                        Cancel
                      </MDButton>
                    </DialogActions>
                  </BootstrapDialog>
                </Box>
              </GridItem>
            ) : (
              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledTextInput
                  name="availability"
                  control={control}
                  labelText="Enter Availibility "
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "number",
                  }}
                />
              </GridItem>
            )}

            <GridItem xs={12} sm={12}>
              <br />
            </GridItem>
          </GridContainer>
          <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            maxWidth="xl"
            sx={{
              "& .MuiPaper-root": {
                width: "800px",
              },
            }}
            open={openDialog}
          >
            <DialogTitle sx={{ m: 0, p: 2, color: "#3f3f3f" }} id="customized-dialog-title">
              Import Digital Keys
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleDialogClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent dividers>
              <Importer
                chunkSize={10000} // optional, internal parsing chunk size in bytes
                assumeNoHeaders={false} // optional, keeps "data has headers" checkbox off by default
                restartable={false} // optional, lets user choose to upload another file when import is complete
                onStart={({ file, fields }) => {
                  // optional, invoked when user has mapped columns and started import
                  console.log("starting import of file", file, "with fields", fields);
                }}
                processChunk={async (rows) => {
                  // required, receives a list of parsed objects based on defined fields and user column mapping;
                  // may be called several times if file is large
                  // (if this callback returns a promise, the widget will wait for it before parsing more data)
                  console.log("received batch of rows", rows);
                  let newKeys = [];
                  rows.map((key) => newKeys.push(key.digital_keys));
                  setDynamicFields([...dynamicFields, ...newKeys]);

                  console.log(newKeys);
                  console.log(dynamicFields);

                  // mock timeout to simulate processing
                  await new Promise((resolve) => setTimeout(resolve, 500));
                }}
                onComplete={({ file, fields }) => {
                  // optional, invoked right after import is done (but user did not dismiss/reset the widget yet)
                  console.log("finished import of file", file, "with fields", fields);
                }}
                onClose={() => {
                  // optional, invoked when import is done and user clicked "Finish"
                  // (if this is not specified, the widget lets the user upload another file)
                  console.log("importer dismissed");
                  handleDialogClose();
                }}
              >
                <ImporterField name="digital_keys" label="Digital Keys" />
              </Importer>
            </DialogContent>
            <DialogActions>
              {/* <MDButton
                type="submit"
                color="primary"
                variant="gradient"
                id="submit"
                onClick={handleSubmit(onSubmit)}
              >
                Add Country
              </MDButton> */}
              <MDButton color="secondary" variant="gradient" autoFocus onClick={handleDialogClose}>
                Cancel
              </MDButton>
            </DialogActions>
          </BootstrapDialog>
        </form>
        <CardActions disableSpacing>
          * Required fields
          <Box sx={{ marginLeft: "auto" }}>
            <MDButton
              color="primary"
              variant="gradient"
              id="submit"
              onClick={handleSubmit(onSubmit)}
            >
              Edit Stock
            </MDButton>
            <MDButton
              color="secondary"
              variant="gradient"
              onClick={() => navigate.goBack()}
              sx={{ ml: 1 }}
            >
              Cancel
            </MDButton>
          </Box>
        </CardActions>
      </CardContent>
    </Card>
  );
}

export default EditInventory;
