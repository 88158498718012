/* eslint-disable react/prop-types */
import { MenuItem } from "@mui/material";
// import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { CustomAutoComplete } from "components/CustomAutoComplete";
import React from "react";
import { Controller } from "react-hook-form";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

// const useStyles = makeStyles(customSelectStyle);

const ControlledAutoComplete = (props) => {
  // const classes = useStyles();

  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field, formState }) => {
        const { name, onChange, value, ref } = field;
        return (
          <Autocomplete
            sx={{
              '& .MuiAutocomplete-input':{
                height: '0.85em'
              }
            }}
            disabled={props.disabled ? true : false}
            value={value ? props.options.find(option => {
              return value === option.id;
            }) ?? null : null}
            onChange={(event, newValue) => {
              onChange(newValue ? newValue.id : null);
            }}
            getOptionLabel={option => {
              return option[props.optionLabelProperty]
            }}
            id={props.id}
            options={props.options}
            renderInput={(params) => <TextField 
              {...params} 
              label={props.label}
              error={formState.errors[`${name}`]?.message ? true : false}
              helperText={formState.errors[`${name}`]?.message ? formState.errors[`${name}`]?.message : ''}
              inputRef={ref}
            />}
          />
        )
      }}
    />
  );
};

export default ControlledAutoComplete;
