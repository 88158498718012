import {
  go,
  goBack,
  goForward,
  push,
  replace,
  locationChange
} from "store/router";

// listener
export const startRouterListener = (history, store) => {
  store.dispatch(
    locationChange({
      pathname: history.location.pathname,
      state: history.location.state
    })
  );

  history.listen(location => {
    store.dispatch(
      locationChange({
        pathname: location.pathname,
        state: location.state
      })
    );
  });
};

// middleware
const routerMiddleware = history => () => next => action => {
  switch (action.type) {
    case push.type:
      history.push(action.payload);
      break;
    case replace.type:
      history.replace(action.payload);
      break;
    case go.type:
      history.go(action.payload);
      break;
    case goBack.type:
      history.goBack();
      break;
    case goForward.type:
      history.goForward();
      break;
    default:
      return next(action);
  }
};

export default routerMiddleware;
