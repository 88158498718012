import React, { useState, useEffect, useMemo } from "react";

import PropTypes from "prop-types";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { styled } from "@mui/material/styles";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";

import MDButton from "components/MDButton";
import ControlledTextInput from "components/FormInputs/ControlledTextInput";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { trackingLnkSchema } from "./schemas/_model";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
}));

const TrackingLinkDialog = ({ openTrackingDialog, setOpenTrackingDialog, onTrackingLinkSubmit }) => {

    const initialFormState = {
        tracking_link: ""
    };

    const { control, watch, handleSubmit } = useForm({
        resolver: yupResolver(trackingLnkSchema),
        defaultValues: initialFormState,
    });

    return (
        <BootstrapDialog
            onClose={() => setOpenTrackingDialog(false)}
            aria-labelledby="customized-dialog-title"
            maxWidth="lg"
            sx={{
                "& .MuiPaper-root": {
                    width: "550px",
                },
            }}
            open={openTrackingDialog}
        >
            <DialogTitle sx={{ m: 0, p: 2, color: "#4b4b4f" }} id="customized-dialog-title">
                Enter tracking link
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={() => setOpenTrackingDialog(false)}
                sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                <Typography
                    variant="p"
                    sx={{
                        fontWeight: 400,
                        mt: 1,
                        color: "#3f3f3f",
                    }}
                >
                    Enter tracking link in order to mark this as 'Shipped'
                </Typography>
                <form>
                    <ControlledTextInput
                        name="tracking_link"
                        control={control}
                        labelText="Tracking link *"
                        formControlProps={{
                            fullWidth: true,
                        }}
                        multiline
                        sx={{ mt: 2 }}
                        // inputProps={{
                        //     type: "text",
                        // }}
                    />
                </form>
                <br />
            </DialogContent>
            <DialogActions>
                <MDButton
                    type="submit"
                    color="primary"
                    variant="gradient"
                    id="submit"
                    onClick={handleSubmit(onTrackingLinkSubmit)}
                >
                    Mark as shipped
                </MDButton>
                <MDButton color="secondary" variant="gradient" autoFocus onClick={() => setOpenTrackingDialog(false)}>
                    Cancel
                </MDButton>
            </DialogActions>
        </BootstrapDialog>
    )
}

TrackingLinkDialog.propTypes = {
    openTrackingDialog: PropTypes.bool,
    setOpenTrackingDialog: PropTypes.func,
    onTrackingLinkSubmit: PropTypes.func
  };

export default TrackingLinkDialog;