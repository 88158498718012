import { apis } from "./base";

const digitalKeysService = apis.injectEndpoints({
  endpoints: (builder) => ({
    // getAllDigitalKeys: builder.query({
    //   query: () => {
    //     return ``;
    //   },
    //   transformResponse: (response) => response.response,
    //   providesTags: ["DigitalKeys"],
    // }),

    getAllDigitalKeysByProductId: builder.query({
      query: (product_id) => {
        return `/admin/products/keys/all/${product_id}`;
      },
      transformResponse: (response) => response.response.sort((a, b) => b.id - a.id),
      providesTags: ["DigitalKeys"],
    }),

    getAllDigitalKeysByOrderId: builder.query({
      query: (order_id) => {
        return `/admin/orders/digital-keys/${order_id}`;
      },
      transformResponse: (response) => response.response.sort((a, b) => b.id - a.id),
      providesTags: ["DigitalKeys"],
    }),

    postDigitalKey: builder.mutation({
      query: ({product_id, data}) => ({
        url: `/admin/products/keys/new/${product_id}`,
        method: "POST",
        body: data,
      }),
      // transformResponse: (response) => response.response
      invalidatesTags: ["DigitalKeys"],
    }),

    changeDigitalKeysStatus: builder.mutation({
        query: (data) => ({
          url: `/admin/products/keys/update/`,
          method: "PUT",
          body: data,
        }),
        // transformResponse: (response) => response.response
        invalidatesTags: ["DigitalKeys"],
    }),

    updateDigitalKey: builder.mutation({
      query: (data) => ({
        url: `/admin/orders/assign-digital-keys`,
        method: "PUT",
        body: data,
      }),
      // transformResponse: (response) => response.response
      invalidatesTags: ["DigitalKeys"],
  }),

    deleteDigitalKey: builder.mutation({
      query: (key_id) => ({
        url: `/admin/products/keys/delete/${key_id}`,
        method: "DELETE",
      }),
      transformResponse: (response) => response.response,
      invalidatesTags: ["DigitalKeys"],
    }),

    // getAllCountries: builder.query({
    //     query: () => ({
    //       url: "/countries/all",
    //     }),
    //     transformResponse: (response) => response.response,
    //     // providesTags: ["Countries"],
    // }),

    // getOrderProductsByOrderId: builder.query({
    //   query: (order_id) => {
    //     return `/admin/orders/id/${order_id}`;
    // },
    //   transformResponse: (response) => response.response,
    //   providesTags: ["OrderProducts"],
    // }),

    // assigned end users
    getEndUserByDigitalKey: builder.query({
      query: (digitalKeyId) => {
        return `/admin/orders/end-user/${digitalKeyId}`;
      },
      transformResponse: (response) => response.response,
      providesTags: ["DigitalKeys"],
    }),

    postEndUserToDigitalKey: builder.mutation({
      query: (data) => ({
        url: `/admin/orders/end-user/assign`,
        method: "POST",
        body: data,
      }),
      // transformResponse: (response) => response.response
      invalidatesTags: ["DigitalKeys"],
    }),

    updateEndUserToDigitalKey: builder.mutation({
      query: ({ id, data}) => ({
        url: `/admin/orders/end-user/edit/${id}`,
        method: "PUT",
        body: data,
      }),
      // transformResponse: (response) => response.response
      invalidatesTags: ["DigitalKeys"],
    }),

    deleteEndUserToDigitalKey: builder.mutation({
      query: (key_id) => ({
        url: `/admin/orders/end-user/remove/${key_id}`,
        method: "DELETE",
      }),
      transformResponse: (response) => response.response,
      invalidatesTags: ["DigitalKeys"],
    }),
  }),
});

export const {
  useGetAllDigitalKeysByProductIdQuery,
  useLazyGetAllDigitalKeysByProductIdQuery,
  useGetAllDigitalKeysByOrderIdQuery,
  useLazyGetAllDigitalKeysByOrderIdQuery,
  usePostDigitalKeyMutation,
  useChangeDigitalKeysStatusMutation,
//   usePDigitalKeyMutation,
  useUpdateDigitalKeyMutation,
  useDeleteDigitalKeyMutation,
  useGetEndUserByDigitalKeyQuery,
  useLazyGetEndUserByDigitalKeyQuery,
  usePostEndUserToDigitalKeyMutation,
  useUpdateEndUserToDigitalKeyMutation,
  useDeleteEndUserToDigitalKeyMutation
} = digitalKeysService;
