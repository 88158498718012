/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import { Importer, ImporterField } from "react-csv-importer";

import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { styled } from "@mui/material/styles";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import MDButton from "components/MDButton";

import { AddBox } from "@mui/icons-material";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";

import Typography from "@mui/material/Typography";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import ControlledTextInput from "components/FormInputs/ControlledTextInput";
import CustomInputs from "components/CustomInputs";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import Box from "@mui/material/Box";

import { digitalKeysSchema } from "../schemas/_model";

import AvailabilityDialog from '../AvailabilityDialog';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function AddDigitalKeysDialog({ onSubmit }) {
  const initialFormState = {
    digitalKeys: []
  };

  const [openDigitalKeysDialog, setOpenDigitalKeysDialog] = useState(false);

  const [availabilityFields, setAvailabilityFields] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);

  const [openDialog, setOpenDialog] = useState(false);

  const [openAvailabilityDialog, setOpenAvailabilityDialog] = useState(false);

  const { control, watch, handleSubmit, setValue, reset } = useForm({
    resolver: yupResolver(digitalKeysSchema),
    defaultValues: initialFormState,
  });

  const { fields, append, remove } = useFieldArray({ name: "digitalKeys", control });

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDialogClickOpen = () => {
    setOpenDialog(true);
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleAvailabilityFields = (index, e) => {
    let data = [];
    data[index] = e.target.value;
  };

  const addEmptyFields = () => {
    append({ key: "" });
  };

  const addFields = (param) => {
    append({ key: param });
  };

  const addAvailability = (availability) => {
    let newField = "Pending";
    setAvailabilityFields([...availabilityFields, ...Array(availability).fill(newField)]);
  };

  const removeFields = (index) => {
    remove(index);
  };

  const removeAvailabilityFields = (index) => {
    let data = [...availabilityFields];
    data.splice(index, 1);
    setAvailabilityFields(data);
  };

  const onAvailabilitySubmit = (data) => {
    addAvailability(data.availability);
    setOpenAvailabilityDialog(false);
  }

  const onDigitalKeysSubmit = (data) => {
    let keys = [...availabilityFields];
    if(data && data.digitalKeys && data.digitalKeys.length > 0) {
      for(let key of data.digitalKeys) {
        if(key.key) keys.push(key.key);
      }
    }
    onSubmit(keys);
    setOpenDigitalKeysDialog(false);
    reset();
  };

  return (
    <>
      <MDButton
          variant="gradient"
          color="primary"
          sx={{ ml: 2 }}
          onClick={() => setOpenDigitalKeysDialog(true)}
      >
          Add Digital keys
      </MDButton>
      <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          maxWidth="xl"
          sx={{
              "& .MuiPaper-root": {
              width: "800px",
              },
          }}
          open={openDialog}
          >
          <DialogTitle sx={{ m: 0, p: 2, color: "#3f3f3f" }} id="customized-dialog-title">
              Import Digital Keys
          </DialogTitle>
          <IconButton
              aria-label="close"
              onClick={handleDialogClose}
              sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
              }}
          >
              <CloseIcon />
          </IconButton>
          <DialogContent dividers>
              <Importer
              chunkSize={10000} // optional, internal parsing chunk size in bytes
              assumeNoHeaders={false} // optional, keeps "data has headers" checkbox off by default
              restartable={false} // optional, lets user choose to upload another file when import is complete
              onStart={({ file, fields }) => {
                  // optional, invoked when user has mapped columns and started import
                  console.log("starting import of file", file, "with fields", fields);
              }}
              processChunk={async (rows) => {
                  // required, receives a list of parsed objects based on defined fields and user column mapping;
                  // may be called several times if file is large
                  // (if this callback returns a promise, the widget will wait for it before parsing more data)
                  // console.log("received batch of rows", rows);
                  let newKeys = [];
                  rows.map((key) => newKeys.push(key.digital_keys));
                  for(let key of newKeys) {
                    addFields(key);
                  }

                  // mock timeout to simulate processing
                  await new Promise((resolve) => setTimeout(resolve, 500));
              }}
              onComplete={({ file, fields }) => {
                  // optional, invoked right after import is done (but user did not dismiss/reset the widget yet)
                  console.log("finished import of file", file, "with fields", fields);
              }}
              onClose={() => {
                  // optional, invoked when import is done and user clicked "Finish"
                  // (if this is not specified, the widget lets the user upload another file)
                  console.log("importer dismissed");
                  handleDialogClose();
              }}
              >
              <ImporterField name="digital_keys" label="Digital Keys" />
              </Importer>
          </DialogContent>
      </BootstrapDialog>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        maxWidth="xl"
        sx={{
          "& .MuiPaper-root": {
            width: "900px",
          },
        }}
        open={openDigitalKeysDialog}
      >
        <DialogTitle sx={{ m: 0, p: 2, color: "#3f3f3f" }} id="customized-dialog-title">
          Add Digital Keys
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setOpenDigitalKeysDialog(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: 500,
                color: "#3f3f3f",
              }}
            >
              Click the button on the right to add/import keys.
            </Typography>
            <MDButton
              color="info"
              variant="gradient"
              iconOnly
              id="keys-button"
              aria-controls={open ? "keys-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <AddBox sx={{}} />
            </MDButton>
            <Menu
              id="keys-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "keys-button",
              }}
            >
              <MenuItem onClick={handleDialogClickOpen}>Import CSV</MenuItem>
              <MenuItem onClick={addEmptyFields}>Add New Field</MenuItem>
              <MenuItem onClick={() => setOpenAvailabilityDialog(true)}>Enter Availability Manually</MenuItem>
            </Menu>
          </Box>
          <form>
          {fields.map((input, index) => {
            return (
              <GridContainer key={index}>
                <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                  <ControlledTextInput
                    name={`digitalKeys.${index}.key`}
                    control={control}
                    label="Digital Key *"
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={1} lg={1} xl={1}>
                  <MDButton
                    color="warning"
                    variant="gradient"
                    iconOnly
                    onClick={() => removeFields(index)}
                  >
                    <IndeterminateCheckBoxIcon />
                  </MDButton>
                </GridItem>
              </GridContainer>
            );
          })}
          {availabilityFields.map((input, index) => {
            return (
              <GridContainer key={index}>
                <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                  <CustomInputs
                    name="pending_digital_key"
                    id="pending_digital_key"
                    label="Digital Key (Manual)"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      readOnly: true
                    }}
                    value={input}
                    onChange={(e) => handleAvailabilityFields(index, e)}
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={1} lg={1} xl={1}>
                  <MDButton
                    color="warning"
                    variant="gradient"
                    iconOnly
                    onClick={() => removeAvailabilityFields(index)}
                  >
                    <IndeterminateCheckBoxIcon />
                  </MDButton>
                </GridItem>
              </GridContainer>
            );
          })}
          </form>
          <AvailabilityDialog
            openDialog={openAvailabilityDialog}
            closeDialog={setOpenAvailabilityDialog}
            onAvailabilitySubmit={onAvailabilitySubmit}
          />
        </DialogContent>
        <DialogActions>
          <MDButton
            type="submit"
            color="primary"
            variant="gradient"
            id="submit"
            disabled={fields.length == 0 && availabilityFields.length == 0}
            onClick={handleSubmit(onDigitalKeysSubmit)}
          >
            Add {fields.length > 1 ? "Keys" : "Key"}
          </MDButton>
          <MDButton
            color="secondary"
            variant="gradient"
            autoFocus
            onClick={() => setOpenDigitalKeysDialog(false)}
          >
            Cancel
          </MDButton>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}

export default AddDigitalKeysDialog;
