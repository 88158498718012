/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Notify } from "notiflix/build/notiflix-notify-aio";
import { Confirm } from "notiflix/build/notiflix-confirm-aio";

import { styled } from "@mui/material/styles";

import moment from "moment";

import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Block } from "notiflix/build/notiflix-block-aio";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";

import TextField from "@mui/material/TextField";

import Box from "@mui/material/Box";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { AddBox } from "@mui/icons-material";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Typography from "@mui/material/Typography";

import ControlledTextInput from "components/FormInputs/ControlledTextInput";
import ControlledDateTime from "components/FormInputs/ControlledDateTime";
import ControlledCheckbox from "components/FormInputs/ControlledCheckbox";
import ControlledSelect from "components/FormInputs/ControlledSelect";
import CustomInputs from "components/CustomInputs";
import { CustomSelect } from "components/CustomSelect";

//components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
import MDBadge from "components/MDBadge";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import { BDMSchema } from "./schemas/_model";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import Check from "@mui/icons-material/Check";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from '@mui/material/FormControlLabel';

import Tooltip from "@mui/material/Tooltip";

import Edit from "@mui/icons-material/Edit";
import Close from "@mui/icons-material/Close";
import SettingsIcon from '@mui/icons-material/Settings';

import InputAdornment from "@mui/material/InputAdornment";

import Alert from "components/CustomAlert";

import { tables, dateFormat, dateTimeFormat } from "utils/constants";

import DetailBlock from "components/DetailBlock";

import EditSchedulerRule from './Edit';

// rtk queries
import {
  useGetAllConfigsQuery,
  useLazyGetAllConfigsQuery,
  useGetSchedulerStatusQuery,
  useStartSchedulerMutation,
  useStopSchedulerMutation,
} from "services/configs";

import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import StopCircleIcon from '@mui/icons-material/StopCircle';

import { globalCountry } from "store/entities/countries";

import { useDispatch, useSelector } from "react-redux";

import { replaceHTMLEntityAnd } from "utils/helperFunctions";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function Configs () {

  const { data: configsList = [], isLoading, isSuccess } = useGetAllConfigsQuery();

  const { data: schedulerStatus = {}, isLoading: isSchedulerStatusLoading, isSchedulerStatusSuccess } = useGetSchedulerStatusQuery();

  const [startScheduler] = useStartSchedulerMutation();
  const [stopScheduler] = useStopSchedulerMutation();

  const dispatch = useDispatch();

  useEffect(() => {
    isLoading && isSchedulerStatusLoading ? Loading.dots({
      svgColor: "rgba(159, 3, 3, 0.8)",
    }) : Loading.remove(500);
  }, [isLoading, isSchedulerStatusLoading]);

  const handleStartScheduler = async () => {
    Loading.dots({
      svgColor: "rgba(159, 3, 3, 0.8)",
    });

    try {
      const result = await startScheduler();
      if(result && result.data?.status == 'success') {
        Notify.success("Started the scheduler!");
      } else {
        Notify.failure("Something went wrong. Please try again!");
      }
    } catch (error) {

    }

    Loading.remove(500);
  }

  const handleStopScheduler = async () => {
    Loading.dots({
      svgColor: "rgba(159, 3, 3, 0.8)",
    });

    try {
      const result = await stopScheduler();
      if(result && result.data?.status == 'success') {
        Notify.success("Stopped the scheduler!");
      } else {
        Notify.failure("Something went wrong. Please try again!");
      }
    } catch (error) {

    }

    Loading.remove(500);
  }

  return (
    <Card>
      <CardHeader
        // avatar={<AccountCircleIcon fontSize="large" color="info" />}
        title={<MDTypography color="info">Configs</MDTypography>}
        action={schedulerStatus && schedulerStatus.running ? <Tooltip title="Stop the scheduler" placement="top">
        <IconButton aria-label="view" size="large" onClick={handleStopScheduler} sx={{ mr: 1 }}>
          <StopCircleIcon color="error" fontSize="inherit" />
        </IconButton>
      </Tooltip> : <Tooltip title="Start the scheduler" placement="top">
        <IconButton aria-label="view" size="large" onClick={handleStartScheduler} sx={{ mr: 1 }}>
          <PlayCircleFilledWhiteIcon color="info" fontSize="inherit" />
        </IconButton>
    </Tooltip>}
      />
      <CardContent>
        <br />
        {schedulerStatus && <GridContainer>
        <GridItem xs={12} sm={12} md={3} lg={3} xl={3}>
          <DetailBlock blockTitle="Scheduler status" blockDesc={schedulerStatus.running ? <MDBadge badgeContent="Active" color="success" variant="gradient" size="sm" /> : <MDBadge badgeContent="Inactive" color="error" variant="gradient" size="sm" />} />
        </GridItem>
        <GridItem xs={12} sm={12} md={3} lg={3} xl={3}>
          <DetailBlock blockTitle="Next Trigger time" blockDesc={schedulerStatus.running ? moment(schedulerStatus.nextTriggerTime).format(dateTimeFormat) : 'N/A'} />
        </GridItem><br />
        </GridContainer>}
        {configsList && configsList.length > 0 ? <GridContainer>
          <GridItem xs={12} sm={12} md={3} lg={3} xl={3}>
            <DetailBlock blockTitle="Timezone" blockDesc={configsList[0].value} />
          </GridItem>
          <GridItem xs={12} sm={12} md={3} lg={3} xl={3}>
            <DetailBlock blockTitle="Hour" blockDesc={configsList[1].value} />
          </GridItem>
          <GridItem xs={12} sm={12} md={3} lg={3} xl={3}>
            <DetailBlock blockTitle="Minutes" blockDesc={configsList[2].value} />
          </GridItem>
          <GridItem xs={12} sm={12} md={3} lg={3} xl={3}>
            <EditSchedulerRule initialData={configsList} />
          </GridItem>
        </GridContainer> : ""}
        <br />
      </CardContent>
    </Card>
  );
}

export default Configs;
