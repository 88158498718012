import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Notify } from "notiflix/build/notiflix-notify-aio";

// react hook form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

// react-router-dom components
import { Link, useHistory } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

import CircularProgress from "@mui/material/CircularProgress";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

//components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import ControlledTextInput from "components/FormInputs/ControlledTextInput";

// queries
import { setAuth } from "store/auth";
import { usePostLoginMutation } from "services/auth";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

// schemas
import { LoginSchema } from "./_model";

// constants, initials
const initialFormState = {
  email: "",
  password: "",
};

function Basic() {
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();

  const history = useHistory();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues: initialFormState,
  });

  const [postCredentials, { isLoading }] = usePostLoginMutation();

  useEffect(() => {
    localStorage.clear();
  }, []);

  const submit = async (data) => {
    postCredentials(data)
      .then((res) => {
        console.log(res);
        if (res.data?.status == "success") {
          let jwt = res.data.response.token;
          let uId = res.data.response.id;
          let userData = {
            isLoggedIn: true,
            userId: uId,
            email: res.data.response.email,
            tokenKey: jwt,
          };
          localStorage.setItem("jwt", jwt);
          localStorage.setItem("uId", uId);
          dispatch(setAuth(userData));
          // console.log(res);
          history.push("/user/inventory");
          Notify.success("Succefully Logged In!");
        } else if (res.error) {
          if (res.error?.status == 400) {
            Notify.failure(res.error.data.message);
          } else {
            Notify.failure(res.error.data.message);
          }
        } else {
          Notify.failure("Something went wrong!");
          // console.log(res);
        }
      })
      .catch((err) => {
        Notify.failure("Something went wrong!");
        console.log(err);
      });
  };

  return (
    <BasicLayout sx={{ backgroundColor: "#f0f2f5" }}>
      <Card sx={{ maxWidth: "400px", margin: "0 auto" }}>
        <CardHeader
          title={
            <MDTypography color="dark" sx={{ textAlign: "center" }}>
              Login
            </MDTypography>
          }
        />
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form">
            <MDBox mb={2}>
              <ControlledTextInput
                name="email"
                spellCheck={false}
                control={control}
                labelText="Email *"
                formControlProps={{
                  fullWidth: true,
                  variant: "outlined",
                  size: "small",
                }}
                inputProps={{
                  type: "email",
                }}
                autoComplete="email"
              />
            </MDBox>
            <MDBox mb={2}>
              <ControlledTextInput
                name="password"
                spellCheck={false}
                control={control}
                labelText="Password *"
                formControlProps={{
                  fullWidth: true,
                  variant: "outlined",
                  size: "small",
                }}
                inputProps={{
                  type: "password",
                }}
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="primary"
                disabled={isLoading}
                sx={{ backgroundColor: "linear-gradient(195deg, #c93939, #9F0303)" }}
                onClick={handleSubmit(submit)}
                fullWidth
              >
                Login{" "}
                {isLoading && (
                  <CircularProgress
                    sx={{ width: "20px!important", height: "20px!important", ml: 1 }}
                    color="inherit"
                  />
                )}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
