import * as yup from "yup";

export const companiesSchema = yup.object().shape({
  is_credit_customer: yup.bool().label("Is credit customer"),
  name: yup.string().required().default("").label("Name"),
  trading_as: yup.string().default("").label("Trading as"),
  proprietor: yup.string().default("").label("Proprietor"),
  gst_no: yup.string().required().default("").label("GST no"),
  reg_no: yup.string().required().default("").label("Registration no"),
  is_public_company: yup.bool().label("Is public company"),
  website: yup
    .string()
    .default("")
    .label("Website"),
  no_of_employees: yup.number().default(1).label("Number of employees"),
  years_in_business: yup.number().default(1).label("Years in business"),
  type: yup.string().required().default("").label("Type"),
  invoice_email: yup.string().email().required().default("").label("Invoice email"),
  address: yup.string().required().default("").label("Address"),
  billing_address: yup.string().required().default("").label("Billing Address"),
  // country_id: yup.number().required().default("").label("Country"),
  postcode: yup.string().required().default("").label("Postcode"),
  biling_postcode: yup.string().required().default("").label("Biling postcode"),
  main_phone: yup.string().required().default("").label("Main phone"),
  email: yup.string().email().required().default("").label("Email"),
  account_payable_contact: yup.string().required().default("").label("Account payable contact"),
  account_payable_phone: yup.string().required().default("").label("Account payable phone"),
  account_payable_fax: yup.string().default("").label("Account payable fax"),
  account_payable_email: yup.string().email().default("").label("Account payable email"),
  booking_officer: yup.string().default("").label("Booking officer"),
  booking_officer_email: yup.string().email().default("").label("Booking officer email"),
  booking_officer_phone: yup.string().default("").label("Booking officer phone"),
  is_part_ship: yup.bool().label("Is part ship"),
  expected_monthly_purchase: yup.number().min(0).default("").label("Expected monthly purchase"),
  credit_limit: yup.number().min(0).default("").label("Credit limit"),
  // credit_note: yup.string().default("").label("Credit Note"),
  year_end_date: yup.string().default("").label("Year end date"),
  is_audited_on_year_end: yup.bool().label("Is audited on year end"),
  invoice_ninja_client_id: yup.string().required().default("").label("Invoice ninja client ID"),
  enable_free_shipping: yup.bool().label("Is part ship"),
});

export const addDiscountSchema = yup.object().shape({
  product_id: yup.array().of(yup.number()).required().min(1).label("One or more products"),
  discount_percentage: yup.number().min(1).default("").label("Discount persentage"),
});

export const editDiscountSchema = yup.object().shape({
  product_id: yup.number().min(1).default("").label("Product"),
  discount_percentage: yup.number().min(1).default("").label("Discount persentage"),
});

export const resellersSchema = (edit) =>
  yup.object().shape({
    email: yup.string().email().required().default("").label("Email"),
    firstName: yup.string().required().default("").label("First Name"),
    lastName: yup.string().required().default("").label("Last Name"),
    phone: yup.string().required().default("").label("Phone"),
    address1: edit
      ? yup.string().required().default("").label("Address 1")
      : yup.string().default("").label("Address 1"),
    address2: edit
      ? yup.string().required().default("").label("Address 2")
      : yup.string().default("").label("Address 2"),
    state: edit ? yup.number().required().label("State") : yup.string().label("State"),
    subCompanyName: yup.string().default("").label("Sub company"),
    memberId: yup.string().default("").label("Member ID"),
  });
