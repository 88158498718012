import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";

import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER
  } from "redux-persist";

import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import { createBrowserHistory } from "history";

import reducer from "./reducers";
import router, { startRouterListener } from "./middleware/router";

import { apis } from "services/base";

export const history = createBrowserHistory();

const persistConfig = {
    key: "root",
    version: 1,
    storage,
    whitelist: ["UI", "auth"],
    throttle: 1000,
    stateReconciler: autoMergeLevel2
};

const persistedReducer = persistReducer(persistConfig, reducer);
  
export const configStore = function () {
    return configureStore({
      reducer: persistedReducer,
      middleware: [
        ...getDefaultMiddleware({
          serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
          }
        }).concat(apis.middleware),
        router(history)
      ],
      devTools: process.env.NODE_ENV !== "production"
    });
};

const store = configStore();

export let persistor = persistStore(store);

startRouterListener(history, store);

export default store;

