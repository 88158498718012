import React from "react";

import PropTypes from "prop-types";

import { TextField } from "@mui/material";

function CustomInput({ ...props }) {
  const {
    // classes,
    formControlProps,
    labelText,
    id,
    labelProps,
    inputProps,
    error,
    white,
    // inputRootCustomClasses,
    success,
    helperText,
    name,
    onClick,
    onKeyDown,
    spellCheck = true,
    multiline = false,
    sx,
    ...rest
  } = props;

  return (
    <TextField
      // className={formControlClasses}
      variant="outlined"
      error={error}
      id={id}
      name={name ? name : ""}
      label={labelText ?? ""}
      helperText={helperText}
      sx={{
        ...(sx && sx),
        mb: 2,
        "& .MuiInputBase-input.Mui-disabled": {
          textFillColor: "#7d8184",
          color: "#7d8184",
        },
        "& .MuiInputBase-root.MuiOutlinedInput-root": { paddingRight: "8px" },
        "& .MuiButtonBase-root.MuiIconButton-root": { marginLeft: "8px" },
      }}
      spellCheck={spellCheck}
      multiline={multiline}
      InputProps={inputProps}
      onClick={onClick}
      onKeyDown={onKeyDown}
      {...formControlProps}
      {...rest}
    />
  );
}

CustomInput.propTypes = {
  formControlProps: PropTypes.object,
  labelText: PropTypes.string,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  labelProps: PropTypes.object,
  white: PropTypes.string,
  success: PropTypes.bool,
  // success: PropTypes.string,
  onKeyDown: PropTypes.object,
  sx: PropTypes.object,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func,
  variant: PropTypes.string,
  multiline: PropTypes.bool,
  spellCheck: PropTypes.bool,
};

export default CustomInput;
