import { combineReducers } from "@reduxjs/toolkit";
import UIReducer from "./UIReducer";
import EntityReducer from "./entityReducer";
import authReducer from "store/auth";
import { apis } from "services/base";
import { productStoreAPI } from "services/store";

import countries from "store/entities/countries";

const appReducer = combineReducers({
  auth: authReducer,
  [apis.reducerPath]: apis.reducer,
  [productStoreAPI.reducerPath]: productStoreAPI.reducer,
  UI: UIReducer,
  Entities: EntityReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "LOG_OUT") {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
