// import * as React from 'react';
// import Backdrop from '@mui/material/Backdrop';
// import CircularProgress from '@mui/material/CircularProgress';

// export default function FallBack() {
//   return (
//     <div>
//       <Backdrop
//         sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
//         open={open}
//         onClick={true}
//       >
//         <CircularProgress color="inherit" />
//       </Backdrop>
//     </div>
//   );
// }

import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

const FallBack = () => {

  return <div>Loading...</div>;
};
export default FallBack;