import React, { useState, useEffect, useMemo } from "react";

import PropTypes from "prop-types";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Notify } from "notiflix/build/notiflix-notify-aio";
import { Loading } from "notiflix/build/notiflix-loading-aio";

import { styled } from "@mui/material/styles";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import Link from '@mui/material/Link';

import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import ControlledTextInput from "components/FormInputs/ControlledTextInput";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import AddVariationDialog from '../AddInventory/AddVariationDialog';
import EditVariationDialog from '../AddInventory/EditVariationDialog';

// queries
import {
  useConvertProductMutation,
  usePostProductMutation,
  useLazyGetAllResellerProductsByCountryIdQuery
} from "services/products";

import { useUploadFileMutation, useDeleteFileMutation } from "services/files";

import { replaceHTMLEntityAnd } from "utils/helperFunctions";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
}));

const TypeConversionDialog = ({ openTypeConversionDialog, setOpenTypeConversionDialog, parentProduct, distinctAttributes }) => {

    const [productVariations, setProductVariations] = useState([]);

    const [uploadFile, { isLoading: isFileUploading }] = useUploadFileMutation();

    const [postProduct] = usePostProductMutation();

    const [convertProductType] = useConvertProductMutation();

    const [
      refetchAllProducts,
      { data: productList = [], isLoading: isLoadingProductList, isSuccess: isSuccessProductList },
    ] = useLazyGetAllResellerProductsByCountryIdQuery();

    const convertToVariation = async () => {
      let parentCategories = [];
      let parentImages = [];

      for(let category of parentProduct.proucts_category) {
        parentCategories.push(category.category_id)
      }

      for(let image of parentProduct.products_images) {
        parentImages.push(image.id)
      }
      
      try {
        let data = {
          woocommerceId: 0,
          parentId: 0,
          countryId: parentProduct.country_id,
          isDigitalGood: parentProduct.is_digital_good,
          inStock: parentProduct.is_in_stock,
          price: parentProduct.price,
          retailPrice: parentProduct.retail_price,
          isFeaturedProduct: parentProduct.is_featured_product,
          urlId: parentProduct.url_id,
          digitalKeys: [],
          images: [],
          attributes: [],
          categories: parentCategories,
          averageRating: parentProduct.average_rating ? parseFloat(parentProduct.average_rating) : 5,
          description: parentProduct.description ? parentProduct.description : "...",
          name: replaceHTMLEntityAnd(parentProduct.name),
          ratingCount: parentProduct.rating_count,
          shortDescription: parentProduct.short_description ? parentProduct.short_description : "...",
          sku: parentProduct.sku,
          type: 'variable',
        };
  
        const simplePostResponse = await postProduct(data);

        if(simplePostResponse && simplePostResponse.data && simplePostResponse.data.response) {
          const typeConversion = await convertProductType({ id: parentProduct.id, data: { type: 'variable', woocommerceId: 0, parentId: simplePostResponse.data.response.id, parentImages: parentImages } });

          if (typeConversion.data?.status == "success") {
            Notify.success("Product has been converted successfully, please add attributes to the variation!");
          }
          
        } else {
          Notify.failure("Something went wrong. Please try again!");
        }
      } catch (e) {
        console.log(e);
      } finally {
        setOpenTypeConversionDialog(false);
        refetchAllProducts(parentProduct.country_id);
        Loading.remove(500);
      }
    }
    
    const handleSubmit = async () => {
      let parentCategories = [];

      for(let category of parentProduct.proucts_category) {
        parentCategories.push(category.category_id)
      }

      Loading.dots({
        svgColor: "rgba(159, 3, 3, 0.8)",
      });
      
      try {
        const typeConversion = await convertProductType({ id: parentProduct.id, data: { type: 'variable', woocommerceId: 0 } });

        if (typeConversion.data?.status == "success") {
          Notify.success("Product has been edited successfully!");
          
          if (productVariations.length > 0) {
            for (let variation of productVariations) {
              variation.urlId = parentProduct.url_id;
              variation.countryId = parentProduct.country_id;
              variation.isFeaturedProduct = false;
              variation.type = "variable";
              variation.categories = parentCategories;
              variation.parentId = parentProduct.id;
  
              delete variation.id;

              if (variation.variationImage) {
                const variableImagePath = [];

                let formData = new FormData();

                formData.append("file", variation.variationImage);

                let variationImageResponse = await uploadFile({
                  formData: formData,
                  directory: "product_images",
                });
                if (variationImageResponse.data.status == "success") {
                  variableImagePath.push(
                    variationImageResponse.data?.response.path.replace(/\\/g, "/")
                  );
                }

                variation.images = variableImagePath;
              } else {
                variation.images = [];
              }

              await postProduct(variation);
            }
          }
          
        } else {
          Notify.failure("Something went wrong. Please try again!");
        }
      } catch (e) {
      } finally {
        setOpenTypeConversionDialog(false);
        refetchAllProducts(parentProduct.country_id);
        Loading.remove(500);
      }
    }

    const showMessage = (type, message) => {
        if(type == 'error') {
          Notify.failure(message);
        } else {
          Notify.success(message);
        }
      }

    const handleProductVariationSubmit = (variationData) => {
        let data = [...productVariations, variationData];
        setProductVariations(data);
    }

    const handleEditProductVariationSubmit = (variationData) => {
        let data = [];
        let filterVariation = productVariations.filter((variation) => variation.id != variationData.id);
        if (filterVariation) {
        data = [...filterVariation, variationData];
        }
        setProductVariations(data);
    }

    const deleteVariation = (id) => {
        let filterVariation = productVariations.filter((variation) => variation.id != id);
        setProductVariations(filterVariation);
    }

    return (
        <BootstrapDialog
            onClose={() => setOpenTypeConversionDialog(false)}
            aria-labelledby="customized-dialog-title"
            maxWidth="lg"
            sx={{
                "& .MuiPaper-root": {
                    width: "600px",
                },
            }}
            open={openTypeConversionDialog}
        >
            <DialogTitle sx={{ m: 0, p: 2, color: "#4b4b4f" }} id="customized-dialog-title">
                Add variations
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={() => setOpenTypeConversionDialog(false)}
                sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                <Typography
                    variant="p"
                    sx={{
                        fontWeight: 400,
                        mt: 1,
                        color: "#3f3f3f",
                    }}
                >
                    You need to add at least one variation in order to convert the product to variable. (this method is not recommend if the existing product is a digital product)
                </Typography>
                <br />
                <MDBox
                  px={2}
                  pb={2}
                  p1={1}
                  sx={{ border: "0.0625rem solid #f0f2f5", borderRadius: "0.375rem", mb: 2, mt: 1 }}
                >
                  <MDBox display="flex" justifyContent="space-between" my={1}>
                    <Typography
                        variant="p"
                        sx={{
                            fontWeight: 400,
                            mt: 1,
                            mb: 1,
                            color: "#3f3f3f",
                        }}
                    >
                        Variations
                    </Typography>
                    <div>
                      <AddVariationDialog
                        showMessage={showMessage}
                        handleProductVariationSubmit={handleProductVariationSubmit}
                        variationCount={productVariations.length + 1}
                        parentAttributes={distinctAttributes}
                      />
                    </div>
                  </MDBox>
                  <br />
                  {productVariations.map((data, index) => {
                    return (
                      <EditVariationDialog
                        key={index}
                        handleEditProductVariationSubmit={handleEditProductVariationSubmit}
                        initData={data}
                        parentAttributes={distinctAttributes}
                        deleteVariation={deleteVariation}
                      >
                        {data.name}
                      </EditVariationDialog>
                    );
                  })}
                </MDBox>
                <Typography
                    variant="p"
                    sx={{
                        fontWeight: 400,
                        mt: 1,
                        mb: 1,
                        color: "#3f3f3f",
                    }}
                >
                    Or you can <Link component="button" variant="body2" sx={{ color: '#1A73E8', fontWeight: 500, lineHeight: 1 }} onClick={convertToVariation}>convert this simple product as a variation</Link> (Parent product will have the current product decriptions...).
                </Typography>
            </DialogContent>
            <DialogActions>
                <MDButton
                    type="submit"
                    color="primary"
                    variant="gradient"
                    id="submit"
                    disabled={productVariations && productVariations.length > 0 ? false : true}
                    onClick={handleSubmit}
                >
                    Submit
                </MDButton>
                <MDButton color="secondary" variant="gradient" autoFocus onClick={() => setOpenTypeConversionDialog(false)}>
                    Cancel
                </MDButton>
            </DialogActions>
        </BootstrapDialog>
    )
}

TypeConversionDialog.propTypes = {
    openTypeConversionDialog: PropTypes.bool,
    setOpenTypeConversionDialog: PropTypes.func,
    distinctAttributes: PropTypes.array,
    parentProduct: PropTypes.object,
    closeNote: PropTypes.func
};

export default TypeConversionDialog;