/* eslint-disable react/prop-types */
import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";

import moment from "moment";

import { styled } from "@mui/material/styles";

import PropTypes from "prop-types";

import MDButton from "components/MDButton";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import KeyIcon from "@mui/icons-material/Key";
import CloseIcon from "@mui/icons-material/Close";

import MDBadge from "components/MDBadge";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";

import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

import DataTable from "examples/Tables/DataTable/DisabledState";

import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Notify } from "notiflix/build/notiflix-notify-aio";
import { Confirm } from "notiflix/build/notiflix-confirm-aio";

import Alert from "components/CustomAlert";
import { Block } from "notiflix/build/notiflix-block-aio";

import AssignedUsers from "./DigitalKeys/AssignedUsers";

import { dateFormat } from "utils/constants";

import {
  useLazyGetAllDigitalKeysByOrderIdQuery,
  useChangeDigitalKeysStatusMutation,
} from "services/digitalKeys";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

import MDBox from "components/MDBox";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const DigitalKeys = ({
  product_id,
  order_product_id,
  order_id,
  product_name,
  currentDigitalKeys,
  product_variation,
}) => {
  const [openDigitalKeysDialog, setOpenDigitalKeysDialog] = useState(false);

  const [tableData, setTableData] = useState([]);

  const [
    triggerDigitalKeys,
    {
      data: digitalKeysByProduct = [],
      isLoading: isLoadingDigitalKeysByProduct,
      isSuccess: isSuccessDigitalKeysByProduct,
    },
  ] = useLazyGetAllDigitalKeysByOrderIdQuery();

  const [changeDigitalKeyStatus, { isLoading: isStatusChanging }] =
    useChangeDigitalKeysStatusMutation();

  const handleDialogOpen = async () => {
    // Loading.dots({ svgColor: "rgba(159, 3, 3, 0.8)", });
    setOpenDigitalKeysDialog(true);

    if (currentDigitalKeys && currentDigitalKeys.length > 0) {
      setTableData(currentDigitalKeys);
    } else {
      resyncDigitalKeys();
    }
    // let data = {
    //   "countryId": countryId,
    //   "paymentIntentId": paymentIntentId,
    // }
    // triggerRefundHistory(data).then(response => setTableData(response.data.response.data));
  };

  const resyncDigitalKeys = async () => {
    // Loading.dots({ svgColor: "rgba(159, 3, 3, 0.8)", });
    Block.dots("#digitalKeysTable", { svgColor: "rgba(159, 3, 3, 0.8)" });

    const digitalKeys = await triggerDigitalKeys(order_id);
    if (digitalKeys && digitalKeys.data.length > 0) {
      setTableData(digitalKeys.data);
    }

    Block.remove("#digitalKeysTable", 500);
  };

  const handleDialogClose = () => {
    setOpenDigitalKeysDialog(false);
  };

  const handleDeactivateKeys = (data) => {
    changeDigitalKeyStatus(data)
      .then((response) => {
        if (response.data?.status == "success") {
          Loading.remove(500);
          Notify.success("Succefully Changed!");
        } else {
          console.log(response);
          Loading.remove(500);
        }
      })
      .catch((err) => {
        console.log(err);
        Notify.failure("Something went wrong!");
        Loading.remove(500);
      });
  };

  const digitalKeysTableData = useMemo(() => {
    if (tableData)
      if (tableData.length > 0) {
        let data = tableData.map((prop) => {
          if (prop && prop.product_id == product_id) {
            return {
              id: prop.id,
              active: prop.active,
              product_id: prop.product_id,
              key: prop.key,
              order_id: prop.order_id,
              date_created: moment(prop.date_created).format(dateFormat),
              date_assigned:
                prop.date_assigned != null ? moment(prop.date_assigned).format(dateFormat) : "",
            };
          }
        });
        data = data.filter((item) => item != undefined);
        return data;
      } else {
        return [];
      }
  }, [tableData, currentDigitalKeys]);

  return (
    <>
      <Tooltip title="View Assigned Digital Keys" placement="top">
        <IconButton aria-label="view" size="small" onClick={handleDialogOpen}>
          <KeyIcon color="info" fontSize="inherit" />
        </IconButton>
      </Tooltip>
      <BootstrapDialog
        // onClose={handleDialogClose}
        aria-labelledby="customized-dialog-title"
        maxWidth="lg"
        sx={{
          "& .MuiPaper-root": {
            width: "850px",
          },
        }}
        open={openDigitalKeysDialog}
      >
        <DialogTitle sx={{ m: 0, p: 2, color: "#4b4b4f" }} id="customized-dialog-title">
          Digital keys of - {product_name}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleDialogClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography
            variant="h6"
            sx={{
              fontWeight: 400,
              mb: product_variation ? 0 : 1,
              color: "#3f3f3f",
            }}
          >
            <strong>Product:</strong> {product_name}
          </Typography>
          {product_variation && (
            <Typography
              variant="h6"
              sx={{
                fontWeight: 400,
                mb: 1,
                color: "#3f3f3f",
              }}
            >
              <strong>Variation:</strong> {product_variation}
            </Typography>
          )}
          {tableData && tableData.length > 0 ? (
            <DataTable
              id="digitalKeysTable"
              headerActions={
                <Tooltip title="Refetch digital keys" sx={{ ml: 1 }} placement="top">
                  <IconButton
                    aria-label="view"
                    size="small"
                    onClick={() => resyncDigitalKeys(order_id)}
                  >
                    <RestartAltIcon color="info" fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              }
              columns={[
                {
                  Header: "#",
                  accessor: "id",
                },
                {
                  Header: "Key",
                  accessor: "key",
                },
                {
                  Header: "Status",
                  accessor: "active",
                  Cell: ({ row: { original } }) =>
                    original.active ? (
                      <MDBadge badgeContent="Active" color="success" variant="gradient" size="sm" />
                    ) : (
                      <MDBadge
                        badgeContent="Deactivated"
                        color="secondary"
                        variant="gradient"
                        size="sm"
                      />
                    ),
                },
                {
                  Header: "Created Date",
                  accessor: "date_created",
                },
                {
                  Header: "Assigned Date",
                  accessor: "date_assigned",
                  Cell: ({ row: { original } }) => (
                    <span style={{ display: "flex", alignItems: "center" }}>
                      {original.date_assigned ? (
                        original.date_assigned
                      ) : (
                        <MDBadge
                          badgeContent="Not Assigned Yet"
                          color="secondary"
                          variant="gradient"
                          size="sm"
                        />
                      )}
                    </span>
                  ),
                },
                {
                  Header: "Actions",
                  accessor: "actions",
                  disableSortBy: true,
                  disableFilters: true,
                  show: true,
                  align: "right",
                  // eslint-disable-next-line react/display-name
                  Cell: ({ row: { original } }) => (
                    <div className="actions-right">
                      {original.date_assigned != null && <AssignedUsers orderProductId={original.id} />}
                      {original.active ? (
                        <Tooltip title="Deactivate" sx={{ ml: 1 }} placement="top">
                          <IconButton
                            aria-label="view"
                            size="small"
                            onClick={() => {
                              Confirm.show(
                                `Please Confirm`,
                                "Are you sure you want to deactivate this key?",
                                "Yes",
                                "No",
                                () => {
                                  let data = {
                                    active: false,
                                    ids: [original.id],
                                  };
                                  handleDeactivateKeys(data);
                                },
                                () => {},
                                {}
                              );
                            }}
                          >
                            <PauseCircleIcon color="secondary" fontSize="inherit" />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Activate" placement="top">
                          <IconButton
                            aria-label="view"
                            size="small"
                            onClick={() => {
                              let data = {
                                active: true,
                                ids: [original.id],
                              };
                              handleDeactivateKeys(data);
                            }}
                          >
                            <PlayCircleIcon color="success" fontSize="inherit" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </div>
                  ),
                },
              ]}
              // data={digitalKeysTableData}
              data={digitalKeysTableData}
              isSorted={true}
              entriesPerPage={false}
              showTotalEntries={false}
              hideColumnSelection={false}
              noEndBorder
              canSearch
              // actionButton={
              //   <MDButton
              //     variant="gradient"
              //     color="primary"
              //     sx={{ ml: 2 }}
              //     onClick={() => setOpenDigitalKeysDialog(true)}
              //   >
              //     Add Digital keys
              //   </MDButton>
              // }
            />
          ) : (
            <Alert severity="warning">No Digital keys found!</Alert>
          )}
        </DialogContent>
        <DialogActions>
          <MDButton color="secondary" variant="gradient" autoFocus onClick={handleDialogClose}>
            Cancel
          </MDButton>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

DigitalKeys.propTypes = {
  product_id: PropTypes.number.isRequired,
};

export default DigitalKeys;
