/* eslint-disable react/prop-types */
import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";

import moment from "moment";

import { styled } from "@mui/material/styles";

import PropTypes from "prop-types";

import MDButton from "components/MDButton";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import KeyIcon from "@mui/icons-material/Key";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import MDBadge from "components/MDBadge";
import DetailBlock from "components/DetailBlock";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";

import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

import DataTable from "examples/Tables/DataTable/DisabledState";

import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Notify } from "notiflix/build/notiflix-notify-aio";
import { Confirm } from "notiflix/build/notiflix-confirm-aio";

import Alert from "components/CustomAlert";
import { Block } from "notiflix/build/notiflix-block-aio";

import { dateFormat, dateTimeFormat } from "utils/constants";

import {
  useLazyGetEndUserByDigitalKeyQuery,
  useDeleteEndUserToDigitalKeyMutation,
} from "services/digitalKeys";

import {
  useLazyGetAllReminderTypesQuery
} from "services/reminders";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

import MDBox from "components/MDBox";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AssignedUsers = ({
  data
}) => {
  const [openAssignedUsersDialog, setOpenAssignedUsersDialog] = useState(false);

  const handleDialogOpen = async () => {
    // Loading.dots({ svgColor: "rgba(159, 3, 3, 0.8)", });
    setOpenAssignedUsersDialog(true);
  };

  const handleDialogClose = () => {
    setOpenAssignedUsersDialog(false);
  };

  return (
    <>
      <Tooltip title="View assigned user" placement="top">
        <IconButton aria-label="view" size="small" onClick={handleDialogOpen}>
          <AccountCircleIcon color="warning" fontSize="inherit" />
        </IconButton>
      </Tooltip>
      <BootstrapDialog
        // onClose={handleDialogClose}
        aria-labelledby="customized-dialog-title"
        maxWidth="lg"
        sx={{
          "& .MuiDialog-paper": {
            width: "650px",
          },
        }}
        open={openAssignedUsersDialog}
      >
        <DialogTitle sx={{ m: 0, p: 2, color: "#4b4b4f" }} id="customized-dialog-title">
          End user details {data.digital_key_id && (`(${data.digital_key_id})`)}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleDialogClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <GridContainer>
            {data.digital_key_id && (
              <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                <DetailBlock blockTitle="Digital key id" blockDesc={`#${data.digital_key_id}`} />
              </GridItem>
            )}
            {data.email && (
              <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                <DetailBlock blockTitle="Email" blockDesc={`${data.email}`} />
              </GridItem>
            )}
            {data.first_name && (
              <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                <DetailBlock blockTitle="First name" blockDesc={`${data.first_name}`} />
              </GridItem>
            )}
            {data.last_name && (
              <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                <DetailBlock blockTitle="Last name" blockDesc={`${data.last_name}`} />
              </GridItem>
            )}
             {data.expiry_date && (
              <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                <DetailBlock blockTitle="Expiry date" blockDesc={moment(data.expiry_date).format(dateFormat)} />
              </GridItem>
            )}
          </GridContainer>
        </DialogContent>
        <DialogActions>
          <MDButton color="secondary" variant="gradient" autoFocus onClick={handleDialogClose}>
            Cancel
          </MDButton>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

AssignedUsers.propTypes = {
  data: PropTypes.object,
};

export default AssignedUsers;
