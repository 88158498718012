import React from "react";
import PropTypes from "prop-types";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
// @mui/icons-material
import Check from "@mui/icons-material/Check";

const CustomCheckbox = (props) => {
  const { checked, onChange, label, formControlProps, disabled, ...rest } = props;

  return (
    <div>
      <FormControlLabel
        disabled={disabled}
        control={
          <Checkbox
            sx={{
              marginLeft: '3px'
            }}
            checkedIcon={<Check />}
            icon={<Check />}
            onChange={onChange}
            checked={checked}
            {...rest}
          />
        }
        label={label}
        {...formControlProps}
        sx={{
          mb: 1
        }}
      />
    </div>
  );
};

CustomCheckbox.propTypes = {
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  formControlProps: PropTypes.object,
  onChange: PropTypes.func,
  label: PropTypes.string,
};

export default CustomCheckbox;
