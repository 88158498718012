import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import borders from "assets/theme/base/borders";

import Container from "@mui/material/Container";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import { AppBar } from "@mui/material";

// store
import { actions as tabActions } from 'store/ui/tab';

const { borderRadius } = borders;

const CustomTabsDefault = (props) => {

  const dispatch = useDispatch();

  const [value, setValue] = useState(0);

  const handleChange = (event, value) => {
    setValue(value);
    dispatch(tabActions.setCurrentTab(value));
  };

  const { tabs } = props;

  return (
    <>
      <AppBar position="static">
        <Tabs value={value} variant="scrollable" scrollButtons="auto" aria-label="scrollable auto tabs example"  onChange={handleChange}>
          {tabs.map((prop, key) => {
            var icon = {};
            if (prop.tabIcon) {
              icon = {
                icon: <prop.tabIcon />
              };
            }
            return (
              <Tab
                key={key}
                label={prop.tabName}
                {...icon}
                onClick={prop.onClick}
                disabled={prop.disabled}
              />
            );
          })}
        </Tabs>
      </AppBar>
      {tabs.map((prop, key) => {
        if (key === value) {
          return (
            <div value={value} key={key}>
              {prop.tabContent}
            </div>
          );
        }
        return null;
      })}
    </>
  );
}

CustomTabsDefault.propTypes = {
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            tabName: PropTypes.node.isRequired,
            // tabName: PropTypes.string.isRequired,
            tabIcon: PropTypes.object,
            tabContent: PropTypes.node.isRequired,
            onClick: PropTypes.func
        })
    )
};

export default CustomTabsDefault;
