import { combineReducers } from "@reduxjs/toolkit";

import countries from "store/entities/countries";
import notification from "store/entities/notifications";
import stockNotifications from "store/entities/stockNotifications";
import tableReducer from "store/ui/table";
import tabReducer from "store/ui/tab";
import loaderReducer from "store/ui/loader";

export default combineReducers({
  tables: tableReducer,
  tabs: tabReducer,
  [loaderReducer.name]: loaderReducer.reducer,
  [countries.name]: countries.reducer,
  [notification.name]: notification.reducer,
  [stockNotifications.name]: stockNotifications.reducer,
});
