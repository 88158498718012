import React from "react";
import PropTypes from "prop-types";

// import makeStyles from "@mui/styles/makeStyles";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";

// const useStyles = makeStyles({ ...customInputStyle, ...customSelectStyle });

const CustomSelect = (props) => {
  const {
    value,
    onChange,
    inputProps,
    label,
    labelProps,
    formControlProps,
    error,
    helperText,
    children,
    sx,
    ...rest
  } = props;

  // const classes = useStyles();

  return (
    <FormControl
      variant="outlined"
      fullWidth
      sx={{
        mb: 2,
        ...sx,
        "& .MuiSelect-select.Mui-disabled": {
          textFillColor: "#7d8184",
        },
      }}
      error={Boolean(error)}
      {...formControlProps}
    >
      {label !== undefined ? (
        <InputLabel htmlFor={inputProps.id} disabled={rest.disabled} {...labelProps}>
          {label}
        </InputLabel>
      ) : null}
      <Select
        sx={{
          "& .Mui-disabled": {
            color: "#7b809adb",
            textFillColor: "#7b809adb !important",
          },
        }}
        value={value}
        onChange={onChange}
        inputProps={inputProps}
        {...rest}
      >
        {children}
      </Select>
      {helperText !== undefined ? <FormHelperText>{helperText}</FormHelperText> : null}
    </FormControl>
  );
};

CustomSelect.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
  inputProps: PropTypes.object,
  sx: PropTypes.object,
  label: PropTypes.string,
  labelProps: PropTypes.object,
  formControlProps: PropTypes.object,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  children: PropTypes.node,
};

export default CustomSelect;
