import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useSelector } from "react-redux";
// import makeStyles from "@mui/styles/makeStyles";
import FormControl from "@mui/material/FormControl";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { FormHelperText, TextField } from "@mui/material";

import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
// import AvTimerIcon from "@mui/icons-material/AvTimer";

import { dateFormat as defaultDateFormat } from "utils/constants";

// styles
const styles = {
  picker: {
    "& button": {
      fontSize: "1.2rem",
      marginRight: "-8px",
    },
  },
};
// const useStyles = makeStyles(styles);

const propperStylesDatePicker = {
  zIndex: 20000,
  "& .MuiCalendarOrClockPicker-root>div": {
    width: "290px",
  },
  "& .MuiYearPicker-root": {
    maxHeight: "260px",
  },
  "& .MuiYearPicker-root button.PrivatePickersYear-yearButton": {
    fontSize: "1rem",
    height: "30px",
    width: "60px",
  },
  "& .MuiCalendarPicker-root": {
    width: "290px",
  },
  "& .PrivatePickersSlideTransition-root": {
    minHeight: "200px",
  },
  "& .MuiCalendarPicker-root .MuiPickersCalendarHeader-labelContainer": {
    fontSize: "1rem",
  },
  "& .MuiDayPicker-header .MuiDayPicker-weekDayLabel": {
    width: "30px",
  },
  "& button.MuiPickersDay-root": {
    width: "30px",
    height: "30px",
  },
  "& button.MuiPickersDay-root.Mui-selected": {
    background: "#1A73E8",
  },
  "& button.MuiPickersDay-root.MuiPickersDay-today": {
    border: "1px solid #1A73E8",
  },
  "& button.PrivatePickersYear-yearButton.Mui-selected": {
    background: "#1A73E8",
  },
  "& .MuiCalendarOrClockPicker-root>div, & .MuiClockPicker-root": {
    width: "290px",
  },
  "& .MuiCalendarOrClockPicker-root .MuiClockPicker-root .MuiPickersArrowSwitcher-root": {
    right: "10px",
    top: "12px",
  },
  "& .MuiCalendarOrClockPicker-root .MuiClockPicker-root .MuiClock-root .MuiClock-pin, & .MuiCalendarOrClockPicker-root .MuiClockPicker-root .MuiClock-root .MuiClockPointer-root":
    {
      backgroundColor: "#1A73E8",
    },
  "& .MuiCalendarOrClockPicker-root .MuiClockPicker-root .MuiClock-root .MuiClockPointer-thumb": {
    borderColor: "#1A73E8",
    backgroundColor: "#1A73E8",
  },
  "& .MuiCalendarOrClockPicker-root .MuiClockPicker-root .MuiPickersArrowSwitcher-root .MuiPickersArrowSwitcher-spacer":
    {
      width: "15px",
    },
};

const propperStylesForTimePicker = {
  zIndex: 20000,
  "& .MuiCalendarOrClockPicker-root>div, & .MuiClockPicker-root": {
    width: "290px",
  },
  "& .MuiCalendarOrClockPicker-root .MuiClockPicker-root .MuiPickersArrowSwitcher-root": {
    right: "10px",
    top: "12px",
  },
  "& .MuiCalendarOrClockPicker-root .MuiClockPicker-root .MuiClock-root .MuiClock-pin, & .MuiCalendarOrClockPicker-root .MuiClockPicker-root .MuiClock-root .MuiClockPointer-root":
    {
      backgroundColor: "#1A73E8",
    },
  "& .MuiCalendarOrClockPicker-root .MuiClockPicker-root .MuiClock-root .MuiClockPointer-thumb": {
    borderColor: "#1A73E8",
    backgroundColor: "#1A73E8",
  },
  "& .MuiCalendarOrClockPicker-root .MuiClockPicker-root .MuiPickersArrowSwitcher-root .MuiPickersArrowSwitcher-spacer":
    {
      width: "15px",
    },
  "& button.MuiPickersDay-root.Mui-selected": {},
};

const DateTime = (props) => {
  const {
    dateFormat = defaultDateFormat,
    timeFormat = false,
    onChange,
    value,
    label,
    error,
    ...rest
  } = props;

  // const classes = useStyles();

  let labelText = label;
  if (error && !timeFormat) {
    labelText += ` - ${dateFormat}`;
  }
  if (error && timeFormat) {
    labelText += ` - ${timeFormat}`;
  }

  return (
    <>
      <FormControl fullWidth sx={{
        mb: 1
      }}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          {timeFormat && dateFormat ? (
            <DateTimePicker
              sx={styles.picker}
              label={label}
              onChange={onChange}
              value={value}
              // InputProps={{
              //   classes: { root: classes.picker }
              // }}
              showDaysOutsideCurrentMonth={true}
              shouldDisableDate={rest.isValidDate}
              disabled={props.inputProps.disabled === true}
              ampm={false}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name={props.name}
                  error={error}
                  // className={
                  //   props.inputProps.disabled ? classes.disabled : null
                  // }
                  sx={{
                    mb: 1,
                    ".MuiFormLabel-root.Mui-disabled": {
                      textFillColor: "#7b809a",
                      color: "#7b809a",
                    },
                    ".MuiInputBase-input.Mui-disabled": {
                      textFillColor: "#7d8184",
                      color: "#7d8184",
                    },
                    ...styles.picker,
                  }}
                  // variant="standard"
                />
              )}
              PopperProps={{
                sx: propperStylesDatePicker,
              }}
              components={{
                OpenPickerIcon: CalendarMonthIcon,
                LeftArrowIcon: ArrowLeftIcon,
                RightArrowIcon: ArrowRightIcon,
              }}
            />
          ) : dateFormat && !timeFormat ? (
            <DatePicker
              sx={styles.picker}
              label={labelText}
              // InputProps={{
              //   classes: { root: classes.picker }
              // }}
              onChange={onChange}
              inputFormat={dateFormat}
              disabled={props.inputProps.disabled === true}
              value={value}
              showDaysOutsideCurrentMonth={true}
              shouldDisableDate={rest.isValidDate}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name={props.name}
                  error={error}
                  // className={
                  //   props.inputProps.disabled ? classes.disabled : null
                  // }
                  sx={{
                    mb: 1,
                    ".MuiFormLabel-root.Mui-disabled": {
                      textFillColor: "#7b809a",
                      color: "#7b809a",
                    },
                    ".MuiInputBase-input.Mui-disabled": {
                      textFillColor: "#7d8184",
                      color: "#7d8184",
                    },
                    ...styles.picker,
                  }}
                  // variant="standard"
                />
              )}
              PopperProps={{
                sx: propperStylesDatePicker,
              }}
              components={{
                OpenPickerIcon: CalendarMonthIcon,
                LeftArrowIcon: ArrowLeftIcon,
                RightArrowIcon: ArrowRightIcon,
              }}
            />
          ) : (
            <TimePicker
              label={labelText}
              onChange={onChange}
              inputFormat={timeFormat}
              // InputProps={{
              //   classes: { root: classes.picker }
              // }}
              disabled={props.inputProps.disabled === true}
              value={moment(value, timeFormat)}
              ampm={false}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name={props.name}
                  error={error}
                  // className={
                  //   props.inputProps.disabled ? classes.disabled : null
                  // }
                  sx={{
                    mb: 1,
                    ".MuiFormLabel-root.Mui-disabled": {
                      textFillColor: "#7b809a",
                      color: "#7b809a",
                    },
                    ".MuiInputBase-input.Mui-disabled": {
                      textFillColor: "#7d8184",
                      color: "#7d8184",
                    },
                    ...styles.picker,
                  }}
                  // variant="standard"
                />
              )}
              PopperProps={{
                sx: propperStylesForTimePicker,
              }}
              components={{
                // OpenPickerIcon: AvTimerIcon,
                LeftArrowIcon: ArrowLeftIcon,
                RightArrowIcon: ArrowRightIcon,
              }}
            />
          )}
        </LocalizationProvider>
      </FormControl>
    </>
  );
};

DateTime.propTypes = {
  name: PropTypes.string,
  dateFormat: PropTypes.string,
  error: PropTypes.bool,
  timeFormat: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  inputProps: PropTypes.object,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  isValidDate: PropTypes.func,
  label: PropTypes.string,
};

export default DateTime;
