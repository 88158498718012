/* eslint-disable react/prop-types */
import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";

import { styled } from "@mui/material/styles";

import PropTypes from "prop-types";

import MDButton from "components/MDButton";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";

import MDBadge from "components/MDBadge";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import Button from "@mui/material/Button";

import DataTable from "examples/Tables/DataTable";

import { Loading } from "notiflix/build/notiflix-loading-aio";

import Alert from "components/CustomAlert";

import { useLazyGetHistoryByDirectorIdQuery } from "services/companies";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

import MDBox from "components/MDBox";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const DirectorHistory = ({ director }) => {
  const [directorHistoryOpen, setDirectorHistoryOpen] = useState(false);

  const [
    getHistoryByDirectorId,
    {
      data: historyDetails = [],
      isLoading: isLoadingHistoryDetails,
      isSuccess: isSuccessHistoryDetails,
    },
  ] = useLazyGetHistoryByDirectorIdQuery();

  const handleClickOpen = () => {
    setDirectorHistoryOpen(true);

    getHistoryByDirectorId(director.id);
  };
  const handleClose = () => {
    setDirectorHistoryOpen(false);
  };

  useEffect(() => {
    isLoadingHistoryDetails
      ? Loading.dots({
          svgColor: "rgba(159, 3, 3, 0.8)",
        })
      : Loading.remove(500);
  }, [isLoadingHistoryDetails]);

  return (
    <>
      <Tooltip title="View History" placement="top">
        <MDButton
          variant="contained"
          size="small"
          color="primary"
          sx={{
            ml: 1,
            color: "#fff",
            fontSize: "10px!important",
            fontWeight: "500",
            letterSpacing: "0.5px",
          }}
          onClick={handleClickOpen}
        >
          Past Directorship History
        </MDButton>
      </Tooltip>
      <BootstrapDialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        maxWidth="lg"
        sx={{
          "& .MuiPaper-root": {
            width: "850px",
          },
        }}
        open={directorHistoryOpen}
      >
        <DialogTitle sx={{ m: 0, p: 2, color: "#4b4b4f" }} id="customized-dialog-title">
          Directorship history of {director.full_name}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {historyDetails.length > 0 ? (
            <DataTable
              columns={[
                {
                  Header: "Id",
                  accessor: "id",
                },
                {
                  Header: "Company Name",
                  accessor: "company_name",
                },
                {
                  Header: "Registration no",
                  accessor: "reg_no",
                },
                {
                  Header: "Tax no",
                  accessor: "tax_no",
                },
                {
                  Header: "Public Company?",
                  accessor: "is_public_company",
                  Cell: ({ row: { original } }) =>
                    original.is_public_company ? (
                      <MDBox ml={-1}>
                        <MDBadge badgeContent="Yes" color="success" variant="gradient" size="sm" />
                      </MDBox>
                    ) : (
                      <MDBox ml={-1}>
                        <MDBadge badgeContent="No" color="dark" variant="gradient" size="sm" />
                      </MDBox>
                    ),
                },
              ]}
              data={historyDetails || []}
              isSorted={true}
              entriesPerPage={false}
              showTotalEntries={false}
              hideColumnSelection={false}
              noEndBorder
              canSearch
            />
          ) : (
            <Alert severity="warning">No History found!</Alert>
          )}
        </DialogContent>
        <DialogActions>
          <MDButton color="secondary" variant="gradient" autoFocus onClick={handleClose}>
            Cancel
          </MDButton>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

DirectorHistory.propTypes = {
  director: PropTypes.object.isRequired, // You can also use PropTypes.element if you want to enforce a single element.
};

export default DirectorHistory;
