import CustomInput from "components/CustomInputs";
import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";

const ControlledTextInput = (props) => {
  // props.control._defaultValues[props.name] = !props.control._defaultValues[props.name] ? "x" : props.control._defaultValues[props.name];
  // console.log(props.control._defaultValues[props.name])
  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field, fieldState: { error, isTouched } }) => (
        <CustomInput
          {...props}
          inputProps={{ ...field, ...props.inputProps }}
          error={!!error}
          success={!error && isTouched}
          helperText={error && error.message}
          onClick={props.onClick}
          spellCheck={props.spellCheck}
          multiline={props.multiline}
        />
      )}
    />
  );
};

ControlledTextInput.propTypes = {
  control: PropTypes.object,
  name: PropTypes.string,
  onClick: PropTypes.func,
  inputProps: PropTypes.object,
  variant: PropTypes.string,
  multiline: PropTypes.bool,
  spellCheck: PropTypes.bool,
};

export default ControlledTextInput;
