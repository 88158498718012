/* eslint-disable react/prop-types */
import { useEffect } from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { Block } from 'notiflix/build/notiflix-block-aio';

export default ({blockTitle, blockDesc, icon}) => {

  useEffect(() => {
    Block.dots('#blockDesc', { svgColor: "rgba(159, 3, 3, 0.8)" });
    
    Block.remove('#blockDesc', 500);
  }, [])

    return (
      <Box display="block" mb="10px">
        <Typography
            component="h6"
            variant="h6"
            sx={{
                fontWeight: 500,
                color: "#3f3f3f",
                fontSize: '0.8rem',
                lineHeight: 1
            }}
            >
            {blockTitle}:
        </Typography>
        <Box sx={{ display: 'flex' }}>
          <Typography
            id="blockDesc"
            component="p"
            variant="span"
            sx={{
              fontWeight: 600,
              color: "#3f3f3f",
              '& .MuiBadge-root .MuiBadge-badge': { ml: 0 }
            }}
            >
            {blockDesc}
        </Typography>
        {icon ? icon : ''}
        </Box>
      </Box>
    )
  }