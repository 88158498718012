// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

//components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

//base styles
import typography from "assets/theme/base/typography";

function Footer({ company, links }) {
  const { href, name } = company;
  const { size } = typography;

  return (
    <MDBox
      width="100%"
      display="flex"
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent="end"
      alignItems="center"
      px={1.5}
    >
      {/* <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        color={light ? "white" : "text"}
        fontSize={size.sm}
      >
        softvire.com.au
      </MDBox> */}
    </MDBox>
  );
}

// Setting default values for the props of Footer
Footer.defaultProps = {
  company: { href: "", name: "" },
  links: [
    { href: "", name: "" },
    { href: "", name: "" },
    { href: "", name: "" },
    { href: "", name: "" },
  ],
};

// Typechecking props for the Footer
Footer.propTypes = {
  company: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.object),
};

export default Footer;
