/* eslint-disable react/prop-types */
import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";

import moment from "moment";

import { styled } from "@mui/material/styles";

import PropTypes from "prop-types";

import MDButton from "components/MDButton";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import KeyIcon from "@mui/icons-material/Key";
import CloseIcon from "@mui/icons-material/Close";

import MDBadge from "components/MDBadge";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";

import { imageSrcDecoder } from "utils/helperFunctions";

import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import DeleteIcon from "@mui/icons-material/Delete";

import DataTable from "examples/Tables/DataTable/DisabledState";

import CustomTabs from "components/CustomTabs";

import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Notify } from "notiflix/build/notiflix-notify-aio";
import { Confirm } from "notiflix/build/notiflix-confirm-aio";

import Alert from "components/CustomAlert";
import { Block } from "notiflix/build/notiflix-block-aio";

import {
  useDeleteProductImageMutation,
  useLazyGetProductImagesByProductIdQuery,
  useUploadProductImageMutation,
} from "services/products";

import { useUploadFileMutation, useDeleteFileMutation } from "services/files";

import { dateFormat } from "utils/constants";
import { checkFileType } from "utils/helperFunctions";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

import MDBox from "components/MDBox";

import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ProductImages = ({
  parentData,
  initialData,
  openDigitalKeysDialog,
  setOpenDigitalKeysDialog,
}) => {
  const [parentImages, setParentImages] = useState({});

  const [variationImages, setVariationImages] = useState([]);

  const [triggerProductImages] = useLazyGetProductImagesByProductIdQuery();

  const [deleteExistingProductImage] = useDeleteProductImageMutation();

  const [uploadProductImage] = useUploadProductImageMutation();

  const [uploadFile, { isLoading: isFileUploading }] = useUploadFileMutation();

  const handleDialogOpen = async () => {
    setOpenDigitalKeysDialog(true);
  };

  const handleDialogClose = async () => {
    setOpenDigitalKeysDialog(false);
  };

  const fetchProductImages = async () => {
    if (openDigitalKeysDialog) {
      setParentImages({});
      setVariationImages([]);
      if (parentData) {
        const parentProductImagesResponse = await triggerProductImages(parentData.id);
        if (
          parentProductImagesResponse &&
          parentProductImagesResponse.data &&
          parentProductImagesResponse.data.length > 0
        ) {
          let parentImagesData = {};
          parentImagesData.name = parentData.name;
          parentImagesData.id = parentData.id;
          parentImagesData.images = parentProductImagesResponse.data;
          setParentImages(parentImagesData);
        }
        if (parentData.productVariations && parentData.productVariations.length > 0) {
          for (let variation of parentData.productVariations) {
            let variationData = {};
            variationData.name = variation.name;
            variationData.id = variation.id;
            variationData.images = [];
            const variationProduct = await triggerProductImages(variation.id);
            if (variationProduct && variationProduct.data && variationProduct.data.length > 0) {
              for (let variationImage of variationProduct.data) {
                variationData.images.push(variationImage);
              }
            }
            setVariationImages((prevData) => [...prevData, variationData]);
          }
        }
      } else {
        if(initialData) {
          const parentProductImagesResponse = await triggerProductImages(initialData.id);
          if (
            parentProductImagesResponse &&
            parentProductImagesResponse.data &&
            parentProductImagesResponse.data.length > 0
          ) {
            let parentImagesData = {};
            parentImagesData.name = initialData.name;
            parentImagesData.id = initialData.id;
            parentImagesData.images = parentProductImagesResponse.data;
            setParentImages(parentImagesData);
          }
        }
      }
    }
  };

  async function handleChange(event, id) {
    if (checkFileType(event.target.files[0].type)) {
      Loading.dots({
        svgColor: "rgba(159, 3, 3, 0.8)",
      });

      if (id) {
        let imagePath = "";
        let formData = new FormData();
        formData.append("file", event.target.files[0]);
        let response = await uploadFile({
          formData: formData,
          directory: "product_images",
        });
        if (response.data.status == "success") {
          imagePath = response.data?.response.path.replace(/\\/g, "/");

          if (imagePath) {
            let data = {
              imageUrls: [imagePath],
            };
            const imageResponse = await uploadProductImage({
              id: id,
              data,
            });

            if (imageResponse) {
              fetchProductImages();
              setTimeout(() => Notify.success("Succefully uploaded!"), [500]);
            }
          }
        }
      }
      Loading.remove(500);
    } else {
      Notify.failure("File type doesn't support! please select a different one.");
    }
  }

  useEffect(() => {
    fetchProductImages();
  }, [openDigitalKeysDialog]);

  const deleteProductImage = async (id) => {
    Loading.dots({
      svgColor: "rgba(159, 3, 3, 0.8)",
    });

    deleteExistingProductImage(id).then((response) => {
      if (response.data?.status == "success") {
        fetchProductImages();
        Loading.remove(500);
        Notify.success("Succefully deleted!");
      } else {
        console.log(response);
        Loading.remove(500);
      }
    });
  };

  return (
    <>
      <BootstrapDialog
        // onClose={handleDialogClose}
        aria-labelledby="customized-dialog-title"
        maxWidth="lg"
        sx={{
          "& .MuiPaper-root": {
            width: "850px",
          },
        }}
        open={openDigitalKeysDialog}
      >
        <DialogTitle sx={{ m: 0, p: 2, color: "#4b4b4f" }} id="customized-dialog-title">
          Product images
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleDialogClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {parentData && parentData.products_images && parentData.products_images.length > 0 ? (
            <>
              <MDBox>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 400,
                    mb: 1,
                    color: "#3f3f3f",
                  }}
                >
                  <strong>Main product images:</strong>
                </Typography>
                <Typography
                  variant="p"
                  sx={{
                    fontWeight: 400,
                    mb: 1,
                    color: "#3f3f3f",
                  }}
                >
                  There should atleast one main product image, add a new one and delete the existing
                  one if you need to replace.{" "}
                  <Button component="label" variant="text" sx={{}}>
                    Add new
                    <VisuallyHiddenInput
                      type="file"
                      onChange={(event) => handleChange(event, parentData.id)}
                    />
                  </Button>
                </Typography>
                <ImageList sx={{ minHeight: 275 }} cols={3} rowHeight={164}>
                  {parentImages &&
                    parentImages.images &&
                    parentImages.images.length > 0 &&
                    parentImages.images.map((item, index) => (
                      <ImageListItem
                        key={index}
                        sx={{
                          p: 1,
                          height: "auto !important",
                          "& > img": { border: "1px solid #ccc", borderRadius: "3px" },
                        }}
                      >
                        <img
                          srcSet={imageSrcDecoder(item.src)}
                          src={imageSrcDecoder(item.src)}
                          alt={item.alt}
                          loading="lazy"
                        />
                        {parentImages.images.length > 1 && (
                          <Tooltip title="Delete product image" placement="top">
                            <IconButton
                              className="deleteButton"
                              color="error"
                              sx={{ position: "absolute", top: 5, right: 5 }}
                              onClick={() =>
                                Confirm.show(
                                  `Please Confirm`,
                                  "Are you sure you want to delete this product image?",
                                  "Yes",
                                  "No",
                                  () => {
                                    deleteProductImage(item.id);
                                    setTimeout(() => {
                                      setParentImages((prevState) => ({
                                        ...prevState,
                                        images: prevState.images.filter(
                                          (single) => single.id != item.id
                                        ),
                                      }));
                                    }, 500);
                                  },
                                  () => {},
                                  {}
                                )
                              }
                              size="large"
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        )}
                      </ImageListItem>
                    ))}
                </ImageList>
              </MDBox>
              <MDBox>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 400,
                    mb: 1,
                    color: "#3f3f3f",
                  }}
                >
                  <strong>Variation images:</strong>
                </Typography>
                <ImageList sx={{ minHeight: 275 }} cols={3} rowHeight={164}>
                  {variationImages &&
                    variationImages.length > 0 &&
                    variationImages.map((item, index) => (
                      <ImageListItem
                        key={index}
                        sx={{
                          p: 1,
                          height: "auto !important",
                          "& > img": { border: "1px solid #ccc", borderRadius: "3px" },
                        }}
                      >
                        <Typography
                          variant="p"
                          sx={{
                            fontWeight: 400,
                            mb: 1,
                            color: "#3f3f3f",
                          }}
                        >
                          <strong>{item.name}:</strong>
                        </Typography>
                        {item && item.images && item.images.length > 0 ? (
                          <MDBox sx={{ position: "relative", "& img": { width: "100%" } }}>
                            <img
                              srcSet={imageSrcDecoder(item.images[0].src)}
                              src={imageSrcDecoder(item.images[0].src)}
                              alt={item.images[0].alt}
                              loading="lazy"
                            />
                            <Tooltip title="Delete product image" placement="top">
                              <IconButton
                                className="deleteButton"
                                color="error"
                                sx={{ position: "absolute", top: 5, right: 5 }}
                                onClick={() =>
                                  Confirm.show(
                                    `Please Confirm`,
                                    "Are you sure you want to delete this product image?",
                                    "Yes",
                                    "No",
                                    () => {
                                      deleteProductImage(item.images[0].id);
                                      setTimeout(() => {
                                        setVariationImages([
                                          ...variationImages.filter(
                                            (single) => single.id != item.id
                                          ),
                                          {
                                            name: item.name,
                                            id: item.id,
                                            images: [],
                                          },
                                        ]);
                                      }, 500);
                                    },
                                    () => {},
                                    {}
                                  )
                                }
                                size="large"
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </MDBox>
                        ) : (
                          <Button
                            component="label"
                            role={undefined}
                            variant="contained"
                            tabIndex={-1}
                            startIcon={<CloudUploadIcon />}
                            sx={{ color: "#fff" }}
                          >
                            Upload image
                            <VisuallyHiddenInput
                              type="file"
                              onChange={(event) => handleChange(event, item.id)}
                            />
                          </Button>
                        )}
                      </ImageListItem>
                    ))}
                </ImageList>
              </MDBox>
            </>
          ) : (
            <MDBox>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 400,
                  mb: 1,
                  color: "#3f3f3f",
                }}
              >
                <strong>Main product images:</strong>
              </Typography>
              <Typography
                variant="p"
                sx={{
                  fontWeight: 400,
                  mb: 1,
                  color: "#3f3f3f",
                }}
              >
                There should atleast one main product image, add a new one and delete the existing
                one if you need to replace.{" "}
                  <Button component="label" variant="text" sx={{}}>
                    Add new
                    <VisuallyHiddenInput
                      type="file"
                      onChange={(event) => handleChange(event, initialData.id)}
                    />
                  </Button>
              </Typography>
              <ImageList sx={{ minHeight: 275 }} cols={3} rowHeight={164}>
                {parentImages && 
                  parentImages.images &&
                  parentImages.images.length > 0 &&
                  parentImages.images.map((item, index) => (
                    <ImageListItem
                      key={index}
                      sx={{
                        p: 1,
                        height: "auto !important",
                        "& > img": { border: "1px solid #ccc", borderRadius: "3px" },
                      }}
                    >
                      <img
                        srcSet={imageSrcDecoder(item.src)}
                        src={imageSrcDecoder(item.src)}
                        alt={item.alt}
                        loading="lazy"
                      />
                      {parentImages.images.length > 1 && (
                        <Tooltip title="Delete product image" placement="top">
                          <IconButton
                            className="deleteButton"
                            color="error"
                            sx={{ position: "absolute", top: 5, right: 5 }}
                            onClick={() =>
                              Confirm.show(
                                `Please Confirm`,
                                "Are you sure you want to delete this product image?",
                                "Yes",
                                "No",
                                () => {
                                  deleteProductImage(item.id);
                                  setTimeout(() => {
                                    setParentImages((prevState) => ({
                                      ...prevState,
                                      images: prevState.images.filter(
                                        (single) => single.id != item.id
                                      ),
                                    }));
                                  }, 500);
                                },
                                () => {},
                                {}
                              )
                            }
                            size="large"
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </ImageListItem>
                  ))}
              </ImageList>
            </MDBox>
          )}
        </DialogContent>
        <DialogActions>
          <MDButton color="secondary" variant="gradient" autoFocus onClick={handleDialogClose}>
            Cancel
          </MDButton>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

ProductImages.propTypes = {
  product_id: PropTypes.number.isRequired,
};

export default ProductImages;
