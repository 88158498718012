import { apis } from "./base";

const authService = apis.injectEndpoints({
  endpoints: (builder) => ({
    postLogin: builder.mutation({
        query: (body) => ({
            url: "/admin/login",
            method: "POST",
            body: body
        }),
        // transformResponse: (response) => response.response
    }),
  })
});

export const { usePostLoginMutation } = authService;
