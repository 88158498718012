/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { Importer, ImporterField } from "react-csv-importer";

import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";

import { styled } from "@mui/material/styles";

// @mui material components
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";

import Tooltip from "@mui/material/Tooltip";

import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import CircularProgress from "@mui/material/CircularProgress";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import CollectionsIcon from "@mui/icons-material/Collections";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import MDButton from "components/MDButton";

import { AddBox, BorderColor } from "@mui/icons-material";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";

import TextField from "@mui/material/TextField";

import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Notify } from "notiflix/build/notiflix-notify-aio";
import { Confirm } from "notiflix/build/notiflix-confirm-aio";
import { Block } from "notiflix/build/notiflix-block-aio";

import Typography from "@mui/material/Typography";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import DataTable from "examples/Tables/DataTable/DisabledState";

import ControlledTextInput from "components/FormInputs/ControlledTextInput";
import ControlledDateTime from "components/FormInputs/ControlledDateTime";
import ControlledCheckbox from "components/FormInputs/ControlledCheckbox";
import ControlledSelect from "components/FormInputs/ControlledSelect";
import CustomInputs from "components/CustomInputs";

import { CustomSelect } from "components/CustomSelect";

import moment from "moment";

//components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import MDAvatar from "components/MDAvatar";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";

import Box from "@mui/material/Box";

import Edit from "@mui/icons-material/Edit";
import Close from "@mui/icons-material/Close";

// queries
import {
  useGetAllResellerProductsByCountryIdQuery,
  usePutProductMutation,
  useLazyGetProductByIdQuery,
  useDeleteProductMutation,
  useConvertProductMutation,
} from "services/products";

import {
  useGetAllDigitalKeysByProductIdQuery,
  useLazyGetAllDigitalKeysByProductIdQuery,
  usePostDigitalKeyMutation,
  useDeleteDigitalKeyMutation,
  useChangeDigitalKeysStatusMutation,
} from "services/digitalKeys";

import { useUploadFileMutation, useDeleteFileMutation } from "services/files";

import { useGetAllResellerProductCategoriesQuery } from "services/categories";
// import { useGetAllUrlsByCountryIdQuery } from "services/countries";

// import { useGetAllProductsFromStoreUrlQuery } from "services/store";

import { globalCountry } from "store/entities/countries";

import Alert from "components/CustomAlert";

import OrderDetailsFetch from "views/Orders/OrderDetailsFetch";
import ProductImages from "./ProductImages";

import { dateFormat, tables } from "utils/constants";
import {
  imageSrcDecoder,
  removeLocalStorageItemsWithPrefix,
  replaceHTMLEntityAnd,
  addSpacesToNames,
} from "utils/helperFunctions";

import { inventorySchema } from "../schemas/_model";

import CustomCheckbox from "components/CustomCheckbox";

import AvailabilityDialog from "../AvailabilityDialog";

import AddDigitalKeys from "../components/AddDigitalKeysDialog";

import noPreview from "assets/images/no-preview.png";

import { EditorState, ContentState, convertFromHTML } from "draft-js";
import { convertToHTML } from "draft-convert";
import { Editor } from "react-draft-wysiwyg";

import ReactQuill from "react-quill";
// import TinyMCEEditor from "components/TinyMCEEditor";

import SwapHorizontalCircleIcon from "@mui/icons-material/SwapHorizontalCircle";

import { fileTypeMatch } from "./helper";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import AddAttributeDialog from "../components/AddAttributeDialog";
import TypeConversionDialog from "./TypeConversionDialog";

import { replaceSingleQuoteToSQLFriendly } from "utils/helperFunctions";

import "../Inventory.css";

// ReactQuill modules
const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
    ["link", "image", "video"],
    ["clean"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function SimpleProduct({
  parentData,
  setProductData,
  initialData,
  isParentFeaturedProduct,
  setIsParentFeaturedProduct,
  dialogClose,
  variationLength,
  distinctAttributes,
  productShortDescription,
  setProductShortDescription,
  productDescription,
  setProductDescription,
}) {
  const initialFormState = {
    isDigitalGood: false,
    inStock: true,
    isFeaturedProduct: true,
    categories: [],
    price: "",
    retailPrice: "",
    name: "",
    licenseDuration: 0,
    parentName: "",
    averageRating: 0,
    ratingCount: 0,
  };

  const id = initialData.id;

  const navigate = useHistory();

  const selectedCountry = useSelector(globalCountry);

  const [filterDate, setFilterDate] = useState("");

  const [filterDates, setFilterDates] = useState([]);

  const [filteredKeysState, setFilteredKeysState] = useState("");

  const [openAddAttributeDialog, setOpenAddAttributeDialog] = useState(false);

  const [updatedTempPrice, setUpdatedTempPrice] = useState(null);

  const [productInstructionsPath, setProductInstructionsPath] = useState({});

  const [openTypeConversionDialog, setOpenTypeConversionDialog] = useState(false);

  const [filterCategories, setFilterCategories] = useState([]);

  const [openDigitalKeysDialog, setOpenDigitalKeysDialog] = useState(false);

  const [uploadFile, { isLoading: isFileUploading }] = useUploadFileMutation();

  const [deleteFile] = useDeleteFileMutation();

  const [file, setFile] = useState([]);

  const [updateProduct, { isLoading: isUpdateProductLoading }] = usePutProductMutation();

  const [createDigitalKey, { isLoading: isDigitalKeysCreatingtLoading }] =
    usePostDigitalKeyMutation();

  const [deleteDigitalKey, result] = useDeleteDigitalKeyMutation();

  const [changeDigitalKeyStatus, { isLoading: isStatusChanging }] =
    useChangeDigitalKeysStatusMutation();

  const {
    data: categoriesList = [],
    isLoading,
    isSuccess,
  } = useGetAllResellerProductCategoriesQuery(selectedCountry.id);

  const { control, watch, handleSubmit, setValue, reset } = useForm({
    resolver: yupResolver(inventorySchema(parentData ? "variable" : "simple")),
    defaultValues: initialFormState,
  });

  const { fields, append, remove } = useFieldArray({ name: "attributes", control });

  // const {
  //   data: urlsByCountryId = [],
  //   isLoading: isUrlsByCountryIdLoading,
  //   isSuccess: isUrlsByCountryIdSuccess,
  // } = useGetAllUrlsByCountryIdQuery(selectedCountry.id);

  const [
    triggerDigitalKeys,
    {
      data: digitalKeysByProduct = [],
      isLoading: isLoadingDigitalKeysByProduct,
      isSuccess: isSuccessDigitalKeysByProduct,
    },
  ] = useLazyGetAllDigitalKeysByProductIdQuery();

  const [triggerProductById] = useLazyGetProductByIdQuery();

  const [convertProductType] = useConvertProductMutation();

  const [deleteProduct] = useDeleteProductMutation();

  // const {
  //   data: productById = [],
  //   isLoading: isProductByIdLoading,
  //   isSuccess: isProductByIdSuccess,
  // } = useGetProductByIdQuery(id);

  const isDigitalGoodField = watch("isDigitalGood");
  const productName = watch("name");
  const productPrice = watch("price");
  const productRRP = watch("retailPrice");
  const parentName = watch("parentName");
  const categoriesField = watch("categories");

  const onSubmitDigitalKeys = (keys) => {
    let data = { keys: [] };

    let digitalKeys = [];

    JSON.parse(localStorage.getItem("localParentCategories"));

    if (keys.length > 0) {
      digitalKeys = keys.map((item) => {
        if (item.toLowerCase() === "pending") {
          return "Pending";
        }
        return item;
      });
    }

    data.keys = [...digitalKeys];

    createDigitalKey({ product_id: id, data: data }).then((response) => {
      // if (response.status == "success") {
      //   Notify.success("Succefully added!");
      //   // setDynamicFields([]);
      // } else {
      //   Notify.failure("Something went wrong!");
      // }
    });
  };

  const handleDigitalKeyDelete = (key_id) => {
    Loading.dots({
      svgColor: "rgba(159, 3, 3, 0.8)",
    });

    deleteDigitalKey(key_id)
      .then((response) => {
        response.data?.status == "success" && Notify.success("Succefully deleted!");
        Loading.remove(500);
      })
      .catch((error) => console.log(error));
  };

  const handleDeactivateKeys = (data) => {
    changeDigitalKeyStatus(data)
      .then((response) => {
        if (response.data?.status == "success") {
          Loading.remove(500);
          Notify.success("Succefully Changed!");
        } else {
          console.log(response);
          Loading.remove(500);
        }
      })
      .catch((err) => {
        console.log(err);
        Notify.failure("Something went wrong!");
        Loading.remove(500);
      });
  };

  const activateAllKeys = () => {
    if (filterDate != "") {
      Loading.dots({
        svgColor: "rgba(159, 3, 3, 0.8)",
      });

      let ids = digitalKeysTableDataByFilteredDate.map((item) => item.id);
      let data = {
        active: true,
        ids: ids,
      };

      changeDigitalKeyStatus(data)
        .then((response) => {
          if (response.data?.status == "success") {
            Loading.remove(500);
            Notify.success("Succefully Changed!");
          } else {
            console.log(response);
            Loading.remove(500);
          }
        })
        .catch((err) => {
          console.log(err);
          Notify.failure("Something went wrong!");
          Loading.remove(500);
        });
    }
  };

  const deactivateAllKeys = () => {
    if (filterDate != "") {
      Loading.dots({
        svgColor: "rgba(159, 3, 3, 0.8)",
      });

      let ids = digitalKeysTableDataByFilteredDate.map((item) => item.id);
      let data = {
        active: false,
        ids: ids,
      };

      changeDigitalKeyStatus(data)
        .then((response) => {
          if (response.data?.status == "success") {
            Loading.remove(500);
            Notify.success("Succefully Changed!");
          } else {
            console.log(response);
            Loading.remove(500);
          }
        })
        .catch((err) => {
          console.log(err);
          Notify.failure("Something went wrong!");
          Loading.remove(500);
        });
    }
  };

  useEffect(() => {
    isLoadingDigitalKeysByProduct
      ? Block.dots("#digitalKeysBlock", { svgColor: "rgba(159, 3, 3, 0.8)" })
      : Block.remove("#digitalKeysBlock", 500);
  }, [isLoadingDigitalKeysByProduct]);

  useEffect(() => {
    isDigitalKeysCreatingtLoading
      ? Block.dots("#digitalKeysBlock", { svgColor: "rgba(159, 3, 3, 0.8)" })
      : Block.remove("#digitalKeysBlock", 500);
  }, [isDigitalKeysCreatingtLoading]);

  useEffect(() => {
    isStatusChanging
      ? Block.dots("#digitalKeysBlock", { svgColor: "rgba(159, 3, 3, 0.8)" })
      : Block.remove("#digitalKeysBlock", 500);
  }, [isStatusChanging]);

  useEffect(() => {
    if (categoriesList && categoriesList.length > 0) {
      let filteredData = categoriesList.map((category) => ({
        ...category,
        name: replaceHTMLEntityAnd(category.name),
      }));
      const modifiedCategories = addSpacesToNames(filteredData);
      setFilterCategories(modifiedCategories);
    } else {
      setFilterCategories([]);
    }
  }, [categoriesList]);

  useEffect(() => {
    if (initialData)
      if (initialData != {}) {
        let attributes = [];

        if (
          initialData.variation_atribute_option &&
          initialData.variation_atribute_option.length > 0
        ) {
          for (let attribute of initialData.variation_atribute_option) {
            if (attribute.products_attribute_options) {
              let optionName = attribute.products_attribute_options.option;
              if (
                attribute.products_attribute_options.products_attributes &&
                attribute.products_attribute_options.products_attributes.attribute
              )
                attributes.push({
                  attribute: attribute.products_attribute_options.products_attributes.attribute,
                  option: optionName,
                });
            }
          }
        }

        // if (initialData.proucts_category && initialData.proucts_category.length > 0) {
        //   let categories = [];
        //   for (let category of initialData.proucts_category) {
        //     let ifCategoryExists = categoriesList.filter((item) => item.id == category.category_id);
        //     if (ifCategoryExists && ifCategoryExists.length > 0)
        //       categories.push(category.category_id);
        //   }

        //   if (categories.length > 0) {
        //     setValue("categories", categories);
        //   }
        // }

        if (initialData.license_duration_in_months)
          setValue("licenseDuration", initialData.license_duration_in_months);

        setValue("attributes", attributes);

        setValue("isDigitalGood", initialData.is_digital_good);
        if (parentData) {
          setValue("isFeaturedProduct", parentData.is_featured_product);
          if (localStorage.getItem("localParentName")) {
            setValue("parentName", JSON.parse(localStorage.getItem("localParentName")));
          }
          if (localStorage.getItem("localParentCategories")) {
            setValue("categories", JSON.parse(localStorage.getItem("localParentCategories")));
          }
          // setValue("parentName", parentData.name);
        } else {
          setValue("isFeaturedProduct", initialData.is_featured_product);

          let parentCategories = [];
          if (initialData.proucts_category && initialData.proucts_category.length > 0) {
            for (let category of initialData.proucts_category) {
              parentCategories.push(category.category_id);
            }
          }
          setValue("categories", parentCategories);
        }
        setValue("retailPrice", initialData.retail_price);
        setValue("inStock", initialData.is_in_stock);
        setValue("name", initialData.name);
        setValue("price", initialData.price);
        setValue("averageRating", initialData.average_rating);
        setValue("ratingCount", initialData.rating_count);
        // if(initialData.is_digital_good) {
        //   triggerDigitalKeys(initialData.id);
        // }
      }

    if (initialData.instructionsFilePath) {
      let obj = fileTypeMatch(initialData.instructionsFilePath);
      setProductInstructionsPath(obj);
    }
  }, [initialData]);

  useEffect(() => {
    if (isDigitalGoodField) {
      triggerDigitalKeys(initialData.id);
    }
  }, [isDigitalGoodField, triggerDigitalKeys, initialData.id]);

  const digitalKeysTableData = useMemo(() => {
    if (digitalKeysByProduct && digitalKeysByProduct.length > 0) {
      let digitalKeys = [...digitalKeysByProduct];
      // take real keys to first
      digitalKeys.sort((a, b) => {
        if (a.key !== "Pending" && b.key === "Pending") {
          return -1;
        } else if (a.key === "Pending" && b.key !== "Pending") {
          return 1;
        } else {
          return 0;
        }
      });

      let data = digitalKeys.map((prop) => ({
        id: prop.id,
        active: prop.active,
        product_id: prop.product_id,
        key: prop.key,
        order_id: prop.order_id,
        date_created: moment(prop.date_created).format(dateFormat),
        date_assigned:
          prop.date_assigned != null ? moment(prop.date_assigned).format(dateFormat) : "",
      }));
      return data;
    } else {
      return [];
    }
  }, [digitalKeysByProduct]);

  useEffect(() => {
    if (digitalKeysTableData && digitalKeysTableData.length > 0) {
      let data = [...new Set(digitalKeysTableData.map((item) => item.date_created))];
      setFilterDates(data);
    }
  }, [digitalKeysTableData]);

  const digitalKeysTableDataByFilteredDate = useMemo(() => {
    if (filterDate && digitalKeysTableData && digitalKeysTableData.length > 0) {
      let data = digitalKeysTableData.filter((item) => item.date_created == filterDate);

      // let digitalKeysStatus = data.every((item) => item.active == true);
      // setFilteredKeysState(digitalKeysStatus);

      // Check if all items are active
      const allActive = data.every((item) => item.active == true);

      // Check if all items are inactive
      const allInactive = data.every((item) => item.active == false);

      // Determine the status based on the above checks
      if (allActive) {
        setFilteredKeysState("active");
      } else if (allInactive) {
        setFilteredKeysState("inactive");
      } else {
        setFilteredKeysState(""); // Mixture of active and inactive
      }

      return data;
    } else {
      return [];
    }
  }, [filterDate, digitalKeysTableData]);

  const onSubmit = async (data) => {
    if (!productShortDescription) {
      Notify.failure("Short description cannot be empty!");
      return;
    }

    if (!productDescription) {
      Notify.failure("Product description cannot be empty!");
      return;
    }

    Loading.dots({
      svgColor: "rgba(159, 3, 3, 0.8)",
    });

    data.instructionsFilePath = initialData.instructionsFilePath;

    if (file.length != 0) {
      let formData = new FormData();
      formData.append("file", file);
      await uploadFile({ formData: formData, directory: "product-instructions" }).then(
        (response) => {
          if (response.data?.status == "success") {
            let path = response.data.response.path;
            data.instructionsFilePath = path.replace(/\\/g, "/");

            handleDeleteProductInstruction(initialData.instructionsFilePath);
          }
        }
      );
    }

    data.countryId = initialData.country_id;
    data.parentId = initialData.parent_id;
    data.productId = initialData.woocommerce_id;
    data.urlId = initialData.url_id;
    data.description = productDescription
      ? replaceSingleQuoteToSQLFriendly(productDescription)
      : "...";
    data.shortDescription = productShortDescription
      ? replaceSingleQuoteToSQLFriendly(productShortDescription)
      : "...";
    // data.attributes = [];
    data.sku = initialData.sku;
    data.averageRating = initialData.average_rating;
    data.ratingCount = initialData.rating_count;

    try {
      updateProduct({ id, data }).then((response) => {
        if (response.data && response.data?.status == "success") {
          if (parentData) {
            // parent's price update
            if (parentData.minPrice > data.price) {
              let updatedParentData = {
                countryId: parentData.country_id,
                parentId: parentData.parent_id,
                productId: parentData.woocommerce_id,
                urlId: parentData.url_id,
                description: data.description,
                shortDescription: data.shortDescription,
                attributes: [],
                categories: data.categories,
                sku: parentData.sku,
                averageRating: data.average_rating,
                ratingCount: data.rating_count,
                isDigitalGood: parentData.is_digital_good,
                inStock: true,
                isFeaturedProduct: isParentFeaturedProduct,
                price: data.price,
                retailPrice: parentData.retail_price,
                name: data.parentName,
              };
              updateProduct({ id: parentData.id, data: updatedParentData });
            } else {
              let minPrice = parentData.minPrice;
              if (parentData.productVariations && parentData.productVariations.length > 0) {
                let prices = [];
                for (let variationProduct of parentData.productVariations) {
                  prices.push(parseFloat(variationProduct.price));
                }
                minPrice = Math.min(...prices);
              }

              let updatedParentData = {
                countryId: parentData.country_id,
                parentId: parentData.parent_id,
                productId: parentData.woocommerce_id,
                urlId: parentData.url_id,
                description: data.description,
                shortDescription: data.shortDescription,
                attributes: [],
                categories: data.categories,
                sku: parentData.sku,
                averageRating: parentData.average_rating,
                ratingCount: parentData.rating_count,

                isDigitalGood: parentData.is_digital_good,
                inStock: true,
                isFeaturedProduct: isParentFeaturedProduct,
                price: minPrice,
                retailPrice: parentData.retail_price,
                name: data.parentName,
              };
              updateProduct({ id: parentData.id, data: updatedParentData });
            }
            let modifiedParentData = { ...parentData, isFeaturedProduct: data.isFeaturedProduct };
            setProductData(modifiedParentData);
          }
          Notify.success("Succefully updated!");
          setUpdatedTempPrice(data.price);
          if (initialData.type == "simple") {
            dialogClose();
          } else {
            triggerProductById(id).then((response) => {
              if (response.data && response.data.instructionsFilePath) {
                let obj = fileTypeMatch(response.data.instructionsFilePath);
                setProductInstructionsPath(obj);
                setFile([]);

                let formValues = localStorage.getItem(`cacheVariation${initialData.id}`);

                if (formValues) {
                  formValues = JSON.parse(formValues);

                  if (obj && obj.path) {
                    formValues.instructionFilePath = obj;
                    localStorage.setItem(
                      `cacheVariation${initialData.id}`,
                      JSON.stringify(formValues)
                    );
                  }
                }
              }
            });
          }
        } else {
          Notify.failure("Something went wrong!");
        }
      });
    } catch (e) {
    } finally {
      Loading.remove(500);
    }
  };

  const handleDeleteProductInstruction = async (data) => {
    deleteFile(data).then((response) => {
      if (response.data) {
        // Notify.success("Succefully deleted!");
      } else {
        // Notify.failure("Something went wrong!");
      }
    });
  };

  const deleteRecord = async (id) => {
    Loading.dots({
      svgColor: "rgba(159, 3, 3, 0.8)",
    });
    try {
      if (variationLength == 1) {
        if (parentData.id) {
          await convertProductType({
            id: parentData.id,
            data: { type: "simple", woocommerceId: 0, parentId: 0 },
          });
          // deleteProduct(parentData.id);
        }
      }

      deleteProduct(id)
        .then((response) => {
          if (response) {
            Notify.success("Succefully updated!");
          } else {
            Notify.failure("Something went wrong!");
          }
        })
        .catch((error) => {
          Notify.failure("Something went wrong!");
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    } finally {
      dialogClose();
      Loading.remove(500);
    }
  };

  const handleUpdateFiles = (files) => {
    if (files.length > 0) {
      const photo = files[0].source;
      setFile(photo);
    } else {
      setFile([]);
    }
  };

  const addAttributeFields = (attributeName) => {
    attributeName
      ? append({ attribute: attributeName, option: "" })
      : append({ attribute: "", option: "" });
  };

  const removeAttributeFields = (index) => {
    remove(index);
    setTimeout(() => {
      let formValues = localStorage.getItem(`cacheVariation${initialData.id}`);
      if (formValues) {
        formValues = JSON.parse(formValues);
        // populating the data for the fields
        reset(formValues);
      }
    }, 200);
  };

  useEffect(() => {
    // let formValues = sessionStorage.getItem("formValues");
    let formValues = localStorage.getItem(`cacheVariation${initialData.id}`);
    if (formValues) {
      formValues = JSON.parse(formValues);
      if (localStorage.getItem("localParentName")) {
        formValues.parentName = JSON.parse(localStorage.getItem("localParentName"));
      }
      if (localStorage.getItem("localParentCategories")) {
        formValues.categories = JSON.parse(localStorage.getItem("localParentCategories"));
      }
      // populating the data for the fields
      reset(formValues);
      setProductInstructionsPath(formValues.instructionFilePath);
      setUpdatedTempPrice(formValues.price);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("localParentName", JSON.stringify(parentName));
  }, [parentName]);

  useEffect(() => {
    localStorage.setItem("localParentCategories", JSON.stringify(categoriesField));
  }, [categoriesField]);

  useEffect(() => {
    const subscription = watch((data) => {
      if (productInstructionsPath && productInstructionsPath.path) {
        data.instructionFilePath = productInstructionsPath;
      }
      localStorage.setItem(`cacheVariation${initialData.id}`, JSON.stringify(data));
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [watch]);

  return (
    <Card sx={{ mt: 2 }}>
      <CardContent>
        <form>
          <MDBox
            display="flex"
            sx={{
              padding: "20px",
              border: "0.0625rem solid #f0f2f5",
              borderRadius: "0.375rem",
              position: "relative",
              "& .deleteButton": {
                position: "absolute",
                top: "5px",
                right: "5px",
              },
              "& .typeConvertButton": {
                position: "absolute",
                top: "5px",
                right: "5px",
              },
              "& .productImagesBtn": {
                position: "absolute",
                bottom: "5px",
                right: "5px",
              },
            }}
          >
            <MDBox lineHeight={1}>
              <Tooltip title="Click to view the product images" placement="top">
                <MDAvatar
                  name={initialData.name}
                  src={
                    initialData.products_images &&
                    initialData.products_images.length > 0 &&
                    initialData.products_images[0] &&
                    initialData.products_images[0].src
                      ? imageSrcDecoder(initialData.products_images[0].src)
                      : noPreview
                  }
                  // onLoad={() => setImageLoading(false)}
                  size="lg"
                  sx={{ mr: 3, cursor: "pointer" }}
                  onClick={() => setOpenDigitalKeysDialog(true)}
                />
              </Tooltip>
              <ProductImages
                parentData={parentData}
                initialData={initialData}
                openDigitalKeysDialog={openDigitalKeysDialog}
                setOpenDigitalKeysDialog={setOpenDigitalKeysDialog}
              />
            </MDBox>
            <MDBox lineHeight={1}>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 400,
                  mb: 1,
                  color: "#3f3f3f",
                }}
              >
                <strong>{productName}</strong>
              </Typography>
              <Typography
                variant="p"
                sx={{
                  fontWeight: 400,
                  color: "#3f3f3f",
                }}
              >
                <strong>
                  Price:{" "}
                  {updatedTempPrice != null
                    ? `${selectedCountry.currency} ${updatedTempPrice}`
                    : productPrice
                    ? `${selectedCountry.currency} ${productPrice}`
                    : ""}
                </strong>
              </Typography>
              <br />
              <Typography
                variant="p"
                sx={{
                  fontWeight: 400,
                  color: "#3f3f3f",
                  display: "block",
                  marginTop: "5px",
                }}
              >
                <strong>RRP: {`${selectedCountry.currency} ${productRRP}`}</strong>
              </Typography>
              <MDBox
                sx={{
                  marginTop: "10px",
                }}
              >
                {parentData ? (
                  <CustomCheckbox
                    name="parentFeaturedProduct"
                    checked={isParentFeaturedProduct}
                    onChange={(e) => setIsParentFeaturedProduct(e.target.checked)}
                    label="is Parent Product Featured?"
                  />
                ) : (
                  <ControlledCheckbox
                    name={"isFeaturedProduct"}
                    control={control}
                    label={"is Featured Product?"}
                  />
                )}
              </MDBox>
            </MDBox>
            {initialData.type == "variable" && initialData.status == undefined ? (
              <IconButton
                className="deleteButton"
                color="error"
                onClick={() =>
                  Confirm.show(
                    `Please Confirm`,
                    "Are you sure you want to delete this variation?",
                    "Yes",
                    "No",
                    () => {
                      deleteRecord(initialData.id);
                    },
                    () => {},
                    {}
                  )
                }
                size="large"
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            ) : (
              <>
                <Tooltip title="Convert to variable product" sx={{ ml: 1 }} placement="top">
                  <IconButton
                    className="typeConvertButton"
                    color="info"
                    onClick={() => {
                      setOpenTypeConversionDialog(true);
                      removeLocalStorageItemsWithPrefix("variationImage=");
                    }}
                    size="large"
                  >
                    <SwapHorizontalCircleIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                <TypeConversionDialog
                  parentProduct={initialData}
                  selectedURL={initialData.url_id}
                  openTypeConversionDialog={openTypeConversionDialog}
                  distinctAttributes={distinctAttributes}
                  setOpenTypeConversionDialog={setOpenTypeConversionDialog}
                />
              </>
            )}
            <Tooltip title="Click to view the product images" sx={{ ml: 1 }} placement="top">
              <IconButton
                className="productImagesBtn"
                color="info"
                onClick={() => setOpenDigitalKeysDialog(true)}
                size="large"
              >
                <CollectionsIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </MDBox>
          <br />
          <GridContainer>
            {parentData && (
              <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                <ControlledTextInput
                  name="parentName"
                  control={control}
                  labelText="Enter Parent product name *"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  sx={{
                    "& fieldset": {
                      borderColor: "#007d6a!important",
                    },
                  }}
                />
              </GridItem>
            )}
            <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
              <ControlledTextInput
                name="name"
                control={control}
                labelText="Enter Name *"
                formControlProps={{
                  fullWidth: true,
                }}
              />
            </GridItem>

            <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
              <ControlledSelect
                multiple
                name={"categories"}
                control={control}
                label="Select parent category *"
                menu={{
                  id: "id",
                  value: "name",
                  title: "Categories",
                  list: filterCategories,
                }}
                disabled={filterCategories && filterCategories.length > 0 ? false : true}
                sx={{
                  "& .MuiSelect-select:focus": {
                    backgroundColor: "transparent",
                  },
                  "& .Mui-selected:focus": {
                    backgroundColor: "transparent",
                  },
                  "& .MuiFormLabel-root": {
                    backgroundColor: "#fff",
                  },
                  "& .MuiInputBase-root": {
                    height: "44px",
                  },
                  ...(parentData && {
                    "& fieldset": {
                      borderColor: "#007d6a !important",
                    },
                  }),
                }}
              />
            </GridItem>

            <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
              <ControlledTextInput
                name="price"
                control={control}
                labelText="Enter Reseller Price *"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: "number",
                }}
              />
            </GridItem>

            <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
              <ControlledTextInput
                name="retailPrice"
                control={control}
                labelText="Enter Recommended Retailer Price *"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: "number",
                }}
              />
            </GridItem>

            <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
              <br />
              <FormControl
                variant="outlined"
                sx={{
                  border: "1px solid rgba(0, 0, 0, 0.125)",
                  borderRadius: "5px",
                  width: "100%",
                }}
              >
                <InputLabel
                  htmlFor="shortDescription"
                  sx={{
                    transform: "translate(12px, -5px) scale(.95)",
                    backgroundColor: "#fff",
                    padding: "0 5px 0 5px",
                  }}
                >
                  {parentData && "Parent"} Short Description
                </InputLabel>
                <MDBox
                  sx={{
                    ...(parentData && {
                      "& .wrapper-class": {
                        borderColor: "#007d6a",
                      },
                    }),
                    mt: 2,
                    "& .tox-tinymce": {
                      border: 0,
                    },
                    "& .ql-editor": { minHeight: "200px" },
                  }}
                >
                  <ReactQuill
                    theme="snow"
                    value={productShortDescription}
                    onChange={setProductShortDescription}
                    modules={modules}
                  />
                </MDBox>
              </FormControl>
            </GridItem>

            <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
              <br />
              <FormControl
                variant="outlined"
                sx={{
                  mb: 3,
                  border: "1px solid rgba(0, 0, 0, 0.125)",
                  borderRadius: "5px",
                  width: "100%",
                }}
              >
                <InputLabel
                  htmlFor="description"
                  sx={{
                    transform: "translate(12px, -5px) scale(.95)",
                    backgroundColor: "#fff",
                    padding: "0 5px 0 5px",
                  }}
                >
                  {parentData && "Parent"} Description
                </InputLabel>
                <MDBox
                  sx={{
                    ...(parentData && {
                      "& .wrapper-class": {
                        borderColor: "#007d6a",
                      },
                    }),
                    mt: 2,
                    "& .tox-tinymce": {
                      border: 0,
                    },
                    "& .ql-editor": { minHeight: "250px" },
                  }}
                >
                  <ReactQuill
                    theme="snow"
                    value={productDescription}
                    onChange={setProductDescription}
                    modules={modules}
                  />
                </MDBox>
              </FormControl>
            </GridItem>

            <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
              <ControlledTextInput
                name="averageRating"
                control={control}
                labelText={"Average rating *"}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: "number",
                }}
              />
            </GridItem>

            <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
              <ControlledTextInput
                name="ratingCount"
                control={control}
                labelText="Rating count *"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: "number",
                }}
              />
            </GridItem>

            <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
              <ControlledCheckbox name="inStock" control={control} label="Is in stock?" />
            </GridItem>

            <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
              <ControlledCheckbox name="isDigitalGood" control={control} label="Is Digital Good?" />
            </GridItem>

            {isDigitalGoodField ? (
              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledTextInput
                  name="licenseDuration"
                  control={control}
                  labelText="License Duration (no of months) *"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "number",
                  }}
                />
              </GridItem>
            ) : (
              ""
            )}

            <GridItem xs={12} sm={12}>
              <br />
            </GridItem>

            {initialData.type == "variable" &&
              initialData.variation_atribute_option &&
              initialData.variation_atribute_option.length > 0 && (
                <GridItem xs={12} sm={12}>
                  <Box
                    px={2}
                    pb={2}
                    p1={1}
                    sx={{ border: "0.0625rem solid #f0f2f5", borderRadius: "0.375rem", mb: 2 }}
                  >
                    <Box display="flex" justifyContent="space-between" my={1}>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 500,
                          color: "#3f3f3f",
                        }}
                      >
                        Attributes
                      </Typography>
                      <MDButton
                        color="info"
                        variant="gradient"
                        iconOnly
                        onClick={() => {
                          setOpenAddAttributeDialog(true);
                        }}
                      >
                        <AddBox sx={{}} />
                      </MDButton>
                    </Box>
                    <br />
                    {fields.map((input, index) => {
                      return (
                        <GridContainer key={index}>
                          <GridItem xs={12} sm={12} md={11} lg={11} xl={11}>
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                                <ControlledTextInput
                                  name={`attributes.${index}.attribute`}
                                  control={control}
                                  labelText="Attribute *"
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                />
                              </GridItem>

                              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                                <ControlledTextInput
                                  name={`attributes.${index}.option`}
                                  control={control}
                                  labelText="Option *"
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                />
                              </GridItem>
                            </GridContainer>
                          </GridItem>

                          <GridItem xs={12} sm={12} md={1} lg={1} xl={1}>
                            <MDButton
                              color="warning"
                              variant="gradient"
                              iconOnly
                              onClick={() => removeAttributeFields(index)}
                              // disabled={fields && fields.length > 1 ? false : true}
                            >
                              <IndeterminateCheckBoxIcon />
                            </MDButton>
                          </GridItem>
                        </GridContainer>
                      );
                    })}
                    <AddAttributeDialog
                      addAttributeFields={addAttributeFields}
                      openAddAttributeDialog={openAddAttributeDialog}
                      setOpenAddAttributeDialog={setOpenAddAttributeDialog}
                      distinctAttributes={distinctAttributes}
                      existingAttributes={fields}
                    />
                  </Box>
                </GridItem>
              )}

            {isDigitalGoodField ? (
              <GridItem xs={12} sm={12}>
                <Box
                  px={2}
                  pb={2}
                  p1={1}
                  sx={{ border: "0.0625rem solid #f0f2f5", borderRadius: "0.375rem" }}
                  id="digitalKeysBlock"
                >
                  <Box display="flex" justifyContent="space-between" my={1}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 500,
                        color: "#3f3f3f",
                      }}
                    >
                      Digital Keys
                    </Typography>
                    <AddDigitalKeys onSubmit={onSubmitDigitalKeys} />
                  </Box>
                  <br />
                  <Box>
                    <div>
                      <GridContainer sx={{ mb: 2 }}>
                        {digitalKeysByProduct?.length > 0 ? (
                          <GridItem xs={12} sm={6} md={6} lg={6}>
                            <CustomSelect
                              id={"dates"}
                              label="Filter by Date"
                              inputProps={{
                                id: "date_label",
                              }}
                              value={filterDate}
                              onChange={(e) => {
                                setFilterDate(e.target.value);
                              }}
                              sx={{
                                "& .MuiFormLabel-root": {
                                  backgroundColor: "#fff",
                                },
                                "& .MuiInputBase-root": {
                                  height: "44px",
                                },
                              }}
                            >
                              {filterDates.map((x, i) => (
                                <MenuItem key={i} value={x}>
                                  {x}
                                </MenuItem>
                              ))}
                            </CustomSelect>
                          </GridItem>
                        ) : (
                          ""
                        )}
                        <GridItem xs={12} sm={6} md={6} lg={6}>
                          {digitalKeysTableData.length > 0 &&
                          filterDate &&
                          filteredKeysState == "active" ? (
                            <Tooltip
                              title="Deactivate All keys created in this Date"
                              placement="top"
                            >
                              <MDButton
                                variant="text"
                                color="secondary"
                                sx={{ ml: 2 }}
                                onClick={() =>
                                  Confirm.show(
                                    `Please Confirm`,
                                    "Are you sure you want to deactivate all the digital keys created in this date?",
                                    "Yes",
                                    "No",
                                    () => {
                                      deactivateAllKeys();
                                    },
                                    () => {},
                                    {}
                                  )
                                }
                              >
                                <MDBox sx={{ display: "inline-flex", alignItems: "center" }}>
                                  <PauseCircleIcon color="secondary" fontSize="inherit" />{" "}
                                  <MDBox component="span" ml={1}>
                                    {" "}
                                    Deactivate All
                                  </MDBox>
                                </MDBox>
                              </MDButton>
                            </Tooltip>
                          ) : digitalKeysTableData.length > 0 &&
                            filterDate &&
                            filteredKeysState == "inactive" ? (
                            <Tooltip title="Activate All keys created in this Date" placement="top">
                              <MDButton
                                variant="text"
                                color="success"
                                sx={{ ml: 2 }}
                                onClick={() => activateAllKeys()}
                              >
                                <MDBox sx={{ display: "inline-flex", alignItems: "center" }}>
                                  <PlayCircleIcon color="success" fontSize="inherit" />{" "}
                                  <MDBox component="span" ml={1}>
                                    {" "}
                                    Activate All
                                  </MDBox>
                                </MDBox>
                              </MDButton>
                            </Tooltip>
                          ) : (
                            ""
                          )}
                        </GridItem>
                      </GridContainer>
                      {digitalKeysByProduct?.length > 0 ? (
                        <DataTable
                          id={tables.DIGITAL_KEYS}
                          disableTableState={true}
                          headerActions={
                            filterDate && (
                              <Tooltip title="Reset Filter" sx={{ ml: 1 }} placement="top">
                                <IconButton
                                  aria-label="view"
                                  size="small"
                                  onClick={() => setFilterDate("")}
                                >
                                  <RestartAltIcon color="info" fontSize="inherit" />
                                </IconButton>
                              </Tooltip>
                            )
                          }
                          columns={[
                            {
                              Header: "#",
                              accessor: "id",
                            },
                            {
                              Header: "Key",
                              accessor: "key",
                            },
                            {
                              Header: "Status",
                              accessor: "active",
                              Cell: ({ row: { original } }) =>
                                original.active ? (
                                  <MDBadge
                                    badgeContent="Active"
                                    color="success"
                                    variant="gradient"
                                    size="sm"
                                  />
                                ) : (
                                  <MDBadge
                                    badgeContent="Deactivated"
                                    color="secondary"
                                    variant="gradient"
                                    size="sm"
                                  />
                                ),
                            },
                            {
                              Header: "Created Date",
                              accessor: "date_created",
                            },
                            {
                              Header: "Assigned Date",
                              accessor: "date_assigned",
                              Cell: ({ row: { original } }) => (
                                <span style={{ display: "flex", alignItems: "center" }}>
                                  {original.date_assigned ? (
                                    original.date_assigned
                                  ) : (
                                    <MDBadge
                                      badgeContent="Not Assigned Yet"
                                      color="secondary"
                                      variant="gradient"
                                      size="sm"
                                    />
                                  )}
                                  {original.date_assigned && original.order_id && (
                                    <OrderDetailsFetch order_id={original.order_id} />
                                  )}
                                </span>
                              ),
                            },
                            {
                              Header: "Actions",
                              accessor: "actions",
                              disableSortBy: true,
                              disableFilters: true,
                              show: true,
                              align: "right",
                              // eslint-disable-next-line react/display-name
                              Cell: ({ row: { original } }) => (
                                <div className="actions-right">
                                  {original.active ? (
                                    <Tooltip title="Deactivate" sx={{ ml: 1 }} placement="top">
                                      <IconButton
                                        aria-label="view"
                                        size="small"
                                        onClick={() =>
                                          Confirm.show(
                                            `Please Confirm`,
                                            "Are you sure you want to deactivate this digital key?",
                                            "Yes",
                                            "No",
                                            () => {
                                              let data = {
                                                active: false,
                                                ids: [original.id],
                                              };
                                              handleDeactivateKeys(data);
                                            },
                                            () => {},
                                            {}
                                          )
                                        }
                                      >
                                        <PauseCircleIcon color="secondary" fontSize="inherit" />
                                      </IconButton>
                                    </Tooltip>
                                  ) : (
                                    <Tooltip title="Activate" placement="top">
                                      <IconButton
                                        aria-label="view"
                                        size="small"
                                        onClick={() => {
                                          let data = {
                                            active: true,
                                            ids: [original.id],
                                          };
                                          handleDeactivateKeys(data);
                                        }}
                                      >
                                        <PlayCircleIcon color="success" fontSize="inherit" />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                  {original.date_assigned ? (
                                    ""
                                  ) : (
                                    <Tooltip title="Delete Key" sx={{ ml: 1 }} placement="top">
                                      <IconButton
                                        aria-label="view"
                                        size="small"
                                        onClick={() =>
                                          Confirm.show(
                                            `Please Confirm`,
                                            "Are you sure you want to delete this digital key?",
                                            "Yes",
                                            "No",
                                            () => {
                                              handleDigitalKeyDelete(original.id);
                                            },
                                            () => {},
                                            {}
                                          )
                                        }
                                      >
                                        <Close color="error" fontSize="inherit" />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                </div>
                              ),
                            },
                          ]}
                          // data={digitalKeysTableData}
                          data={
                            filterDate ? digitalKeysTableDataByFilteredDate : digitalKeysTableData
                          }
                          isSorted={true}
                          entriesPerPage={false}
                          showTotalEntries={false}
                          hideColumnSelection={false}
                          noEndBorder
                          canSearch
                          // actionButton={
                          //   <MDButton
                          //     variant="gradient"
                          //     color="primary"
                          //     sx={{ ml: 2 }}
                          //     onClick={() => setOpenDigitalKeysDialog(true)}
                          //   >
                          //     Add Digital keys
                          //   </MDButton>
                          // }
                        />
                      ) : (
                        <Alert severity="warning">No Digital keys found!</Alert>
                      )}
                    </div>
                  </Box>
                </Box>
              </GridItem>
            ) : (
              ""
            )}
            <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
              <MDBox
                px={2}
                pb={2}
                p1={1}
                mt={2}
                sx={{ border: "0.0625rem solid #f0f2f5", borderRadius: "0.375rem" }}
                id="Instructions"
              >
                <Box display="flex" justifyContent="space-between" my={1}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 500,
                      color: "#3f3f3f",
                    }}
                  >
                    Instructions
                  </Typography>
                </Box>
                {productInstructionsPath &&
                productInstructionsPath.path &&
                productInstructionsPath.file_type == "pdf" ? (
                  <MDBox sx={{ position: "relative", zIndex: 0 }}>
                    <MDBox
                      mb={2}
                      component="iframe"
                      src={process.env.REACT_APP_URL + "/" + productInstructionsPath.path}
                      sx={{ width: "100%", height: 600, position: "relative", mt: 3 }}
                    />
                  </MDBox>
                ) : productInstructionsPath &&
                  productInstructionsPath.path &&
                  productInstructionsPath.file_type == "img" ? (
                  <MDBox sx={{ position: "relative", zIndex: 0 }}>
                    <MDBox
                      mb={2}
                      component="img"
                      src={process.env.REACT_APP_URL + "/" + productInstructionsPath.path}
                      sx={{ width: "100%", position: "relative", mt: 3 }}
                    />
                  </MDBox>
                ) : productInstructionsPath &&
                  productInstructionsPath.path &&
                  productInstructionsPath.file_type == "doc" ? (
                  <MDBox sx={{ position: "relative", zIndex: 0 }}>
                    <MDBox
                      mb={2}
                      component="iframe"
                      src={googleDocsViewerURL}
                      sx={{ width: "100%", height: 600, position: "relative", mt: 3 }}
                    />
                  </MDBox>
                ) : (
                  productInstructionsPath &&
                  productInstructionsPath.path && (
                    <MDBox sx={{ position: "relative", zIndex: 0 }}>
                      <MDBox
                        mb={2}
                        component="img"
                        src={process.env.REACT_APP_URL + "/" + productInstructionsPath.path}
                        sx={{ width: "100%", position: "relative", mt: 3 }}
                      />
                    </MDBox>
                  )
                )}
                <>
                  <Box
                    component={FilePond}
                    sx={{
                      fontFamily: '"Poppins", "Roboto", "Helvetica", "Arial", sans-serif',
                      "& .filepond--file-action-button": {
                        cursor: "pointer",
                      },
                      "& .filepond--drop-label.filepond--drop-label label": {
                        fontSize: "13px",
                        letterSpacing: "0.5px",
                        fontWeight: "600",
                      },
                      mt: 2,
                    }}
                    instantUpload={false}
                    allowFileSizeValidation={true}
                    name="file"
                    allowFileTypeValidation={true}
                    acceptedFileTypes={[
                      "image/jpeg",
                      "image/jpg",
                      "image/png",
                      "application/msword", // for .doc
                      "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // for .docx
                      "application/pdf",
                    ]}
                    allowMultiple={false}
                    allowFilePoster={false}
                    labelIdle={
                      productInstructionsPath && productInstructionsPath.path
                        ? 'Drag & Drop instruction file or <span class="filepond--label-action">Browse</span>, if you want to replace the existing file'
                        : 'Drag & Drop instruction file or <span class="filepond--label-action">Browse</span>'
                    }
                    onupdatefiles={handleUpdateFiles}
                    allowRevert={false}
                  />
                </>
              </MDBox>
            </GridItem>
            <GridItem xs={12} sm={12}>
              <br />
            </GridItem>
          </GridContainer>
        </form>
        <CardActions disableSpacing>
          * Required fields
          <Box sx={{ marginLeft: "auto" }}>
            <MDButton
              color="primary"
              variant="gradient"
              id="submit"
              onClick={handleSubmit(onSubmit)}
            >
              Update Stock
            </MDButton>
            <MDButton
              color="secondary"
              variant="gradient"
              onClick={() => dialogClose()}
              sx={{ ml: 1 }}
            >
              Cancel
            </MDButton>
          </Box>
        </CardActions>
      </CardContent>
    </Card>
  );
}

export default SimpleProduct;
