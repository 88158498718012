/* eslint-disable react/prop-types */
import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";

import { Notify } from "notiflix/build/notiflix-notify-aio";
import { Confirm } from "notiflix/build/notiflix-confirm-aio";

import { styled } from "@mui/material/styles";

import PropTypes from "prop-types";

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import MDButton from "components/MDButton";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";
import LockResetIcon from "@mui/icons-material/LockReset";

import MDBadge from "components/MDBadge";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import Button from "@mui/material/Button";

import DataTable from "examples/Tables/DataTable";

import { Loading } from "notiflix/build/notiflix-loading-aio";

import Alert from "components/CustomAlert";

import {
  useLazyGetAllResellerProductsByCountryIdQuery,
  useGetAllResellerProductsByCountryIdQuery,
} from "services/products";

import {
  useLazyGetAllDiscountProductsQuery,
  useGetAllDiscountProductsQuery,
  useDeleteDiscountMutation,
} from "services/companies";

import AddDiscountProduct from "./Add";
import EditDiscountProduct from "./Edit";

import { apis } from "services/base";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

import MDBox from "components/MDBox";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const DiscountProducts = ({ company }) => {
  const [open, setOpen] = useState(false);

  const apisCache = useSelector((state) => state.apis);

  // const apisCache = apis.endpoints.getAllDiscountProducts.useQueryState(company.id);

  const [tableData, setTableData] = useState([]);

  const [products, setProducts] = useState([]);

  const [triggerProducts] = useLazyGetAllResellerProductsByCountryIdQuery();

  const {
    data: triggeredDiscountProducts = [],
    isLoading: isDiscountsProductsLoading,
    isSuccess: isDiscountsProductsSuccess,
  } = useGetAllDiscountProductsQuery(company.id);

  const [triggerDiscountProducts] = useLazyGetAllDiscountProductsQuery();

  const [deleteDiscountProduct] = useDeleteDiscountMutation();

  const handleClickOpen = async () => {
    setOpen(true);

    // Loading.dots({
    //   svgColor: "rgba(159, 3, 3, 0.8)",
    // });

    const triggeredProducts = await triggerProducts(company.country_id);

    if (triggeredProducts) {
      let parentProducts = triggeredProducts.data.filter((product) => product.parent_id == 0);
      if (parentProducts && parentProducts.length > 0) {
        const productNames  = parentProducts.map(product => product.name);
        // Sort product names alphabetically
        const sortedProductNames = productNames .sort();
        
        // Reorganize products based on sorted names
        const sortedProducts = sortedProductNames.map(name => {
          return parentProducts.find(product => product.name == name);
        });
        setProducts(sortedProducts);
      }
    }

    if (triggeredProducts) Loading.remove(500);
  };

  const refetchDiscountProducts = async () => {
    const triggeredDiscountedProducts = await triggerDiscountProducts(company.id);
    if (triggeredDiscountedProducts) {
      console.log(triggeredDiscountedProducts.data);
      // setTableData(apisCache);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteRecord = (id) => {
    Loading.dots({
      svgColor: "rgba(159, 3, 3, 0.8)",
    });
    try {
      deleteDiscountProduct(id)
        .then((response) => {
          if (response.data?.status == "success") {
            Notify.success("Succefully Deleted!");
            triggerDiscountProducts(company.id);
          } else {
            Notify.failure("Something went wrong, Please try again!");
          }
        })
        .catch((error) => {
          console.log(error);
          Notify.failure("Something went wrong, Please try again!");
        });
    } catch (error) {
      console.log(error);
      Notify.failure("Something went wrong, Please try again!");
    } finally {
      Loading.remove(500);
    }
  };

  useEffect(() => {
    isDiscountsProductsLoading
      ? Loading.dots({
          svgColor: "rgba(159, 3, 3, 0.8)",
        })
      : Loading.remove(500);
  }, [isDiscountsProductsLoading]);

  return (
    <>
      <Tooltip title="View discounted products" placement="top">
        <MDButton
          variant="contained"
          size="small"
          color="success"
          sx={{
            ml: 1,
            color: "#fff",
            fontSize: "10px!important",
            fontWeight: "500",
            letterSpacing: "0.5px",
          }}
          onClick={handleClickOpen}
        >
          Discounted products
        </MDButton>
      </Tooltip>
      <BootstrapDialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        maxWidth="lg"
        sx={{
          "& .MuiPaper-root": {
            width: "950px",
          },
        }}
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2, color: "#4b4b4f" }} id="customized-dialog-title">
          Discounted products of {company.name}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <DataTable
            columns={[
              {
                Header: "Id",
                accessor: "id",
              },
              {
                Header: "Company",
                accessor: "company_id",
                Cell: ({ row: { original } }) => (
                  <>{original.companies.name ? original.companies.name : "N/A"}</>
                ),
              },
              {
                Header: "product",
                accessor: "product_id",
                Cell: ({ row: { original } }) => (
                  <>{original.products.name ? original.products.name : "N/A"}</>
                ),
              },
              {
                Header: "Discount (%)",
                accessor: "",
                Cell: ({ row: { original } }) => <>{`${original.discount_percentage}%`}</>,
              },
              {
                Header: "Actions",
                accessor: "actions",
                disableSortBy: true,
                disableFilters: true,
                show: true,
                align: "right",
                // eslint-disable-next-line react/display-name
                Cell: ({ row: { original } }) => (
                  <div className="actions-right">
                    <EditDiscountProduct
                      products={products}
                      companyId={company.id}
                      initData={original}
                      refetchDiscountProducts={refetchDiscountProducts}
                    />
                    <Tooltip title="Delete" sx={{ ml: 1 }} placement="top">
                      <IconButton
                        aria-label="view"
                        size="small"
                        onClick={() =>
                          Confirm.show(
                            `Please Confirm`,
                            "Are you sure you want to delete this reseller?",
                            "Yes",
                            "No",
                            () => {
                              deleteRecord(original.id);
                            },
                            () => {},
                            {}
                          )
                        }
                      >
                        <CloseIcon color="error" fontSize="inherit" />
                      </IconButton>
                    </Tooltip>
                  </div>
                ),
              },
            ]}
            data={triggeredDiscountProducts || []}
            isSorted={true}
            entriesPerPage={false}
            showTotalEntries={false}
            hideColumnSelection={false}
            noEndBorder
            canSearch
            actionButton={
              <AddDiscountProduct
                products={products}
                countryId={company.country_id}
                companyId={company.id}
                refetchDiscountProducts={refetchDiscountProducts}
                triggeredDiscountProducts={triggeredDiscountProducts}
              />
            }
          />
          {triggeredDiscountProducts.length == 0 && (
            <Alert severity="warning">No discounts found!</Alert>
          )}
        </DialogContent>
        <DialogActions>
          <MDButton color="secondary" variant="gradient" autoFocus onClick={handleClose}>
            Cancel
          </MDButton>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

DiscountProducts.propTypes = {
  children: PropTypes.node.isRequired, // You can also use PropTypes.element if you want to enforce a single element.
  contact: PropTypes.string.isRequired,
};

export default DiscountProducts;
