/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Notify } from "notiflix/build/notiflix-notify-aio";
import { Confirm } from "notiflix/build/notiflix-confirm-aio";

import { styled } from "@mui/material/styles";

import moment from "moment";

import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Block } from "notiflix/build/notiflix-block-aio";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";

import TextField from "@mui/material/TextField";

import Box from "@mui/material/Box";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { AddBox } from "@mui/icons-material";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Typography from "@mui/material/Typography";

import ControlledTextInput from "components/FormInputs/ControlledTextInput";
import ControlledDateTime from "components/FormInputs/ControlledDateTime";
import ControlledCheckbox from "components/FormInputs/ControlledCheckbox";
import ControlledSelect from "components/FormInputs/ControlledSelect";
import CustomInputs from "components/CustomInputs";
import { CustomSelect } from "components/CustomSelect";

//components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
import MDBadge from "components/MDBadge";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import { BDMSchema } from "./schemas/_model";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import Check from "@mui/icons-material/Check";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from '@mui/material/FormControlLabel';

import Tooltip from "@mui/material/Tooltip";

import Edit from "@mui/icons-material/Edit";
import Close from "@mui/icons-material/Close";
import SettingsIcon from '@mui/icons-material/Settings';

import InputAdornment from "@mui/material/InputAdornment";

import Alert from "components/CustomAlert";

import EditCustomReminder from "./Edit";
import AddCustomReminder from "./Add";

import { tables, dateFormat } from "utils/constants";

// rtk queries
import {
  useDeleteCustomReminderMutation,
  useGetAllCustomRemindersQuery,
  useGetAllReminderTypesQuery
} from "services/reminders";

import { globalCountry } from "store/entities/countries";

import "./assets/styles.css";

import { useDispatch, useSelector } from "react-redux";

import { replaceHTMLEntityAnd } from "utils/helperFunctions";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function ReminderHistory () {

  const selectedCountry = useSelector(globalCountry);

  const { data: reminderTypesList = [], isLoading: isReminderTypesLoading, isSuccess: isReminderTypesSuccess } = useGetAllReminderTypesQuery();

  const { data: customReminders = [], isLoading, isSuccess } = useGetAllCustomRemindersQuery();

  const [deleteReminder] = useDeleteCustomReminderMutation();

  const dispatch = useDispatch();

  useEffect(() => {
    Loading.dots({
      svgColor: "rgba(159, 3, 3, 0.8)",
    });

    isSuccess && isReminderTypesSuccess && Loading.remove(500);
  }, [isSuccess, isReminderTypesSuccess]);

  const deleteRecord = async (id) => {
    Loading.dots({
      svgColor: "rgba(159, 3, 3, 0.8)",
    });
    
    try {
      deleteReminder(id)
        .then((response) => {
          if(response.data != {}) {
            Notify.success("Succefully deleted!");
          }
        })
        .catch((error) => console.log(error));
        
    } catch (error) {
      console.log(error);
    } finally {
      Loading.remove(500);
    }
  };

  return (
    <Card>
      <CardHeader
        // avatar={<AccountCircleIcon fontSize="large" color="info" />}
        title={<MDTypography color="info">Custom reminders</MDTypography>}
      />
      <CardContent>
        <br />
        {/* {BDMList && BDMList.length > 0 ? ( */}
          <DataTable
            // headerActions={
            //   <FormControlLabel control={
            //     <Checkbox
            //     checkedIcon={<Check />}
            //     icon={<Check />}
            //     onChange={(e) => setWithOldRecords(e.target.checked)}
            //     checked={withOldRecords}
            //   />
            //   } label="Including inactive" />
            // }
            columns={[
              { 
                Header: "id", 
                accessor: "id",
              },
              { 
                Header: "product", 
                accessor: "product_name"
              },
              { 
                Header: "reseller", 
                accessor: "reseller_email"
              },
              { 
                Header: "grand total", 
                accessor: "grand_total",
                Cell: ({ row: { original } }) => <>{selectedCountry.currency + ' ' + original.grand_total}</>
              },
              {
                Header: "Expiry Date",
                accessor: "expiry_date",
                Cell: ({ row: { original } }) => moment(original.expiry_date).format(dateFormat),
              },
              {
                Header: "action",
                accessor: "action",
                align: "right",
                disableSortBy: true,
                disableFilters: true,
                show: true,
                // eslint-disable-next-line react/display-name
                Cell: ({ row: { original } }) => (
                  <div className="actions-right">
                    <EditCustomReminder
                      reminderTypes={reminderTypesList}
                      initialData={original}
                    />
                    <Tooltip title="Delete" sx={{ ml: 1 }} placement="top">
                      <IconButton
                        aria-label="view"
                        size="small"
                        onClick={() => {
                          Confirm.show(
                            `Please Confirm`,
                            "Are you sure you want to delete?",
                            "Yes",
                            "No",
                            () => {
                              deleteRecord(original.id);
                            },
                            () => {},
                            {}
                          );
                        }}
                      >
                        <Close color={"error"} fontSize="inherit" />
                      </IconButton>
                    </Tooltip>
                  </div>
                ),
              },
            ]}
            data={customReminders.filter(reminder => reminder.country_id == selectedCountry.id)}
            isSorted={true}
            entriesPerPage={false}
            showTotalEntries={false}
            hideColumnSelection={false}
            noEndBorder
            canSearch
            actionButton={
              <AddCustomReminder reminderTypes={reminderTypesList} country={selectedCountry.id} />
            }
          />
        {/* ) : (
          <Alert severity="warning">No Business Developement Managers found!</Alert>
        )} */}
      </CardContent>
    </Card>
  );
}

export default ReminderHistory;
