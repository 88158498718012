import React, { useState, useEffect, useMemo } from "react";

import PropTypes from "prop-types";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { styled } from "@mui/material/styles";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";

import MDButton from "components/MDButton";
import ControlledTextInput from "components/FormInputs/ControlledTextInput";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { availabilitySchema } from "./schemas/_model";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
}));

const AvailabilityDialog = ({ openDialog, closeDialog, onAvailabilitySubmit }) => {

    const initialFormState = {
        availability: 1
    };

    const { control, watch, handleSubmit } = useForm({
        resolver: yupResolver(availabilitySchema),
        defaultValues: initialFormState,
      });

    return (
        <BootstrapDialog
            onClose={() => closeDialog(false)}
            aria-labelledby="customized-dialog-title"
            maxWidth="lg"
            sx={{
                "& .MuiPaper-root": {
                    width: "450px",
                },
            }}
            open={openDialog}
        >
            <DialogTitle sx={{ m: 0, p: 2, color: "#4b4b4f" }} id="customized-dialog-title">
                Enter Availability Manually
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={() => closeDialog(false)}
                sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                <form>
                    <ControlledTextInput
                        name="availability"
                        control={control}
                        labelText="Availability *"
                        formControlProps={{
                            fullWidth: true,
                        }}
                        sx={{ mt: 2 }}
                        inputProps={{
                            type: "number",
                        }}
                    />
                </form>
                <br />
            </DialogContent>
            <DialogActions>
                <MDButton
                    type="submit"
                    color="primary"
                    variant="gradient"
                    id="submit"
                    onClick={handleSubmit(onAvailabilitySubmit)}
                >
                    Add
                </MDButton>
                <MDButton color="secondary" variant="gradient" autoFocus onClick={() => closeDialog(false)}>
                    Cancel
                </MDButton>
            </DialogActions>
        </BootstrapDialog>
    )
}

AvailabilityDialog.propTypes = {
    openDialog: PropTypes.bool,
    closeDialog: PropTypes.func,
    onAvailabilitySubmit: PropTypes.func
  };

export default AvailabilityDialog;