import { apis } from "./base";

const configsService = apis.injectEndpoints({
  endpoints: (builder) => ({
    // Reminder types
    getAllConfigs: builder.query({
      query: () => {
        return `/admin/configs/all`;
      },
      transformResponse: (response) => response.response,
      providesTags: ["Configs"],
    }),

    getSchedulerStatus: builder.query({
        query: () => {
          return `/admin/configs/scheduler/status`;
        },
        transformResponse: (response) => response.response,
        providesTags: ["CronScheduler"],
    }),

    startScheduler: builder.mutation({
      query: () => ({
        url: `/admin/configs/scheduler/start`,
        method: "POST",
        body: {},
      }),
      // transformResponse: (response) => response.response
      invalidatesTags: ["CronScheduler"],
    }),

    stopScheduler: builder.mutation({
        query: () => ({
          url: `/admin/configs/scheduler/stop`,
          method: "POST",
          body: {},
        }),
        // transformResponse: (response) => response.response
        invalidatesTags: ["CronScheduler"],
      }),

    editSchedulerRule: builder.mutation({
        query: ({ data }) => ({
          url: `/admin/configs/scheduler/edit`,
          method: "PUT",
          body: data,
        }),
        // transformResponse: (response) => response.response
        invalidatesTags: ["Configs"],
    }),

    // putReminderType: builder.mutation({
    //     query: ({reminderTypeId, data}) => ({
    //       url: `/admin/reminder/types/edit/${reminderTypeId}`,
    //       method: "PUT",
    //       body: data,
    //     }),
    //     // transformResponse: (response) => response.response
    //     invalidatesTags: ["Configs"],
    // }),

    // putReminderType: builder.mutation({
    //   query: ({reminderTypeId, data}) => ({
    //     url: `/admin/reminder/types/edit/${reminderTypeId}`,
    //     method: "PUT",
    //     body: data,
    //   }),
    //   // transformResponse: (response) => response.response
    //   invalidatesTags: ["Configs"],
    // }),

    // deleteReminderType: builder.mutation({
    //   query: (id) => ({
    //     url: `/admin/reminder/types/delete/${id}`,
    //     method: "DELETE",
    //   }),
    //   transformResponse: (response) => response.response,
    //   invalidatesTags: ["Configs"],
    // }),
  }),
});

export const {
  useGetAllConfigsQuery,
  useLazyGetAllConfigsQuery,
  useGetSchedulerStatusQuery,
  useStartSchedulerMutation,
  useStopSchedulerMutation,
  useEditSchedulerRuleMutation
//   usePutReminderTypeMutation,
//   useDeleteReminderTypeMutation,
} = configsService;
