import { apis } from "./base";

const productsService = apis.injectEndpoints({
  endpoints: (builder) => ({
    getAllResellerProducts: builder.query({
      query: () => {
        return `/products/all`;
      },
      transformResponse: (response) => response.response.sort((a, b) => b.id - a.id),
      providesTags: ["Products"],
    }),

    getAllResellerProductsByCountryId: builder.query({
      query: (country_id) => {
        return `/admin/products/all/${country_id}`;
      },
      transformResponse: (response) => response.response,
      // transformResponse: (response) => response.response.sort((a, b) => b.id - a.id),
      providesTags: ["Products"],
    }),

    getProductById: builder.query({
      query: (id) => {
        return `/admin/products/id/${id}`;
      },
      transformResponse: (response) => response.response,
      // providesTags: ["Products"],
    }),

    getWoocommerceSiteProducts: builder.mutation({
      query: (data) => ({
        url: `/admin/woocommerce-fetch`,
        method: "POST",
        body: data,
      }),
      transformResponse: (response) => response.response,
      invalidatesTags: [],
    }),

    postProduct: builder.mutation({
      query: (data) => ({
        url: `admin/products/new`,
        method: "POST",
        body: data,
      }),
      // transformResponse: (response) => response.response
      // invalidatesTags: ["Products"],
    }),

    putProduct: builder.mutation({
      query: ({ id, data }) => ({
        url: `/admin/products/edit/${id}`,
        method: "PUT",
        body: data,
      }),
      // transformResponse: (response) => response.response
      // invalidatesTags: ["Products"],
    }),

    convertProduct: builder.mutation({
      query: ({ id, data }) => ({
        url: `/admin/products/convert-type/${id}`,
        method: "PUT",
        body: data,
      }),
      // transformResponse: (response) => response.response
      // invalidatesTags: ["Products"],
    }),

    reorderProducts: builder.mutation({
      query: (data) => ({
        url: `/admin/products/reorder/`,
        method: "PUT",
        body: { productIdsAndPriorities: data },
      }),
      // transformResponse: (response) => response.response
      invalidatesTags: ["Products"],
    }),

    reorderProductsWithoutAutoRefetch: builder.mutation({
      query: (data) => ({
        url: `/admin/products/reorder/`,
        method: "PUT",
        body: { productIdsAndPriorities: data },
      }),
      // transformResponse: (response) => response.response
      // invalidatesTags: ["Products"],
    }),

    deleteProduct: builder.mutation({
      query: (id) => ({
        url: `/admin/products/delete/${id}`,
        method: "DELETE",
      }),
      transformResponse: (response) => response.response,
      invalidatesTags: ["Products"],
    }),

    uploadProductImage: builder.mutation({
      query: (data) => ({
        url: `/admin/products/images/upload/${data.id}`,
        method: "POST",
        body: data.data,
      }),
      // transformResponse: (response) => response.response
      // invalidatesTags: ["Products"],
    }),

    getDistinctAttributes: builder.query({
      query: () => {
        return `/admin/products/distinct-attributes`;
      },
      transformResponse: (response) => response.response,
      providesTags: ["Products"],
    }),

    getProductImagesByProductId: builder.query({
      query: (id) => {
        return `/admin/products/images/id/${id}`;
      },
      transformResponse: (response) => response.response,
      // providesTags: ["Products"],
    }),

    deleteProductImage: builder.mutation({
      query: (id) => ({
        url: `/admin/products/delete-image/${id}`,
        method: "DELETE",
      }),
      transformResponse: (response) => response.response,
      // invalidatesTags: ["Products"],
    }),

    // getAllCountries: builder.query({
    //     query: () => ({
    //       url: "/countries/all",
    //     }),
    //     transformResponse: (response) => response.response,
    //     // providesTags: ["Countries"],
    // }),

    // getOrderProductsByOrderId: builder.query({
    //   query: (order_id) => {
    //     return `/admin/orders/id/${order_id}`;
    // },
    //   transformResponse: (response) => response.response,
    //   providesTags: ["OrderProducts"],
    // }),
  }),
});

export const {
  useGetAllResellerProductsQuery,
  useGetDistinctAttributesQuery,
  useGetProductByIdQuery,
  useLazyGetProductByIdQuery,
  useGetAllResellerProductsByCountryIdQuery,
  useLazyGetAllResellerProductsByCountryIdQuery,
  useGetWoocommerceSiteProductsMutation,
  usePostProductMutation,
  usePutProductMutation,
  useReorderProductsMutation,
  useReorderProductsWithoutAutoRefetchMutation,
  useConvertProductMutation,
  useDeleteProductMutation,
  useUploadProductImageMutation,
  useGetProductImagesByProductIdMutation,
  useLazyGetProductImagesByProductIdQuery,
  useDeleteProductImageMutation,
} = productsService;
