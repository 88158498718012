import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "tables",
  initialState: {},
  reducers: {
    add: (state, action) => ({ ...state, ...action.payload }),
    reset: () => {}
  }
});

export const actions = slice.actions;

export default slice.reducer;
