/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { styled } from "@mui/material/styles";

import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Block } from "notiflix/build/notiflix-block-aio";
import { Notify } from "notiflix/build/notiflix-notify-aio";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import Chip from "@mui/material/Chip";

import { AddBox } from "@mui/icons-material";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Typography from "@mui/material/Typography";

import ControlledTextInput from "components/FormInputs/ControlledTextInput";
import ControlledDateTime from "components/FormInputs/ControlledDateTime";
import ControlledCheckbox from "components/FormInputs/ControlledCheckbox";
import ControlledSelect from "components/FormInputs/ControlledSelect";
import CustomInputs from "components/CustomInputs";
import { CustomSelect } from "components/CustomSelect";

//components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

//example components
import MDButton from "components/MDButton";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import InputAdornment from '@mui/material/InputAdornment';

import { addDiscountSchema } from "../../schemas/_model";

// rtk queries
import { useGetAllStatesByCountryIdQuery } from "services/countries";

import { useAddDiscountMutation, useLazyGetAllDiscountProductsQuery } from "services/companies";

import { globalCountry } from "store/entities/countries";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function AddDiscountProduct({ products, companyId, countryId, refetchDiscountProducts, triggeredDiscountProducts }) {
  const [addDiscount] = useAddDiscountMutation();

  const [triggerDiscountProducts] = useLazyGetAllDiscountProductsQuery();

  const initialFormState = {
    product_id: [],
    discount_percentage: 0,
  };

  const { control, watch, handleSubmit, setValue, reset } = useForm({
    resolver: yupResolver(addDiscountSchema),
    defaultValues: initialFormState,
  });

  const selectedProducts = watch("product_id");

  const [openDiscountAdd, setOpenDiscountAdd] = useState(false);

  const handleClickOpen = () => {
    setOpenDiscountAdd(true);
  };
  const handleClose = () => {
    setOpenDiscountAdd(false);
  };

  const onSubmit = async (data) => {
    let filterData = [];
    if (data.product_id && data.product_id.length > 0) {
      for (let discount of data.product_id) {
        let obj = {};
        obj.company_id = companyId;
        obj.product_id = discount;
        obj.discount_percentage = data.discount_percentage;

        filterData.push(obj);

        if(triggeredDiscountProducts && triggeredDiscountProducts.length > 0) {
          let ifProductExist = triggeredDiscountProducts.filter(item => item.product_id == discount);
          if(ifProductExist && ifProductExist.length > 0)  {
            Notify.failure("Product is already added!");
            return;
          }
        }
      }
    }

    Loading.dots({
      svgColor: "rgba(159, 3, 3, 0.8)",
    });

    delete data.address1;
    delete data.address2;

    try {
      const response = await addDiscount({ data: filterData });
      if (response.data?.status == "success") {
        Notify.success("Succefully Added!");
        handleClose();
      } else {
        if (response.error?.data) {
          Notify.failure(response.error.data.message);
        } else {
          Notify.failure("Something went wrong, Please try again!");
        }
      }
    } catch (e) {
    } finally {
      reset();
      Loading.remove(500);
    }
  };

  const removeProduct = (id) => {
    let filteredProductIds = selectedProducts.filter((item) => item != id);
    setValue("product_id", filteredProductIds);
  };

  return (
    <>
      <MDButton variant="gradient" color="primary" sx={{ ml: 2 }} onClick={handleClickOpen}>
        Add discount product
      </MDButton>
      <BootstrapDialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        maxWidth="xl"
        sx={{
          "& .MuiPaper-root": {
            width: "750px",
          },
        }}
        open={openDiscountAdd}
      >
        <DialogTitle sx={{ m: 0, p: 2, color: "#3f3f3f" }} id="customized-dialog-title">
          Add discount product
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <MDBox sx={{ mb: 1.5 }}>
            {selectedProducts &&
              selectedProducts.length > 0 &&
              selectedProducts.map((product) => (
                <Chip
                  key={product}
                  sx={{
                    height: "26px",
                    mr: 1,
                    mb: "5px",
                    "& .MuiChip-label": { fontSize: "0.65rem" },
                    "& .MuiSvgIcon-root": { marginTop: "-1px" },
                  }}
                  label={products.filter((item) => item.id == product)[0].name}
                  onDelete={() => removeProduct(product)}
                />
              ))}
          </MDBox>
          <form>
            <GridContainer>
              <GridItem xs={12} sm={12} md={8} lg={8} xl={8}>
                <ControlledSelect
                  multiple
                  name={"product_id"}
                  control={control}
                  label="Select products *"
                  menu={{
                    id: "id",
                    value: "name",
                    title: "Products",
                    list: products,
                  }}
                  sx={{
                    "& .MuiFormLabel-root": {
                      backgroundColor: "#fff",
                    },
                    "& .MuiInputBase-root": {
                      height: "44px",
                    },
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledTextInput
                  name="discount_percentage"
                  control={control}
                  labelText="Discount *"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "number",
                    endAdornment: <InputAdornment position="end" sx={{ '& .MuiTypography-root': { fontSize: 15, mr: 1, fontWeight: 600 } }}>%</InputAdornment>,
                  }}
                />
              </GridItem>
            </GridContainer>
          </form>
        </DialogContent>
        <DialogActions>
          <MDButton
            type="submit"
            color="primary"
            variant="gradient"
            id="submit"
            onClick={handleSubmit(onSubmit)}
          >
            Add
          </MDButton>
          <MDButton color="secondary" variant="gradient" autoFocus onClick={handleClose}>
            Cancel
          </MDButton>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}

export default AddDiscountProduct;
