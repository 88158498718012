// import { createSelector } from "@reduxjs/toolkit";
export const isLoggedIn = (state) => state.auth.isLoggedIn;

// export const getLayout = createSelector(getIsAdmin, (isAdmin) => {
//   if (isAdmin === null) {
//     return "/auth";
//   } else {
//     return "/user";
//   }
// });
