/* eslint-disable react/prop-types */
import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";

import { styled } from "@mui/material/styles";

import PropTypes from "prop-types";

import moment from "moment";

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import MDButton from "components/MDButton";
import Typography from "@mui/material/Typography";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import MDBadge from "components/MDBadge";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import RejectNote from "components/RejectNote";

import Divider from "@mui/material/Divider";

import Button from "@mui/material/Button";

import DataTable from "examples/Tables/DataTable";

import { Loading } from "notiflix/build/notiflix-loading-aio";

import Alert from "components/CustomAlert";

import { globalCountry } from "store/entities/countries";

import {
  useLazyGetOrderProductsByOrderIdQuery,
  useChangeOrderProductDeliveryStatusMutation,
  useLazyGetOrderDetailsByOrderIdQuery
} from "services/orders";

import { formatDate } from "utils/helperFunctions";

import DetailBlock from "components/DetailBlock";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

import MDBox from "components/MDBox";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const OrderDetailsFetch = ({ order_id }) => {
  const selectedCountry = useSelector(globalCountry);

  const [singleOrderDetails, setSingleOrderDetails] = useState({});

  const [orderProductByOrderId, setOrderProductByOrderId] = useState([]);

  const [openOrderDetails, setOpenOrderDetails] = useState(false);

  const [rejectNoteOpen, setRejectNoteOpen] = useState(false);

  const [canceledData, setCanceledData] = useState({});

  const [ triggerOrderDetails ] = useLazyGetOrderDetailsByOrderIdQuery({
    // pollingInterval: 5000,
  });

  const [
    triggerByOrderId
  ] = useLazyGetOrderProductsByOrderIdQuery();

  const [changeDeliveryStatus, { isLoading }] = useChangeOrderProductDeliveryStatusMutation();

  const handleClickOpen = async () => {
    Loading.dots({
      svgColor: "rgba(159, 3, 3, 0.8)",
    });
    let orderDetails = await triggerOrderDetails(order_id);
    if(orderDetails.status == "fulfilled") {
      setSingleOrderDetails(orderDetails.data);
    }
    // setSingleOrderDetails();
    let orderProducts = await triggerByOrderId(order_id);
    if(orderProducts.status == "fulfilled") {
      console.log(orderProducts);
      setOrderProductByOrderId(orderProducts.data);
    }
    Loading.remove(500);
    setOpenOrderDetails(true);
  };
  const handleClose = () => {
    setOpenOrderDetails(false);
  };

  const onDeliveryStatusCanceledSubmit = (data) => {
    Loading.dots({
      svgColor: "rgba(159, 3, 3, 0.8)",
    });

    let orderProductId = canceledData.order_product_id;
    canceledData.note = data.note;
    delete canceledData.order_product_id;

    try {
      changeDeliveryStatus({ order_product_id: orderProductId, data: { ...canceledData } }).then(
        (response) => {
          triggerByOrderId(order_id);
        }
      );
    } catch (e) {
    } finally {
      setRejectNoteOpen(false);
      Loading.remove(500);
    }
  };

  // useEffect(() => {
  //   isRefundHistoryLoading ? Loading.dots({
  //     svgColor: "rgba(159, 3, 3, 0.8)",
  //   }) : Loading.remove(500);
  // }, [isRefundHistoryLoading]);

  const orderProductDetails = useMemo(() => {
    return orderProductByOrderId;
  }, [orderProductByOrderId]);

  return (
    <>
      <Tooltip title="View Assigned Order Details" placement="top">
        <IconButton aria-label="view" size="small" onClick={handleClickOpen} sx={{ ml: 1 }} >
          <InfoIcon color="info" fontSize="inherit" />
        </IconButton>
        {/* <MDButton
          variant="contained"
          size="small"
          color="info"
          sx={{
            ml: 1,
            color: "#fff",
            fontSize: "10px!important",
            fontWeight: "400",
          }}
          onClick={handleClickOpen}
        >
          View
        </MDButton> */}
      </Tooltip>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        maxWidth="lg"
        sx={{
          "& .MuiPaper-root": {
            width: "900px",
          },
        }}
        open={openOrderDetails}
      >
        <DialogTitle color="info" sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Order Details
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {singleOrderDetails && (
            <>
              <MDBox sx={{ textAlign: "right" }}></MDBox>
              <MDBox sx={{ mt: 2 }}>
                <GridContainer>
                  {order_id && (
                    <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                      <DetailBlock blockTitle="Order ID" blockDesc={`#${order_id}`} />
                    </GridItem>
                  )}
                  {singleOrderDetails ? (
                    <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                      <DetailBlock
                        blockTitle="Ordered Date"
                        blockDesc={formatDate(singleOrderDetails.datetime)}
                      />
                    </GridItem>
                  ) : (
                    ""
                  )}
                  {singleOrderDetails.reseller_id && (
                    <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                      <DetailBlock
                        blockTitle="Reseller ID"
                        blockDesc={`#${singleOrderDetails.reseller_id}`}
                      />
                    </GridItem>
                  )}
                  {singleOrderDetails.contact_name && (
                    <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                      <DetailBlock
                        blockTitle="Reseller Name"
                        blockDesc={singleOrderDetails.contact_name}
                      />
                    </GridItem>
                  )}
                  {singleOrderDetails.contact_phone && (
                    <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                      <DetailBlock
                        blockTitle="Reseller Contact"
                        blockDesc={singleOrderDetails.contact_phone}
                      />
                    </GridItem>
                  )}
                  <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                    <DetailBlock
                      blockTitle="PO Number"
                      blockDesc={singleOrderDetails.po_number ? singleOrderDetails.po_number : 'N/A'}
                    />
                  </GridItem>
                  {singleOrderDetails.payment_method ? (
                    <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                      <DetailBlock
                        blockTitle="Payment Method"
                        blockDesc={singleOrderDetails.payment_method}
                      />
                    </GridItem>
                  ) : (
                    ""
                  )}
                  {singleOrderDetails.payment_status == "PENDING" ? (
                    <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                      <DetailBlock
                        blockTitle="Payment Status"
                        blockDesc={
                          <MDBadge badgeContent="Pending" color="info" variant="gradient" size="sm" />
                        }
                      />
                    </GridItem>
                  ) : singleOrderDetails.payment_status == "PAID" ? (
                    <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                      <DetailBlock
                        blockTitle="Payment Status"
                        blockDesc={
                          <MDBadge badgeContent="Paid" color="success" variant="gradient" size="sm" />
                        }
                      />
                    </GridItem>
                  ) : (
                    <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                      <DetailBlock
                        blockTitle="Payment Status"
                        blockDesc={
                          singleOrderDetails.payment_status == "REFUNDED" ? <MDBadge badgeContent="Refunded" color="error" variant="gradient" size="sm" /> : <MDBadge badgeContent="Canceled" color="error" variant="gradient" size="sm" />
                        }
                      />
                    </GridItem>
                  )}

                  <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Divider />
                  </GridItem>
                </GridContainer>
              </MDBox>
              {singleOrderDetails && (
                <MDBox>
                  <MDTypography component="h6" sx={{ mb: 2 }} color="info">
                    Order Items
                  </MDTypography>
                  {orderProductDetails.length > 0 ? (
                    <MDBox
                      sx={{
                        // padding: "5px 12px",
                        // border: "1px solid #b7b7b9",
                        // borderRadius: "0.375rem",
                        marginBottom: "3px",
                        "& table tr td": {
                          fontSize: "12px",
                          paddingLeft: "5px",
                          paddingRight: "5px",
                        },
                        "& table tr th": {
                          fontSize: "12px",
                          paddingLeft: "5px",
                          paddingRight: "5px",
                        },
                        "& table tr th .MuiBox-root": {
                          color: "#3f3f3f",
                        },
                        "& table tr td .MuiBox-root": {
                          color: "#3f3f3f",
                        },
                      }}
                    >
                      <DataTable
                        columns={[
                          {
                            Header: "Product Name",
                            accessor: "name",
                            Cell: ({ row: { original } }) =>
                              original.attributes != "" ? <MDBox>
                                    <MDBox sx={{ fontSize: '13px' }}>{original.name}</MDBox>
                                    <MDBox sx={{ fontWeight: 400, fontStyle: 'italic' }}>{original.attributes}</MDBox>
                                  </MDBox>
                                : <>
                              <MDBox sx={{ fontSize: '13px' }}>
                                {original.name}
                              </MDBox>
                            </>,
                          },
                          {
                            Header: "Quantity",
                            accessor: "quantity",
                            align: "center",
                          },
                          {
                            Header: "Price",
                            accessor: "price",
                            align: "center",
                            Cell: ({ row: { original } }) => (
                              <>
                                {selectedCountry.currency} {original.price?.toFixed(2)}
                              </>
                            ),
                          },
                          {
                            Header: "Status",
                            accessor: "status",
                            disableSortBy: true,
                            disableFilters: true,
                            show: true,
                            align: "right",
                            Cell: ({ row: { original } }) =>
                              original.status == "PROCESSING" ? (
                                <>
                                  <MDBox>
                                    <MDBadge
                                      badgeContent="Processing"
                                      color="info"
                                      variant="gradient"
                                      size="sm"
                                    />
                                  </MDBox>
                                </>
                              ) : original.status == "SHIPPED" ? (
                                <MDBox>
                                  <MDBadge
                                    badgeContent="Shipped"
                                    color="success"
                                    variant="gradient"
                                    size="sm"
                                  />
                                </MDBox>
                              ) : original.status == "DELIVERED" ? (
                                <MDBox>
                                  <MDBadge
                                    badgeContent="Delivered"
                                    color="secondary"
                                    variant="gradient"
                                    size="sm"
                                  />
                                </MDBox>
                              ) : (
                                <MDBox display="flex" justifyContent="space-between">
                                  <MDBadge
                                    badgeContent="Cancelled"
                                    color="error"
                                    variant="gradient"
                                    size="sm"
                                  />
                                  {original.note && (
                                    <Tooltip title={original.note} placement="top">
                                      <InfoOutlinedIcon
                                        sx={{
                                          ml: 2,
                                          fontSize: "16px!important",
                                        }}
                                      />
                                    </Tooltip>
                                  )}
                                </MDBox>
                              ),
                          },
                          // {
                          //   Header: "Change the status",
                          //   accessor: "actions",
                          //   disableSortBy: true,
                          //   disableFilters: true,
                          //   show: true,
                          //   align: "right",
                          //   // eslint-disable-next-line react/display-name
                          //   Cell: ({ row: { original } }) => (
                          //     <div className="actions-right">
                          //       {original.status == "PROCESSING" && (
                          //         <Tooltip title="Mark as Cancelled" placement="top">
                          //           <MDButton
                          //             variant="contained"
                          //             size="small"
                          //             color="primary"
                          //             sx={{
                          //               ml: 1,
                          //               color: "#fff",
                          //               fontSize: "10px!important",
                          //               fontWeight: "400",
                          //             }}
                          //             onClick={() => {
                          //               setCanceledData({
                          //                 ...canceledData,
                          //                 status: "CANCELED",
                          //                 productId: original.product_id,
                          //                 orderId: order_id,
                          //                 order_product_id: original.id,
                          //               });
                          //               setRejectNoteOpen(true);
                          //             }}
                          //           >
                          //             Cancelled
                          //           </MDButton>
                          //         </Tooltip>
                          //       )}
                          //       {original.status == "PROCESSING" && (
                          //         <Tooltip title="Mark as Shipped" placement="top">
                          //           <MDButton
                          //             variant="contained"
                          //             size="small"
                          //             color="info"
                          //             sx={{
                          //               ml: 1,
                          //               color: "#fff",
                          //               fontSize: "10px!important",
                          //               fontWeight: "400",
                          //             }}
                          //             onClick={() => {
                          //               Loading.dots({
                          //                 svgColor: "rgba(159, 3, 3, 0.8)",
                          //               });
                          //               let data = {
                          //                 status: "SHIPPED",
                          //                 productId: original.product_id,
                          //                 orderId: order_id,
                          //                 note: "",
                          //               };
                          //               try {
                          //                 changeDeliveryStatus({
                          //                   order_product_id: original.id,
                          //                   data: { ...data },
                          //                 }).then((response) => {
                          //                   triggerByOrderId(order_id);
                          //                 });
                          //               } catch (e) {
                          //               } finally {
                          //                 Loading.remove(500);
                          //               }
                          //             }}
                          //           >
                          //             Shipped
                          //           </MDButton>
                          //         </Tooltip>
                          //       )}
                          //       {original.status == "SHIPPED" && (
                          //         <Tooltip title="Mark as Delivered" placement="top">
                          //           <MDButton
                          //             variant="contained"
                          //             size="small"
                          //             color="secondary"
                          //             sx={{
                          //               ml: 1,
                          //               color: "#fff",
                          //               fontSize: "10px!important",
                          //               fontWeight: "400",
                          //             }}
                          //             onClick={() => {
                          //               Loading.dots({
                          //                 svgColor: "rgba(159, 3, 3, 0.8)",
                          //               });
                          //               let data = {
                          //                 status: "DELIVERED",
                          //                 productId: original.product_id,
                          //                 orderId: order_id,
                          //                 note: "",
                          //               };
                          //               try {
                          //                 changeDeliveryStatus({
                          //                   order_product_id: original.id,
                          //                   data: { ...data },
                          //                 }).then((response) => {
                          //                   triggerByOrderId(order_id);
                          //                 });
                          //               } catch (e) {
                          //               } finally {
                          //                 Loading.remove(500);
                          //               }
                          //             }}
                          //           >
                          //             Delivered
                          //           </MDButton>
                          //         </Tooltip>
                          //       )}
                          //       <RejectNote
                          //         dialogTitle="Cancel Note"
                          //         dialogNote={`A note is required in order to cancel an order product: (${original.name})`}
                          //         openNote={rejectNoteOpen}
                          //         closeNote={setRejectNoteOpen}
                          //         onRejectSubmit={onDeliveryStatusCanceledSubmit}
                          //         submitButtonTitle="Submit"
                          //       />
                          //     </div>
                          //   ),
                          // },
                        ]}
                        data={orderProductDetails || []}
                        isSorted={true}
                        entriesPerPage={false}
                        showTotalEntries={false}
                        hideColumnSelection={true}
                        noEndBorder
                        canSearch={false}
                        unlimitedRows={true}
                      />
                    </MDBox>
                  ) : (
                    <Alert severity="warning">No Order products found!</Alert>
                  )}
                  <MDBox sx={{ mt: 2 }}>
                    <GridContainer>
                      {singleOrderDetails.subtotal ? (
                        <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                          <DetailBlock
                            blockTitle="Subtotal"
                            blockDesc={`${selectedCountry.currency} ${singleOrderDetails.subtotal?.toFixed(2)}`}
                          />
                        </GridItem>
                      ) : (
                        ""
                      )}
                      {singleOrderDetails.delivery_fee ? (
                        <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                          <DetailBlock
                            blockTitle="Delivery Fee"
                            blockDesc={`${selectedCountry.currency} ${singleOrderDetails.delivery_fee?.toFixed(2)}`}
                          />
                        </GridItem>
                      ) : (
                        ""
                      )}
                      {singleOrderDetails.tax ? (
                        <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                          <DetailBlock
                            blockTitle="Tax"
                            blockDesc={`${selectedCountry.currency} ${singleOrderDetails.tax?.toFixed(2)}`}
                          />
                        </GridItem>
                      ) : (
                        ""
                      )}
                      {singleOrderDetails.grandtotal ? (
                        <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                          <DetailBlock
                            blockTitle="Total"
                            blockDesc={`${selectedCountry.currency} ${singleOrderDetails.grandtotal?.toFixed(2)}`}
                          />
                        </GridItem>
                      ) : (
                        ""
                      )}

                      <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Divider />
                      </GridItem>

                      {singleOrderDetails.order_delivery_details && singleOrderDetails.order_delivery_details.length > 0 ? (
                        <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                          <DetailBlock
                            blockTitle="Delivery Details"
                            blockDesc={<>{singleOrderDetails.order_delivery_details[0].contact_name}<br/>{singleOrderDetails.order_delivery_details[0].delivery_address}<br/>{singleOrderDetails.order_delivery_details[0].contact_phone}</>}
                          />
                        </GridItem>
                      ) : (
                        <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                          <DetailBlock
                            blockTitle="Delivery Details"
                            blockDesc={singleOrderDetails.billing_address}
                          />
                        </GridItem>
                      )}
                      {singleOrderDetails.billing_address && (
                        <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                          <DetailBlock
                            blockTitle="Billing Address"
                            blockDesc={singleOrderDetails.billing_address}
                          />
                        </GridItem>
                      )}
                    </GridContainer>
                  </MDBox>
                </MDBox>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          {/* {singleOrderDetails.invoice_link && <MDButton type="submit" color="primary" variant="gradient" target="_blank" href={singleOrderDetails.invoice_link} sx={{ mr: 1 }}>
            View Invoice
          </MDButton>} */}
          <MDButton color="secondary" variant="gradient" autoFocus onClick={handleClose}>
            Cancel
          </MDButton>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

OrderDetailsFetch.propTypes = {
  order_id: PropTypes.number.isRequired,
};

export default OrderDetailsFetch;
