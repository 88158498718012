/* eslint-disable react/prop-types */
import { MenuItem } from "@mui/material";
// import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { CustomSelect } from "components/CustomSelect";
import React from "react";
import { Controller } from "react-hook-form";

// const useStyles = makeStyles(customSelectStyle);

const ControlledSelect = (props) => {
  // const classes = useStyles();

  return (
    <Controller
      name={props.name}
      control={props.control}
      defaultValue={props.defaultValue}
      render={({
        field: { name, onBlur, onChange, ref, value },
        fieldState: { error, isTouched },
      }) => (
        <CustomSelect
          {...props}
          label={props.label}
          inputProps={{
            id: `${name}-id`,
            name: name,
            ...props.inputProps,
          }}
          value={value ? value : ""}
          onChange={onChange}
          helperText={error && error.message}
          error={!!error}
          onBlur={onBlur}
        >
          {props.quickadd
            ? props.quickadd
            : props.menu && (
                <MenuItem
                  disabled
                >
                  {props.menu.title}
                </MenuItem>
              )}

          {props.menu &&
            props.menu?.list?.map((prop, key) => (
              <MenuItem
                key={key}
                value={
                  typeof props.menu.id === "function" ? props.menu.id(prop) : prop[props.menu.id]
                }
              >
                {typeof props.menu.value === "function"
                  ? props.menu.value(prop)
                  : prop[props.menu.value]}
              </MenuItem>
            ))}
        </CustomSelect>
      )}
    />
  );
};

export default ControlledSelect;
