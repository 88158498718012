/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from 'react-redux';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Notify } from "notiflix/build/notiflix-notify-aio";
import { Confirm } from "notiflix/build/notiflix-confirm-aio";

import { styled } from "@mui/material/styles";

import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";

import { convertToHTML } from "draft-convert";

import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Block } from "notiflix/build/notiflix-block-aio";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";

import TextField from "@mui/material/TextField";

import Box from "@mui/material/Box";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { AddBox } from "@mui/icons-material";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Typography from "@mui/material/Typography";

import ControlledTextInput from "components/FormInputs/ControlledTextInput";
import ControlledDateTime from "components/FormInputs/ControlledDateTime";
import ControlledCheckbox from "components/FormInputs/ControlledCheckbox";
import ControlledSelect from "components/FormInputs/ControlledSelect";
import CustomInputs from "components/CustomInputs";
import { CustomSelect } from "components/CustomSelect";

//components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
import MDBadge from "components/MDBadge";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import { countriesSchema } from "../schemas/_model";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import Tooltip from "@mui/material/Tooltip";

import Edit from "@mui/icons-material/Edit";
import Close from "@mui/icons-material/Close";

import InputAdornment from "@mui/material/InputAdornment";

import TinyMCEEditor from 'components/TinyMCEEditor';

import Alert from "components/CustomAlert";

import { replaceSingleQuoteToSQLFriendly } from 'utils/helperFunctions'

import { usePostEmailTemplateMutation, usePutEmailTemplateMutation } from 'services/email_templates'

import EmailRecipients from "./email_recipients";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function EditEmailTemplate({ initData, selectedCountryId }) {

  const [open, setOpen] = useState(false);

  const [editorOnChange, setEditorOnChange] = useState(initData.body);

  const initialFormState = {
    subject: "",
    recipients: []
  };

  const [recipients, setRecipients] = useState(initData.recipients ?? []);

  const [postEmailTemplate] = usePostEmailTemplateMutation();

  const [putEmailTemplate] = usePutEmailTemplateMutation();

  const { control, watch, handleSubmit, setValue } = useForm({
    resolver: yupResolver(countriesSchema),
    defaultValues: initialFormState,
    mode: 'onChange'
  });

  const handleClickOpen = () => {
    setOpen(true);
    setValue('subject', initData.subject);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = (data) => {
    let addedRecipients = recipients;
    if(recipients && recipients.length > 0) {
      for(let recipient of addedRecipients) {
        recipient.is_bcc = recipient.isBcc ? 1 : 0;
        delete recipient.isBcc;
      }
    }
    Loading.dots({
      svgColor: "rgba(159, 3, 3, 0.8)",
    });

    let bodyInit = editorOnChange;

    data.type = initData.type;
    data.body = replaceSingleQuoteToSQLFriendly(bodyInit);
    data.countryId = selectedCountryId;
    data.recipientList = recipients;
    if(initData.country_id == 0) {
      try {
        postEmailTemplate(data).then((response) => {
          console.log(response);
          if (response && response.data?.status == "success") {
            Notify.success("Succefully Updated!");
          } else {
            Notify.failure(response.error?.data?.message);
          }
        });
      } catch (e) {
        console.log(e);
      } finally {
        Loading.remove(500);
        handleClose();
      }
    } else {
      data.id = initData.id;
      try {
        putEmailTemplate(data).then((response) => {
          if (response && response.data?.status == "success") {
            Notify.success("Succefully Updated!");
          } else {
            Notify.failure(response.error?.data?.message);
          }
        });
      } catch (e) {
        console.log(e);
      } finally {
        Loading.remove(500);
        handleClose();
      }
    }
  };

  return (
    <>
      <Tooltip title="Edit" placement="top">
        <IconButton aria-label="view" size="small" onClick={handleClickOpen}>
          <Edit color="warning" fontSize="inherit" />
        </IconButton>
      </Tooltip>
      <BootstrapDialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        maxWidth="xl"
        sx={{
          "& .MuiPaper-root": {
            width: "900px",
          },
        }}
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2, color: "#3f3f3f" }} id="customized-dialog-title">
          Edit Template
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <br />
          <form>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                <ControlledTextInput
                  name="subject"
                  control={control}
                  labelText="Email subject *"
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                <EmailRecipients recipients={recipients} setRecipients={setRecipients} control={control} watch={watch} setValue={setValue} initData={initData.recipients ? initData.recipients : []} />
              </GridItem>

              <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                <TinyMCEEditor initialContent={initData.body} realTimeContent={setEditorOnChange} />
              </GridItem>
            </GridContainer>
          </form>
        </DialogContent>
        <DialogActions>
          <MDButton
            type="submit"
            color="primary"
            variant="gradient"
            id="submit"
            onClick={handleSubmit(onSubmit)}
          >
            Update Template
          </MDButton>
          <MDButton color="secondary" variant="gradient" autoFocus onClick={handleClose}>
            Cancel
          </MDButton>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}

export default EditEmailTemplate;
