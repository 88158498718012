import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const consumerKey = "ck_b27b8546d199bb7a49c2ee36ba37fcf5fea65cd5";
const consumerSecret = "cs_f76c5da6e1a444b0f288fb1eab69dbf04cce9c83";

export const productStoreAPI = createApi({
  reducerPath: "productStore",
  baseQuery: fetchBaseQuery({ baseUrl: "" }),
  endpoints: (builder) => ({
    getAllProductsFromStoreUrl: builder.query({
      query: () => ({
        url: "https://www.au.softvire.com/wp-json/wc/v3/products?per_page=100&page=1&_fields=id,name,type,description,short_description,sku,images,attributes,related_ids,average_rating,rating_count,regular_price,price,variations&consumer_key=ck_2651cc9fea3cc0033f0adb2f46fb96ac8dd0272d&consumer_secret=cs_a031afba34818aba4c68ea2bd832cf5f87cfb1cd",
        method: "GET",
        // headers: {
        //   Authorization: `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`,
        // },
      }),
    }),
  }),
});

export const { useGetAllProductsFromStoreUrlQuery } = productStoreAPI;
