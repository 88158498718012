import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id: 0,
  company_id: 0,
};

const slice = createSlice({
  name: "stockNotifications",
  initialState,
  reducers: {
    setStockId: (state, action) => {
      state.id = action.payload.id;
      state.company_id = action.payload.company_id;
    },

    resetStockId: (state) => {
      state.id = 0;
      state.company_id = 0;
    },
  },
});

export const getCurrentStockId = (state) => state.UI.stockNotifications;

export const { setStockId, resetStockId } = slice.actions;

export default { name: slice.name, reducer: slice.reducer };
