/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { styled } from "@mui/material/styles";

import { EditorState, ContentState, convertFromHTML, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";

import { convertToHTML } from "draft-convert";

import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Block } from "notiflix/build/notiflix-block-aio";
import { Notify } from "notiflix/build/notiflix-notify-aio";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";

import TextField from "@mui/material/TextField";

import Box from "@mui/material/Box";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { AddBox } from "@mui/icons-material";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Typography from "@mui/material/Typography";

import ControlledTextInput from "components/FormInputs/ControlledTextInput";
import ControlledDateTime from "components/FormInputs/ControlledDateTime";
import ControlledCheckbox from "components/FormInputs/ControlledCheckbox";
import ControlledSelect from "components/FormInputs/ControlledSelect";
import CustomInputs from "components/CustomInputs";
import { CustomSelect } from "components/CustomSelect";

//components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

//example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
import MDBadge from "components/MDBadge";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import InputAdornment from '@mui/material/InputAdornment';

import { editDiscountSchema } from "../../schemas/_model";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import Tooltip from "@mui/material/Tooltip";

import Edit from "@mui/icons-material/Edit";
import Close from "@mui/icons-material/Close";

import Alert from "components/CustomAlert";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";

// rtk queries
import { useGetAllStatesByCountryIdQuery } from "services/countries";

import { useUpdateDiscountMutation, useLazyGetAllDiscountProductsQuery } from "services/companies";

import { globalCountry } from "store/entities/countries";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function EditDiscountProduct({ products, companyId, initData, refetchDiscountProducts }) {
  const [updateDiscount] = useUpdateDiscountMutation();

  const [triggerDiscountProducts] = useLazyGetAllDiscountProductsQuery();

  const initialFormState = {
    product_id: "",
    discount_percentage: 0,
  };

  const { control, watch, handleSubmit, setValue } = useForm({
    resolver: yupResolver(editDiscountSchema),
    defaultValues: initialFormState,
  });

  const [openDiscountEdit, setOpenDiscountEdit] = useState(false);

  const handleClickOpen = () => {
    setOpenDiscountEdit(true);
  };
  const handleClose = () => {
    setOpenDiscountEdit(false);
  };

  const onSubmit = async (data) => {
    Loading.dots({
      svgColor: "rgba(159, 3, 3, 0.8)",
    });

    try {
      const response = await updateDiscount({
        discountProductd: initData.id,
        data: { data: data },
      });
      if (response.data?.status == "success") {
        Notify.success("Succefully Updated!");
        handleClose();
      } else {
        Notify.failure("Something went wrong, Please try again!");
      }
    } catch (e) {
    } finally {
      Loading.remove(500);
    }
  };

  useEffect(() => {
    if (initData) {
      setValue("product_id", initData.product_id);
      setValue("discount_percentage", initData.discount_percentage);
    }
  }, [initData]);

  return (
    <>
      <Tooltip title="Edit reseller" placement="top">
        <IconButton aria-label="view" size="small" onClick={() => handleClickOpen()}>
          <Edit color="warning" fontSize="inherit" />
        </IconButton>
      </Tooltip>
      <BootstrapDialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        maxWidth="xl"
        sx={{
          "& .MuiPaper-root": {
            width: "750px",
          },
        }}
        open={openDiscountEdit}
      >
        <DialogTitle sx={{ m: 0, p: 2, color: "#3f3f3f" }} id="customized-dialog-title">
          Edit discount product
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <br />
          <form>
            <GridContainer>
              <GridItem xs={12} sm={12} md={8} lg={8} xl={8}>
                <ControlledSelect
                  name={"product_id"}
                  control={control}
                  label="Select product *"
                  menu={{
                    id: "id",
                    value: "name",
                    title: "Products",
                    list: products,
                  }}
                  sx={{
                    "& .MuiFormLabel-root": {
                      backgroundColor: "#fff",
                    },
                    "& .MuiInputBase-root": {
                      height: "44px",
                    },
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledTextInput
                  name="discount_percentage"
                  control={control}
                  labelText="Discount *"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "number",
                    endAdornment: <InputAdornment position="end" sx={{ '& .MuiTypography-root': { fontSize: 15, mr: 1, fontWeight: 600 } }}>%</InputAdornment>,
                  }}
                />
              </GridItem>
            </GridContainer>
          </form>
        </DialogContent>
        <DialogActions>
          <MDButton
            type="submit"
            color="primary"
            variant="gradient"
            id="submit"
            onClick={handleSubmit(onSubmit)}
          >
            Update
          </MDButton>
          <MDButton color="secondary" variant="gradient" autoFocus onClick={handleClose}>
            Cancel
          </MDButton>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}

export default EditDiscountProduct;
