/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import axios from "axios";

import { styled } from "@mui/material/styles";

import { Loading } from "notiflix/build/notiflix-loading-aio";

import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import Edit from "@mui/icons-material/Edit";

import Tooltip from "@mui/material/Tooltip";
import VisibilityIcon from "@mui/icons-material/Visibility";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import CustomTabs from "components/CustomTabs";

import { globalCountry } from "store/entities/countries";

import SimpleProduct from "../AddInventory/AddVariationDialog";

import noPreview from "assets/images/no-preview.png";

import {
  useLazyGetAllResellerProductsByCountryIdQuery,
  useGetWoocommerceSiteProductsMutation,
} from "services/products";

import { stripHTMLTags } from "utils/helperFunctions";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

import MDBox from "components/MDBox";

const AddInventoryDialog = ({
  productData,
  countryURL,
  countryConsumerKey,
  countryConsumerSecret,
}) => {
  const [openAddDialog, setOpenAddDialog] = useState(false);

  const [fetchWoocommerceVaiations] = useGetWoocommerceSiteProductsMutation();

  const [variationProductData, setVariationProductData] = useState([]);

  const [simpleProductData, setSimpleProductData] = useState({});

  const [fetchedVariationProductData, setFetchedVariationProductData] = useState([]);

  const [imageLoading, setImageLoading] = useState(true);

  const selectedCountry = useSelector(globalCountry);

  const handleClickOpen = () => {
    setOpenAddDialog(true);
  };

  const handleClose = () => {
    setOpenAddDialog(false);
  };

  return (
    <>
      <Tooltip title="Select to add inventory" placement="top">
        <IconButton aria-label="view" size="small" onClick={handleClickOpen}>
          <ArrowForwardIcon color="warning" fontSize="inherit" />
        </IconButton>
      </Tooltip>
      <BootstrapDialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        maxWidth="xl"
        sx={{
          "& .MuiDialog-paper": {
            width: "1000px",
          },
        }}
        open={openAddDialog}
      >
        <DialogTitle sx={{ m: 0, p: 2, color: "#3f3f3f" }} id="customized-dialog-title">
          Add Inventory
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Box>
            {productData.type == "simple" ? (
              <SimpleProduct initialData={simpleProductData} dialogClose={handleClose} />
            ) : (
              // <SimpleProduct initialData={simpleProductData} countryURL={countryURL} countryConsumerKey={countryConsumerKey} countryConsumerSecret={countryConsumerSecret} dialogClose={handleClose} />
              variationProductData &&
              variationProductData.length > 0 && (
                <CustomTabs headerColor="primary" tabs={variationTabs} />
              )
            )}
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};

AddInventoryDialog.propTypes = {
  // children: PropTypes.node.isRequired, // You can also use PropTypes.element if you want to enforce a single element.
  productData: PropTypes.object.isRequired,
};

export default AddInventoryDialog;
