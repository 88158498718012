import React, { useState, useEffect, useMemo, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";

// react-router components
import { Route, useLocation, Switch, Redirect, useHistory } from "react-router-dom";

// @mui material components

import Icon from "@mui/material/Icon";

import { isLoggedIn } from "store/selectors/auth";
import { loaderStatus, globalLoaderMessage, resetGlobalLoader } from "store/ui/loader";

//components
import MDBox from "components/MDBox";

//example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

import CloseIcon from "@mui/icons-material/Close";

import MDTypography from "components/MDTypography";

// RTL plugins
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

import FallBack from "components/FallBack";

import { parseJwt } from "utils/helperFunctions";

import WarningIcon from "@mui/icons-material/Warning";
import IconButton from "@mui/material/IconButton";

//routes
import routes from "routes";
import { userRoutes } from "routes/user";

//contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";

import AuthLayout from "layouts/Auth.jsx";

const UserLayout = React.lazy(() => import("layouts/User.jsx"));

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-quill/dist/quill.snow.css";

import "styles.css";

export default function App() {
  const dispatch = useDispatch();

  const { pathname } = useLocation();

  const [redirectUrl, setRedirectUrl] = useState("/auth/login");

  const [minimize, setMinimize] = useState(false);

  const hasSession = useSelector(isLoggedIn);

  const globalLoaderStatus = useSelector(loaderStatus);
  const globalLoaderText = useSelector(globalLoaderMessage);

  const history = useHistory();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    if (localStorage.getItem("jwt")) {
      const decodedToken = parseJwt(localStorage.getItem("jwt"));
      const currentTime = Date.now() / 1000;
      if (decodedToken.exp < currentTime) {
        dispatch({ type: "LOG_OUT" });
        localStorage.clear();
      }
    } else {
      dispatch({ type: "LOG_OUT" });
      localStorage.clear();
    }
  }, []);

  // useEffect(() => {
  //   if (hasSession == null && hasSession == undefined) {
  //     setRedirectUrl("/auth/login");
  //   }
  // }, [hasSession]);

  useEffect(() => {
    if (hasSession !== null && hasSession !== undefined) {
      setRedirectUrl("/user/inventory");
    } else {
      setRedirectUrl("/auth/login");
    }
  }, [hasSession]);

  return (
    <Suspense fallback={<FallBack />}>
      {globalLoaderStatus && (
        <MDBox component="div">
          <MDBox
            component="div"
            id="GlobalLoadingWrap"
            className="notiflix-loading nx-with-animation"
            style={{ height: minimize ? "200px" : "100%", top: minimize ? "auto" : 0 }}
          >
            <IconButton
              aria-label="delete"
              color="primary"
              sx={{
                fontSize: "1.8rem",
                position: "absolute",
                top: minimize ? "auto" : "20px",
                bottom: minimize ? "20px" : "auto",
                right: "20px",
                cursor: "pointer",
                display: minimize ? "none" : "inline-block",
              }}
              onClick={() => {
                // setMinimize(true);
                dispatch(resetGlobalLoader());
              }}
            >
              <CloseIcon fontSize="large" color="white" />
            </IconButton>{" "}
            <MDBox sx={{ display: "flex", alignItems: "center" }}>
              <WarningIcon fontSize="large" color="white" sx={{ fontSize: "1.8rem" }} />{" "}
              <MDTypography
                color="white"
                sx={{ display: "inline-block", fontSize: "1.1rem", marginLeft: "20px" }}
              >
                {" "}
                Please do not close the tab until all the data have been fetched!
              </MDTypography>
            </MDBox>
            <div
              style={{ width: "80px", height: "65px" }}
              className="notiflix-loading-icon nx-with-message"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="rgba(159, 3, 3, 0.8)"
                viewBox="0 0 100 100"
              >
                <g transform="translate(25 50)">
                  <circle r="9" fill="inherit" transform="scale(.239)">
                    <animateTransform
                      attributeName="transform"
                      begin="-0.266s"
                      calcMode="spline"
                      dur="0.8s"
                      keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                      keyTimes="0;0.5;1"
                      repeatCount="indefinite"
                      type="scale"
                      values="0;1;0"
                    ></animateTransform>
                  </circle>
                </g>
                <g transform="translate(50 50)">
                  <circle r="9" fill="inherit" transform="scale(.00152)">
                    <animateTransform
                      attributeName="transform"
                      begin="-0.133s"
                      calcMode="spline"
                      dur="0.8s"
                      keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                      keyTimes="0;0.5;1"
                      repeatCount="indefinite"
                      type="scale"
                      values="0;1;0"
                    ></animateTransform>
                  </circle>
                </g>
                <g transform="translate(75 50)">
                  <circle r="9" fill="inherit" transform="scale(.299)">
                    <animateTransform
                      attributeName="transform"
                      begin="0s"
                      calcMode="spline"
                      dur="0.8s"
                      keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                      keyTimes="0;0.5;1"
                      repeatCount="indefinite"
                      type="scale"
                      values="0;1;0"
                    ></animateTransform>
                  </circle>
                </g>
              </svg>
            </div>
            <p
              id="GlobalLoadingMessage"
              className="nx-loading-message"
              style={{ color: "#dcdcdc", fontSize: "16px", fontWeight: 500 }}
            >
              {globalLoaderText}
            </p>
          </MDBox>
        </MDBox>
      )}
      <Switch>
        <Route path="/auth" component={AuthLayout} />
        {hasSession !== null && hasSession !== undefined ? (
          <>
            <Sidenav brand={brandWhite} brandName="ICT Distribution" routes={userRoutes} />
            <Route path="/user" render={(routeProps) => <UserLayout {...routeProps} />} />
          </>
        ) : (
          ""
        )}

        <Route path="/" render={() => <Redirect to={redirectUrl} />} />
        {/* <Redirect from="/" to={redirectUrl} /> */}
      </Switch>
    </Suspense>
  );
}
