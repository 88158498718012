import { apis } from "./base";

const bdmService = apis.injectEndpoints({
  endpoints: (builder) => ({
    getAllBDM: builder.query({
      query: () => {
        return `/admin/bdms/all`;
      },
      transformResponse: (response) => response.response.filter(item => item.active == true),
      providesTags: ["BDM"],
    }),

    getAllBDMWithInactive: builder.query({
      query: () => {
        return `/admin/bdms/all`;
      },
      transformResponse: (response) => response.response,
      providesTags: ["BDM"],
    }),

    postBDM: builder.mutation({
      query: (data) => ({
        url: `/admin/bdms/add`,
        method: "POST",
        body: {
          bdmModel: data
        },
      }),
      // transformResponse: (response) => response.response
      invalidatesTags: ["BDM"],
    }),
    updateBDM: builder.mutation({
      query: ({ bdmId, data }) => ({
        url: `/admin/bdms/edit/${bdmId}`,
        method: "PUT",
        body: {
          bdmModel: data
        },
      }),
      // transformResponse: (response) => response.response
      invalidatesTags: ["BDM"],
    }),
    deleteBDM: builder.mutation({
      query: (bdmId) => ({
        url: `/admin/bdms/delete/${bdmId}`,
        method: "DELETE",
      }),
      // transformResponse: (response) => response.response,
      invalidatesTags: ["BDM"],
    }),
  }),
});

export const {
  useGetAllBDMQuery,
  useLazyGetAllBDMQuery,
  useGetAllBDMWithInactiveQuery,
  usePostBDMMutation,
  useUpdateBDMMutation,
  useDeleteBDMMutation
} = bdmService;
