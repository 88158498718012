import * as yup from "yup";

export const digitalKeysUpdateSchema = yup.object().shape({
  keys: yup.array().of(
    yup.object().shape({
      id: yup.number().required(),
      key: yup
        .string()
        .required("Key is required")
        .test("pending-check", 'Key cannot be "Pending"', function (value) {
          // Add your custom condition here
          return value !== "Pending";
        }),
    })
  ),
});

export const poNumberUpdateSchema = yup.object().shape({
  poNumber: yup.string().default("").required("Key is required"),
});

export const updateOrderProductExpiryDateSchema = yup.object().shape({
  expiryDate: yup.string().required().default("").label("Expiry date"),
});

export const addOrderSchema = yup.object().shape({
  contactName: yup.string().required().label("Contact name"),
  contactPhone: yup.string().required().label("Contact phone"),
  deliveryAddress: yup.string().required().label("Delivery address"),
  sameAsDelivery: yup.bool(),
  isExpressShipping: yup.bool(),
  state: yup.number().required("State"),
  companyId: yup.number().required().label("Company"),
  resellerId: yup.number().required().label("Reseller"),
  billingAddress: yup.string().when(["sameAsDelivery"], {
    is: (sameAsDelivery) => sameAsDelivery === true,
    then: () => yup.string().notRequired(),
    otherwise: () => yup.string().required("Billing address"),
  }).label("Billing address"),
  products: yup.array().of(
    yup.object().shape({
      product: yup.number().default("").required("Product is required"),
      type: yup.string().default("").required("Type is required"),
      variation: yup.number().when(["type"], {
        is: (type) => type === "simple",
        then: () => yup.number().notRequired(),
        otherwise: () => yup.number().required("Variation is required").min(0).integer(),
      }).label("Product variation"),
      attributes: yup.array().of(yup.string()),
      qty: yup.number().min(1).required("Quantity is required"),
    })
  ),
});
