import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import { styled } from '@mui/material/styles';
import Grid from "@mui/material/Grid";

const GridElement = styled(Grid)(({ theme }) => ({
  padding: "0 15px !important"
}))

function GridItem({ ...props }) {
  const { children, className, ...rest } = props;
  return (
    <GridElement item {...rest} className={className}>
      {children}
    </GridElement>
  );
}

GridItem.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

export default GridItem;
