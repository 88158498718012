/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { styled } from "@mui/material/styles";

import { EditorState, ContentState, convertFromHTML, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";

import { convertToHTML } from "draft-convert";

import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Block } from "notiflix/build/notiflix-block-aio";
import { Confirm } from "notiflix/build/notiflix-confirm-aio";
import { Notify } from "notiflix/build/notiflix-notify-aio";

// @mui material components
import Box from "@mui/material/Box";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { AddBox } from "@mui/icons-material";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Typography from "@mui/material/Typography";

import ControlledTextInput from "components/FormInputs/ControlledTextInput";
import ControlledDateTime from "components/FormInputs/ControlledDateTime";
import ControlledCheckbox from "components/FormInputs/ControlledCheckbox";
import ControlledSelect from "components/FormInputs/ControlledSelect";
import CustomInputs from "components/CustomInputs";
import { CustomSelect } from "components/CustomSelect";

//components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import { assignedEndUserSchema } from "../schemas/_model";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import InputAdornment from "@mui/material/InputAdornment";

import Tooltip from "@mui/material/Tooltip";

import Edit from "@mui/icons-material/Edit";
import Close from "@mui/icons-material/Close";
import AddIcon from '@mui/icons-material/Add';

import Alert from "components/CustomAlert";

// rtk queries
import {
  usePostEndUserToDigitalKeyMutation
} from "services/digitalKeys";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function AssignEndUser({ orderProductId, reminderTypes }) {

  const [openAddDialog, setOpenAddDialog] = useState(false);

  const initialFormState = {
    first_name: "",
    last_name: "",
    email: "",
    reminderTypeIds: []
  };

  const [postEnduserToDigitalKey] = usePostEndUserToDigitalKeyMutation();

  const { control, watch, handleSubmit, setValue, reset } = useForm({
    resolver: yupResolver(assignedEndUserSchema),
    defaultValues: initialFormState,
  });

  const handleClickOpen = () => {
    setOpenAddDialog(true);
  };
  const handleClose = () => {
    setOpenAddDialog(false);
  };


  const onSubmit = (data) => {
    let reminderTypes = data.reminderTypeIds;
    data.digital_key_id = orderProductId;
    delete data.reminderTypeIds;

    Loading.dots({
      svgColor: "rgba(159, 3, 3, 0.8)",
    });

    try {
      postEnduserToDigitalKey({ data: data, reminderTypeIds: reminderTypes }).then((response) => {
        if(response.data?.status == "success") {
          Notify.success("Succefully Added!");
          handleClose();
        } else {
          if(response.error?.data) {
            Notify.failure(response.error.data.message);
          } else {
            Notify.failure("Something went wrong, Please try again!");
          }
        }
      });
    } catch (e) {
    } finally {
      Loading.remove(500);
      reset();
      handleClose();
    }
  };

  return (
    <>
      <Tooltip title="Assign end user" placement="top">
        <IconButton
          className="addButton"
          color="info"
          onClick={handleClickOpen}
          size="large"
          sx={{ ml: 1, padding: '5px' }}
        >
          <AddIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <BootstrapDialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        maxWidth="xl"
        sx={{
          "& .MuiPaper-root": {
            width: "650px",
          },
        }}
        open={openAddDialog}
      >
        <DialogTitle sx={{ m: 0, p: 2, color: "#3f3f3f" }} id="customized-dialog-title">
          Assign End user
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <br />
          <form>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                <ControlledTextInput
                  name="first_name"
                  control={control}
                  labelText="First name *"
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                <ControlledTextInput
                  name="last_name"
                  control={control}
                  labelText="Last name *"
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                <ControlledTextInput
                  name="email"
                  control={control}
                  labelText="Email *"
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                <ControlledSelect
                  multiple
                  name={"reminderTypeIds"}
                  control={control}
                  label="Select reminder types *"
                  menu={{
                    id: "id",
                    value: "name",
                    title: "Reminder Type",
                    list: reminderTypes,
                  }}
                  sx={{
                    "& .MuiFormLabel-root": {
                      backgroundColor: "#fff",
                    },
                    "& .MuiInputBase-root": {
                      height: "44px",
                    },
                  }}
                />
              </GridItem>
            </GridContainer>
          </form>
        </DialogContent>
        <DialogActions>
          <MDButton
            type="submit"
            color="primary"
            variant="gradient"
            id="submit"
            onClick={handleSubmit(onSubmit)}
          >
            Add user
          </MDButton>
          <MDButton color="secondary" variant="gradient" autoFocus onClick={handleClose}>
            Cancel
          </MDButton>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}

export default AssignEndUser;
