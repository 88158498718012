/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { Importer, ImporterField } from "react-csv-importer";

import { useForm, useFieldArray, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { styled } from "@mui/material/styles";

import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import MDButton from "components/MDButton";

import { AddBox } from "@mui/icons-material";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Notify } from "notiflix/build/notiflix-notify-aio";

import Typography from "@mui/material/Typography";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import ControlledTextInput from "components/FormInputs/ControlledTextInput";
import ControlledDateTime from "components/FormInputs/ControlledDateTime";
import ControlledCheckbox from "components/FormInputs/ControlledCheckbox";
import ControlledSelect from "components/FormInputs/ControlledSelect";
import ControlledAutoComplete from "components/FormInputs/ControlledAutoComplete";
import CustomInputs from "components/CustomInputs";
import CustomAutoComplete from "components/CustomAutoComplete";

//components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import MDAvatar from "components/MDAvatar";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Hidden from '@mui/material/Hidden';

//example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import Box from "@mui/material/Box";

import Edit from "@mui/icons-material/Edit";
import Close from "@mui/icons-material/Close";

// theme CSS for React CSV Importer
import "react-csv-importer/dist/index.css";

// queries
import {
  useGetAllResellerProductsByCountryIdQuery,
  useLazyGetAllResellerProductsByCountryIdQuery,
  useUploadProductImageMutation,
  usePostProductMutation,
  useGetDistinctAttributesQuery
} from "services/products";

import { useGetAllResellerProductCategoriesQuery } from "services/categories";

import { useGetAllUrlsByCountryIdQuery } from "services/countries";

import { useUploadFileMutation, useDeleteFileMutation } from "services/files";

// import { useGetAllProductsFromStoreUrlQuery } from "services/store";

import { globalCountry, setSelectedCountryURL } from "store/entities/countries";

import TinyMCEEditor from "components/TinyMCEEditor";

import {
  replaceSingleQuoteToSQLFriendly,
  replaceHTMLEntityAnd,
  removeLocalStorageItemsWithPrefix,
  addSpacesToNames
} from "utils/helperFunctions";

import Alert from "components/CustomAlert";

import CircularProgress from "@mui/material/CircularProgress";

import CustomTabs from "components/CustomTabs";

import { ArrowBack } from "@mui/icons-material";

import { addOrderSchema } from "../schemas/_model";

function ProductNode({ productsList, control, removeProductNode, watch, setValue, resetField, index, state, setQuotationStatus }) {

  const selectedCountry = useSelector(globalCountry);

  const [filterProducts, setFilterProducts] = useState([]);

  const [productVariations, setProductVariations] = useState([]);

  const [selectedAttributes, setSelectedAttributes] = useState([]);

  const dispatch = useDispatch();

  const productInput = watch(`products.${index}.product`);
  const variationInput = watch(`products.${index}.variation`);
  const qtyInput = watch(`products.${index}.qty`);
  const typeInput = watch(`products.${index}.type`);

  useEffect(() => {
    if (productsList && productsList.length > 0) {
      createProductVariations(productsList);
    } else {
      setFilterProducts([]);
    }
  }, [productsList]);

  useEffect(() => {
    if(productInput) {
      let filterProduct = filterProducts.filter(item => item.id == productInput);
      if(filterProduct && filterProduct.length > 0) {
        setValue(`products.${index}.type`, filterProduct[0].type);
        // if(filterProduct[0].products_images) {
        //   if(filterProduct[0].products_images.length > 0) {
        //     if(filterProduct[0].products_images[0].src) setValue(`products.${index}.iamge`, filterProduct[0].products_images[0].src);
        //   }
        // }
        if(filterProduct[0].type == 'variable') {
          // let attributes = [];
          // if(filterProduct[0].productVariations && filterProduct[0].productVariations.length > 0) {
          //   for(let variation of filterProduct[0].productVariations) {
          //     let variationAttribute
          //     if(variation.variation_atribute_option && variation.variation_atribute_option.length > 0) {

          //     }
          //   }
          // }
          // setFilterAttributes
          // setShowAttributes(true);
          // console.log(filterProduct[0]);
          if(filterProduct[0].productVariations && filterProduct[0].productVariations.length > 0) {
            setProductVariations(filterProduct[0].productVariations);
          }
        } else {
          setProductVariations([]);
        }
      }
    } else {
      resetField(`products.${index}.variation`);
      setProductVariations([]);
    }
  }, [productInput]);

  useEffect(() => {
    if(variationInput) {
      let filteredProduct = productsList.filter(item => item.id == variationInput);
      if(filteredProduct && filteredProduct.length > 0) {
        if(filteredProduct[0].variation_atribute_option && filteredProduct[0].variation_atribute_option.length > 0) {
          let attributes = [];
          for(let attribute of filteredProduct[0].variation_atribute_option) {
            if(attribute.products_attribute_options) {
              attributes.push(attribute.products_attribute_options.option);
            }
          }
          setSelectedAttributes(attributes);
          setValue(`products.${index}.attributes`, attributes);
        }
      }
    }
  }, [variationInput]);

  const createProductVariations = (products) => {
    const productMap = {};

    // Iterate through the response and create a mapping of parent IDs to products
    products.forEach((product) => {
      const productId = product.id;
      const parentId = product.parent_id;

      // Create 'productVariations' property for each product
      const productWithVariations = Object.assign({}, product, { productVariations: [] });

      if (parentId === 0) {
        // If the product has no parent, add it to the main products array
        productMap[productId] = productWithVariations;
      } else {
        // If the product has a parent, add it as a variation to the parent
        if (!productMap[parentId].productVariations) {
          productMap[parentId].productVariations = [];
        }
        productMap[parentId].productVariations.push(product);
      }
    });

    Object.values(productMap).forEach((product) => {
      if (product.productVariations.length > 0) {
        const prices = product.productVariations.map((variation) => variation.price);
        const minPrice = Math.min(...prices);
        const maxPrice = Math.max(...prices);
        product.priceRange =
          minPrice == maxPrice ? `${product.price}` : `${minPrice} - ${maxPrice}`;
        product.minPrice = minPrice;
      } else {
        product.priceRange = `${product.price}`;
      }
    });

    let filterMappedProdcuts = Object.values(productMap).filter(
      (product) => !(product.type == "variable" && product.productVariations.length == 0)
    ).sort((a, b) => b.priority - a.priority);

    // Set the products state with the main products array
    setFilterProducts(filterMappedProdcuts);
  };

  useEffect(() => {
    if(state) {
      if(productInput && qtyInput) {
        if(typeInput == 'variable') {
          if(variationInput) {
            setQuotationStatus(true);
          }
        } else {
          setQuotationStatus(true);
        }
      }
    }
  }, [productInput, variationInput, qtyInput]);

  return (
    <MDBox
      sx={{
        border: "1px solid #d2d6da",
        borderRadius: "0.375rem",
        mb: 3,
        pt: 2,
        pl: 1,
        position: "relative",
        "& .tox-tinymce": { border: "none", position: "relative" },
      }}
      key={index}
    >
      <MDTypography
        component="p"
        color="info"
        sx={{
          lineHeight: "1.5",
          fontSize: "0.8rem",
          fontWeight: 500,
          transform: "translate(7px, -50%)",
          backgroundColor: "#fff",
          color: "#7b809a",
          padding: "0 5px",
          position: "absolute",
          left: 0,
          top: 0,
          zIndex: 1,
        }}
      >
        Item {index+1}
      </MDTypography>
      <GridContainer sx={{ mt: .5 }}>
          <GridItem xs={12} sm={12} md={11} lg={11} xl={11}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledAutoComplete 
                  id={`parentProduct${index}`}
                  name={`products.${index}.product`}
                  control={control}
                  options={filterProducts}
                  optionLabelProperty="name"
                  label="Product"
                />
              </GridItem>
              {/* <MDBox sx={{ display: 'none' }}>
                <ControlledSelect
                  name={`products.${index}.type`}
                  control={control}
                  label="Type *"
                  menu={{
                    id: (prop) => prop,
                    value: (prop) => prop,
                    title: "Type",
                    list: ["simple", "variable"],
                  }}
                  sx={{
                    "& .MuiFormLabel-root": {
                      backgroundColor: "#fff",
                    },
                    "& .MuiInputBase-root": {
                      height: "44px",
                    },
                  }}
                />
              </MDBox> */}
              
              {productVariations && productVariations.length > 0 && <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledSelect
                  name={`products.${index}.variation`}
                  control={control}
                  label="Select variation *"
                  menu={{
                    id: "id",
                    value: "name",
                    title: "Products",
                    list: productVariations,
                  }}
                  sx={{
                    "& .MuiFormLabel-root": {
                      backgroundColor: "#fff",
                    },
                    "& .MuiInputBase-root": {
                      height: "44px",
                    },
                  }}
                />
              </GridItem>}

              {/* <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledSelect
                  multiple
                  name={`products.${index}.attributes`}
                  control={control}
                  label="Select attributes *"
                  menu={{
                    id: "id",
                    value: "name",
                    title: "Products",
                    list: productsList,
                  }}
                  sx={{
                    "& .MuiFormLabel-root": {
                      backgroundColor: "#fff",
                    },
                    "& .MuiInputBase-root": {
                      height: "44px",
                    },
                  }}
                />
              </GridItem> */}
              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledTextInput
                  name={`products.${index}.qty`}
                  control={control}
                  labelText="QTY *"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "number",
                  }}
                />
              </GridItem>
            </GridContainer>
          </GridItem>

          <GridItem xs={12} sm={12} md={1} lg={1} xl={1}>
            <MDButton
              color="warning"
              variant="gradient"
              iconOnly
              onClick={() => removeProductNode(index)}
              // disabled={fields && fields.length > 1 ? false : true}
            >
              <IndeterminateCheckBoxIcon />
            </MDButton>
          </GridItem>
        </GridContainer>
    </MDBox>
  );
}

export default ProductNode;
