/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from "react";

// form libraries
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

// notiflix
import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Block } from "notiflix/build/notiflix-block-aio";
import { Confirm } from "notiflix/build/notiflix-confirm-aio";
import { Notify } from "notiflix/build/notiflix-notify-aio";

// @mui material components
import { styled } from "@mui/material/styles";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Tooltip from "@mui/material/Tooltip";
import Edit from "@mui/icons-material/Edit";

//components
import MDButton from "components/MDButton";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ControlledTextInput from "components/FormInputs/ControlledTextInput";

// rtk queries
import { useUpdateBDMMutation } from "services/bdm";

// utils, schemas
import { BDMSchema } from "../schemas/_model";

// styles
import "../assets/styles.css";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function EditBDM({ bdmData, disabled }) {

  const [openEdit, setOpenEdit] = useState(false);

  const initialFormState = {
    email: "",
    first_name: "",
    last_name: "",
  };

  const [updateBDM, { isLoading: isUpdatingResellerLoading }] = useUpdateBDMMutation();


  const { control, watch, handleSubmit, setValue, reset } = useForm({
    resolver: yupResolver(BDMSchema),
    defaultValues: initialFormState,
  });

  useEffect(() => {
    reset(bdmData);
  }, [bdmData]);

  const handleClickOpen = () => {
    setOpenEdit(true);
  };
  const handleClose = () => {
    setOpenEdit(false);
  };

  const onSubmit = (data) => {
    Loading.dots({
      svgColor: "rgba(159, 3, 3, 0.8)",
    });

    delete data.created_date;
    delete data.active;
    delete data.total_sales;

    try {
      updateBDM({ bdmId: bdmData.id, data: data }).then((response) => {
        if (response.data?.status == "success") {
          Notify.success("Succefully Updated!");
          handleClose();
        } else {
          Notify.failure("Something went wrong, Please try again!");
        }
      });
    } catch (e) {
    } finally {
      Loading.remove(500);
    }
  };

  return (
    <>
      <Tooltip title="Edit" placement="top">
        <IconButton aria-label="view" size="small" disabled={disabled} onClick={handleClickOpen}>
          <Edit color={!disabled ? "warning" : "text"} fontSize="inherit" />
        </IconButton>
      </Tooltip>
      <BootstrapDialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        maxWidth="xl"
        sx={{
          "& .MuiPaper-root": {
            width: "750px",
          },
        }}
        open={openEdit}
      >
        <DialogTitle sx={{ m: 0, p: 2, color: "#3f3f3f" }} id="customized-dialog-title">
          Edit Business Developement Manager
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <br />
          <form>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                <ControlledTextInput
                  name="email"
                  control={control}
                  labelText="Email *"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "email",
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                <ControlledTextInput
                  name="first_name"
                  control={control}
                  labelText="First Name *"
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                <ControlledTextInput
                  name="last_name"
                  control={control}
                  labelText="Last Name *"
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>
            </GridContainer>
          </form>
        </DialogContent>
        <DialogActions>
          <MDButton
            type="submit"
            color="primary"
            variant="gradient"
            id="submit"
            onClick={handleSubmit(onSubmit)}
          >
            Update BDM
          </MDButton>
          <MDButton color="secondary" variant="gradient" autoFocus onClick={handleClose}>
            Cancel
          </MDButton>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}

export default EditBDM;
