/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import { DragDropContext, Droppable, Draggable, OnDragEndResponder } from "react-beautiful-dnd";

import axios from "axios";

import { styled } from "@mui/material/styles";

import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Notify } from "notiflix/build/notiflix-notify-aio";

import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import Edit from "@mui/icons-material/Edit";

import Tooltip from "@mui/material/Tooltip";
import VisibilityIcon from "@mui/icons-material/Visibility";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import FolderIcon from "@mui/icons-material/Folder";
import DeleteIcon from "@mui/icons-material/Delete";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";

import CustomTabs from "components/CustomTabs";
import Alert from "components/CustomAlert";

import { globalCountry } from "store/entities/countries";

import noPreview from "assets/images/no-preview.png";

import { useReorderProductsMutation } from "services/products";

import { stripHTMLTags } from "utils/helperFunctions";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

const ProductReorder = ({
  openProductReorderDialog,
  setOpenProductReorderDialog,
  reorderProducts,
}) => {
  const [variationProductData, setVariationProductData] = useState([]);

  const [simpleProductData, setSimpleProductData] = useState({});

  const [fetchedVariationProductData, setFetchedVariationProductData] = useState([]);

  const [imageLoading, setImageLoading] = useState(true);

  const selectedCountry = useSelector(globalCountry);

  const [box, setBox] = useState([]);

  const [reorderProductMutaion] = useReorderProductsMutation();

  function handleOnDragEnd(result) {
    if (!result.destination) return;
    const newBox = Array.from(box);
    const [draggedItem] = newBox.splice(result.source.index, 1);
    newBox.splice(result.destination.index, 0, draggedItem);
    setBox(newBox);
  }

  const handleClose = () => {
    setOpenProductReorderDialog(false);
  };

  const handleSubmit = async (e) => {
    Loading.dots({
      svgColor: "rgba(159, 3, 3, 0.8)",
    });

    e.preventDefault();

    const hiddenInputs = document.querySelectorAll('input[class="product-priority-item"]');
    const priorityArray = [];

    hiddenInputs.forEach((input) => {
      const productId = parseInt(input.getAttribute("name"));
      const priority = parseInt(input.value);

      priorityArray.push({ productId, priority });
    });

    try {
      let response = await reorderProductMutaion(priorityArray);

      if (response.data?.status == "success") {
        Notify.success("Successfully saved!");
      } else {
        Notify.failure("Failed to save!");
      }
    } catch (error) {
      console.log("Error adding to reorder list", error);
    } finally {
      setTimeout(() => {
        Loading.remove(500);
      }, 500);
    }
  };

  const removeFromReorderProducts = async (currentProductId) => {
    Loading.dots({
      svgColor: "rgba(159, 3, 3, 0.8)",
    });

    let data = {
      productId: currentProductId,
      priority: 0,
    };

    try {
      let response = await reorderProductMutaion([data]);

      if (response.data?.status == "success") {
        Notify.success("Successfully removed!");
      } else {
        Notify.failure("Failed to remove!");
      }
    } catch (error) {
      console.log("Error adding to reorder list", error);
    } finally {
      setTimeout(() => {
        Loading.remove(500);
      }, 500);
    }
  };

  useEffect(() => {
    setBox(reorderProducts.sort((a, b) => b.priority - a.priority));
  }, [reorderProducts]);

  return (
    <>
      <Tooltip title="Product reorder" placement="top">
        <MDButton
          variant="gradient"
          color="secondary"
          sx={{ mr: 1 }}
          onClick={() => {
            setOpenProductReorderDialog(true);
            console.log(reorderProducts);
          }}
        >
          Reorder Products
        </MDButton>
      </Tooltip>
      <BootstrapDialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        maxWidth="xl"
        sx={{
          "& .MuiDialog-paper": {
            width: "750px",
          },
        }}
        open={openProductReorderDialog}
      >
        <DialogTitle sx={{ m: 0, p: 2, color: "#3f3f3f" }} id="customized-dialog-title">
          Product Reordering
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {reorderProducts && reorderProducts.length > 0 ? (
            <Box>
              <form onSubmit={handleSubmit}>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                  <Droppable droppableId="boxes">
                    {(provided) => (
                      <List
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        sx={{
                          "& .MuiListItem-root": {
                            padding: "5px 30px 5px 10px",
                            marginBottom: "4px",
                            border: "1px solid #f3f3f3",
                            borderRadius: "3px",
                            backgroundColor: "#f8f9fa8f",
                          },
                        }}
                      >
                        {box.map((product, index) => (
                          <Draggable
                            key={product.id}
                            draggableId={product.id.toString()}
                            index={index}
                          >
                            {(provided) => (
                              <ListItem
                                ref={provided.innerRef}
                                secondaryAction={
                                  <Tooltip title="Delete from reorder list" placement="top">
                                    <IconButton
                                      edge="end"
                                      aria-label="delete"
                                      sx={{
                                        right: "15px",
                                      }}
                                      onClick={() => removeFromReorderProducts(product.id)}
                                    >
                                      <DeleteIcon color="error" fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                }
                                {...provided.dragHandleProps}
                                {...provided.draggableProps}
                                sx={{
                                  "& .MuiAvatar-root": {
                                    fontSize: "0.75rem",
                                    width: "26px",
                                    height: "26px",
                                    color: "#212020",
                                    fontWeight: "600",
                                  },
                                }}
                              >
                                <ListItemAvatar>
                                  <Avatar>{index + 1}</Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                  sx={{
                                    "& .MuiTypography-root": {
                                      fontSize: "1rem",
                                      color: "#212020",
                                      fontWeight: "600",
                                    },
                                  }}
                                >
                                  <input
                                    className="product-priority-item"
                                    type="hidden"
                                    name={product.id}
                                    value={box.length - index}
                                  />
                                  {product.name}
                                </ListItemText>
                              </ListItem>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </List>
                    )}
                  </Droppable>
                </DragDropContext>
                <br />
                <MDBox display="flex" justifyContent="end" mt={1}>
                  <MDButton type="submit" color="primary" variant="gradient" id="submit">
                    Save
                  </MDButton>
                </MDBox>
              </form>
            </Box>
          ) : (
            <Alert severity="warning">No products found!</Alert>
          )}
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};

ProductReorder.propTypes = {
  // children: PropTypes.node.isRequired, // You can also use PropTypes.element if you want to enforce a single element.
  productData: PropTypes.object.isRequired,
};

export default ProductReorder;
