import moment from "moment";
import { dateFormat as defaultDateFormat } from "./constants";

export const parseJwt = token => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export const formatDate = (date, dateFormat = defaultDateFormat) => {
  return date ? moment(date).format(dateFormat) : "";
};

export const stripHTMLTags = (html) => {
  let filter = html.replace("&#8211;", "")
  return filter.replace(/<[^>]*>/g, "");
}

export const replaceHTMLEntityAnd = (html) => {
  let filter = html.replace("&amp;", "&")
  return filter;
}

export const replaceSingleQuoteToSQLFriendly = (html) => {
  let filter = html.replace("'", "''")
  return filter;
}

export const replaceHtmlEntities = (inputString) => {
  const doc = new DOMParser().parseFromString(inputString, 'text/html');
  return doc.body.textContent || '';
};

export function removeLocalStorageItemsWithPrefix(prefix) {
  for (var i = 0; i < localStorage.length; i++) {
    var key = localStorage.key(i);

    // Check if the key starts with the specified prefix
    if (key && key.startsWith(prefix)) {
      // Remove the matching item from local storage
      localStorage.removeItem(key);
    }
  }
}

export const imageSrcDecoder = (imageUrl) => {
  if (imageUrl.startsWith('http')) {
    return imageUrl;
  } else {
    // Assuming REACT_APP_RESELLER_PORTAL is defined in your environment
    return `${process.env.REACT_APP_URL}/${imageUrl.replace(/\\/g, '/')}`;
  }
};

export function dataBASE64URLtoFile(dataurl, filename) {
  var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]), 
      n = bstr.length, 
      u8arr = new Uint8Array(n);
  while(n--){
      u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, {type:mime});
}

export const addSpacesToNames = (data, parentId = 0, level = 0) => {
  return data
    .filter((item) => item.parent_id === parentId)
    .map((item) => ({
      ...item,
      name: "-".repeat(level * 2) + item.name,
    }))
    .flatMap((item) => [item, ...addSpacesToNames(data, item.id, level + 0.5)]);
};

export const imageFileTypes = ["image/jpeg", "image/jpg", "image/png", "image/webp"];

export const checkFileType = (type) => {
  return imageFileTypes.includes(type);
};