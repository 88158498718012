import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    visibility: false,
    message: ''
};

const slice = createSlice({
  name: "loader",
  initialState,
  reducers: {
    setGlobalLoader: (state, action) => ({ ...state, visibility: true }),
    setGlobalLoaderMessage: (state, action) => ({ ...state, message: action.payload }),
    resetGlobalLoader: (state, action) => ({ ...state, ...initialState })
  }
});

export const globalLoaderMessage = (state) => state.UI.loader.message;
export const loaderStatus = (state) => state.UI.loader.visibility;

export const { setGlobalLoader, setGlobalLoaderMessage, resetGlobalLoader } = slice.actions;

export default { name: slice.name, reducer: slice.reducer };
