import DateTime from "components/DateTime";
import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";

const ControlledDateTime = ({ label, name, control, inputProps, ...rest }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
        <DateTime
          {...rest}
          name={name}
          label={label}
          inputProps={inputProps}
          error={error}
          value={value ? value : ""}
          onChange={onChange}
          onBlur={onBlur}
        />
      )}
    />
  );
};

ControlledDateTime.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  control: PropTypes.object,
  inputProps: PropTypes.object,
};

export default ControlledDateTime;
