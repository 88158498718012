//layouts
import Dashboard from "layouts/dashboard";
import Billing from "layouts/billing";
import Notifications from "layouts/notifications";

import Configs from "views/Configs";
import Inventory from "views/Inventory";
import AddInventory from "views/Inventory/AddInventory";
import EditInventory from "views/Inventory/EditInventory";
import Companies from "views/Companies";
import Orders from "views/Orders";
import AddOrder from "views/Orders/AddOrder";
import Countries from "views/Countries";
import EmailTemplates from "views/EmailTemplates";
import Categories from "views/Categories";
import BDMs from "views/BDMs";
import Reminders from "views/Reminders/ReminderTypes";
import ReminderTypes from "views/Reminders/ReminderTypes";
import ReminderHistory from "views/Reminders/ReminderHistory";
import UpcomingReminders from "views/Reminders/UpcomingReminders";
import CustomReminders from "views/Reminders/CustomReminders";

// @mui icons
import Icon from "@mui/material/Icon";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CategoryIcon from "@mui/icons-material/Category";
import SettingsIcon from "@mui/icons-material/Settings";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import UpdateIcon from '@mui/icons-material/Update';
import RestoreIcon from '@mui/icons-material/Restore';
import MoreTimeIcon from '@mui/icons-material/MoreTime';

export const userRoutes = [
  // {
  //   type: "collapse",
  //   name: "Dashboard",
  //   key: "dashboard",
  //   icon: <Icon fontSize="small">dashboard</Icon>,
  //   route: "/dashboard",
  //   component: <Dashboard />,
  //   visible: true,
  // },
  {
    type: "collapse",
    name: "Configs",
    key: "configs",
    icon: <SettingsIcon fontSize="small" />,
    route: "/user/configs",
    component: Configs,
    visible: true,
  },
  {
    type: "collapse",
    name: "Inventory",
    key: "inventory",
    icon: <Icon fontSize="small">inventory</Icon>,
    route: "/user/inventory",
    component: Inventory,
    visible: true,
  },
  {
    type: "collapse",
    name: "Add Product",
    key: "add-product",
    icon: <Icon fontSize="small">inventory</Icon>,
    route: "/user/add-inventory",
    component: AddInventory,
    invisible: false,
  },
  {
    type: "collapse",
    name: "Edit Inventory",
    key: "edit-inventory",
    icon: <Icon fontSize="small">inventory</Icon>,
    route: "/user/:id/edit-inventory",
    component: EditInventory,
    invisible: false,
  },
  {
    type: "collapse",
    name: "Categories",
    key: "categories",
    icon: <CategoryIcon fontSize="small" />,
    route: "/user/categories",
    component: Categories,
    visible: true,
  },
  {
    type: "collapse",
    name: "Companies",
    key: "resellers",
    icon: <Icon fontSize="small">storefront</Icon>,
    route: "/user/companies",
    component: Companies,
    visible: true,
  },
  {
    type: "collapse",
    name: "Orders",
    key: "orders",
    icon: <AttachMoneyIcon fontSize="small" />,
    route: "/user/orders",
    component: Orders,
    visible: true,
  },
  {
    type: "collapse",
    name: "Add Order",
    key: "add-order",
    icon: <AttachMoneyIcon fontSize="small" />,
    route: "/user/add-order",
    component: AddOrder,
    invisible: false,
  },
  {
    type: "collapse",
    name: "BDM",
    key: "bdm",
    icon: <SupervisedUserCircleIcon fontSize="small" />,
    route: "/user/bdm",
    component: BDMs,
    visible: true,
  },
  {
    type: "collapse",
    name: "Reminders",
    key: "bdm",
    icon: <AccessAlarmIcon fontSize="small" />,
    route: "/user/reminders",
    component: Reminders,
    visible: true,
    nestedChild: true,
    children: [
      { type: "collapse", name: "Reminder Types", route: "/user/reminder-types", key: "reminder-types", component: ReminderTypes, icon: <SettingsIcon fontSize="small" />, },
      { type: "collapse", name: "Upcoming Reminders", route: "/user/reminders-upcoming", key: "upcoming-reminders", component: UpcomingReminders, icon: <UpdateIcon fontSize="small" />, },
      { type: "collapse", name: "Reminder History", route: "/user/reminders-history", key: "reminder-history", component: ReminderHistory, icon: <RestoreIcon fontSize="small" />, },
      { type: "collapse", name: "Custom Reminders", route: "/user/reminders-custom", key: "custom-reminders", component: CustomReminders, icon: <MoreTimeIcon fontSize="small" />, },
    ],
  },
  {
    type: "collapse",
    name: "Reminder Types",
    key: "reminder-types",
    icon: <SettingsIcon fontSize="small" />,
    route: "/user/reminder-types",
    component: ReminderTypes,
    visible: false,
  },
  {
    type: "collapse",
    name: "Upcoming Reminders",
    key: "upcoming-reminders",
    icon: <UpdateIcon fontSize="small" />,
    route: "/user/reminders-upcoming",
    component: UpcomingReminders,
    visible: false,
  },
  {
    type: "collapse",
    name: "Reminder History",
    key: "reminder-history",
    icon: <RestoreIcon fontSize="small" />,
    route: "/user/reminders-history",
    component: ReminderHistory,
    visible: false,
  },
  {
    type: "collapse",
    name: "Custom Reminders",
    key: "custom-reminders",
    icon: <MoreTimeIcon fontSize="small" />,
    route: "/user/reminders-custom",
    component: CustomReminders,
    visible: false,
  },
  {
    type: "collapse",
    name: "Countries",
    key: "countries",
    icon: <Icon fontSize="small">public</Icon>,
    route: "/user/countries",
    component: Countries,
    visible: true,
  },
  {
    type: "collapse",
    name: "Email Templates",
    key: "email_templates",
    icon: <Icon fontSize="small">email</Icon>,
    route: "/user/email_templates",
    component: EmailTemplates,
    visible: true,
  },
  // {
  //   type: "collapse",
  //   name: "Billing",
  //   key: "billing",
  //   icon: <Icon fontSize="small">receipt_long</Icon>,
  //   route: "/user/billing",
  //   component: Billing,
  //   visible: false,
  // },
  // {
  //   type: "collapse",
  //   name: "Notifications",
  //   key: "notifications",
  //   icon: <Icon fontSize="small">notifications</Icon>,
  //   route: "/user/notifications",
  //   component: Notifications,
  //   visible: false,
  // },
];
