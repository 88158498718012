/* eslint-disable react/prop-types */
import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";

import { styled } from "@mui/material/styles";

import PropTypes from "prop-types";
import MDButton from "components/MDButton";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import CloseIcon from "@mui/icons-material/Close";

import MDBadge from "components/MDBadge";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Box from "@mui/material/Box";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

import MDBox from "components/MDBox";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Instructions = ({ data }) => {
  const [openInstructions, setOpenInstructions] = useState(false);

  const handleClickOpen = () => {
    setOpenInstructions(true);
  };
  const handleClose = () => {
    setOpenInstructions(false);
  };

  return (
    <>
      <Tooltip title="View instructions" sx={{ ml: 1 }} placement="top">
        <IconButton aria-label="view" size="small" onClick={() => handleClickOpen()}>
          <HelpRoundedIcon color="secondary" fontSize="inherit" />
        </IconButton>
      </Tooltip>
      <BootstrapDialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        maxWidth="lg"
        sx={{
          "& .MuiPaper-root": {
            width: "600px",
          },
        }}
        open={openInstructions}
      >
        <DialogTitle
          sx={{ m: 0, p: 2, color: "#4b4b4f", fontSize: "1rem" }}
          id="customized-dialog-title"
        >
          {data.title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Box
            sx={{
              "& ol": {
                paddingLeft: "20px",
                fontSize: "0.9rem",
              },
              "& ol li": {
                paddingBottom: "10px",
              },
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: data.description }} />
          </Box>
        </DialogContent>
        {/* <DialogActions>
          <MDButton color="secondary" variant="gradient" autoFocus onClick={handleClose}>
            Cancel
          </MDButton>
        </DialogActions> */}
      </BootstrapDialog>
    </>
  );
};

Instructions.propTypes = {
  data: PropTypes.object,
};

export default Instructions;
