/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { Importer, ImporterField } from "react-csv-importer";

import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import ReactQuill from "react-quill";

import { styled } from "@mui/material/styles";

import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import MDButton from "components/MDButton";

import { AddBox } from "@mui/icons-material";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";

import TextField from "@mui/material/TextField";

import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Notify } from "notiflix/build/notiflix-notify-aio";

import Typography from "@mui/material/Typography";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import ControlledTextInput from "components/FormInputs/ControlledTextInput";
import ControlledDateTime from "components/FormInputs/ControlledDateTime";
import ControlledCheckbox from "components/FormInputs/ControlledCheckbox";
import ControlledSelect from "components/FormInputs/ControlledSelect";
import ControlledAutoComplete from "components/FormInputs/ControlledAutoComplete";
import CustomInputs from "components/CustomInputs";
import CustomAutoComplete from "components/CustomAutoComplete";

//components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import MDAvatar from "components/MDAvatar";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

//example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import Box from "@mui/material/Box";

import Edit from "@mui/icons-material/Edit";
import Close from "@mui/icons-material/Close";

// theme CSS for React CSV Importer
import "react-csv-importer/dist/index.css";

// queries
import {
  useGetAllResellerProductsByCountryIdQuery,
  useLazyGetAllResellerProductsByCountryIdQuery,
  useUploadProductImageMutation,
  usePostProductMutation,
  useGetDistinctAttributesQuery,
} from "services/products";

import { useGetAllResellerProductCategoriesQuery } from "services/categories";

import { useUploadFileMutation, useDeleteFileMutation } from "services/files";

// import { useGetAllProductsFromStoreUrlQuery } from "services/store";

import { globalCountry } from "store/entities/countries";

// import TinyMCEEditor from "components/TinyMCEEditor";

import {
  replaceSingleQuoteToSQLFriendly,
  replaceHTMLEntityAnd,
  removeLocalStorageItemsWithPrefix,
  addSpacesToNames,
} from "utils/helperFunctions";

import Alert from "components/CustomAlert";

import CircularProgress from "@mui/material/CircularProgress";

import CustomTabs from "components/CustomTabs";

import { ArrowBack } from "@mui/icons-material";

import AddVariationDialog from "./AddVariationDialog";
import EditVariationDialog from "./EditVariationDialog";

import SelectAttributeFromPool from "./SelectAttributeFromPool";
import AddDigitalKeys from "../components/AddDigitalKeys";

import { addInventorySchema } from "../schemas/_model";

// ReactQuill modules
const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
    ["link", "image", "video"],
    ["clean"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};

function AddInventory() {
  const initialFormState = {
    isDigitalGood: false,
    isFeaturedProduct: false,
    price: 0,
    retailPrice: 0,
    categories: [],
    name: "",
    inStock: true,
    averageRating: 0,
    ratingCount: 0,
    // shortDescription: "",
    sku: "",
    type: "simple",
    digitalKeys: [],
  };

  const navigate = useHistory();

  const selectedCountry = useSelector(globalCountry);

  const [editorDescriptionOnChange, setEditorDescriptionOnChange] = useState("");

  const [editorShortDescriptionOnChange, setEditorShortDescriptionOnChange] = useState("");

  const [imageLoading, setImageLoading] = useState(true);

  const [attributeFields, setAttributeFields] = useState([]);

  const [availabilityFields, setAvailabilityFields] = useState([]);

  const [anchorAttributeEl, setAnchorAttributeEl] = useState(null);

  const [productVariations, setProductVariations] = useState([]);

  const [filterCategories, setFilterCategories] = useState([]);

  const [file, setFile] = useState([]);

  const [selectedProductTypeChanged, setSelectedProductTypeChanged] = useState("simple");

  const [selectedIsDigitalKeyChanged, setSelectedIsDigitalKeyChanged] = useState(false);

  const [openSelectAttributeFromPool, setOpenSelectAttributeFromPool] = useState(false);

  const [uploadFile, { isLoading: isFileUploading }] = useUploadFileMutation();

  const [postProduct] = usePostProductMutation();

  const openAttributeMenu = Boolean(anchorAttributeEl);

  const dispatch = useDispatch();

  const handleMenuClick = (event) => {
    setAnchorAttributeEl(event.currentTarget);
  };

  const handleMenuClose = (event) => {
    setAnchorAttributeEl(null);
  };

  const { control, watch, handleSubmit, setValue, resetField, formState } = useForm({
    resolver: yupResolver(
      addInventorySchema(selectedProductTypeChanged, selectedIsDigitalKeyChanged)
    ),
    defaultValues: initialFormState,
  });

  const {
    data: distinctAttributes = [],
    isLoading: isDistinctAttributesLoading,
    isSuccess: isDistinctAttributesSuccess,
  } = useGetDistinctAttributesQuery();

  const {
    data: categoriesList = [],
    isLoading,
    isSuccess,
  } = useGetAllResellerProductCategoriesQuery(selectedCountry.id);

  const [triggerProducts] = useLazyGetAllResellerProductsByCountryIdQuery();

  const isDigitalGoodField = watch("isDigitalGood");
  const selectedProductType = watch("type");

  const onSubmit = async (data) => {
    if (file.length == 0) {
      Notify.failure("You must add atleast one product image!");
      return;
    }

    if (editorShortDescriptionOnChange === undefined || editorShortDescriptionOnChange == "") {
      Notify.failure("You must add the product short description!");
      return;
    }

    if (editorDescriptionOnChange === undefined || editorDescriptionOnChange == "") {
      Notify.failure("You must add the product description!");
      return;
    }

    if (data.type == "variable" && productVariations.length == 0) {
      Notify.failure("You should add atleast one variation for variable product!");
      return;
    }

    if (isDigitalGoodField) {
      if (data.digitalKeys.length > 0 || availabilityFields.length > 0) {
        if (availabilityFields.length > 0) {
          let keys = [...availabilityFields];
          for (let key of data.digitalKeys) {
            if (key.key) keys.push(key.key);
          }
          data.digitalKeys = keys;
        } else {
          let keys = [];
          for (let key of data.digitalKeys) {
            if (key.key) keys.push(key.key);
          }
          data.digitalKeys = keys;
        }
      } else {
        if (data.type == "variable") {
          data.digitalKeys = ["Pending"];
        } else {
          Notify.failure("You must add atleast one digital key.");
          return;
        }
      }
    } else {
      data.digitalKeys = [];
    }

    // setting up the loader

    Loading.dots({
      svgColor: "rgba(159, 3, 3, 0.8)",
    });

    const imagePath = [];

    if (file && file.length > 0) {
      for (let image of file) {
        let formData = new FormData();
        formData.append("file", image);
        let imageResponse = await uploadFile({
          formData: formData,
          directory: "product_images",
        });
        if (imageResponse.data.status == "success") {
          imagePath.push(imageResponse.data?.response.path.replace(/\\/g, "/"));
        }
      }
    }

    if (imagePath && imagePath.length > 0) {
      let description = editorDescriptionOnChange ? editorDescriptionOnChange : "<p>&nbsp; </p>";
      let shortDescription = editorShortDescriptionOnChange
        ? editorShortDescriptionOnChange
        : "<p>&nbsp; </p>";

      data.description = replaceSingleQuoteToSQLFriendly(description);
      data.shortDescription = replaceSingleQuoteToSQLFriendly(shortDescription);
      data.parentId = 0;
      data.countryId = selectedCountry.id;
      data.attributes = [];
      data.images = imagePath;

      if (data.type == "variable" && productVariations.length > 0) {
        data.retailPrice = productVariations[0].retailPrice;
        data.price = productVariations[0].price;
      }

      for (let variation of productVariations) {
        variation.countryId = selectedCountry.id;
        variation.isFeaturedProduct = false;
        variation.type = "variable";
        variation.categories = data.categories;

        if (variation.digitalKeys.length > 0) {
          let keys = [];
          for (let key of variation.digitalKeys) {
            if (key.key) keys.push(key.key);
          }
          variation.digitalKeys = keys;
        }

        if (variation.pendingKeys && variation.pendingKeys.length > 0) {
          for (let key of variation.pendingKeys) {
            variation.digitalKeys.push(key);
          }
        }

        delete variation.id;
        delete variation.pendingKeys;
      }

      try {
        const parentProduct = await postProduct(data);

        if (parentProduct.data?.status == "success") {
          Notify.success("Product has been added successfully!");
          if (data.type == "variable" && productVariations.length > 0) {
            for (let variation of productVariations) {
              variation.parentId = parentProduct.data.response.id;

              if (variation.variationImage) {
                const variableImagePath = [];

                let formData = new FormData();

                formData.append("file", variation.variationImage);

                let variationImageResponse = await uploadFile({
                  formData: formData,
                  directory: "product_images",
                });
                if (variationImageResponse.data.status == "success") {
                  variableImagePath.push(
                    variationImageResponse.data?.response.path.replace(/\\/g, "/")
                  );
                }

                variation.images = variableImagePath;
              } else {
                variation.images = [];
              }

              await postProduct(variation);
            }
          }

          removeCachedData();
          triggerProducts(selectedCountry.id);
          navigate.push("/user/inventory");
        } else {
          Notify.failure("Something went wrong. Please try again!");
        }
      } catch (e) {
      } finally {
        Loading.remove(500);
      }
    } else {
      Notify.failure(
        "There was an issue uploading the product image, please check again in few minutes!"
      );
      // Loading.remove(500);
    }
  };

  const removeCachedData = () => {
    const variationImagePrefix = "variationImage";

    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith(variationImagePrefix)) {
        localStorage.removeItem(key);
      }
    });
  };

  useEffect(() => {
    resetField("category");
  }, [selectedCountry]);

  useEffect(() => {
    Loading.dots({
      svgColor: "rgba(159, 3, 3, 0.8)",
    });

    isDistinctAttributesSuccess && Loading.remove(500);
  }, [isDistinctAttributesSuccess]);

  const handleUpdateFiles = (files) => {
    if (files.length > 0) {
      let photos = [];
      for (let file of files) {
        photos.push(file.source);
      }
      setFile(photos);
    } else {
      setFile([]);
    }
  };

  useEffect(() => {
    if (categoriesList && categoriesList.length > 0) {
      const modifiedCategories = addSpacesToNames(categoriesList);
      setFilterCategories(modifiedCategories);
    } else {
      setFilterCategories([]);
    }
  }, [categoriesList]);

  const handleAttributeFields = (index, e) => {
    let data = [...attributeFields];
    data[index] = e.target.value;
    setAttributeFields(data);
  };

  const addAttributeFields = () => {
    let newfield = "";
    setAttributeFields([...attributeFields, newfield]);
  };

  const removeAttributeFields = (index) => {
    let data = [...attributeFields];
    data.splice(index, 1);
    setAttributeFields(data);
  };

  //--------------------------------- product variations related logic
  const handleProductVariationSubmit = (variationData, pendingKeys) => {
    let data = variationData;
    data.pendingKeys = [];
    if (data.isDigitalGood) {
      if (pendingKeys && pendingKeys.length > 0) {
        for (let pendingKey of pendingKeys) {
          data.pendingKeys.push(pendingKey);
        }
      }
    }
    setProductVariations([...productVariations, data]);
  };

  const handleEditProductVariationSubmit = (variationData, pendingKeys) => {
    let data = [];
    let filterVariation = productVariations.filter((variation) => variation.id != variationData.id);
    if (filterVariation) {
      let variation = variationData;
      variation.pendingKeys = [];
      if (variation.isDigitalGood) {
        if (pendingKeys && pendingKeys.length > 0) {
          for (let pendingKey of pendingKeys) {
            variation.pendingKeys.push(pendingKey);
          }
        }
      }
      data = [...filterVariation, variation];
    }
    setProductVariations(data);
  };

  const deleteVariation = (id) => {
    let filterVariation = productVariations.filter((variation) => variation.id != id);
    setProductVariations(filterVariation);
  };

  // useEffect(() => {
  //   if(distinctAttributes && distinctAttributes.length > 0) setAttributeFields(distinctAttributes);
  // }, [distinctAttributes]);

  useEffect(() => {
    setSelectedProductTypeChanged(selectedProductType);
  }, [selectedProductType]);

  useEffect(() => {
    setSelectedIsDigitalKeyChanged(isDigitalGoodField);
  }, [isDigitalGoodField]);

  useEffect(() => {
    removeLocalStorageItemsWithPrefix("variationImage=");
  }, []);

  const showMessage = (type, message) => {
    if (type == "error") {
      Notify.failure(message);
    } else {
      Notify.success(message);
    }
  };

  return (
    <Card>
      <CardHeader
        // avatar={<AccountCircleIcon fontSize="large" color="dark" />}
        title={<MDTypography color="info">Add Product</MDTypography>}
        action={
          <IconButton color={"secondary"} onClick={() => navigate.goBack()} size="large">
            <ArrowBack />
          </IconButton>
        }
      />
      <CardContent>
        <form>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
              <ControlledTextInput
                name="name"
                control={control}
                labelText="Name *"
                formControlProps={{
                  fullWidth: true,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
              <ControlledSelect
                name={"type"}
                control={control}
                label="Type *"
                menu={{
                  id: (prop) => prop,
                  value: (prop) => prop,
                  title: "Type",
                  list: ["simple", "variable"],
                }}
                sx={{
                  "& .MuiFormLabel-root": {
                    backgroundColor: "#fff",
                  },
                  "& .MuiInputBase-root": {
                    height: "44px",
                  },
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
              <ControlledSelect
                multiple
                name={"categories"}
                control={control}
                label="Select category *"
                menu={{
                  id: "id",
                  value: "name",
                  title: "Categories",
                  list: filterCategories,
                }}
                disabled={filterCategories.length > 0 ? false : true}
                sx={{
                  "& .MuiFormLabel-root": {
                    backgroundColor: "#fff",
                  },
                  "& .MuiInputBase-root": {
                    height: "44px",
                  },
                }}
              />
            </GridItem>
            {selectedProductType == "variable" ? (
              <GridItem xs={12} sm={12}>
                <Box
                  px={2}
                  pb={2}
                  p1={1}
                  mb={1}
                  sx={{ border: "0.0625rem solid #f0f2f5", borderRadius: "0.375rem" }}
                >
                  <Box display="flex" justifyContent="space-between" my={1}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 500,
                        color: "#3f3f3f",
                      }}
                    >
                      Attributes
                    </Typography>
                    <div>
                      <MDButton
                        color="info"
                        variant="gradient"
                        iconOnly
                        // onClick={addAttributeFields}
                        id="attributes-button"
                        aria-controls={openAttributeMenu ? "attribute-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={openAttributeMenu ? "true" : undefined}
                        onClick={handleMenuClick}
                      >
                        <AddBox sx={{}} />
                      </MDButton>
                      <Menu
                        id="attribute-menu"
                        anchorEl={anchorAttributeEl}
                        open={openAttributeMenu}
                        onClose={handleMenuClose}
                        MenuListProps={{
                          "aria-labelledby": "attributes-button",
                        }}
                      >
                        <MenuItem onClick={() => setOpenSelectAttributeFromPool(true)}>
                          Select from the pool
                        </MenuItem>
                        <MenuItem onClick={addAttributeFields}>Add New Field</MenuItem>
                      </Menu>
                    </div>
                  </Box>
                  <br />
                  {attributeFields.map((input, index) => {
                    return (
                      <GridContainer key={index}>
                        <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                          <CustomInputs
                            name="attribute"
                            id="attribute_key"
                            label="Attribute Name *"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            value={input}
                            onChange={(e) => handleAttributeFields(index, e)}
                          />
                        </GridItem>

                        <GridItem xs={12} sm={12} md={1} lg={1} xl={1}>
                          <MDButton
                            color="warning"
                            variant="gradient"
                            iconOnly
                            onClick={() => removeAttributeFields(index)}
                          >
                            <IndeterminateCheckBoxIcon />
                          </MDButton>
                        </GridItem>
                      </GridContainer>
                    );
                  })}
                  <SelectAttributeFromPool
                    addAttributeFields={setAttributeFields}
                    existingAttributes={attributeFields}
                    closeAttributeMenu={handleMenuClose}
                    openSelectAttributeFromPool={openSelectAttributeFromPool}
                    setOpenSelectAttributeFromPool={setOpenSelectAttributeFromPool}
                    distinctAttributes={distinctAttributes}
                  />
                </Box>
              </GridItem>
            ) : (
              <></>
            )}
            {selectedProductType == "variable" ? (
              <GridItem xs={12} sm={12}>
                <Box
                  px={2}
                  pb={2}
                  p1={1}
                  sx={{ border: "0.0625rem solid #f0f2f5", borderRadius: "0.375rem", mb: 2 }}
                >
                  <Box display="flex" justifyContent="space-between" my={1}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 500,
                        color: "#3f3f3f",
                      }}
                    >
                      Variations{" "}
                      {attributeFields.length == 0 &&
                        "(You must add atleast one attribute in order to add variations)"}
                    </Typography>
                    <div>
                      <AddVariationDialog
                        showMessage={showMessage}
                        btnDisability={
                          selectedProductType == "variable" && attributeFields.length > 0
                            ? false
                            : true
                        }
                        handleProductVariationSubmit={handleProductVariationSubmit}
                        variationCount={productVariations.length + 1}
                        parentAttributes={attributeFields}
                      />
                    </div>
                  </Box>
                  <br />
                  {productVariations.map((data, index) => {
                    return (
                      <EditVariationDialog
                        key={index}
                        handleEditProductVariationSubmit={handleEditProductVariationSubmit}
                        initData={data}
                        parentAttributes={attributeFields}
                        deleteVariation={deleteVariation}
                      >
                        {data.name}
                      </EditVariationDialog>
                    );
                  })}
                </Box>
              </GridItem>
            ) : (
              <></>
            )}
            {/* <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
              <ControlledTextInput
                multiline={true}
                name="shortDescription"
                control={control}
                labelText="Short description *"
                formControlProps={{
                  fullWidth: true,
                }}
              />
            </GridItem> */}
            <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
              <MDBox
                sx={{
                  border: "1px solid #d2d6da",
                  borderRadius: "0.375rem",
                  mb: 3,
                  pt: 2,
                  position: "relative",
                  // "& .tox-tinymce": { border: "none", position: "relative" },
                  "& .ql-editor": { minHeight: "250px" },
                }}
              >
                <MDTypography
                  component="p"
                  color="info"
                  sx={{
                    lineHeight: "1.5",
                    fontSize: "0.8rem",
                    fontWeight: 500,
                    transform: "translate(7px, -50%)",
                    backgroundColor: "#fff",
                    color: "#7b809a",
                    padding: "0 5px",
                    position: "absolute",
                    left: 0,
                    top: 0,
                    zIndex: 1,
                  }}
                >
                  Short Description
                </MDTypography>
                {/* <TinyMCEEditor
                  initialContent={""}
                  height={300}
                  realTimeContent={setEditorShortDescriptionOnChange}
                /> */}
                <ReactQuill
                  theme="snow"
                  value={editorShortDescriptionOnChange}
                  onChange={setEditorShortDescriptionOnChange}
                  modules={modules}
                />
              </MDBox>
            </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
              <MDBox
                sx={{
                  border: "1px solid #d2d6da",
                  borderRadius: "0.375rem",
                  mb: 3,
                  pt: 2,
                  position: "relative",
                  // "& .tox-tinymce": { border: "none", position: "relative" },
                  "& .ql-editor": { minHeight: "300px" },
                }}
              >
                <MDTypography
                  component="p"
                  color="info"
                  sx={{
                    lineHeight: "1.5",
                    fontSize: "0.8rem",
                    fontWeight: 500,
                    transform: "translate(7px, -50%)",
                    backgroundColor: "#fff",
                    color: "#7b809a",
                    padding: "0 5px",
                    position: "absolute",
                    left: 0,
                    top: 0,
                    zIndex: 1,
                  }}
                >
                  Description
                </MDTypography>
                {/* <TinyMCEEditor initialContent={""} realTimeContent={setEditorDescriptionOnChange} /> */}
                <ReactQuill
                  theme="snow"
                  value={editorDescriptionOnChange}
                  onChange={setEditorDescriptionOnChange}
                  modules={modules}
                />
              </MDBox>
            </GridItem>
            <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
              <ControlledTextInput
                name="sku"
                control={control}
                labelText="SKU"
                formControlProps={{
                  fullWidth: true,
                }}
              />
            </GridItem>
            {selectedProductType == "simple" && (
              <>
                <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                  <ControlledTextInput
                    name="price"
                    control={control}
                    labelText="Enter Reseller Price *"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "number",
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                  <ControlledTextInput
                    name="retailPrice"
                    control={control}
                    labelText="Enter Retailer Price *"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "number",
                    }}
                  />
                </GridItem>
              </>
            )}

            <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
              <ControlledTextInput
                name="averageRating"
                control={control}
                labelText="Average rating *"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: "number",
                }}
              />
            </GridItem>

            <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
              <ControlledTextInput
                name="ratingCount"
                control={control}
                labelText="Rating count *"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: "number",
                }}
              />
            </GridItem>

            <GridItem xs={12} sm={12}>
              <>
                <Box
                  component={FilePond}
                  sx={{
                    fontFamily: '"Poppins", "Roboto", "Helvetica", "Arial", sans-serif',
                    "& .filepond--file-action-button": {
                      cursor: "pointer",
                    },
                    "& .filepond--drop-label.filepond--drop-label label": {
                      fontSize: "13px",
                      letterSpacing: "0.5px",
                      fontWeight: "600",
                    },
                    mt: 2,
                  }}
                  instantUpload={false}
                  allowFileSizeValidation={true}
                  name="file"
                  allowFileTypeValidation={true}
                  acceptedFileTypes={[
                    "image/jpeg",
                    "image/jpg",
                    "image/png",
                    "image/webp",
                    "application/msword", // for .doc
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // for .docx
                    "application/pdf",
                  ]}
                  allowMultiple={true}
                  allowFilePoster={false}
                  labelIdle={
                    'Drag & Drop product images or <span class="filepond--label-action">Browse</span>'
                  }
                  onupdatefiles={handleUpdateFiles}
                  allowRevert={false}
                />
              </>
            </GridItem>

            <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
              <ControlledCheckbox
                name="isFeaturedProduct"
                control={control}
                label="Is Feature Product?"
              />
            </GridItem>

            <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
              <ControlledCheckbox name="inStock" control={control} label="Is in stock?" />
            </GridItem>

            <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
              <ControlledCheckbox name="isDigitalGood" control={control} label="Is Digital Good?" />
            </GridItem>

            {isDigitalGoodField ? (
              <AddDigitalKeys
                control={control}
                availabilityFields={availabilityFields}
                setAvailabilityFields={setAvailabilityFields}
              />
            ) : (
              <></>
            )}
          </GridContainer>
        </form>
        <CardActions disableSpacing>
          * Required fields
          <Box sx={{ marginLeft: "auto" }}>
            <MDButton
              color="primary"
              variant="gradient"
              id="submit"
              onClick={handleSubmit(onSubmit)}
            >
              Add Stock
            </MDButton>
            <MDButton
              color="secondary"
              variant="gradient"
              onClick={() => navigate.goBack()}
              sx={{ ml: 1 }}
            >
              Cancel
            </MDButton>
          </Box>
        </CardActions>
      </CardContent>
    </Card>
  );
}

export default AddInventory;
