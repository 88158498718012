import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { history, persistor } from "store";

import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import { MaterialUIControllerProvider } from "context";

import ErrorBoundary from "components/ErrorBoundary";

//themes
import theme from "assets/theme";

const container = document.getElementById("app");
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter history={history}>
        <MaterialUIControllerProvider>
          <ThemeProvider theme={theme}>
            {/* <CssBaseline /> */}
            <App />
          </ThemeProvider>
        </MaterialUIControllerProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);
