/* eslint-disable react/prop-types */
import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";

import moment from "moment";

import { styled } from "@mui/material/styles";

import PropTypes from "prop-types";

import MDButton from "components/MDButton";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import KeyIcon from "@mui/icons-material/Key";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import MDBadge from "components/MDBadge";
import DetailBlock from "components/DetailBlock";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";

import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

import DataTable from "examples/Tables/DataTable/DisabledState";

import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Notify } from "notiflix/build/notiflix-notify-aio";
import { Confirm } from "notiflix/build/notiflix-confirm-aio";

import Alert from "components/CustomAlert";
import { Block } from "notiflix/build/notiflix-block-aio";

import EditAssignedEndUser from './Edit'
import AssignEndUser from './Add'

import { dateFormat, dateTimeFormat } from "utils/constants";

import {
  useLazyGetEndUserByDigitalKeyQuery,
  useDeleteEndUserToDigitalKeyMutation,
} from "services/digitalKeys";

import {
  useLazyGetAllReminderTypesQuery
} from "services/reminders";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

import MDBox from "components/MDBox";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AssignedUsers = ({
  orderProductId
}) => {
  const [openAssignedUsersDialog, setOpenAssignedUsersDialog] = useState(false);

  const [userData, setUserData] = useState({});

  const [reminderTypeDisplay, setReminderTypeDisplay] = useState('');

  const [triggerReminderTypes, {data: reminderTypes}] = useLazyGetAllReminderTypesQuery();

  const [getEndUser] = useLazyGetEndUserByDigitalKeyQuery();

  const [deleteEndUser] = useDeleteEndUserToDigitalKeyMutation();

  const handleDialogOpen = async () => {
    // Loading.dots({ svgColor: "rgba(159, 3, 3, 0.8)", });
    setOpenAssignedUsersDialog(true);
    let enduserdata = await getEndUser(orderProductId);
    let reminderTypes = await triggerReminderTypes();
    if(enduserdata.data) {
      let assignedReminderTypes = [];

      if(reminderTypes) {
        let assignedReminderTypeNames = '';
        for(let type of enduserdata.data.end_user_reminder_types) {
          assignedReminderTypes.push(type.reminder_type_id);
        }
        for(let reminderType of reminderTypes.data) {
          for(let type of enduserdata.data.end_user_reminder_types) {
            if(type.reminder_type_id == reminderType.id) {
              if(assignedReminderTypeNames == "") {
                assignedReminderTypeNames = reminderType.name
              } else {
                assignedReminderTypeNames = assignedReminderTypeNames + ', ' + reminderType.name;
              }
            }
          }
        }
        setReminderTypeDisplay(assignedReminderTypeNames);
        setUserData({ ...enduserdata.data, reminderTypeIds: assignedReminderTypes });
      } else {
        setUserData({ ...enduserdata.data });
      }
    }
  };

  const deleteRecord = async (id) => {
    Loading.dots({
      svgColor: "rgba(159, 3, 3, 0.8)",
    });
    try {
      deleteEndUser(id)
        .then((response) => {
          if (response) {
            Notify.success("Succefully updated!");
          } else {
            Notify.failure("Something went wrong!");
          }
        })
        .catch((error) => {
          Notify.failure("Something went wrong!");
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    } finally {
      Loading.remove(500);
    }
  };

  const handleDialogClose = () => {
    setOpenAssignedUsersDialog(false);
  };

  return (
    <>
      <Tooltip title="View assigned user" placement="top">
        <IconButton aria-label="view" size="small" onClick={handleDialogOpen}>
          <AccountCircleIcon color="warning" fontSize="inherit" />
        </IconButton>
      </Tooltip>
      <BootstrapDialog
        // onClose={handleDialogClose}
        aria-labelledby="customized-dialog-title"
        maxWidth="lg"
        sx={{
          "& .MuiDialog-paper": {
            width: "850px",
          },
        }}
        open={openAssignedUsersDialog}
      >
        <DialogTitle sx={{ m: 0, p: 2, color: "#4b4b4f" }} id="customized-dialog-title">
          End user details {userData.digital_key_id && (`(${userData.digital_key_id})`)}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleDialogClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {userData && userData.id && <MDBox sx={{ textAlign: "right" }}>
            <EditAssignedEndUser initialData={userData} reminderTypes={reminderTypes} />
            <Tooltip title="Delete end user" placement="top">
              <IconButton
                className="deleteButton"
                color="error"
                onClick={() =>
                  Confirm.show(
                    `Please Confirm`,
                    "Are you sure you want to delete this end user?",
                    "Yes",
                    "No",
                    () => {
                      deleteRecord(userData.id);
                    },
                    () => {},
                    {}
                  )
                }
                size="large"
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </MDBox>}
          <GridContainer>
            {userData.digital_key_id && (
              <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                <DetailBlock blockTitle="Digital key id" blockDesc={`#${userData.digital_key_id}`} />
              </GridItem>
            )}
            {userData.email && (
              <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                <DetailBlock blockTitle="Email" blockDesc={`${userData.email}`} />
              </GridItem>
            )}
            {userData.first_name && (
              <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                <DetailBlock blockTitle="First name" blockDesc={`${userData.first_name}`} />
              </GridItem>
            )}
            {userData.last_name && (
              <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                <DetailBlock blockTitle="Last name" blockDesc={`${userData.last_name}`} />
              </GridItem>
            )}
             {userData.expiry_date && (
              <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                <DetailBlock blockTitle="Expiry date" blockDesc={moment(userData.expiry_date).format(dateFormat)} />
              </GridItem>
            )}
            {reminderTypeDisplay != "" && (
              <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                <DetailBlock blockTitle="Assigned reminder types" blockDesc={reminderTypeDisplay} />
              </GridItem>
            )}
            {/* {userData.expiry_date && (
              <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                <DetailBlock blockTitle="Reminder type" blockDesc={} />
              </GridItem>
            )} */}
          </GridContainer>
          {!userData.id && <Alert severity="warning" sx={{ display: 'flex' }}>No end users found! <AssignEndUser orderProductId={orderProductId} reminderTypes={reminderTypes} /></Alert>}
        </DialogContent>
        <DialogActions>
          <MDButton color="secondary" variant="gradient" autoFocus onClick={handleDialogClose}>
            Cancel
          </MDButton>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

AssignedUsers.propTypes = {
  orderProductId: PropTypes.number.isRequired,
};

export default AssignedUsers;
