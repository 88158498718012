export default {
  stripe_public: {
    title: "How to get the Stripe Public Key",
    description:
      "<ol><li>Log in to your Stripe Dashboard.</li><li>Go to 'Developers' section. It might be in the left-hand menu or under a tab named 'Developers' or 'API'.</li><li>You'll see a set of keys labeled 'Publishable key' and 'Secret key'. The 'Publishable key' is your public key. Copy it and paste here!</li></ol>",
  },
  stripe_secret: {
    title: "How to get the Stripe Secret Key",
    description:
      "<ol><li>Log in to your Stripe Dashboard.</li><li>Go to 'Developers' section. It might be in the left-hand menu or under a tab named 'Developers' or 'API'.</li><li>You'll see a set of keys labeled 'Publishable key' and 'Secret key'. The 'Secret key' is your secret key. Never share your secret key publicly; it should be kept confidential. Copy it and paste here!</li></ol>",
  },
  stripe_webhook: {
    title: "How to get the Stripe Webhook Key",
    description:
      "<ol><li>Log in to your Stripe Dashboard.</li><li>Go to 'Developers' section. It might be in the left-hand menu or under a tab named 'Developers'.</li><li>Go to 'Webhooks' or 'Webhook Endpoints' subsection.</li><li>You can create a new webhook endpoint. Once created, you will be given a 'Webhook secret'. Copy it and paste here!</li></ol>",
  },
  invoice_ninja_key: {
    title: "How to get the Invoice Ninja Api Key",
    description:
      "<ol><li>Log in to your Invoice Ninja account.</li><li>Navigate to the API settings.</li><li>In the API settings section, you should find an option to generate an API key. Please note that some systems might refer to this as a 'token' rather than a key.</li><li>After generating the API key, copy it and paste here!</li></ol>",
  },
  consumer_key: {
    title: "How to get the Consumer Key and Consumer Secret",
    description:
      "<ol><li>Log in to the wordpress dashboard (make sure to login to the correct website specified in the URL).</li><li>Go to WooCommerce > Settings > Advanced (Tab) > REST API. Click the 'Add Key' button</li><li>In the next screen, add a description and select the WordPress user you would like to generate the key for. Use of the REST API with the generated keys will conform to that user's WordPress roles and capabilities.</li><li>Choose the level of access for this REST API key, which can be Read access, Write access or Read/Write access. Then click the 'Generate API Key' button and WooCommerce will generate REST API keys for the selected user.</li><li>Now that keys have been generated, you should see two new keys, a QRCode, and a Revoke API Key button. These two keys are your Consumer Key and Consumer Secret.</li></ol>",
  },
  consumer_secret: {
    title: "How to get the Consumer Key and Consumer Secret",
    description:
      "<ol><li>Log in to the wordpress dashboard (make sure to login to the correct website specified in the URL).</li><li>Go to WooCommerce > Settings > Advanced (Tab) > REST API. Click the 'Add Key' button</li><li>In the next screen, add a description and select the WordPress user you would like to generate the key for. Use of the REST API with the generated keys will conform to that user's WordPress roles and capabilities.</li><li>Choose the level of access for this REST API key, which can be Read access, Write access or Read/Write access. Then click the 'Generate API Key' button and WooCommerce will generate REST API keys for the selected user.</li><li>Now that keys have been generated, you should see two new keys, a QRCode, and a Revoke API Key button. These two keys are your Consumer Key and Consumer Secret.</li></ol>",
  },
};
