/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { styled } from "@mui/material/styles";

import { EditorState, ContentState, convertFromHTML, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";

import { convertToHTML } from "draft-convert";

import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Block } from "notiflix/build/notiflix-block-aio";
import { Confirm } from "notiflix/build/notiflix-confirm-aio";
import { Notify } from "notiflix/build/notiflix-notify-aio";

// @mui material components
import Box from "@mui/material/Box";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { AddBox } from "@mui/icons-material";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";

import ControlledTextInput from "components/FormInputs/ControlledTextInput";
import ControlledDateTime from "components/FormInputs/ControlledDateTime";
import ControlledCheckbox from "components/FormInputs/ControlledCheckbox";
import ControlledSelect from "components/FormInputs/ControlledSelect";
import CustomInputs from "components/CustomInputs";
import { CustomSelect } from "components/CustomSelect";

//components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import { customReminderSchema } from "../schemas/_model";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import InputAdornment from "@mui/material/InputAdornment";

import Tooltip from "@mui/material/Tooltip";

import Edit from "@mui/icons-material/Edit";
import Close from "@mui/icons-material/Close";

import Alert from "components/CustomAlert";

// rtk queries
import { usePostCustomReminderMutation } from "services/reminders";

import "../assets/styles.css";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function AddCustomReminder({ reminderTypes, country }) {
  const [openAddDialog, setOpenAddDialog] = useState(false);

  const initialFormState = {
    company_name: "",
    product_name: "",
    additional_notes: "",
    grand_total: 0,
    reseller_email: "",
    expiry_date: "",
    reminderTypeIds: [],
  };

  const [postCustomerReminder] = usePostCustomReminderMutation();

  const { control, watch, handleSubmit, setValue, reset } = useForm({
    resolver: yupResolver(customReminderSchema),
    defaultValues: initialFormState,
  });

  const { fields, append, remove } = useFieldArray({ name: "digitalKeys", control });

  const reminderTypeIds = watch("reminderTypeIds");

  const handleClickOpen = () => {
    setOpenAddDialog(true);
  };
  const handleClose = () => {
    setOpenAddDialog(false);
  };

  const addDigitalKeyFields = () => {
    append("");
  };

  const removeDigitalKeyFields = (index) => {
    remove(index);
  };

  const onSubmit = (data) => {
    data.country_id = country;

    if (fields.length == 0) {
      Notify.failure("You must add atleast one digital key.");
      return;
    }

    let digitalKeys = data.digitalKeys;
    delete data.digitalKeys;

    let reminderTypes = [];

    if (data.reminderTypeIds && data.reminderTypeIds.length > 0) {
      for (let type of data.reminderTypeIds) {
        reminderTypes.push({ reminder_type_id: type });
      }
    }

    delete data.reminderTypeIds;

    Loading.dots({
      svgColor: "rgba(159, 3, 3, 0.8)",
    });

    try {
      postCustomerReminder({ reminder: data, digitalKeys: digitalKeys, reminderTypeIds: reminderTypes }).then((response) => {
        if (response.data?.status == "success") {
          Notify.success("Succefully Added!");
          handleClose();
        } else {
          if (response.error?.data) {
            Notify.failure(response.error.data.message);
          } else {
            Notify.failure("Something went wrong, Please try again!");
          }
        }
      });
    } catch (e) {
    } finally {
      Loading.remove(500);
      reset();
      remove();
      handleClose();
    }
  };

  const removeReminderType = (id) => {
    let filteredReminderTypeIds = reminderTypeIds.filter((item) => item != id);
    setValue("reminderTypeIds", filteredReminderTypeIds);
  };

  return (
    <>
      <MDButton variant="gradient" color="primary" sx={{ ml: 2 }} onClick={handleClickOpen}>
        Add New
      </MDButton>
      <BootstrapDialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        maxWidth="xl"
        sx={{
          "& .MuiPaper-root": {
            width: "650px",
          },
        }}
        open={openAddDialog}
      >
        <DialogTitle sx={{ m: 0, p: 2, color: "#3f3f3f" }} id="customized-dialog-title">
          Add Custom Reminder
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <br />
          <form>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                <ControlledTextInput
                  name="company_name"
                  control={control}
                  labelText="Company name *"
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                <ControlledTextInput
                  name="product_name"
                  control={control}
                  labelText="Product name *"
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                <ControlledTextInput
                  name="reseller_email"
                  control={control}
                  labelText="Reseller email *"
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                <ControlledTextInput
                  name="grand_total"
                  control={control}
                  labelText="Grand total *"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "number",
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                <ControlledDateTime
                  label="Expiry Date"
                  name="expiry_date"
                  control={control}
                  inputProps={{
                    placeholder: "Pick a Date",
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12}>
                <Box
                  px={2}
                  pb={2}
                  p1={1}
                  sx={{ border: "0.0625rem solid #f0f2f5", borderRadius: "0.375rem", mb: 2 }}
                >
                  <Box display="flex" justifyContent="space-between" my={1}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 500,
                        color: "#3f3f3f",
                      }}
                    >
                      Digital Keys
                    </Typography>
                    <MDButton
                      color="info"
                      variant="gradient"
                      iconOnly
                      onClick={addDigitalKeyFields}
                    >
                      <AddBox sx={{}} />
                    </MDButton>
                  </Box>
                  <br />
                  {fields.map((input, index) => {
                    return (
                      <GridContainer key={index}>
                        <GridItem xs={12} sm={12} md={10} lg={10} xl={10}>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                              <ControlledTextInput
                                name={`digitalKeys.${index}`}
                                control={control}
                                labelText="Key *"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />
                              {/* <CustomInputs
                                name="option"
                                id="option"
                                label="Option *"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                value={input.option}
                                onChange={(e) => handleAttribeFields(index, e)}
                              /> */}
                            </GridItem>
                          </GridContainer>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={2} lg={2} xl={2}>
                          <MDButton
                            color="warning"
                            variant="gradient"
                            iconOnly
                            onClick={() => removeDigitalKeyFields(index)}
                            // disabled={fields && fields.length > 1 ? false : true}
                          >
                            <IndeterminateCheckBoxIcon />
                          </MDButton>
                        </GridItem>
                      </GridContainer>
                    );
                  })}
                </Box>
              </GridItem>

              <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                <MDBox sx={{ mb: 1.5 }}>
                  {reminderTypeIds &&
                    reminderTypeIds.length > 0 &&
                    reminderTypeIds.map((type) => (
                      <Chip
                        key={type}
                        sx={{
                          height: "26px",
                          mr: 1,
                          mb: "5px",
                          "& .MuiChip-label": { fontSize: "0.65rem" },
                          "& .MuiSvgIcon-root": { marginTop: "-1px" },
                        }}
                        label={reminderTypes.filter((item) => item.id == type)[0].name}
                        onDelete={() => removeReminderType(type)}
                      />
                    ))}
                </MDBox>
                <ControlledSelect
                  multiple
                  name={"reminderTypeIds"}
                  control={control}
                  label="Select reminder types *"
                  menu={{
                    id: "id",
                    value: "name",
                    title: "Reminder type",
                    list: reminderTypes,
                  }}
                  sx={{
                    "& .MuiFormLabel-root": {
                      backgroundColor: "#fff",
                    },
                    "& .MuiInputBase-root": {
                      height: "44px",
                    },
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                <ControlledTextInput
                  multiline
                  name="additional_notes"
                  control={control}
                  labelText="Additional notes"
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>
            </GridContainer>
          </form>
        </DialogContent>
        <DialogActions>
          <MDButton
            type="submit"
            color="primary"
            variant="gradient"
            id="submit"
            onClick={handleSubmit(onSubmit)}
          >
            Add Reminder
          </MDButton>
          <MDButton color="secondary" variant="gradient" autoFocus onClick={handleClose}>
            Cancel
          </MDButton>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}

export default AddCustomReminder;
