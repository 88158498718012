/* eslint-disable react/prop-types */
import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";

import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";

import { styled } from "@mui/material/styles";

import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import MDButton from "components/MDButton";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import CloseIcon from "@mui/icons-material/Close";

import MDBadge from "components/MDBadge";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import CircularProgress from "@mui/material/CircularProgress";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';

import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Notify } from "notiflix/build/notiflix-notify-aio";
import { Confirm } from "notiflix/build/notiflix-confirm-aio";

import Alert from "components/CustomAlert";

import { dateFormat } from "utils/constants";

import { useUploadPaymentReceiptMutation, useDeletePaymentReceiptMutation } from "services/orders";

import { useUploadFileMutation, useDeleteFileMutation } from "services/files";

import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

registerPlugin(FilePondPluginImagePreview);

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

import MDBox from "components/MDBox";

const BankReceipts = ({ orderId, bankRecieptPath }) => {
  const [openReceiptDialog, setOpenReceiptDialog] = useState(false);

  const [receipt, setReceipt] = useState({});

  const [uploadFile, { isLoading: isFileUploading }] = useUploadFileMutation();

  const [uploadPaymentReceipt, { isLoading: isPaymentReceiptUploading }] = useUploadPaymentReceiptMutation();

  const [deletePaymentReceipt, { isLoading: isPaymentReceiptDeleting }] = useDeletePaymentReceiptMutation();

  // const [deletePaymentReceipt] = useDeleteFileMutation();

  const handleDialogOpen = () => {
    setOpenReceiptDialog(true);
  };

  const handleDialogClose = () => {
    setOpenReceiptDialog(false);
  };

  const [file, setFile] = useState([]);

  const handleUpdateFiles = (files) => {
    if (files.length > 0) {
      const photo = files[0].source;
      setFile(photo);
    } else {
      setFile([]);
    }
  };

  function handleSubmit(event) {
    event.preventDefault();
    if (file.length != 0) {
      let formData = new FormData();
      formData.append("file", file);
      uploadFile({ formData: formData, directory: "reciepts" }).then((response) => {
        console.log(response.data.response.path);
        if (response.data?.status == "success") {
          let path = response.data.response.path;
          uploadPaymentReceipt({ order_id: orderId, path: path.replace(/\\/g, "/") }).then(
            (response) => {
              if (response.data?.status == "success") Notify.success("Succefully Uploaded!");
            }
          );
        }
      });
    } else {
      Notify.failure("Please select a file");
    }
  }

  const handleDeletePaymentReciept = (data) => {
    deletePaymentReceipt(data).then(response => {
      if(response.data) {
        Notify.success("Succefully deleted!");
      } else {
        Notify.failure("Something went wrong!");
      }
    });
  }

  useEffect(() => {
    console.log(bankRecieptPath);
    if (bankRecieptPath != "") {
      let fileExtension = bankRecieptPath.split(".").pop().toLowerCase();
      if (fileExtension == "pdf") {
        setReceipt({
          path: bankRecieptPath,
          file_type: "pdf",
        });
      } else if (["jpg", "jpeg", "png"].includes(fileExtension)) {
        setReceipt({
          path: bankRecieptPath,
          file_type: "img",
        });
      } else if (["doc", "docx"].includes(fileExtension)) {
        const googleDocsViewerURL = `https://docs.google.com/gview?url=${encodeURIComponent(
          process.env.REACT_APP_URL + "/" + bankRecieptPath
        )}&embedded=true`;
        setReceipt({
          path: googleDocsViewerURL,
          file_type: "doc",
        });
      } else {
        setReceipt({
          path: bankRecieptPath,
          file_type: "img",
        });
      }
    }
  }, [bankRecieptPath]);

  return (
    <>
      <Tooltip title="View Bank Receipts" placement="top">
        <MDButton color="info" variant="gradient" onClick={handleDialogOpen}>
          Bank Receipts
        </MDButton>
      </Tooltip>
      <BootstrapDialog
        // onClose={handleDialogClose}
        aria-labelledby="customized-dialog-title"
        maxWidth="lg"
        sx={{
          "& .MuiPaper-root": {
            width: "900px",
          },
          "& .MuiPaper-root.MuiAlert-root": {
            width: "auto",
          },
        }}
        open={openReceiptDialog}
      >
        <DialogTitle sx={{ m: 0, p: 2, color: "#4b4b4f" }} id="customized-dialog-title">
          Bank receipts of #{orderId}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleDialogClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {receipt.path != undefined ? (
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                {receipt.file_type == "pdf" ? (
                  <MDBox sx={{ position: 'relative', zIndex: 0 }}>
                    <MDBox
                      mb={2}
                      component="iframe"
                      src={process.env.REACT_APP_URL + "/" + receipt.path}
                      sx={{ width: "100%", height: 700, position: 'relative', mt: 3 }}
                    />
                    <Tooltip title="Delete payment proof" placement="top">
                       <IconButton  sx={{ position: 'absolute', zIndex: 1, right: 0, top: 0 }} aria-label="view" size="small" onClick={() => {
                        Confirm.show(
                          `Please Confirm`,
                          "Are you sure you want to delete this payment proof?",
                          "Yes",
                          "No",
                          () => {
                            handleDeletePaymentReciept({ orderId, bankRecieptPath });
                          },
                          () => {},
                          {}
                        );
                      }}>
                        <HighlightOffRoundedIcon color="error" fontSize="inherit" />
                      </IconButton>
                    </Tooltip>
                  </MDBox>
                ) : receipt.file_type == "img" ? (
                  <MDBox sx={{ position: 'relative', zIndex: 0 }}>
                    <MDBox
                      mb={2}
                      component="img"
                      src={process.env.REACT_APP_URL + "/" + receipt.path}
                      sx={{ width: "100%", position: 'relative', mt: 3 }}
                    />
                    <Tooltip title="Delete payment proof" placement="top">
                      <IconButton  sx={{ position: 'absolute', zIndex: 1, right: 0, top: 0 }} aria-label="view" size="small" onClick={() => {
                        Confirm.show(
                          `Please Confirm`,
                          "Are you sure you want to delete this payment proof?",
                          "Yes",
                          "No",
                          () => {
                            handleDeletePaymentReciept({ orderId, bankRecieptPath });
                          },
                          () => {},
                          {}
                        );
                      }}>
                        <HighlightOffRoundedIcon color="error" fontSize="inherit" />
                      </IconButton>
                    </Tooltip>
                  </MDBox>
                ) : receipt.file_type == "doc" ? (
                  <MDBox sx={{ position: 'relative', zIndex: 0 }}>
                    <MDBox
                      mb={2}
                      component="iframe"
                      src={googleDocsViewerURL}
                      sx={{ width: "100%", height: 700, position: 'relative', mt: 3 }}
                    />
                    <Tooltip title="Delete payment proof" placement="top">
                      <IconButton  sx={{ position: 'absolute', zIndex: 1, right: 0, top: 0 }} aria-label="view" size="small" onClick={() => {
                        Confirm.show(
                          `Please Confirm`,
                          "Are you sure you want to delete this payment proof?",
                          "Yes",
                          "No",
                          () => {
                            handleDeletePaymentReciept({ orderId, bankRecieptPath });
                          },
                          () => {},
                          {}
                        );
                      }}>
                        <HighlightOffRoundedIcon color="error" fontSize="inherit" />
                      </IconButton>
                    </Tooltip>
                  </MDBox>
                ) : (
                  <MDBox sx={{ position: 'relative', zIndex: 0 }}>
                    <MDBox
                      mb={2}
                      component="img"
                      src={process.env.REACT_APP_URL + "/" + receipt.path}
                      sx={{ width: "100%", position: 'relative', mt: 3 }}
                    />
                    <Tooltip title="Delete payment proof" placement="top">
                      <IconButton  sx={{ position: 'absolute', zIndex: 1, right: 0, top: 0 }} aria-label="view" size="small" onClick={() => {
                        Confirm.show(
                          `Please Confirm`,
                          "Are you sure you want to delete this payment proof?",
                          "Yes",
                          "No",
                          () => {
                            handleDeletePaymentReciept({ orderId, bankRecieptPath });
                          },
                          () => {},
                          {}
                        );
                      }}>
                        <HighlightOffRoundedIcon color="error" fontSize="inherit" />
                      </IconButton>
                    </Tooltip>
                  </MDBox>
                  
                )}
              </GridItem>
            </GridContainer>
          ) : (
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                <Alert severity="warning">
                  There are no receipts found. Please upload a receipt
                </Alert>
              </GridItem>
              <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                {/* <form onSubmit={handleSubmit}>
                  <input type="file" onChange={handleChange} />
                  <button type="submit">Upload</button>
                </form> */}
                {/* <button onClick={handleDelete}>Delete</button> */}
                <>
                  <Box
                    component={FilePond}
                    sx={{
                      fontFamily: '"Poppins", "Roboto", "Helvetica", "Arial", sans-serif',
                      "& .filepond--file-action-button": {
                        cursor: "pointer",
                      },
                      "& .filepond--drop-label.filepond--drop-label label": {
                        fontSize: "13px",
                        letterSpacing: "0.5px",
                        fontWeight: "600",
                      },
                    }}
                    instantUpload={false}
                    allowFileSizeValidation={true}
                    name="file"
                    allowFileTypeValidation={true}
                    acceptedFileTypes={[
                      "image/jpeg",
                      "image/jpg",
                      "image/png",
                      "application/msword", // for .doc
                      "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // for .docx
                      "application/pdf",
                    ]}
                    allowMultiple={false}
                    allowFilePoster={false}
                    labelIdle='Drag & Drop file or <span class="filepond--label-action">Browse</span>'
                    onupdatefiles={handleUpdateFiles}
                    allowRevert={false}
                  />
                  {file.length != 0 && (
                    <Box sx={{ textAlign: "center" }}>
                      <MDButton
                        // component="label"
                        color="primary"
                        variant="gradient"
                        onClick={handleSubmit}
                        startIcon={isFileUploading ? <CircularProgress /> : <CloudUploadIcon />}
                        sx={{ color: "#fff" }}
                      >
                        Upload
                      </MDButton>
                    </Box>
                  )}
                </>
              </GridItem>
            </GridContainer>
          )}
        </DialogContent>
        <DialogActions>
          <MDButton color="secondary" variant="gradient" autoFocus onClick={handleDialogClose}>
            Cancel
          </MDButton>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

BankReceipts.propTypes = {
  orderId: PropTypes.number.isRequired,
  bankRecieptPath: PropTypes.string,
};

export default BankReceipts;
