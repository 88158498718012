import * as yup from "yup";

export const customReminderSchema = yup.object().shape({
  company_name: yup.string().required().default("").label("Company name"),
  product_name: yup.string().required().default("").label("Product name"),
  additional_notes: yup.string().default("").label("Additional notes"),
  grand_total: yup.number().min(1).default("").label("Grand total"),
  reseller_email: yup.string().email().required().default("").label("Email"),
  expiry_date: yup.string().required().default("").label("Expiry date"),
  digitalKeys: yup.array().of(yup.string().required("Key is required")),
  reminderTypeIds: yup.array().of(yup.number()).required().min(1).label("One or more reminder type")
  // terms_and_conditions: yup.string().label("Terms and Condition"),
});
