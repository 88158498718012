/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import axios from "axios";

import { Importer, ImporterField } from "react-csv-importer";

import { FilePond, registerPlugin } from "react-filepond";

import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { styled } from "@mui/material/styles";

// @mui material components
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import MDButton from "components/MDButton";

import { AddBox } from "@mui/icons-material";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";

import TextField from "@mui/material/TextField";

import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Notify } from "notiflix/build/notiflix-notify-aio";

import MDTypography from "components/MDTypography";

import Typography from "@mui/material/Typography";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import ControlledTextInput from "components/FormInputs/ControlledTextInput";
import ControlledCheckbox from "components/FormInputs/ControlledCheckbox";
import CustomInputs from "components/CustomInputs";
import ControlledSelect from "components/FormInputs/ControlledSelect";

//components
import MDBox from "components/MDBox";
import MDAvatar from "components/MDAvatar";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import Tooltip from "@mui/material/Tooltip";

import Box from "@mui/material/Box";

import DialogActions from "@mui/material/DialogActions";

// theme CSS for React CSV Importer
import "react-csv-importer/dist/index.css";

// queries
import {
  useGetAllResellerProductsByCountryIdQuery,
  usePostProductMutation,
} from "services/products";

import { replaceSingleQuoteToSQLFriendly, dataBASE64URLtoFile } from "utils/helperFunctions";

// import { useGetAllProductsFromStoreUrlQuery } from "services/store";

import { globalCountry } from "store/entities/countries";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const AddAttributeDialog = ({
  addAttributeFields,
  openAddAttributeDialog,
  setOpenAddAttributeDialog,
  distinctAttributes,
  existingAttributes,
}) => {
  return (
    <>
      <BootstrapDialog
        onClose={() => setOpenAddAttributeDialog(false)}
        aria-labelledby="customized-dialog-title"
        maxWidth="lg"
        sx={{
          "& .MuiPaper-root": {
            width: "450px",
          },
        }}
        open={openAddAttributeDialog}
      >
        <DialogTitle sx={{ m: 0, p: 2, color: "#4b4b4f" }} id="customized-dialog-title">
          Select Attribute
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setOpenAddAttributeDialog(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {distinctAttributes &&
            distinctAttributes.length > 0 &&
            distinctAttributes
              .filter((attribute) => {
                return !existingAttributes.some((field) => field.attribute === attribute);
              })
              .map((input, index) => {
                return (
                  <MDButton
                    key={index}
                    sx={{ mr: 1, mb: 1 }}
                    color="info"
                    variant="gradient"
                    onClick={() => {
                      addAttributeFields(input);
                      setOpenAddAttributeDialog(false);
                    }}
                  >
                    {input}
                  </MDButton>
                );
              })}
          <MDButton
            sx={{ mr: 1, mb: 1 }}
            color="secondary"
            variant="gradient"
            onClick={() => {
              addAttributeFields();
              setOpenAddAttributeDialog(false);
            }}
          >
            New
          </MDButton>
          <br />
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};

export default AddAttributeDialog;
