/* eslint-disable react/prop-types */
import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";

import moment from "moment";

import { styled } from "@mui/material/styles";

import PropTypes from "prop-types";

import MDButton from "components/MDButton";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import KeyIcon from "@mui/icons-material/Key";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";

import MDBadge from "components/MDBadge";
import DetailBlock from "components/DetailBlock";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";

import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

import DataTable from "examples/Tables/DataTable/DisabledState";

import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Notify } from "notiflix/build/notiflix-notify-aio";
import { Confirm } from "notiflix/build/notiflix-confirm-aio";

import Alert from "components/CustomAlert";
import { Block } from "notiflix/build/notiflix-block-aio";

import AssignedUsers from '../AssignedUsers'

import { dateFormat, dateTimeFormat } from "utils/constants";

import {
  useLazyGetEndUserByDigitalKeyQuery,
  useDeleteEndUserToDigitalKeyMutation,
} from "services/digitalKeys";

import {
  useLazyGetAllReminderTypesQuery
} from "services/reminders";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

import MDBox from "components/MDBox";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ViewReminder = ({ data, reminderTypes, country }) => {
  const [openViewReminderDialog, setOpenViewReminderDialog] = useState(false);

  const handleDialogOpen = () => {
    setOpenViewReminderDialog(true);
  };

  const handleDialogClose = () => {
    setOpenViewReminderDialog(false);
  };

  return (
    <>
      <Tooltip title="View details" placement="top">
        <IconButton aria-label="view" size="small" onClick={handleDialogOpen}>
          <VisibilityIcon color="info" fontSize="inherit" />
        </IconButton>
      </Tooltip>
      <BootstrapDialog
        // onClose={handleDialogClose}
        aria-labelledby="customized-dialog-title"
        maxWidth="lg"
        sx={{
          "& .MuiDialog-paper": {
            width: "850px",
          },
        }}
        open={openViewReminderDialog}
      >
        <DialogTitle sx={{ m: 0, p: 2, color: "#4b4b4f" }} id="customized-dialog-title">
          View reminder details
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleDialogClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {data ? <GridContainer>
            {(data.reseller_email) ? (
              <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                <DetailBlock blockTitle="Reseller" blockDesc={data.reseller_email} />
              </GridItem>
            ) : ""}
            {(data.product_name) ? (
              <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                <DetailBlock blockTitle="Product" blockDesc={data.product_name} />
              </GridItem>
            ) : ""}
            {data.subscription_expiry_date ? (
              <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                <DetailBlock blockTitle="Expiry date" blockDesc={moment(data.subscription_expiry_date).format(dateFormat)} />
              </GridItem>
            ) : ""}
            {data.triggered_time ? (
              <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                <DetailBlock blockTitle="Triggered Time" blockDesc={moment(data.triggered_time).format(dateTimeFormat)} />
              </GridItem>
            ) : ""}
            <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
              <DetailBlock
                blockTitle="Type"
                blockDesc={data.custom_reminder_id ? <MDBadge badgeContent="Custom" color="success" variant="gradient" size="sm" /> : data.end_user_id ? <MDBox display="flex" alignItems="center" justifyContent="space-between"><MDBadge badgeContent="End-User reminder" color="info" variant="gradient" size="sm" /><AssignedUsers data={data.digital_key_end_users} /></MDBox> : <MDBadge badgeContent="Class A" color="primary" variant="gradient" size="sm" />
                }
              />
            </GridItem>
            {data.reminder_type_id ? (
              <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                <DetailBlock blockTitle="Reminder type" blockDesc={reminderTypes.filter(type => type.id == data.reminder_type_id)[0].name} />
              </GridItem>
            ) : ""}
          </GridContainer> : <Alert severity="warning" sx={{ display: 'flex' }}>Data not available!</Alert>}
        </DialogContent>
        <DialogActions>
          <MDButton color="secondary" variant="gradient" autoFocus onClick={handleDialogClose}>
            Cancel
          </MDButton>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

ViewReminder.propTypes = {
  orderProductId: PropTypes.number.isRequired,
};

export default ViewReminder;
