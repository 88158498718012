import React, { useState, useEffect, createRef } from "react";
import { Switch, Route, Redirect  } from "react-router-dom";

import { authRoutes } from "routes/auth";

const Auth = () => {

    const getRoutes = routes => {
      return routes.map((prop, key) => {
        return (
            <Route
              path={prop.route}
              component={prop.component}
              key={key}
            />
          );
      });
    };

      return (
        <>
            <Switch>
                {getRoutes(authRoutes)}
                <Route path="/auth" render={() => <Redirect to="/auth/login" />} />
                {/* <Redirect from="/auth" to="/auth/login-page" /> */}
            </Switch>
        </>
      );
  
  }
  
  export default Auth;