import { apis } from "./base";

const filesService = apis.injectEndpoints({
  endpoints: (builder) => ({
    uploadFile: builder.mutation({
      query: (data) => ({
        url: `/files/upload?dir=${data.directory}`,
        method: "POST",
        body: data.formData,
      }),
      // transformResponse: (response) => response.response
    }),

    deleteFile: builder.mutation({
        query: (filePath) => ({
          url: "/files/delete",
          method: "DELETE",
          body: {
            path: filePath, // Ex. (uploads/1345556.png)
          },
        }),
    }),

    // getRefundHistoryOrderId: builder.query({
    //   query: (order_id) => {
    //     return `/admin/payments/refund-history/${order_id}`;
    //   },
    //   transformResponse: (response) => response.response,
    //   // providesTags: ["OrderProducts"],
    // }),
  }),
});

export const {
  useUploadFileMutation,
  useDeleteFileMutation
} = filesService;
