import { apis } from "./base";

const resellersService = apis.injectEndpoints({
  endpoints: (builder) => ({
    getResellersByCompanyId: builder.query({
      query: (company_id) => {
        return `/admin/companies/resellers/all/${company_id}`;
      },
      transformResponse: (response) => response.response,
      providesTags: ["Resellers"],
    }),

    postReseller: builder.mutation({
      query: ({company_id, data}) => ({
        url: `/admin/companies/resellers/new/${company_id}`,
        method: "POST",
        body: data,
      }),
      // transformResponse: (response) => response.response
      invalidatesTags: ["Resellers"],
    }),
    updateReseller: builder.mutation({
      query: ({ reseller_id, data }) => ({
        url: `/admin/companies/resellers/update/${reseller_id}`,
        method: "PUT",
        body: data,
      }),
      // transformResponse: (response) => response.response
      invalidatesTags: ["Resellers"],
    }),
    deleteReseller: builder.mutation({
      query: ({company_id, reseller_id}) => ({
        url: `/admin/companies/resellers/delete/${company_id}/${reseller_id}`,
        method: "DELETE",
      }),
      // transformResponse: (response) => response.response,
      invalidatesTags: ["Resellers"],
    }),

    requestPasswordReset: builder.mutation({
      query: (data) => ({
        url: `/reseller/request-reset-password`,
        method: "POST",
        body: data,
      }),
      // transformResponse: (response) => response.response
      // invalidatesTags: ["Products"],
    }),

    changeProfileImage: builder.mutation({
      query: ({resellerId, data}) => ({
        url: `/admin/companies/resellers/avatar/upload/${resellerId}`,
        method: "POST",
        body: data,
      }),
      // transformResponse: (response) => response.response
      invalidatesTags: ["Resellers"],
    }),
  }),
});

export const {
  useLazyGetResellersByCompanyIdQuery,
  usePostResellerMutation,
  useUpdateResellerMutation,
  useDeleteResellerMutation,
  useRequestPasswordResetMutation,
  useChangeProfileImageMutation
} = resellersService;
