import * as yup from "yup";

export const categorySchema = yup.object().shape({
  name: yup.string().required().label("Custom category name"),
  active: yup.bool().label("Show status"),
  // terms_and_conditions: yup.string().label("Terms and Condition"),
});

export const postCategorySchema = yup.object().shape({
  name: yup.string().required().label("Custom category name"),
  active: yup.bool().label("Show status"),
  isParent: yup.bool().label("Is parent"),
  parentId: yup.number().when(["isParent"], {
    is: (isParent) => isParent === false,
    then: () => yup.number().min(1).required(),
    otherwise: () => yup.number().min(0).notRequired()
  }).label("Parent category"),
  // terms_and_conditions: yup.string().label("Terms and Condition"),
});
