/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from "react";

import { useSelector, useDispatch } from "react-redux";

import { styled } from "@mui/material/styles";

import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Notify } from "notiflix/build/notiflix-notify-aio";

import PropTypes from "prop-types";

import moment from "moment";

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import MDButton from "components/MDButton";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import MDBadge from "components/MDBadge";

import MDTypography from "components/MDTypography";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import Tooltip from "@mui/material/Tooltip";
import VisibilityIcon from "@mui/icons-material/Visibility";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import DataTable from "examples/Tables/DataTable";

import Alert from "components/CustomAlert";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import MDBox from "components/MDBox";

import CustomTabs from "components/CustomTabs";

import { useLazyGetAllBDMQuery } from "services/bdm";

import { useVerifyCompanyMutation } from "services/companies";

import { globalCountry } from "store/entities/countries";

import { dateFormat } from "utils/constants";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const BDMDialog = ({ openBDMSelect, setOpenBDMSelect, BDMList, companyDetails }) => {

	const selectedCountry = useSelector(globalCountry);

  // const { data: BDMList = [], isLoading, isSuccess } = useGetAllBDMQuery();

  const [changeCompanyStatus, { isLoading: isCompanyVerifing }] = useVerifyCompanyMutation();

  const [triggerAllBDMUsers, { isLoading, isSuccess }] =
  	useLazyGetAllBDMQuery({
      // pollingInterval: 5000,
    });

  const handleClickOpen = () => {
    setOpenBDMSelect(true);
    // useLazyGetAllBDMQuery();
  };
  const handleClose = () => {
    setOpenBDMSelect(false);
  };

  useEffect(() => {
    isLoading
      ? Loading.dots({
          svgColor: "rgba(159, 3, 3, 0.8)",
        })
      : Loading.remove(500);
  }, [isLoading]);

  const assignBDM = (BDMId) => {
    if(companyDetails.id) {
      Loading.dots({
        svgColor: "rgba(159, 3, 3, 0.8)",
      });

      try {
        changeCompanyStatus({ company_Id: companyDetails.id, status: "ACTIVE", note: "", assignedBdmId: BDMId }).then((response) => {
          if (response.data?.status == "success") {
            Notify.success("Successfully verified!");
            handleClose();
          }          
        });

      } catch (e) {
      } finally {
        Loading.remove(500);
      }
    }
  }

  return (
    <>
      <BootstrapDialog
          // onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          maxWidth="xl"
          sx={{
            "& .MuiPaper-root": {
              width: "950px",
            },
          }}
          open={openBDMSelect}
        >
          <DialogTitle sx={{ m: 0, p: 2, color: "#3f3f3f" }} id="customized-dialog-title">
            Select a BDM in order to assign to the company
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            {BDMList && BDMList.length > 0 ? (
              <DataTable
                columns={[
                  { 
                    Header: "email", 
                    accessor: "email",
                  },
                  { 
                    Header: "name", 
                    accessor: "name", 
                    Cell: ({ row: { original } }) => <>{original.first_name + ' ' + original.last_name}</>
                  },
                  { 
                    Header: "created date", 
                    accessor: "created_date",
                    Cell: ({ row: { original } }) => moment(original.datetime).format(dateFormat)
                  },
                  { 
                    Header: "total sales", 
                    accessor: "total_sales",
                    // Cell: ({ row: { original } }) => {selectedCountry.currency + original.total_sales.toFixed(2)}
                    Cell: ({ row: { original } }) => selectedCountry.currency + ' ' + original.total_sales.toFixed(2)
                  },
                  {
                    Header: "Actions",
                    accessor: "actions",
                    disableSortBy: true,
                    disableFilters: true,
                    show: true,
                    align: "right",
                    // eslint-disable-next-line react/display-name
                    Cell: ({ row: { original } }) => (
                      <div className="actions-right">
                        <Tooltip title="Assign" sx={{ ml: 1 }} placement="top">
                          <IconButton aria-label="view" size="small" onClick={() => assignBDM(original.id)}>
                            <ArrowForwardIcon color="warning" fontSize="inherit" />
                          </IconButton>
                        </Tooltip>
                      </div>
                    ),
                  },
                ]}
                data={BDMList || []}
                isSorted={false}
                entriesPerPage={false}
                showTotalEntries={false}
                hideColumnSelection={true}
                noEndBorder
                canSearch
                searchOnly={true}
                showPageSize={false}
                // disableTableHeader={true}
              />
            ) : (
              <Alert severity="warning">No Business Developement Managers found!</Alert>
            )}
          </DialogContent>
          <DialogActions>
            <MDButton color="secondary" variant="gradient" autoFocus onClick={handleClose}>
              Cancel
            </MDButton>
          </DialogActions>
        </BootstrapDialog>
    </>
  );
};

BDMDialog.propTypes = {
  // children: PropTypes.node.isRequired, // You can also use PropTypes.element if you want to enforce a single element.
  details: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
};

export default BDMDialog;
