/* eslint-disable react/prop-types */
import React, { useEffect } from "react";

// imports
import { useFieldArray } from "react-hook-form";

// @mui
import { Button } from "@mui/material";

// mui icons
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";

// custom components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import MDButton from "components/MDButton";
import ControlledTextInput from "components/FormInputs/ControlledTextInput";
import ControlledCheckbox from "components/FormInputs/ControlledCheckbox";

export default function EmailRecipients(props) {

  const { fields, append, remove } = useFieldArray({ name: "recipients", control: props.control });

  const recipientsField = props.watch("recipients");

  const addFields = () => {
    append({ email: "", isBcc: false });
  };

  const removeFields = (index) => {
    remove(index);
  };

  useEffect(() => {
    if(recipientsField && recipientsField.length > 0) {
      props.setRecipients(recipientsField);
    } else {
      props.setRecipients([]);
    }

  }, [recipientsField])

  useEffect(() => {
    if(props.initData && props.initData.length > 0) {
      if(fields.length > 0) {
        fields.forEach((item, index) => {
          remove(index)
        })
      }
      let recipients = props.initData.map((item) => ({
        ...item,
        isBcc: item.is_bcc
    }));
      for(let recipient of recipients) {
        delete recipient.is_bcc;
      }
      props.setValue("recipients", recipients);
    }
  }, [props.initData])

  return (
    <>
      <Button onClick={addFields} sx={{ mb: 1 }}>Add New Recipient</Button>
      {fields.map((input, index) => {
        return (
          <GridContainer key={index} sx={{ px: 2 }}>
            <GridItem xs={12} sm={12} md={5} lg={5} xl={5}>
              <ControlledTextInput
                name={`recipients.${index}.email`}
                control={props.control}
                labelText="Recipient Email *"
                formControlProps={{
                  fullWidth: true,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={3} lg={3} xl={3}>
              <ControlledCheckbox
                name={`recipients.${index}.isBcc`}
                control={props.control}
                label="is recipient BCC?"
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={3} lg={3} xl={3}>
              <MDButton
                color="warning"
                variant="gradient"
                iconOnly
                onClick={() => removeFields(index)}
              >
                <IndeterminateCheckBoxIcon />
              </MDButton>
            </GridItem>
          </GridContainer>
        );
      })}
    </>
  );
}
