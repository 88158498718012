/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { styled } from "@mui/material/styles";

import { EditorState, ContentState, convertFromHTML, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";

import { convertToHTML } from "draft-convert";

import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Block } from "notiflix/build/notiflix-block-aio";
import { Notify } from "notiflix/build/notiflix-notify-aio";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";

import TextField from "@mui/material/TextField";

import Box from "@mui/material/Box";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { AddBox } from "@mui/icons-material";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Typography from "@mui/material/Typography";

import ControlledTextInput from "components/FormInputs/ControlledTextInput";
import ControlledDateTime from "components/FormInputs/ControlledDateTime";
import ControlledCheckbox from "components/FormInputs/ControlledCheckbox";
import ControlledSelect from "components/FormInputs/ControlledSelect";
import CustomInputs from "components/CustomInputs";
import { CustomSelect } from "components/CustomSelect";

//components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

//example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
import MDBadge from "components/MDBadge";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

import { resellersSchema } from "../schemas/_model";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import CircularProgress from "@mui/material/CircularProgress";

import Tooltip from "@mui/material/Tooltip";

import Edit from "@mui/icons-material/Edit";
import Close from "@mui/icons-material/Close";

import Alert from "components/CustomAlert";
import MDAvatar from "components/MDAvatar";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";

// rtk queries
import { useGetAllStatesByCountryIdQuery } from "services/countries";

import { useUpdateResellerMutation, useChangeProfileImageMutation } from "services/resellers";

import { globalCountry } from "store/entities/countries";

import { imageSrcDecoder, checkFileType } from "utils/helperFunctions";

import noPreview from "assets/images/no-preview.png";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const AvatarPreview = ({ avatarName, src, children }) => {
  const [imageLoading, setImageLoading] = useState(true);

  return (
    <MDBox sx={{ display: 'inline-flex', position: 'relative', '& .MuiAvatar-root': { width: "100px", height: "100px" } }}>
      {imageLoading && (
        <CircularProgress
          color="primary"
          sx={{
            width: "30px",
            height: "30px",
          }}
          size="small"
        />
      )}
      <MDAvatar
        src={src}
        name={avatarName}
        onLoad={() => setImageLoading(false)}
        size="md"
      />
      <Edit color="warning" fontSize="inherit" sx={{ position: 'absolute', top: 0, right: 0 }} />
      {children}
    </MDBox>
  )
}

function EditReseller({ companyId, initData, existingResellersList, companyName }) {
  const isEdit = true;

  const selectedCountry = useSelector(globalCountry);

  const [updateReseller, { isLoading: isUpdatingResellerLoading }] = useUpdateResellerMutation();

  const [changeProfileImage] = useChangeProfileImageMutation();

  const {
    data: statesByCountryId = [],
    isLoading: isLoadingStates,
    isSuccess: isSuccessStates,
  } = useGetAllStatesByCountryIdQuery(selectedCountry.id);

  const initialFormState = {
    email: "",
    firstName: "",
    lastName: "",
    phone: "", 
    address1: "",
    address2: "",
    state: "",
    subCompanyName: "",
    memberId: ""
  };

  const { control, watch, handleSubmit, setValue } = useForm({
    resolver: yupResolver(resellersSchema(isEdit)),
    defaultValues: initialFormState,
  });

  const [openResellerEdit, setOpenResellerEdit] = useState(false);

  const handleClickOpen = () => {
    setOpenResellerEdit(true);
  };
  const handleClose = () => {
    setOpenResellerEdit(false);
  };

  async function handleChange(event, id) {
    if (checkFileType(event.target.files[0].type)) {
      Loading.dots({
        svgColor: "rgba(159, 3, 3, 0.8)",
      });

      if (id) {
        let formData = new FormData();
        formData.append("file", event.target.files[0]);
        let response = await changeProfileImage({
          resellerId: id,
          data: formData
        });
        if (response.data?.status == "success") {
          setTimeout(() => Notify.success("Succefully uploaded!"), [500]);
        } else {
          Notify.failure("Something went wrong!");
        }
      }
      Loading.remove(500);
    } else {
      Notify.failure("File type doesn't support! please select a different one.");
    }
  }

  const onSubmit = async (data) => {
    Loading.dots({
      svgColor: "rgba(159, 3, 3, 0.8)",
    });

    // if(data.email == initData.email) {
    //   delete data.email;
    // }

    if(!data.subCompanyName) {
      delete data.subCompanyName;
    }

    data.countryId = selectedCountry.id;

    // delete data.email;

    try {
      await updateReseller({ reseller_id: initData.id, data: data }).then((response) => {
        if (response.data?.status == "success") {
          Notify.success("Succefully Updated!");
          setOpenResellerEdit(false);
        } else {
          if(response.error?.data) {
            Notify.failure(response.error.data.message);
          } else {
            Notify.failure("Something went wrong, Please try again!");
          }
        }
      });
    } catch (e) {
    } finally {
      Loading.remove(500);
    }
  };

  useEffect(() => {
    if (initData) if (initData != {}) setValue("email", initData.email);
    setValue("firstName", initData.first_name);
    setValue("lastName", initData.last_name);
    setValue("phone", initData.phone);
    setValue("address1", initData.address1);
    setValue("address2", initData.address2);
    setValue("state", initData.state);
    initData.sub_company_name && setValue("subCompanyName", initData.sub_company_name);
    initData.member_id && setValue("memberId", initData.member_id);
  }, [initData]);

  return (
    <>
      <Tooltip title="Edit reseller" placement="top">
        <IconButton aria-label="view" size="small" onClick={() => handleClickOpen()}>
          <Edit color="warning" fontSize="inherit" />
        </IconButton>
      </Tooltip>
      <BootstrapDialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        maxWidth="xl"
        sx={{
          "& .MuiPaper-root": {
            width: "900px",
          },
        }}
        open={openResellerEdit}
      >
        <DialogTitle sx={{ m: 0, p: 2, color: "#3f3f3f" }} id="customized-dialog-title">
          Edit Reseller
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <MDBox align="center">
            <AvatarPreview avatarName={initData.first_name} src={initData.avatar ? imageSrcDecoder(initData.avatar) : noPreview}>
              <Tooltip title="Edit" placement="top">
                <MDBox component="label" variant="text" sx={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, cursor: 'pointer' }}>
                  <VisuallyHiddenInput
                    type="file"
                    onChange={(event) => handleChange(event, initData.id)}
                  />
                </MDBox>
              </Tooltip>
            </AvatarPreview>
          </MDBox>
          <br />
          <form>
            <GridContainer>
              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledTextInput
                  name="email"
                  control={control}
                  labelText="Email *"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "email",
                    // disabled: true,
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledTextInput
                  name="firstName"
                  control={control}
                  labelText="First Name *"
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledTextInput
                  name="lastName"
                  control={control}
                  labelText="Last Name *"
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledTextInput
                  name="phone"
                  control={control}
                  labelText="Phone *"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  // inputProps={{
                  //   type: "number",
                  // }}
                />
              </GridItem>

              {!initData.is_primary_user && <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledTextInput
                  name="subCompanyName"
                  control={control}
                  labelText="Sub company"
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>}

              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledTextInput
                  name="memberId"
                  control={control}
                  labelText="Member ID"
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledTextInput
                  name="address1"
                  control={control}
                  labelText="Address 1 *"
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledTextInput
                  name="address2"
                  control={control}
                  labelText="Address 2 *"
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledSelect
                  name={"state"}
                  control={control}
                  label="Select State *"
                  menu={{
                    id: "id",
                    value: "state_name",
                    title: "States",
                    list: statesByCountryId,
                  }}
                  sx={{
                    "& .MuiFormLabel-root": {
                      backgroundColor: "#fff",
                    },
                    "& .MuiInputBase-root": {
                      height: "44px",
                    },
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}></GridItem>
            </GridContainer>
          </form>
        </DialogContent>
        <DialogActions>
          <MDButton
            type="submit"
            color="primary"
            variant="gradient"
            id="submit"
            onClick={handleSubmit(onSubmit)}
          >
            Update Reseller
          </MDButton>
          <MDButton color="secondary" variant="gradient" autoFocus onClick={handleClose}>
            Cancel
          </MDButton>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}

export default EditReseller;
