/* eslint-disable react/prop-types */
import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";

import { styled } from "@mui/material/styles";

import { Notify } from "notiflix/build/notiflix-notify-aio";
import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Confirm } from "notiflix/build/notiflix-confirm-aio";

import PropTypes from "prop-types";

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import MDButton from "components/MDButton";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";

import MDBadge from "components/MDBadge";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import Button from "@mui/material/Button";

import DataTable from "examples/Tables/DataTable";

import Alert from 'components/CustomAlert';

import { useLazyGetSuppliersByCompanyIdQuery, useDeleteSupplierMutation } from "services/companies";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

import MDBox from "components/MDBox";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const SuppliersDetails = ({ company }) => {
  const [open, setOpen] = useState(false);

  const [
    getSuppliesByCompanyId,
    { data: suppliesList = [], isLoading: isLoadingSupplies, isSuccess: isSuccessSupplies },
  ] = useLazyGetSuppliersByCompanyIdQuery();

  const [deleteSupplier, result] = useDeleteSupplierMutation();

  const deleteRecord = (id) => {
    Loading.dots({
      svgColor: "rgba(159, 3, 3, 0.8)",
    });
    try {
      deleteSupplier({ company_id: company.id, supplier_id: id })
        .then((response) => {
          if (response.data?.status == "success") {
            Notify.success("Succefully Deleted!");
          } else {
            Notify.failure("Something went wrong, Please try again!");
          }
        })
        .catch((error) => {
          console.log(error);
          Notify.failure("Something went wrong, Please try again!");
        });
    } catch (error) {
      console.log(error);
      Notify.failure("Something went wrong, Please try again!");
    } finally {
      Loading.remove(500);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);

    getSuppliesByCompanyId(company.id);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    isLoadingSupplies
      ? Loading.dots({
          svgColor: "rgba(159, 3, 3, 0.8)",
        })
      : Loading.remove(500);
  }, [isLoadingSupplies]);

  console.log(suppliesList);

  return (
    <>
      <Tooltip title="View Suppliers" placement="top">
        <MDButton
          variant="contained"
          size="small"
          color="secondary"
          sx={{
            ml: 1,
            color: "#fff",
            fontSize: "10px!important",
            fontWeight: "500",
            letterSpacing: '0.5px'
          }}
          onClick={handleClickOpen}
        >
          Trade References
        </MDButton>
      </Tooltip>
      <BootstrapDialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        maxWidth="lg"
        sx={{
          "& .MuiPaper-root": {
            width: "1000px",
          },
        }}
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2, color: "#4b4b4f" }} id="customized-dialog-title">
          Suppliers of {company.name}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {suppliesList.length > 0 ? (
            <DataTable
              columns={[
                {
                  Header: "Id",
                  accessor: "id",
                },
                {
                  Header: "Name",
                  accessor: "contact_name",
                },
                // {
                //   Header: "Date of birth",
                //   accessor: "dob",
                // },
                {
                  Header: "Email",
                  accessor: "email",
                },
                {
                  Header: "Phone",
                  accessor: "phone",
                },
                {
                  Header: "Average Monthly Spend",
                  accessor: "average_monthly_spend",
                },
                {
                  Header: "Actions",
                  accessor: "actions",
                  disableSortBy: true,
                  disableFilters: true,
                  show: true,
                  align: "right",
                  // eslint-disable-next-line react/display-name
                  Cell: ({ row: { original } }) => (
                    <div className="actions-right">
                      {suppliesList.length > 1 ? (
                        <Tooltip title="Delete" sx={{ ml: 1 }} placement="top">
                          <IconButton
                            aria-label="view"
                            size="small"
                            onClick={() =>
                              Confirm.show(
                                `Please Confirm`,
                                "Are you sure you want to delete this supplier?",
                                "Yes",
                                "No",
                                () => {
                                  deleteRecord(original.id);
                                },
                                () => {},
                                {}
                              )
                            }
                          >
                            <CloseIcon color="error" fontSize="inherit" />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        ""
                      )}
                    </div>
                  ),
                },
              ]}
              data={suppliesList || []}
              isSorted={true}
              entriesPerPage={false}
              showTotalEntries={false}
              hideColumnSelection={false}
              noEndBorder
              canSearch
              // actionButton={
              //   <MDButton variant="gradient" color="primary" sx={{ ml: 2 }} onClick={handleClickOpen}>
              //     Add Product
              //   </MDButton>
              // }
            />
          ) : (
            <Alert severity="warning">No Suppliers found!</Alert>
          )}
        </DialogContent>
        <DialogActions>
          <MDButton color="secondary" variant="gradient" autoFocus onClick={handleClose}>
            Cancel
          </MDButton>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

SuppliersDetails.propTypes = {
  company: PropTypes.object.isRequired, // You can also use PropTypes.element if you want to enforce a single element.
};

export default SuppliersDetails;
