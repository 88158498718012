export const fileTypeMatch = (filePath) => {
    let fileExtension = filePath.split(".").pop().toLowerCase();
        if (fileExtension == "pdf") {
          return {
            path: filePath,
            file_type: "pdf",
          };
        } else if (["jpg", "jpeg", "png"].includes(fileExtension)) {
            return{
            path: filePath,
            file_type: "img",
          };
        } else if (["doc", "docx"].includes(fileExtension)) {
          const googleDocsViewerURL = `https://docs.google.com/gview?url=${encodeURIComponent(
            process.env.REACT_APP_URL + "/" + filePath
          )}&embedded=true`;
          return{
            path: googleDocsViewerURL,
            file_type: "doc",
          };
        } else {
            return{
            path: filePath,
            file_type: "img",
          };
    }
}