import { apis } from "./base";

const ordersService = apis.injectEndpoints({
  endpoints: (builder) => ({
    getAllOrdersByCountry: builder.query({
      query: (country_id) => {
        return `/admin/orders/all/${country_id}`;
      },
      transformResponse: (response) => response.response,
      providesTags: ["Orders"],
    }),

    getAllOrdersByCompany: builder.query({
      query: (company_id) => {
        return `/admin/companies/orders/${company_id}`;
      },
      transformResponse: (response) => response.response,
      providesTags: ["Orders"],
    }),

    getOrderProductsByOrderId: builder.query({
      query: (order_id) => {
        return `/admin/orders/products/${order_id}`;
      },
      transformResponse: (response) => response.response,
      // providesTags: ["OrderProducts"],
    }),

    getOrderDetailsByOrderId: builder.query({
      query: (order_id) => {
        return `/admin/orders/id/${order_id}`;
      },
      transformResponse: (response) => response.response,
      providesTags: ["Orders"],
    }),

    changeOrderPaymentStatus: builder.mutation({
      query: ({ order_id, status, invoiceNinjaAPI }) => ({
        url: `/admin/orders/payment-status/${order_id}`,
        method: "PUT",
        body: {
          status: status,
          invoiceNinjaAPI: invoiceNinjaAPI,
        },
      }),
      // transformResponse: (response) => response.response
      invalidatesTags: ["Orders"],
    }),

    changeOrderProductDeliveryStatus: builder.mutation({
      query: ({ order_product_id, data }) => ({
        url: `/admin/orders/delivery-status/${order_product_id}`,
        method: "PUT",
        body: { ...data },
      }),
      // transformResponse: (response) => response.response
    }),

    refundOrder: builder.mutation({
      query: (data) => ({
        url: `/admin/payments/refund`,
        method: "POST",
        body: data,
      }),
      // transformResponse: (response) => response.response
      invalidatesTags: ["Orders"],
    }),

    refundHistory: builder.mutation({
      query: (data) => ({
        url: `/admin/payments/refund-history`,
        method: "POST",
        body: data,
      }),
      // transformResponse: (response) => response.response
      // invalidatesTags: ["Orders"],
    }),

    uploadPaymentReceipt: builder.mutation({
      query: ({ order_id, path }) => ({
        url: `/admin/orders/upload-payment-reciept/${order_id}`,
        method: "PUT",
        body: {
          path: path,
        },
      }),
      // transformResponse: (response) => response.response
      invalidatesTags: ["Orders"],
    }),

    deletePaymentReceipt: builder.mutation({
      query: (data) => ({
        url: `/admin/orders/delete-payment-reciept/${data.orderId}`,
        method: "POST",
        body: {
          path: data.bankRecieptPath,
        },
      }),
      transformResponse: (response) => response.response,
      invalidatesTags: ["Orders"],
    }),

    updatePONumber: builder.mutation({
      query: ({ order_id, data }) => ({
        url: `/admin/orders/edit-po-number/${order_id}`,
        method: "PUT",
        body: data,
      }),
      // transformResponse: (response) => response.response
      invalidatesTags: ["Orders"],
    }),

    updateExpiryDate: builder.mutation({
      query: ({ orderProductId, data }) => ({
        url: `/admin/orders/edit-expiry-date/${orderProductId}`,
        method: "PUT",
        body: data,
      }),
      // transformResponse: (response) => response.response
      invalidatesTags: ["Orders"],
    }),

    resendOrderItemDeliveredMail: builder.mutation({
      query: ({ order_product_id }) => ({
        url: `/admin/orders/resend-email/delivered/${order_product_id}`,
        method: "POST",
        body: {},
      }),
      // transformResponse: (response) => response.response
    }),

    resendOrderPlacedMail: builder.mutation({
      query: ({ orderId }) => ({
        url: `/admin/orders/resend-email/order-placed/${orderId}`,
        method: "POST",
        body: {},
      }),
      // transformResponse: (response) => response.response
    }),

    // getRefundHistoryOrderId: builder.query({
    //   query: (order_id) => {
    //     return `/admin/payments/refund-history/${order_id}`;
    //   },
    //   transformResponse: (response) => response.response,
    //   // providesTags: ["OrderProducts"],
    // }),

    getQuotation: builder.mutation({
      query: (data) => ({
        url: `/admin/orders/quotation`,
        method: "POST",
        body: data,
      }),
      // transformResponse: (response) => response.response
      // invalidatesTags: ["Orders"],
    }),

    createOrder: builder.mutation({
      query: (data) => ({
        url: `/admin/orders/new`,
        method: "POST",
        body: data,
      }),
      // transformResponse: (response) => response.response
      invalidatesTags: ["Orders"],
    }),

    cancelOrder: builder.mutation({
      query: ({ orderId, data }) => ({
        url: `/admin/orders/cancel/${orderId}`,
        method: "POST",
        body: data,
      }),
      // transformResponse: (response) => response.response
      invalidatesTags: ["Orders"],
    }),
  }),
});

export const {
  useGetAllOrdersByCountryQuery,
  useLazyGetAllOrdersByCountryQuery,
  useLazyGetAllOrdersByCompanyQuery,
  useGetOrderProductsByOrderIdQuery,
  useGetOrderDetailsByOrderIdQuery,
  useLazyGetOrderDetailsByOrderIdQuery,
  useLazyGetOrderProductsByOrderIdQuery,
  useChangeOrderProductDeliveryStatusMutation,
  useChangeOrderPaymentStatusMutation,
  useRefundOrderMutation,
  useRefundHistoryMutation,
  useUpdatePONumberMutation,
  useUpdateExpiryDateMutation,
  useUploadPaymentReceiptMutation,
  useDeletePaymentReceiptMutation,
  useResendOrderItemDeliveredMailMutation,
  useResendOrderPlacedMailMutation,
  useGetQuotationMutation,
  useCreateOrderMutation,
  useCancelOrderMutation
  // useLazyGetRefundHistoryOrderIdQuery
} = ordersService;
