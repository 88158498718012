/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from 'react-redux';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Notify } from "notiflix/build/notiflix-notify-aio";
import { Confirm } from "notiflix/build/notiflix-confirm-aio";

import { styled } from "@mui/material/styles";

import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";

import { convertToHTML } from "draft-convert";

import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Block } from "notiflix/build/notiflix-block-aio";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";

import TextField from "@mui/material/TextField";

import Box from "@mui/material/Box";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { AddBox } from "@mui/icons-material";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Typography from "@mui/material/Typography";

import ControlledTextInput from "components/FormInputs/ControlledTextInput";
import ControlledDateTime from "components/FormInputs/ControlledDateTime";
import ControlledCheckbox from "components/FormInputs/ControlledCheckbox";
import ControlledSelect from "components/FormInputs/ControlledSelect";
import CustomInputs from "components/CustomInputs";
import { CustomSelect } from "components/CustomSelect";

//components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
import MDBadge from "components/MDBadge";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import Tooltip from "@mui/material/Tooltip";

import Edit from "@mui/icons-material/Edit";
import Close from "@mui/icons-material/Close";

import RestoreFromTrashRoundedIcon from '@mui/icons-material/RestoreFromTrashRounded';

import InputAdornment from "@mui/material/InputAdornment";

import TinyMCEEditor from 'components/TinyMCEEditor';

import EditTemplate from './EditTemplate'

import Alert from "components/CustomAlert";

import { emailTemplateTypes, tables } from 'utils/constants';

import {
  globalCountry
} from "store/entities/countries";

// rtk queries
import {
  useGetAllCountriesQuery,
  useGetAllInactiveCountriesQuery,
  useLazyGetAllStatesByCountryIdQuery,
  useUpdateCountryMutation,
  useDeleteCountryMutation,
  useGetAllUrlsByCountryIdQuery,
} from "services/countries";

import {
  useGetAllDefaultEmailTemplatesQuery,
  useGetAllEmailTemplatesByCountryQuery,
  useDeleteEmailTemplateMutation
} from "services/email_templates";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function EmailTemplates() {

  const selectedCountry = useSelector(globalCountry);

  const { data: emailTemplates = [], isLoading: isEmailTemplatesLoading, isSuccess: isEmailTemplatesSuccess } = useGetAllEmailTemplatesByCountryQuery(selectedCountry.id);

  const { data: defaultEmailTemplates = [], isLoading: isDefaultEmailTemplatesLoading, isSuccess: isDefaultEmailTemplatesSuccess } = useGetAllDefaultEmailTemplatesQuery();

  const [deleteEmailTemplate, result] = useDeleteEmailTemplateMutation();

  const tableData = useMemo(() => {
    if(defaultEmailTemplates.length > 0) {
      if(emailTemplates.length > 0) {
        let data = defaultEmailTemplates.map((prop, key) => {
          let relatedEmailTemplate = emailTemplates.filter(item => item.type == prop.type);
          if(relatedEmailTemplate.length > 0) {
            return {
              body: relatedEmailTemplate[0].body,
              country_id: relatedEmailTemplate[0].country_id,
              id: relatedEmailTemplate[0].id,
              subject: relatedEmailTemplate[0].subject,
              type: relatedEmailTemplate[0].type,
              recipients: relatedEmailTemplate[0].email_recipients,
            }
          } else {
            return {
              body: prop.body,
              country_id: prop.country_id,
              id: prop.id,
              subject: prop.subject,
              type: prop.type
            }
          }
        });
        return data;
      } else {
        return defaultEmailTemplates;
      }
    } else {
      return [];
    }
  }, [defaultEmailTemplates, emailTemplates]);

  useEffect(() => {
    isEmailTemplatesLoading || isDefaultEmailTemplatesLoading ? Loading.dots({ svgColor: "rgba(159, 3, 3, 0.8)", }) : Loading.remove(500);
  }, [isEmailTemplatesLoading, isDefaultEmailTemplatesLoading]);

  const deleteRecord = async (id) => {
    Loading.dots({
      svgColor: "rgba(159, 3, 3, 0.8)",
    });
    try {
      deleteEmailTemplate(id)
        .then((response) => {
          Notify.success("Succefully Reset!");
        })
        .catch((error) => console.log(error));
    } catch (error) {
      console.log(error);
    } finally {
      Loading.remove(500);
    }
  };

  return (
    <Card>
      <CardHeader
        // avatar={<AccountCircleIcon fontSize="large" color="info" />}
        title={<MDTypography color="info">Email Templates</MDTypography>}
        // action={
        //   <MDButton variant="gradient" color="dark">
        //     add
        //   </MDButton>
        // }
      />
      <CardContent>
        <br />
        <GridContainer>
          {/* <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
            <CustomSelect
                label="Select Template type *"
                inputProps={{
                  id: "template_type",
                  name: "template_type",
                }}
                value={selectedType}
                onChange={(e) => handleEmailTypeChange(e.target.value)}
                sx={{
                  "& .MuiInputBase-root .MuiSelect-select": {
                    height: "44px",
                    // color: "#fff",
                    // fontWeight: "400",
                  },
                  "& .MuiFormLabel-root": {
                    backgroundColor: "#fff",
                    // color: "#e0e1e5",
                    padding: "0 5px",
                  },
                }}
                // helperText={errors.country_id}
                // error={errors.country_id}
              >
                <MenuItem disabled>Template Type</MenuItem>
                {emailTemplateTypes.map((prop, key) => (
                  <MenuItem key={key} value={prop}>
                    {prop}
                  </MenuItem>
                ))}
              </CustomSelect>
          </GridItem> */}
        </GridContainer>
        {tableData.length > 0 ? (
          <DataTable
            id={tables.EMAIL_TEMPLATES}
            columns={[
              { Header: "Type", accessor: "type", width: "45%", align: "left" },
              { Header: "Subject", accessor: "subject", align: "left" },
              {
                Header: "action",
                accessor: "action",
                align: "right",
                disableSortBy: true,
                disableFilters: true,
                show: true,
                // eslint-disable-next-line react/display-name
                Cell: ({ row: { original } }) => (
                  <div className="actions-right">
                    <EditTemplate initData={original} selectedCountryId={selectedCountry.id} />
                    {original.country_id != 0 ? <Tooltip title="Reset to default" sx={{ ml: 1 }} placement="top">
                      <IconButton
                        aria-label="view"
                        size="small"
                        onClick={() => {
                          Confirm.show(
                            `Please Confirm`,
                            "Are you sure you want to delete this template?",
                            "Yes",
                            "No",
                            () => {
                              deleteRecord(original.id);
                            },
                            () => {},
                            {}
                          );
                        }}
                      >
                        <RestoreFromTrashRoundedIcon color="info" fontSize="inherit" />
                      </IconButton>
                    </Tooltip> : ""}
                  </div>
                ),
              },
            ]}
            data={tableData}
            isSorted={true}
            entriesPerPage={false}
            showTotalEntries={false}
            hideColumnSelection={false}
            noEndBorder
            canSearch
            // actionButton={
            //   <MDButton variant="gradient" color="primary" sx={{ ml: 2 }} onClick={handleClickOpen}>
            //     Add New
            //   </MDButton>
            // }
          />
        ) : (
          <Alert severity="warning">No templates found!</Alert>
        )}
      </CardContent>
    </Card>
  );
}

export default EmailTemplates;
