//layouts
import Dashboard from "layouts/dashboard";
import Billing from "layouts/billing";
import Notifications from "layouts/notifications";
import SignIn from "layouts/authentication/sign-in";

import Inventory from "views/Inventory";
import Categories from "views/Categories";
import Companies from "views/Companies";
import Orders from "views/Orders";
import Countries from "views/Countries";

// @mui icons
import Icon from "@mui/material/Icon";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

const routes = [
  // {
  //   type: "collapse",
  //   name: "Dashboard",
  //   key: "dashboard",
  //   icon: <Icon fontSize="small">dashboard</Icon>,
  //   route: "/dashboard",
  //   component: <Dashboard />,
  //   visible: true,
  // },
  {
    type: "collapse",
    name: "Inventory",
    key: "inventory",
    icon: <Icon fontSize="small">inventory</Icon>,
    route: "/user/inventory",
    component: <Inventory />,
    visible: true,
  },
  {
    type: "collapse",
    name: "Categories",
    key: "categories",
    icon: <Icon fontSize="small">inventory</Icon>,
    route: "/user/categories",
    component: <Categories />,
    visible: true,
  },
  {
    type: "collapse",
    name: "Companies",
    key: "companies",
    icon: <Icon fontSize="small">storefront</Icon>,
    route: "/user/companies",
    component: <Companies />,
    visible: true,
  },
  {
    type: "collapse",
    name: "Orders",
    key: "orders",
    icon: <AttachMoneyIcon fontSize="small" />,
    route: "/user/orders",
    component: <Orders />,
    visible: true,
  },
  {
    type: "collapse",
    name: "Countries",
    key: "countries",
    icon: <Icon fontSize="small">public</Icon>,
    route: "/user/countries",
    component: <Countries />,
    visible: true,
  },
  {
    type: "collapse",
    name: "Billing",
    key: "billing",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/user/billing",
    component: <Billing />,
    visible: false,
  },
  {
    type: "collapse",
    name: "Notifications",
    key: "notifications",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/user/notifications",
    component: <Notifications />,
    visible: false,
  },
  {
    type: "collapse",
    name: "Login",
    key: "login",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/auth/login",
    component: <SignIn />,
    visible: false,
  },
  // {
  //   type: "collapse",
  //   name: "Sign Up",
  //   key: "sign-up",
  //   icon: <Icon fontSize="small">assignment</Icon>,
  //   route: "/authentication/sign-up",
  //   component: <SignUp />,
  // },
];

export default routes;
