import * as yup from "yup";

export const inventorySchema = (type) => yup.object().shape({
  name: yup.string().required().label("Name"),
  parentName: type == 'variable' ? yup.string().required().label("Parent Product Name") : yup.string().notRequired(),
  price: yup.number().min(0).default("").label("Reseller Price"),
  retailPrice: yup.number().min(0).default("").label("Recommended Retail Price"),
  inStock: yup.bool().label("Is in stock"),
  isFeaturedProduct: yup.bool().label("Is feature product"),
  isDigitalGood: yup.bool().label("Is digital good"),
  attributes: yup.array().of(
    yup.object().shape({
      attribute: yup.string().required("Name is required"),
      option: yup.string().required("Option is required"),
    })
  ),
  categories: yup.array().of(yup.number()).required().min(1).label("One or more"),
  // licenseDuration: yup.number().positive().default(12).label("License Duration (no of months)"),
  licenseDuration: yup
    .number()
    .when(["isDigitalGood"], {
      is: (isDigitalGood) => isDigitalGood === false,
      then: () => yup.number().notRequired(),
      otherwise: () => yup.number().required().min(0).integer(),
    })
    .default(0)
    .label("License Duration (no of months)"),
  averageRating: yup.number().min(0).max(5).default(0).label("Average rating"),
  ratingCount: yup.number().min(0).default(0).label("Rating count"),
  // availability: yup.number().min(0).default("").label("Availability"),
  // terms_and_conditions: yup.string().label("Terms and Condition"),
});

export const digitalKeysSchema = yup.object().shape({
  digitalKeys: yup.array().of(yup.object().shape({
    key: yup.string().required("Key is required"),
  })).default("").label("Digital keys"),
  // availability: yup.number().min(0).default("").label("Availability"),
  // terms_and_conditions: yup.string().label("Terms and Condition"),
});

export const addInventorySchema = (type, isDigital) =>
  yup.object().shape({
    name: yup.string().required().label("Product Name"),
    categories: yup.array().of(yup.number()).required().min(1).label("One or more"),
    // price: yup.number().min(1).default("").label("Reseller Price"),
    // retailPrice: yup.number().min(1).default("").label("Recommended Retail Price"),
    inStock: yup.bool().label("Is in stock"),
    isFeaturedProduct: yup.bool().label("Is feature product"),
    isDigitalGood: yup.bool().label("Is digital good"),
    averageRating: yup.number().min(0).max(5).default("").label("Average rating"),
    ratingCount: yup.number().min(0).default("").label("Rating count"),
    // shortDescription: yup.string().required().label("Short description"),
    sku: yup.string().label("SKU"),
    type: yup.string().required().label("Product type"),
    price:
      type == "simple"
        ? yup.number().required().min(1).default("").label("Reseller Price")
        : yup.number().notRequired().default("").label("Reseller Price"),
    retailPrice:
      type == "simple"
        ? yup
            .number()
            .required()
            .min(yup.ref("price"), "Retail price must be larger than the price")
            .default("")
            .label("Reseller Price")
        : yup.number().notRequired().default("").label("Recommended Retail Price"),
    digitalKeys: yup.array().of(yup.object().shape({
        key: isDigital ? yup.string().required("Key is required") : yup.string(),
    })).default("").label("Digital keys"),
    licenseDuration: yup
      .number()
      .when(["isDigitalGood"], {
        is: (isDigitalGood) => isDigitalGood === false,
        then: () => yup.number().notRequired(),
        otherwise: () => yup.number().required().min(0).integer(),
      })
      .default(0)
      .label("License Duration (no of months)"),
  });

export const addVariationSchema = (isDigital) => yup.object().shape({
  name: yup.string().required().label("Variation Name"),
  // categories: yup.array().of(yup.number()).required().min(1).label("One or more"),
  price: yup.number().min(1).default("").label("Reseller Price"),
  retailPrice: yup
    .number()
    .min(yup.ref("price"), "Retail price must be larger than the price")
    .default("")
    .label("Recommended Retail Price"),
  inStock: yup.bool().label("Is in stock"),
  // isFeaturedProduct: yup.bool().label("Is feature product"),
  isDigitalGood: yup.bool().label("Is digital good"),
  // averageRating: yup.number().min(0).default("").label("Average rating"),
  // ratingCount: yup.number().min(0).default("").label("Rating count"),
  shortDescription: yup.string().label("Short description"),
  sku: yup.string().label("SKU"),
  digitalKeys: yup.array().of(yup.object().shape({
      key: isDigital ? yup.string().required("Key is required") : yup.string(),
  })).default("").label("Digital keys"),
  attributes: yup.array().of(
    yup.object().shape({
      attribute: yup.string().required("Name is required"),
      option: yup.string().required("Option is required"),
    })
  ),
  licenseDuration: yup
    .number()
    .when(["isDigitalGood"], {
      is: (isDigitalGood) => isDigitalGood === false,
      then: () => yup.number().notRequired(),
      otherwise: () => yup.number().required().min(0).integer(),
    })
    .default(0)
    .label("License Duration (no of months)"),
});
// .when(['price', 'retailPrice'], (price, retailPrice, schema) => {
//   if (price && retailPrice) {
//     return schema.test({
//       test: price < retailPrice,
//       message: 'Price must be less than retail price',
//     });
//   }
//   return schema;
// });

// const averageRating: number = req.body.averageRating ?? 0;
// const description: string = req.body.description ?? "";
// const shortDescription: string = req.body.shortDescription ?? "";
// const attributes: { attribute: string; option: string }[] =
//   req.body.attributes ?? [];
// const categories: number[] = req.body.categories ?? [];
// const name: string = req.body.name ?? "";
// const ratingCount: number = req.body.ratingCount ?? 0;
// const sku: string = req.body.sku ?? "";
