import * as yup from "yup";

export const countriesSchema = yup.object().shape({
  subject: yup.string().required().default("").label("Email Subject"),
  recipients: yup.array().of(
    yup.object().shape({
      email: yup.string().email().required().default("").label("Email"),
      isBcc: yup.bool().default(false),
    })
  ),
});

// export const emailRecipientsSchema = yup.object().shape({
//   recipients: yup.array().of(
//     yup.object().shape({
//       email: yup.string().email().required().default("").label("Email"),
//       is_bcc: yup.bool().default(false),
//     })
//   ),
// });