/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from "react";

import { Importer, ImporterField } from "react-csv-importer";

import { useFieldArray } from "react-hook-form";

import { styled } from "@mui/material/styles";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import MDButton from "components/MDButton";

import { AddBox } from "@mui/icons-material";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";

import Typography from "@mui/material/Typography";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import ControlledTextInput from "components/FormInputs/ControlledTextInput";
import CustomInputs from "components/CustomInputs";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import Box from "@mui/material/Box";

// theme CSS for React CSV Importer
import "react-csv-importer/dist/index.css";

import AvailabilityDialog from "../AvailabilityDialog";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function AddDigitalKeys({ control, availabilityFields, setAvailabilityFields }) {

  const [anchorEl, setAnchorEl] = useState(null);

  const [openDialog, setOpenDialog] = useState(false);

  const [openAvailabilityDialog, setOpenAvailabilityDialog] = useState(false);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDialogClickOpen = () => {
    setOpenDialog(true);
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const { fields, append, remove } = useFieldArray({ name: "digitalKeys", control });

  const handleAvailabilityFields = (index, e) => {
    // let data = [...dynamicFields];
    // data[index] = e.target.value;
    // setAvailabilityFields(data);
  };

  const addEmptyFields = () => {
    append({ key: "" });
  };

  const addFields = (param) => {
    append({ key: param });
  };

  const addAvailability = (availability) => {
    let newField = "Pending";
    setAvailabilityFields([...availabilityFields, ...Array(availability).fill(newField)]);
  };

  const removeFields = (index) => {
    remove(index);
  };

  const removeAvailabilityFields = (index) => {
    let data = [...availabilityFields];
    data.splice(index, 1);
    setAvailabilityFields(data);
  };

  const onAvailabilitySubmit = (data) => {
    addAvailability(data.availability);
    setOpenAvailabilityDialog(false);
  };

  return (
    <>
      <GridItem xs={12} sm={12}>
          <Box
            px={2}
            pb={2}
            p1={1}
            sx={{ border: "0.0625rem solid #f0f2f5", borderRadius: "0.375rem" }}
          >
            <Box display="flex" justifyContent="space-between" my={1}>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 500,
                  color: "#3f3f3f",
                }}
              >
                Digital Keys
              </Typography>
              <div>
                <MDButton
                  color="info"
                  variant="gradient"
                  iconOnly
                  id="keys-button"
                  aria-controls={open ? "keys-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  <AddBox sx={{}} />
                </MDButton>
                <Menu
                  id="keys-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "keys-button",
                  }}
                >
                  <MenuItem onClick={handleDialogClickOpen}>Import CSV</MenuItem>
                  <MenuItem onClick={addEmptyFields}>Add New Field</MenuItem>
                  <MenuItem onClick={() => setOpenAvailabilityDialog(true)}>
                    Enter Availability Manually
                  </MenuItem>
                </Menu>
              </div>
            </Box>
            <br />
            {fields.map((input, index) => {
              return (
                <GridContainer key={index}>
                  <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                    <ControlledTextInput
                      name={`digitalKeys.${index}.key`}
                      control={control}
                      label="Digital Key *"
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={1} lg={1} xl={1}>
                    <MDButton
                      color="warning"
                      variant="gradient"
                      iconOnly
                      onClick={() => removeFields(index)}
                    >
                      <IndeterminateCheckBoxIcon />
                    </MDButton>
                  </GridItem>
                </GridContainer>
              );
            })}
            {availabilityFields.map((input, index) => {
              return (
                <GridContainer key={index}>
                  <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                    <CustomInputs
                      name="pending_digital_key"
                      id="pending_digital_key"
                      label="Digital Key (Manual)"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        readOnly: true,
                      }}
                      value={input}
                      onChange={(e) => handleAvailabilityFields(index, e)}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={1} lg={1} xl={1}>
                    <MDButton
                      color="warning"
                      variant="gradient"
                      iconOnly
                      onClick={() => removeAvailabilityFields(index)}
                    >
                      <IndeterminateCheckBoxIcon />
                    </MDButton>
                  </GridItem>
                </GridContainer>
              );
            })}
            <AvailabilityDialog
              openDialog={openAvailabilityDialog}
              closeDialog={setOpenAvailabilityDialog}
              onAvailabilitySubmit={onAvailabilitySubmit}
            />
          </Box>
        </GridItem>

      <GridItem xs={12} sm={12}>
        <br />
      </GridItem>
      {/* ---------------------- digital keys csv import feature ------------------------ */}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        maxWidth="xl"
        sx={{
          "& .MuiPaper-root": {
            width: "900px",
          },
        }}
        open={openDialog}
      >
        <DialogTitle sx={{ m: 0, p: 2, color: "#3f3f3f" }} id="customized-dialog-title">
          Import Digital Keys
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleDialogClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Importer
            chunkSize={10000} // optional, internal parsing chunk size in bytes
            assumeNoHeaders={false} // optional, keeps "data has headers" checkbox off by default
            restartable={false} // optional, lets user choose to upload another file when import is complete
            onStart={({ file, fields }) => {
              // optional, invoked when user has mapped columns and started import
              console.log("starting import of file", file, "with fields", fields);
            }}
            processChunk={async (rows) => {
              // required, receives a list of parsed objects based on defined fields and user column mapping;
              // may be called several times if file is large
              // (if this callback returns a promise, the widget will wait for it before parsing more data)
              console.log("received batch of rows", rows);
              let newKeys = [];
              rows.map((key) => newKeys.push(key.digital_keys));
              for(let key of newKeys) {
                addFields(key);
              }

              // mock timeout to simulate processing
              await new Promise((resolve) => setTimeout(resolve, 500));
            }}
            onComplete={({ file, fields }) => {
              // optional, invoked right after import is done (but user did not dismiss/reset the widget yet)
              console.log("finished import of file", file, "with fields", fields);
            }}
            onClose={() => {
              // optional, invoked when import is done and user clicked "Finish"
              // (if this is not specified, the widget lets the user upload another file)
              console.log("importer dismissed");
              handleDialogClose();
            }}
          >
            <ImporterField name="digital_keys" label="Digital Keys" />
          </Importer>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
}

export default AddDigitalKeys;
