import CustomCheckbox from "components/CustomCheckbox";
import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";

const ControlledCheckbox = ({ label, name, control, inputProps, ...rest }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange, onBlur } }) => (
        <CustomCheckbox
          {...rest}
          label={label}
          checked={Boolean(value)}
          onChange={onChange}
          onBlur={onBlur}
        />
      )}
    />
  );
};

ControlledCheckbox.propTypes = {
  name: PropTypes.string,
  inputProps: PropTypes.object,
  control: PropTypes.object,
  label: PropTypes.string,
};

export default ControlledCheckbox;
