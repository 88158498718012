/* eslint-disable react/prop-types */
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  Tooltip,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// react-table components
import {
  useAsyncDebounce,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import Button from "components/MDButton";
import MDInput from "components/MDInput";
import MDPagination from "components/MDPagination";
import MDTypography from "components/MDTypography";
import DataTableBodyCell from "./DataTableBodyCell";
// Material Dashboard 2 PRO React example components
import DataTableHeadCell from "./DataTableHeadCell";

import { actions as tableActions } from "store/ui/table";

import borders from "assets/theme/base/borders";

import { tables } from 'utils/constants'

const { borderRadius } = borders;

function DataTable({
  id,
  headerActions,
  canSearch,
  showTotalEntries,
  columns,
  data,
  pagination,
  isSorted,
  noEndBorder,
  refetch,
  customTableHooks,
  stateReducer,
  Toolbar,
  handler,
  hideColumnSelection,
  enableScrollOnTable,
  unlimitedRows,
  disableTableHeader,
  transparent,
  sx,
  actionButton,
  searchOnly,
  showPageSize,
}) {
  const dispatch = useDispatch();

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
      stateReducer,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    ...customTableHooks
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    selectedFlatRows,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state,
    allColumns,
  } = tableInstance;

  const {
    pageIndex,
    pageSize,
    globalFilter,
    columnResizing,
    expanded,
    filters,
    groupBy,
    hiddenColumns,

    sortBy,
  } = state;

  const [menu, setMenu] = React.useState(null);

  const entries = [5, 10, 20, 25, 50, 100].map((el) => el.toString());

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);

  // Set the entries per page value based on the select value
  const setEntriesPerPage = (value) => setPageSize(value);

  useEffect(() => {
    if (unlimitedRows) {
      setPageSize(1000000);
    }
  }, [unlimitedRows]);

  // Render the paginations
  const renderPagination = pageOptions.map((option) => (
    <MDPagination
      item
      key={option}
      onClick={() => gotoPage(Number(option))}
      active={pageIndex === option}
    >
      {option + 1}
    </MDPagination>
  ));

  // Handler for the input to set the pagination index
  const handleInputPagination = ({ target: { value } }) =>
    value > pageOptions.length || value < 0 ? gotoPage(0) : gotoPage(Number(value));

  // Customized page options starting from 1
  const customizedPageOptions = pageOptions.map((option) => option + 1);

  // Setting value for the pagination input
  const handleInputPaginationValue = ({ target: value }) => gotoPage(Number(value.value - 1));

  // Search input value state
  const [search, setSearch] = useState(globalFilter);

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 100);

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  // Setting the entries starting point
  const entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

  // Setting the entries ending point
  let entriesEnd;

  if (pageIndex === 0) {
    entriesEnd = pageSize;
  } else if (pageIndex === pageOptions.length - 1) {
    entriesEnd = rows.length;
  } else {
    entriesEnd = pageSize * (pageIndex + 1);
  }

  useEffect(() => {
    if (id) {
      if (typeof handler === "function") return handler(state);
      if (handler !== false)
        dispatch(
          tableActions.add({
            [id]: state
          })
        );
    }
  }, [
    columnResizing,
    expanded,
    filters,
    groupBy,
    hiddenColumns,
    pageSize,
    sortBy,
    pageIndex
  ]);

  // newly added scripts... not tested yet -- partially tested

  return (
    <TableContainer
      sx={{
        boxShadow: "none",
        overflowX: enableScrollOnTable ? "initial" : "auto",
      }}
      style={{ backgroundColor: transparent ? "transparent" : "#ffffff" }}
      id={id}
    >
      {Toolbar && selectedFlatRows?.length >= 1 && (
        <Toolbar numSelected={selectedFlatRows.length} selectedFlatRows={selectedFlatRows} />
      )}
      {canSearch ? (
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" p="20px 24px">
          <MDBox>{headerActions}</MDBox>
          <MDBox display="flex" width={searchOnly ? "100%" : "auto"}>
            {refetch && (
              <Tooltip title="Refresh Data">
                <IconButton size="small" onClick={(e) => refetch()} color="info">
                  <RefreshIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
            <MDBox width={searchOnly ? "100%" : "12rem"} fullWidth={searchOnly} ml="auto">
              <MDInput
                style={{ marginLeft: "4px", minHeight: "1.7em" }}
                placeholder="Search..."
                value={search}
                size="small"
                fullWidth
                sx={{
                  "& .MuiInputBase-root input": {
                    height: "1.7em",
                  },
                }}
                onChange={({ currentTarget }) => {
                  setSearch(search);
                  onSearchChange(currentTarget.value);
                }}
              />
            </MDBox>

            {!hideColumnSelection && (
              <>
                <Button
                  variant="outlined"
                  size="small"
                  color="info"
                  onClick={openMenu}
                  sx={{ ml: 3 }}
                  disableRipple
                  style={{ boxShadow: "none", minHeight: "1.7em" }}
                >
                  Columns&nbsp;
                  <KeyboardArrowDown />
                </Button>

                <MenuList dense>
                  <Menu
                    anchorEl={menu}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    open={Boolean(menu)}
                    onClose={closeMenu}
                    keepMounted
                  >
                    {allColumns.map(
                      (column) =>
                        !column.show && (
                          <MenuItem onClick={closeMenu} key={column.id} sx={{ padding: 0 }}>
                            <FormControlLabel
                              sx={{
                                ml: 0,
                              }}
                              control={<Checkbox {...column.getToggleHiddenProps()} />}
                              label={column.Header}
                            />
                          </MenuItem>
                        )
                    )}
                  </Menu>
                </MenuList>
              </>
            )}
            {actionButton && <MDBox>{actionButton}</MDBox>}
          </MDBox>
        </MDBox>
      ) : null}
      <div style={{ overflowX: enableScrollOnTable ? "scroll" : "auto" }}>
        <Table
          sx={sx}
          {...getTableProps()}
          size={"small"}
          style={{ marginBottom: enableScrollOnTable ? "25px" : "0" }}
        >
          {!disableTableHeader && (
            <MDBox component="thead">
              {headerGroups.map((headerGroup, key) => (
                <TableRow key={key} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, key) => (
                    <DataTableHeadCell
                      key={key}
                      {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                      hide={column.hide === true}
                      width={column.width ? column.width : "auto"}
                      align={column.align ? column.align : "left"}
                      sorted={column.canSort ? setSortedValue(column) : false}
                    >
                      {column.render("Header")}
                    </DataTableHeadCell>
                  ))}
                </TableRow>
              ))}
            </MDBox>
          )}
          <TableBody {...getTableBodyProps()}>
            {page.map((row, key) => {
              prepareRow(row);
              return (
                <TableRow key={key} {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    let cellStyles = {};

                    return (
                      <DataTableBodyCell
                        key={key}
                        styles={cellStyles}
                        hide={cell.column.hide === true}
                        noBorder={noEndBorder && rows.length - 1 === key}
                        align={cell.column.align ? cell.column.align : "left"}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </DataTableBodyCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>

      {!unlimitedRows && (
        <MDBox
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          p={!showTotalEntries && "20px 24px"}
        >
          {showPageSize && pageSize && (
            <MDBox display="flex" alignItems="center">
              <Autocomplete
                disableClearable
                value={pageSize.toString()}
                options={entries}
                getOptionLabel={(option) => `${option.toString()} rows`}
                onChange={(event, newValue) => {
                  setEntriesPerPage(parseInt(newValue, 10));
                }}
                size="small"
                sx={{ width: "6.3rem" }}
                renderInput={(params) => <MDInput {...params} />}
              />
            </MDBox>
          )}
          {showTotalEntries && (
            <MDBox mb={{ xs: 3, sm: 0 }}>
              <MDTypography variant="button" color="secondary" fontWeight="regular">
                Showing {entriesStart} to {entriesEnd} of {rows.length} entries
              </MDTypography>
            </MDBox>
          )}
          {pageOptions.length > 1 && (
            <MDPagination
              variant={pagination.variant ? pagination.variant : "gradient"}
              color={pagination.color ? pagination.color : "info"}
            >
              {canPreviousPage && (
                <MDPagination item onClick={() => previousPage()}>
                  <ChevronLeft sx={{ fontWeight: "bold" }} />
                </MDPagination>
              )}
              {renderPagination.length > 6 ? (
                <MDBox width="5rem" mx={1}>
                  <MDInput
                    inputProps={{
                      type: "number",
                      min: 1,
                      max: customizedPageOptions.length,
                    }}
                    value={customizedPageOptions[pageIndex]}
                    onChange={(handleInputPagination, handleInputPaginationValue)}
                  />
                </MDBox>
              ) : (
                renderPagination
              )}
              {canNextPage && (
                <MDPagination item onClick={() => nextPage()}>
                  <ChevronRight sx={{ fontWeight: "bold" }} />
                </MDPagination>
              )}
            </MDPagination>
          )}
        </MDBox>
      )}
    </TableContainer>
  );
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
  customTableHooks: [],
  hideColumnSelection: false,
  enableScrollOnTable: false,
  unlimitedRows: false,
  disableTableHeader: false,
  searchOnly: false,
  showPageSize: true,
};

// Typechecking props for the DataTable
DataTable.propTypes = {
  id: PropTypes.string,
  headerActions: PropTypes.object,
  enableScrollOnTable: PropTypes.bool,
  unlimitedRows: PropTypes.bool,
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
  isLoading: PropTypes.bool,
  refetch: PropTypes.func,
  Toolbar: PropTypes.func,
  customTableHooks: PropTypes.arrayOf(PropTypes.func),
  stateReducer: PropTypes.func,
  handler: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  disableTableHeader: PropTypes.bool,
  searchOnly: PropTypes.bool,
  showPageSize: PropTypes.bool,
};

export default DataTable;
